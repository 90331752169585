/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  float8: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  point: { input: any; output: any; }
  polygon: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type Api_Accept_Invite_Input = {
  inviteCode: Scalars['String']['input'];
};

export type Api_Accept_Invite_Output = {
  __typename?: 'api_accept_invite_output';
  ok: Scalars['Boolean']['output'];
};

export type Api_Login_User_Input = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Api_Login_User_Output = {
  __typename?: 'api_login_user_output';
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type Api_Sign_Up_User_Input = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type Api_Sign_Up_User_Output = {
  __typename?: 'api_sign_up_user_output';
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "chargeable_units" */
export type Chargeable_Units = {
  __typename?: 'chargeable_units';
  comment: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "chargeable_units". All fields are combined with a logical 'AND'. */
export type Chargeable_Units_Bool_Exp = {
  _and?: InputMaybe<Array<Chargeable_Units_Bool_Exp>>;
  _not?: InputMaybe<Chargeable_Units_Bool_Exp>;
  _or?: InputMaybe<Array<Chargeable_Units_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Chargeable_Units_Enum {
  Acre = 'acre',
  Bag = 'bag',
  Bales = 'bales',
  /** °C */
  Celsius = 'celsius',
  CubicLitre = 'cubic_litre',
  CubicMetre = 'cubic_metre',
  Day = 'day',
  FixedCost = 'fixed_cost',
  /** gram */
  G = 'g',
  Gallon = 'gallon',
  Head = 'head',
  Hectare = 'hectare',
  Hour = 'hour',
  Kg = 'kg',
  /** kg/acre */
  KgPerAcre = 'kg_per_acre',
  /** kg/ha */
  KgPerHa = 'kg_per_ha',
  Km = 'km',
  Lb = 'lb',
  /** lb/acre */
  LbPerAcre = 'lb_per_acre',
  /** lb/ha */
  LbPerHa = 'lb_per_ha',
  Litre = 'litre',
  /** l/ha */
  LitrePerHa = 'litre_per_ha',
  Load = 'load',
  Metre = 'metre',
  /** m/sec */
  MetrePerSecond = 'metre_per_second',
  Mile = 'mile',
  Minute = 'minute',
  Number = 'number',
  Pack = 'pack',
  /** % */
  Percent = 'percent',
  /** % rate */
  PercentRate = 'percent_rate',
  /** rate/ha */
  RatePerHa = 'rate_per_ha',
  Roll = 'roll',
  SquareMetre = 'square_metre',
  Tonne = 'tonne',
  Unit = 'unit',
  Wrap = 'wrap'
}

/** Boolean expression to compare columns of type "chargeable_units_enum". All fields are combined with logical 'AND'. */
export type Chargeable_Units_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Chargeable_Units_Enum>;
  _in?: InputMaybe<Array<Chargeable_Units_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Chargeable_Units_Enum>;
  _nin?: InputMaybe<Array<Chargeable_Units_Enum>>;
};

/** Ordering options when selecting data from "chargeable_units". */
export type Chargeable_Units_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "chargeable_units" */
export enum Chargeable_Units_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "chargeable_units" */
export type Chargeable_Units_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chargeable_Units_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chargeable_Units_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export enum Entity_Status_Enum {
  Active = 'active',
  Archived = 'archived',
  Deleted = 'deleted'
}

/** Boolean expression to compare columns of type "entity_status_enum". All fields are combined with logical 'AND'. */
export type Entity_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Entity_Status_Enum>;
  _in?: InputMaybe<Array<Entity_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Entity_Status_Enum>;
  _nin?: InputMaybe<Array<Entity_Status_Enum>>;
};

/** Groups fields */
export type Farms = {
  __typename?: 'farms';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geoLocation?: Maybe<Scalars['point']['output']>;
  highResLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['bigint']['output'];
};

/** Boolean expression to filter rows from the table "farms". All fields are combined with a logical 'AND'. */
export type Farms_Bool_Exp = {
  _and?: InputMaybe<Array<Farms_Bool_Exp>>;
  _not?: InputMaybe<Farms_Bool_Exp>;
  _or?: InputMaybe<Array<Farms_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geoLocation?: InputMaybe<Point_Comparison_Exp>;
  highResLink?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "farms" */
export enum Farms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FarmsPkey = 'farms_pkey'
}

/** input type for inserting data into table "farms" */
export type Farms_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  highResLink?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['bigint']['input']>;
};

/** response of any mutation on the table "farms" */
export type Farms_Mutation_Response = {
  __typename?: 'farms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Farms>;
};

/** input type for inserting object relation for remote table "farms" */
export type Farms_Obj_Rel_Insert_Input = {
  data: Farms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Farms_On_Conflict>;
};

/** on_conflict condition type for table "farms" */
export type Farms_On_Conflict = {
  constraint: Farms_Constraint;
  update_columns?: Array<Farms_Update_Column>;
  where?: InputMaybe<Farms_Bool_Exp>;
};

/** Ordering options when selecting data from "farms". */
export type Farms_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geoLocation?: InputMaybe<Order_By>;
  highResLink?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farms */
export type Farms_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "farms" */
export enum Farms_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  HighResLink = 'highResLink',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "farms" */
export type Farms_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  highResLink?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "farms" */
export type Farms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farms_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  highResLink?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['bigint']['input']>;
};

/** update columns of table "farms" */
export enum Farms_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  HighResLink = 'highResLink',
  /** column name */
  Name = 'name'
}

export type Farms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farms_Bool_Exp;
};

/** Snapshot of a field unique for each season */
export type Field_Season_Shot = {
  __typename?: 'field_season_shot';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cropName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  farm: Farms;
  farmId: Scalars['bigint']['output'];
  /** An object relationship */
  field: Fields;
  fieldId: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  jobs: Array<Job_Fields>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  ownershipType: Scalars['String']['output'];
  /** An object relationship */
  polygon: Polygons;
  polygonId: Scalars['bigint']['output'];
  season: Scalars['String']['output'];
  seeding_rate?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** Snapshot of a field unique for each season */
export type Field_Season_ShotJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Fields_Order_By>>;
  where?: InputMaybe<Job_Fields_Bool_Exp>;
};

/** order by aggregate values of table "field_season_shot" */
export type Field_Season_Shot_Aggregate_Order_By = {
  avg?: InputMaybe<Field_Season_Shot_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Season_Shot_Max_Order_By>;
  min?: InputMaybe<Field_Season_Shot_Min_Order_By>;
  stddev?: InputMaybe<Field_Season_Shot_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Field_Season_Shot_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Field_Season_Shot_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Field_Season_Shot_Sum_Order_By>;
  var_pop?: InputMaybe<Field_Season_Shot_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Field_Season_Shot_Var_Samp_Order_By>;
  variance?: InputMaybe<Field_Season_Shot_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "field_season_shot" */
export type Field_Season_Shot_Arr_Rel_Insert_Input = {
  data: Array<Field_Season_Shot_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Season_Shot_On_Conflict>;
};

/** order by avg() on columns of table "field_season_shot" */
export type Field_Season_Shot_Avg_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_season_shot". All fields are combined with a logical 'AND'. */
export type Field_Season_Shot_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Season_Shot_Bool_Exp>>;
  _not?: InputMaybe<Field_Season_Shot_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Season_Shot_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cropName?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  farm?: InputMaybe<Farms_Bool_Exp>;
  farmId?: InputMaybe<Bigint_Comparison_Exp>;
  field?: InputMaybe<Fields_Bool_Exp>;
  fieldId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  jobs?: InputMaybe<Job_Fields_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  ownershipType?: InputMaybe<String_Comparison_Exp>;
  polygon?: InputMaybe<Polygons_Bool_Exp>;
  polygonId?: InputMaybe<Bigint_Comparison_Exp>;
  season?: InputMaybe<String_Comparison_Exp>;
  seeding_rate?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_season_shot" */
export enum Field_Season_Shot_Constraint {
  /** unique or primary key constraint on columns "field_id", "season" */
  FieldSeasonShotFieldIdSeasonKey = 'field_season_shot_field_id_season_key',
  /** unique or primary key constraint on columns "id" */
  FieldSeasonShotPkey = 'field_season_shot_pkey'
}

/** input type for incrementing numeric columns in table "field_season_shot" */
export type Field_Season_Shot_Inc_Input = {
  farmId?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "field_season_shot" */
export type Field_Season_Shot_Insert_Input = {
  cropName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  farm?: InputMaybe<Farms_Obj_Rel_Insert_Input>;
  farmId?: InputMaybe<Scalars['bigint']['input']>;
  field?: InputMaybe<Fields_Obj_Rel_Insert_Input>;
  fieldId?: InputMaybe<Scalars['bigint']['input']>;
  jobs?: InputMaybe<Job_Fields_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ownershipType?: InputMaybe<Scalars['String']['input']>;
  polygon?: InputMaybe<Polygons_Obj_Rel_Insert_Input>;
  polygonId?: InputMaybe<Scalars['bigint']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  seeding_rate?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "field_season_shot" */
export type Field_Season_Shot_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cropName?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ownershipType?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
  season?: InputMaybe<Order_By>;
  seeding_rate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "field_season_shot" */
export type Field_Season_Shot_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cropName?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ownershipType?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
  season?: InputMaybe<Order_By>;
  seeding_rate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_season_shot" */
export type Field_Season_Shot_Mutation_Response = {
  __typename?: 'field_season_shot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Season_Shot>;
};

/** input type for inserting object relation for remote table "field_season_shot" */
export type Field_Season_Shot_Obj_Rel_Insert_Input = {
  data: Field_Season_Shot_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Season_Shot_On_Conflict>;
};

/** on_conflict condition type for table "field_season_shot" */
export type Field_Season_Shot_On_Conflict = {
  constraint: Field_Season_Shot_Constraint;
  update_columns?: Array<Field_Season_Shot_Update_Column>;
  where?: InputMaybe<Field_Season_Shot_Bool_Exp>;
};

/** Ordering options when selecting data from "field_season_shot". */
export type Field_Season_Shot_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cropName?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  farm?: InputMaybe<Farms_Order_By>;
  farmId?: InputMaybe<Order_By>;
  field?: InputMaybe<Fields_Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Fields_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  ownershipType?: InputMaybe<Order_By>;
  polygon?: InputMaybe<Polygons_Order_By>;
  polygonId?: InputMaybe<Order_By>;
  season?: InputMaybe<Order_By>;
  seeding_rate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: field_season_shot */
export type Field_Season_Shot_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "field_season_shot" */
export enum Field_Season_Shot_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CropName = 'cropName',
  /** column name */
  Description = 'description',
  /** column name */
  FarmId = 'farmId',
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnershipType = 'ownershipType',
  /** column name */
  PolygonId = 'polygonId',
  /** column name */
  Season = 'season',
  /** column name */
  SeedingRate = 'seeding_rate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "field_season_shot" */
export type Field_Season_Shot_Set_Input = {
  cropName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  farmId?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ownershipType?: InputMaybe<Scalars['String']['input']>;
  seeding_rate?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "field_season_shot" */
export type Field_Season_Shot_Stddev_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "field_season_shot" */
export type Field_Season_Shot_Stddev_Pop_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "field_season_shot" */
export type Field_Season_Shot_Stddev_Samp_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "field_season_shot" */
export type Field_Season_Shot_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Season_Shot_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Season_Shot_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cropName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  farmId?: InputMaybe<Scalars['bigint']['input']>;
  fieldId?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ownershipType?: InputMaybe<Scalars['String']['input']>;
  polygonId?: InputMaybe<Scalars['bigint']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  seeding_rate?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "field_season_shot" */
export type Field_Season_Shot_Sum_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** update columns of table "field_season_shot" */
export enum Field_Season_Shot_Update_Column {
  /** column name */
  CropName = 'cropName',
  /** column name */
  Description = 'description',
  /** column name */
  FarmId = 'farmId',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnershipType = 'ownershipType',
  /** column name */
  SeedingRate = 'seeding_rate'
}

export type Field_Season_Shot_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Field_Season_Shot_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Season_Shot_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Season_Shot_Bool_Exp;
};

/** order by var_pop() on columns of table "field_season_shot" */
export type Field_Season_Shot_Var_Pop_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "field_season_shot" */
export type Field_Season_Shot_Var_Samp_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "field_season_shot" */
export type Field_Season_Shot_Variance_Order_By = {
  farmId?: InputMaybe<Order_By>;
  fieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  polygonId?: InputMaybe<Order_By>;
};

/** columns and relationships of "fields" */
export type Fields = {
  __typename?: 'fields';
  /** An object relationship */
  defaultFarm: Farms;
  /** Field ownership w.r.t farm is considered dynamic, so we add farm_id to season_shot as well, but still keep a default id at field level */
  defaultFarmId: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
};

/** Boolean expression to filter rows from the table "fields". All fields are combined with a logical 'AND'. */
export type Fields_Bool_Exp = {
  _and?: InputMaybe<Array<Fields_Bool_Exp>>;
  _not?: InputMaybe<Fields_Bool_Exp>;
  _or?: InputMaybe<Array<Fields_Bool_Exp>>;
  defaultFarm?: InputMaybe<Farms_Bool_Exp>;
  defaultFarmId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "fields" */
export enum Fields_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldsPkey = 'fields_pkey'
}

/** input type for inserting data into table "fields" */
export type Fields_Insert_Input = {
  defaultFarm?: InputMaybe<Farms_Obj_Rel_Insert_Input>;
  /** Field ownership w.r.t farm is considered dynamic, so we add farm_id to season_shot as well, but still keep a default id at field level */
  defaultFarmId?: InputMaybe<Scalars['bigint']['input']>;
};

/** response of any mutation on the table "fields" */
export type Fields_Mutation_Response = {
  __typename?: 'fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Fields>;
};

/** input type for inserting object relation for remote table "fields" */
export type Fields_Obj_Rel_Insert_Input = {
  data: Fields_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Fields_On_Conflict>;
};

/** on_conflict condition type for table "fields" */
export type Fields_On_Conflict = {
  constraint: Fields_Constraint;
  update_columns?: Array<Fields_Update_Column>;
  where?: InputMaybe<Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "fields". */
export type Fields_Order_By = {
  defaultFarm?: InputMaybe<Farms_Order_By>;
  defaultFarmId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "fields" */
export enum Fields_Select_Column {
  /** column name */
  DefaultFarmId = 'defaultFarmId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "fields" */
export type Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fields_Stream_Cursor_Value_Input = {
  /** Field ownership w.r.t farm is considered dynamic, so we add farm_id to season_shot as well, but still keep a default id at field level */
  defaultFarmId?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** placeholder for update columns of table "fields" (current role has no relevant permissions) */
export enum Fields_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "high_res_map_annotations" */
export type High_Res_Map_Annotations = {
  __typename?: 'high_res_map_annotations';
  /** in acres */
  area?: Maybe<Scalars['float8']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  geometry: Scalars['String']['output'];
  /** An object relationship */
  highResMap: High_Res_Maps;
  highResMapId: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
};

/** order by aggregate values of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Aggregate_Order_By = {
  avg?: InputMaybe<High_Res_Map_Annotations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<High_Res_Map_Annotations_Max_Order_By>;
  min?: InputMaybe<High_Res_Map_Annotations_Min_Order_By>;
  stddev?: InputMaybe<High_Res_Map_Annotations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<High_Res_Map_Annotations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<High_Res_Map_Annotations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<High_Res_Map_Annotations_Sum_Order_By>;
  var_pop?: InputMaybe<High_Res_Map_Annotations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<High_Res_Map_Annotations_Var_Samp_Order_By>;
  variance?: InputMaybe<High_Res_Map_Annotations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Arr_Rel_Insert_Input = {
  data: Array<High_Res_Map_Annotations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<High_Res_Map_Annotations_On_Conflict>;
};

/** order by avg() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Avg_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "high_res_map_annotations". All fields are combined with a logical 'AND'. */
export type High_Res_Map_Annotations_Bool_Exp = {
  _and?: InputMaybe<Array<High_Res_Map_Annotations_Bool_Exp>>;
  _not?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
  _or?: InputMaybe<Array<High_Res_Map_Annotations_Bool_Exp>>;
  area?: InputMaybe<Float8_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geometry?: InputMaybe<String_Comparison_Exp>;
  highResMap?: InputMaybe<High_Res_Maps_Bool_Exp>;
  highResMapId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "high_res_map_annotations" */
export enum High_Res_Map_Annotations_Constraint {
  /** unique or primary key constraint on columns "id" */
  HighResMapAnnotationsPkey = 'high_res_map_annotations_pkey'
}

/** input type for incrementing numeric columns in table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Inc_Input = {
  /** in acres */
  area?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Insert_Input = {
  /** in acres */
  area?: InputMaybe<Scalars['float8']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  highResMap?: InputMaybe<High_Res_Maps_Obj_Rel_Insert_Input>;
  highResMapId?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Max_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Min_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Mutation_Response = {
  __typename?: 'high_res_map_annotations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<High_Res_Map_Annotations>;
};

/** on_conflict condition type for table "high_res_map_annotations" */
export type High_Res_Map_Annotations_On_Conflict = {
  constraint: High_Res_Map_Annotations_Constraint;
  update_columns?: Array<High_Res_Map_Annotations_Update_Column>;
  where?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
};

/** Ordering options when selecting data from "high_res_map_annotations". */
export type High_Res_Map_Annotations_Order_By = {
  area?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  highResMap?: InputMaybe<High_Res_Maps_Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: high_res_map_annotations */
export type High_Res_Map_Annotations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "high_res_map_annotations" */
export enum High_Res_Map_Annotations_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  HighResMapId = 'highResMapId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Set_Input = {
  /** in acres */
  area?: InputMaybe<Scalars['float8']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Stddev_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Stddev_Pop_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Stddev_Samp_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: High_Res_Map_Annotations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type High_Res_Map_Annotations_Stream_Cursor_Value_Input = {
  /** in acres */
  area?: InputMaybe<Scalars['float8']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  highResMapId?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Sum_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "high_res_map_annotations" */
export enum High_Res_Map_Annotations_Update_Column {
  /** column name */
  Area = 'area',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  Name = 'name'
}

export type High_Res_Map_Annotations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<High_Res_Map_Annotations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<High_Res_Map_Annotations_Set_Input>;
  /** filter the rows which have to be updated */
  where: High_Res_Map_Annotations_Bool_Exp;
};

/** order by var_pop() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Var_Pop_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Var_Samp_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "high_res_map_annotations" */
export type High_Res_Map_Annotations_Variance_Order_By = {
  /** in acres */
  area?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "high_res_map_layers" */
export type High_Res_Map_Layers = {
  __typename?: 'high_res_map_layers';
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  highResMap: High_Res_Maps;
  highResMapId: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  link: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "high_res_map_layers" */
export type High_Res_Map_Layers_Aggregate_Order_By = {
  avg?: InputMaybe<High_Res_Map_Layers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<High_Res_Map_Layers_Max_Order_By>;
  min?: InputMaybe<High_Res_Map_Layers_Min_Order_By>;
  stddev?: InputMaybe<High_Res_Map_Layers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<High_Res_Map_Layers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<High_Res_Map_Layers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<High_Res_Map_Layers_Sum_Order_By>;
  var_pop?: InputMaybe<High_Res_Map_Layers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<High_Res_Map_Layers_Var_Samp_Order_By>;
  variance?: InputMaybe<High_Res_Map_Layers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "high_res_map_layers" */
export type High_Res_Map_Layers_Arr_Rel_Insert_Input = {
  data: Array<High_Res_Map_Layers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<High_Res_Map_Layers_On_Conflict>;
};

/** order by avg() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Avg_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "high_res_map_layers". All fields are combined with a logical 'AND'. */
export type High_Res_Map_Layers_Bool_Exp = {
  _and?: InputMaybe<Array<High_Res_Map_Layers_Bool_Exp>>;
  _not?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
  _or?: InputMaybe<Array<High_Res_Map_Layers_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  highResMap?: InputMaybe<High_Res_Maps_Bool_Exp>;
  highResMapId?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "high_res_map_layers" */
export enum High_Res_Map_Layers_Constraint {
  /** unique or primary key constraint on columns "id" */
  HighResMapLayersPkey = 'high_res_map_layers_pkey'
}

/** input type for inserting data into table "high_res_map_layers" */
export type High_Res_Map_Layers_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  highResMap?: InputMaybe<High_Res_Maps_Obj_Rel_Insert_Input>;
  highResMapId?: InputMaybe<Scalars['bigint']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "high_res_map_layers" */
export type High_Res_Map_Layers_Mutation_Response = {
  __typename?: 'high_res_map_layers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<High_Res_Map_Layers>;
};

/** on_conflict condition type for table "high_res_map_layers" */
export type High_Res_Map_Layers_On_Conflict = {
  constraint: High_Res_Map_Layers_Constraint;
  update_columns?: Array<High_Res_Map_Layers_Update_Column>;
  where?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
};

/** Ordering options when selecting data from "high_res_map_layers". */
export type High_Res_Map_Layers_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  highResMap?: InputMaybe<High_Res_Maps_Order_By>;
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: high_res_map_layers */
export type High_Res_Map_Layers_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "high_res_map_layers" */
export enum High_Res_Map_Layers_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HighResMapId = 'highResMapId',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "high_res_map_layers" */
export type High_Res_Map_Layers_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Stddev_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Stddev_Pop_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Stddev_Samp_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "high_res_map_layers" */
export type High_Res_Map_Layers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: High_Res_Map_Layers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type High_Res_Map_Layers_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  highResMapId?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Sum_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "high_res_map_layers" */
export enum High_Res_Map_Layers_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name'
}

export type High_Res_Map_Layers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<High_Res_Map_Layers_Set_Input>;
  /** filter the rows which have to be updated */
  where: High_Res_Map_Layers_Bool_Exp;
};

/** order by var_pop() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Var_Pop_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Var_Samp_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "high_res_map_layers" */
export type High_Res_Map_Layers_Variance_Order_By = {
  highResMapId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "high_res_maps" */
export type High_Res_Maps = {
  __typename?: 'high_res_maps';
  /** An array relationship */
  annotations: Array<High_Res_Map_Annotations>;
  captured_at?: Maybe<Scalars['timestamptz']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  layers: Array<High_Res_Map_Layers>;
  link?: Maybe<Scalars['String']['output']>;
  ms_link?: Maybe<Scalars['String']['output']>;
  ms_rapid_link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rgb_link?: Maybe<Scalars['String']['output']>;
  rgb_rapid_link?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  seasonShot: Field_Season_Shot;
  seasonShotId: Scalars['bigint']['output'];
  status?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "high_res_maps" */
export type High_Res_MapsAnnotationsArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Map_Annotations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Map_Annotations_Order_By>>;
  where?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
};


/** columns and relationships of "high_res_maps" */
export type High_Res_MapsLayersArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Map_Layers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Map_Layers_Order_By>>;
  where?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "high_res_maps". All fields are combined with a logical 'AND'. */
export type High_Res_Maps_Bool_Exp = {
  _and?: InputMaybe<Array<High_Res_Maps_Bool_Exp>>;
  _not?: InputMaybe<High_Res_Maps_Bool_Exp>;
  _or?: InputMaybe<Array<High_Res_Maps_Bool_Exp>>;
  annotations?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
  captured_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  layers?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  ms_link?: InputMaybe<String_Comparison_Exp>;
  ms_rapid_link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rgb_link?: InputMaybe<String_Comparison_Exp>;
  rgb_rapid_link?: InputMaybe<String_Comparison_Exp>;
  seasonShot?: InputMaybe<Field_Season_Shot_Bool_Exp>;
  seasonShotId?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "high_res_maps" */
export enum High_Res_Maps_Constraint {
  /** unique or primary key constraint on columns "id" */
  HighResMapsPkey = 'high_res_maps_pkey'
}

/** input type for inserting data into table "high_res_maps" */
export type High_Res_Maps_Insert_Input = {
  annotations?: InputMaybe<High_Res_Map_Annotations_Arr_Rel_Insert_Input>;
  captured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  layers?: InputMaybe<High_Res_Map_Layers_Arr_Rel_Insert_Input>;
  link?: InputMaybe<Scalars['String']['input']>;
  ms_link?: InputMaybe<Scalars['String']['input']>;
  ms_rapid_link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rgb_link?: InputMaybe<Scalars['String']['input']>;
  rgb_rapid_link?: InputMaybe<Scalars['String']['input']>;
  seasonShot?: InputMaybe<Field_Season_Shot_Obj_Rel_Insert_Input>;
  seasonShotId?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "high_res_maps" */
export type High_Res_Maps_Mutation_Response = {
  __typename?: 'high_res_maps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<High_Res_Maps>;
};

/** input type for inserting object relation for remote table "high_res_maps" */
export type High_Res_Maps_Obj_Rel_Insert_Input = {
  data: High_Res_Maps_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<High_Res_Maps_On_Conflict>;
};

/** on_conflict condition type for table "high_res_maps" */
export type High_Res_Maps_On_Conflict = {
  constraint: High_Res_Maps_Constraint;
  update_columns?: Array<High_Res_Maps_Update_Column>;
  where?: InputMaybe<High_Res_Maps_Bool_Exp>;
};

/** Ordering options when selecting data from "high_res_maps". */
export type High_Res_Maps_Order_By = {
  annotations_aggregate?: InputMaybe<High_Res_Map_Annotations_Aggregate_Order_By>;
  captured_at?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  layers_aggregate?: InputMaybe<High_Res_Map_Layers_Aggregate_Order_By>;
  link?: InputMaybe<Order_By>;
  ms_link?: InputMaybe<Order_By>;
  ms_rapid_link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rgb_link?: InputMaybe<Order_By>;
  rgb_rapid_link?: InputMaybe<Order_By>;
  seasonShot?: InputMaybe<Field_Season_Shot_Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: high_res_maps */
export type High_Res_Maps_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "high_res_maps" */
export enum High_Res_Maps_Select_Column {
  /** column name */
  CapturedAt = 'captured_at',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MsLink = 'ms_link',
  /** column name */
  MsRapidLink = 'ms_rapid_link',
  /** column name */
  Name = 'name',
  /** column name */
  RgbLink = 'rgb_link',
  /** column name */
  RgbRapidLink = 'rgb_rapid_link',
  /** column name */
  SeasonShotId = 'seasonShotId',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "high_res_maps" */
export type High_Res_Maps_Set_Input = {
  captured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  ms_link?: InputMaybe<Scalars['String']['input']>;
  ms_rapid_link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rgb_link?: InputMaybe<Scalars['String']['input']>;
  rgb_rapid_link?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "high_res_maps" */
export type High_Res_Maps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: High_Res_Maps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type High_Res_Maps_Stream_Cursor_Value_Input = {
  captured_at?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  ms_link?: InputMaybe<Scalars['String']['input']>;
  ms_rapid_link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rgb_link?: InputMaybe<Scalars['String']['input']>;
  rgb_rapid_link?: InputMaybe<Scalars['String']['input']>;
  seasonShotId?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "high_res_maps" */
export enum High_Res_Maps_Update_Column {
  /** column name */
  CapturedAt = 'captured_at',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Link = 'link',
  /** column name */
  MsLink = 'ms_link',
  /** column name */
  MsRapidLink = 'ms_rapid_link',
  /** column name */
  Name = 'name',
  /** column name */
  RgbLink = 'rgb_link',
  /** column name */
  RgbRapidLink = 'rgb_rapid_link',
  /** column name */
  Status = 'status'
}

export type High_Res_Maps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<High_Res_Maps_Set_Input>;
  /** filter the rows which have to be updated */
  where: High_Res_Maps_Bool_Exp;
};

/** columns and relationships of "implements" */
export type Implements = {
  __typename?: 'implements';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  jobs: Array<Job_Operator_Implements>;
  length?: Maybe<Scalars['numeric']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownership: Ownership_Types_Enum;
  status: Entity_Status_Enum;
  userId: Scalars['Int']['output'];
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "implements" */
export type ImplementsJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Implements_Order_By>>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};

/** aggregated selection of "implements" */
export type Implements_Aggregate = {
  __typename?: 'implements_aggregate';
  aggregate?: Maybe<Implements_Aggregate_Fields>;
  nodes: Array<Implements>;
};

/** aggregate fields of "implements" */
export type Implements_Aggregate_Fields = {
  __typename?: 'implements_aggregate_fields';
  avg?: Maybe<Implements_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Implements_Max_Fields>;
  min?: Maybe<Implements_Min_Fields>;
  stddev?: Maybe<Implements_Stddev_Fields>;
  stddev_pop?: Maybe<Implements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Implements_Stddev_Samp_Fields>;
  sum?: Maybe<Implements_Sum_Fields>;
  var_pop?: Maybe<Implements_Var_Pop_Fields>;
  var_samp?: Maybe<Implements_Var_Samp_Fields>;
  variance?: Maybe<Implements_Variance_Fields>;
};


/** aggregate fields of "implements" */
export type Implements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Implements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Implements_Avg_Fields = {
  __typename?: 'implements_avg_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "implements". All fields are combined with a logical 'AND'. */
export type Implements_Bool_Exp = {
  _and?: InputMaybe<Array<Implements_Bool_Exp>>;
  _not?: InputMaybe<Implements_Bool_Exp>;
  _or?: InputMaybe<Array<Implements_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobs?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
  length?: InputMaybe<Numeric_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ownership?: InputMaybe<Ownership_Types_Enum_Comparison_Exp>;
  status?: InputMaybe<Entity_Status_Enum_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  weight?: InputMaybe<Numeric_Comparison_Exp>;
  width?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "implements" */
export enum Implements_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImplementsPkey = 'implements_pkey'
}

/** input type for incrementing numeric columns in table "implements" */
export type Implements_Inc_Input = {
  length?: InputMaybe<Scalars['numeric']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "implements" */
export type Implements_Insert_Input = {
  brand?: InputMaybe<Scalars['String']['input']>;
  jobs?: InputMaybe<Job_Operator_Implements_Arr_Rel_Insert_Input>;
  length?: InputMaybe<Scalars['numeric']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: InputMaybe<Ownership_Types_Enum>;
  status?: InputMaybe<Entity_Status_Enum>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Implements_Max_Fields = {
  __typename?: 'implements_max_fields';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  length?: Maybe<Scalars['numeric']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Implements_Min_Fields = {
  __typename?: 'implements_min_fields';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  length?: Maybe<Scalars['numeric']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "implements" */
export type Implements_Mutation_Response = {
  __typename?: 'implements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Implements>;
};

/** input type for inserting object relation for remote table "implements" */
export type Implements_Obj_Rel_Insert_Input = {
  data: Implements_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Implements_On_Conflict>;
};

/** on_conflict condition type for table "implements" */
export type Implements_On_Conflict = {
  constraint: Implements_Constraint;
  update_columns?: Array<Implements_Update_Column>;
  where?: InputMaybe<Implements_Bool_Exp>;
};

/** Ordering options when selecting data from "implements". */
export type Implements_Order_By = {
  brand?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Operator_Implements_Aggregate_Order_By>;
  length?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownership?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: implements */
export type Implements_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "implements" */
export enum Implements_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Length = 'length',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  Ownership = 'ownership',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
  /** column name */
  Weight = 'weight',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "implements" */
export type Implements_Set_Input = {
  brand?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['numeric']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: InputMaybe<Ownership_Types_Enum>;
  status?: InputMaybe<Entity_Status_Enum>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Implements_Stddev_Fields = {
  __typename?: 'implements_stddev_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Implements_Stddev_Pop_Fields = {
  __typename?: 'implements_stddev_pop_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Implements_Stddev_Samp_Fields = {
  __typename?: 'implements_stddev_samp_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "implements" */
export type Implements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Implements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Implements_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  length?: InputMaybe<Scalars['numeric']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: InputMaybe<Ownership_Types_Enum>;
  status?: InputMaybe<Entity_Status_Enum>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Implements_Sum_Fields = {
  __typename?: 'implements_sum_fields';
  length?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "implements" */
export enum Implements_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  Length = 'length',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  Ownership = 'ownership',
  /** column name */
  Status = 'status',
  /** column name */
  Weight = 'weight',
  /** column name */
  Width = 'width'
}

export type Implements_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Implements_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Implements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Implements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Implements_Var_Pop_Fields = {
  __typename?: 'implements_var_pop_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Implements_Var_Samp_Fields = {
  __typename?: 'implements_var_samp_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Implements_Variance_Fields = {
  __typename?: 'implements_variance_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "inventory" */
export type Inventory = {
  __typename?: 'inventory';
  amount: Scalars['numeric']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  price: Scalars['numeric']['output'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid']['output'];
  remaining: Scalars['numeric']['output'];
  userId: Scalars['Int']['output'];
  /** An object relationship */
  warehouse?: Maybe<Warehouses>;
  warehouseId?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "inventory" */
export type Inventory_Aggregate = {
  __typename?: 'inventory_aggregate';
  aggregate?: Maybe<Inventory_Aggregate_Fields>;
  nodes: Array<Inventory>;
};

/** aggregate fields of "inventory" */
export type Inventory_Aggregate_Fields = {
  __typename?: 'inventory_aggregate_fields';
  avg?: Maybe<Inventory_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Inventory_Max_Fields>;
  min?: Maybe<Inventory_Min_Fields>;
  stddev?: Maybe<Inventory_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Sum_Fields>;
  var_pop?: Maybe<Inventory_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Variance_Fields>;
};


/** aggregate fields of "inventory" */
export type Inventory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Inventory_Avg_Fields = {
  __typename?: 'inventory_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "inventory". All fields are combined with a logical 'AND'. */
export type Inventory_Bool_Exp = {
  _and?: InputMaybe<Array<Inventory_Bool_Exp>>;
  _not?: InputMaybe<Inventory_Bool_Exp>;
  _or?: InputMaybe<Array<Inventory_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Uuid_Comparison_Exp>;
  remaining?: InputMaybe<Numeric_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  warehouse?: InputMaybe<Warehouses_Bool_Exp>;
  warehouseId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory" */
export enum Inventory_Constraint {
  /** unique or primary key constraint on columns "id" */
  InventoryPkey = 'inventory_pkey'
}

/** input type for incrementing numeric columns in table "inventory" */
export type Inventory_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  remaining?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "inventory" */
export type Inventory_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  remaining?: InputMaybe<Scalars['numeric']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  warehouse?: InputMaybe<Warehouses_Obj_Rel_Insert_Input>;
  warehouseId?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Inventory_Max_Fields = {
  __typename?: 'inventory_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  productId?: Maybe<Scalars['uuid']['output']>;
  remaining?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  warehouseId?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Inventory_Min_Fields = {
  __typename?: 'inventory_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  productId?: Maybe<Scalars['uuid']['output']>;
  remaining?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  warehouseId?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "inventory" */
export type Inventory_Mutation_Response = {
  __typename?: 'inventory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Inventory>;
};

/** on_conflict condition type for table "inventory" */
export type Inventory_On_Conflict = {
  constraint: Inventory_Constraint;
  update_columns?: Array<Inventory_Update_Column>;
  where?: InputMaybe<Inventory_Bool_Exp>;
};

/** Ordering options when selecting data from "inventory". */
export type Inventory_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  remaining?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  warehouse?: InputMaybe<Warehouses_Order_By>;
  warehouseId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inventory */
export type Inventory_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "inventory" */
export enum Inventory_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Remaining = 'remaining',
  /** column name */
  UserId = 'userId',
  /** column name */
  WarehouseId = 'warehouseId'
}

/** input type for updating data in table "inventory" */
export type Inventory_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  remaining?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Inventory_Stddev_Fields = {
  __typename?: 'inventory_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Stddev_Pop_Fields = {
  __typename?: 'inventory_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Stddev_Samp_Fields = {
  __typename?: 'inventory_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "inventory" */
export type Inventory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inventory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inventory_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  remaining?: InputMaybe<Scalars['numeric']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  warehouseId?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Inventory_Sum_Fields = {
  __typename?: 'inventory_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  remaining?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  warehouseId?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "inventory" */
export enum Inventory_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Remaining = 'remaining'
}

export type Inventory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Inventory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inventory_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inventory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inventory_Var_Pop_Fields = {
  __typename?: 'inventory_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Inventory_Var_Samp_Fields = {
  __typename?: 'inventory_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Inventory_Variance_Fields = {
  __typename?: 'inventory_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  warehouseId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "job_extras" */
export type Job_Extras = {
  __typename?: 'job_extras';
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  unit: Chargeable_Units_Enum;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "job_extras" */
export type Job_Extras_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Extras_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Extras_Max_Order_By>;
  min?: InputMaybe<Job_Extras_Min_Order_By>;
  stddev?: InputMaybe<Job_Extras_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Extras_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Extras_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Extras_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Extras_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Extras_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Extras_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_extras" */
export type Job_Extras_Arr_Rel_Insert_Input = {
  data: Array<Job_Extras_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Extras_On_Conflict>;
};

/** order by avg() on columns of table "job_extras" */
export type Job_Extras_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_extras". All fields are combined with a logical 'AND'. */
export type Job_Extras_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Extras_Bool_Exp>>;
  _not?: InputMaybe<Job_Extras_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Extras_Bool_Exp>>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  unit?: InputMaybe<Chargeable_Units_Enum_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_extras" */
export enum Job_Extras_Constraint {
  /** unique or primary key constraint on columns "name", "job_id" */
  JobExtrasPkey = 'job_extras_pkey'
}

/** input type for inserting data into table "job_extras" */
export type Job_Extras_Insert_Input = {
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Chargeable_Units_Enum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "job_extras" */
export type Job_Extras_Max_Order_By = {
  jobId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_extras" */
export type Job_Extras_Min_Order_By = {
  jobId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_extras" */
export type Job_Extras_Mutation_Response = {
  __typename?: 'job_extras_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Extras>;
};

/** on_conflict condition type for table "job_extras" */
export type Job_Extras_On_Conflict = {
  constraint: Job_Extras_Constraint;
  update_columns?: Array<Job_Extras_Update_Column>;
  where?: InputMaybe<Job_Extras_Bool_Exp>;
};

/** Ordering options when selecting data from "job_extras". */
export type Job_Extras_Order_By = {
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_extras */
export type Job_Extras_Pk_Columns_Input = {
  jobId: Scalars['bigint']['input'];
  name: Scalars['String']['input'];
};

/** select columns of table "job_extras" */
export enum Job_Extras_Select_Column {
  /** column name */
  JobId = 'jobId',
  /** column name */
  Name = 'name',
  /** column name */
  Unit = 'unit',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "job_extras" */
export type Job_Extras_Set_Input = {
  unit?: InputMaybe<Chargeable_Units_Enum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "job_extras" */
export type Job_Extras_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_extras" */
export type Job_Extras_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_extras" */
export type Job_Extras_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_extras" */
export type Job_Extras_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Extras_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Extras_Stream_Cursor_Value_Input = {
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Chargeable_Units_Enum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "job_extras" */
export type Job_Extras_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** update columns of table "job_extras" */
export enum Job_Extras_Update_Column {
  /** column name */
  Unit = 'unit',
  /** column name */
  Value = 'value'
}

export type Job_Extras_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Extras_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Extras_Bool_Exp;
};

/** order by var_pop() on columns of table "job_extras" */
export type Job_Extras_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_extras" */
export type Job_Extras_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_extras" */
export type Job_Extras_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_fields" */
export type Job_Fields = {
  __typename?: 'job_fields';
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  /** An object relationship */
  seasonShot: Field_Season_Shot;
  seasonShotId: Scalars['bigint']['output'];
};

/** order by aggregate values of table "job_fields" */
export type Job_Fields_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Fields_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Fields_Max_Order_By>;
  min?: InputMaybe<Job_Fields_Min_Order_By>;
  stddev?: InputMaybe<Job_Fields_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Fields_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Fields_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Fields_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Fields_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Fields_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Fields_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_fields" */
export type Job_Fields_Arr_Rel_Insert_Input = {
  data: Array<Job_Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Fields_On_Conflict>;
};

/** order by avg() on columns of table "job_fields" */
export type Job_Fields_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_fields". All fields are combined with a logical 'AND'. */
export type Job_Fields_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Fields_Bool_Exp>>;
  _not?: InputMaybe<Job_Fields_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Fields_Bool_Exp>>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  seasonShot?: InputMaybe<Field_Season_Shot_Bool_Exp>;
  seasonShotId?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_fields" */
export enum Job_Fields_Constraint {
  /** unique or primary key constraint on columns "season_shot_id", "job_id" */
  JobFieldsPkey = 'job_fields_pkey'
}

/** input type for inserting data into table "job_fields" */
export type Job_Fields_Insert_Input = {
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  seasonShot?: InputMaybe<Field_Season_Shot_Obj_Rel_Insert_Input>;
  seasonShotId?: InputMaybe<Scalars['bigint']['input']>;
};

/** order by max() on columns of table "job_fields" */
export type Job_Fields_Max_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_fields" */
export type Job_Fields_Min_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_fields" */
export type Job_Fields_Mutation_Response = {
  __typename?: 'job_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Fields>;
};

/** on_conflict condition type for table "job_fields" */
export type Job_Fields_On_Conflict = {
  constraint: Job_Fields_Constraint;
  update_columns?: Array<Job_Fields_Update_Column>;
  where?: InputMaybe<Job_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "job_fields". */
export type Job_Fields_Order_By = {
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  seasonShot?: InputMaybe<Field_Season_Shot_Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** select columns of table "job_fields" */
export enum Job_Fields_Select_Column {
  /** column name */
  JobId = 'jobId',
  /** column name */
  SeasonShotId = 'seasonShotId'
}

/** order by stddev() on columns of table "job_fields" */
export type Job_Fields_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_fields" */
export type Job_Fields_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_fields" */
export type Job_Fields_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_fields" */
export type Job_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Fields_Stream_Cursor_Value_Input = {
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  seasonShotId?: InputMaybe<Scalars['bigint']['input']>;
};

/** order by sum() on columns of table "job_fields" */
export type Job_Fields_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "job_fields" (current role has no relevant permissions) */
export enum Job_Fields_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "job_fields" */
export type Job_Fields_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_fields" */
export type Job_Fields_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_fields" */
export type Job_Fields_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_locations" */
export type Job_Locations = {
  __typename?: 'job_locations';
  geoLocation?: Maybe<Scalars['point']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  notes: Scalars['String']['output'];
};

/** order by aggregate values of table "job_locations" */
export type Job_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Locations_Max_Order_By>;
  min?: InputMaybe<Job_Locations_Min_Order_By>;
  stddev?: InputMaybe<Job_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_locations" */
export type Job_Locations_Arr_Rel_Insert_Input = {
  data: Array<Job_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Locations_On_Conflict>;
};

/** order by avg() on columns of table "job_locations" */
export type Job_Locations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_locations". All fields are combined with a logical 'AND'. */
export type Job_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Locations_Bool_Exp>>;
  _not?: InputMaybe<Job_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Locations_Bool_Exp>>;
  geoLocation?: InputMaybe<Point_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_locations" */
export enum Job_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobLocationsPkey = 'job_locations_pkey'
}

/** input type for inserting data into table "job_locations" */
export type Job_Locations_Insert_Input = {
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "job_locations" */
export type Job_Locations_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_locations" */
export type Job_Locations_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_locations" */
export type Job_Locations_Mutation_Response = {
  __typename?: 'job_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Locations>;
};

/** on_conflict condition type for table "job_locations" */
export type Job_Locations_On_Conflict = {
  constraint: Job_Locations_Constraint;
  update_columns?: Array<Job_Locations_Update_Column>;
  where?: InputMaybe<Job_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "job_locations". */
export type Job_Locations_Order_By = {
  geoLocation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_locations */
export type Job_Locations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "job_locations" */
export enum Job_Locations_Select_Column {
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  Notes = 'notes'
}

/** input type for updating data in table "job_locations" */
export type Job_Locations_Set_Input = {
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "job_locations" */
export type Job_Locations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_locations" */
export type Job_Locations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_locations" */
export type Job_Locations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_locations" */
export type Job_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Locations_Stream_Cursor_Value_Input = {
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "job_locations" */
export type Job_Locations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** update columns of table "job_locations" */
export enum Job_Locations_Update_Column {
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  Notes = 'notes'
}

export type Job_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Locations_Bool_Exp;
};

/** order by var_pop() on columns of table "job_locations" */
export type Job_Locations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_locations" */
export type Job_Locations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_locations" */
export type Job_Locations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_operator_implements" */
export type Job_Operator_Implements = {
  __typename?: 'job_operator_implements';
  /** An object relationship */
  implement: Implements;
  implementId: Scalars['uuid']['output'];
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  userId: Scalars['Int']['output'];
};

/** order by aggregate values of table "job_operator_implements" */
export type Job_Operator_Implements_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Operator_Implements_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Operator_Implements_Max_Order_By>;
  min?: InputMaybe<Job_Operator_Implements_Min_Order_By>;
  stddev?: InputMaybe<Job_Operator_Implements_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Operator_Implements_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Operator_Implements_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Operator_Implements_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Operator_Implements_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Operator_Implements_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Operator_Implements_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_operator_implements" */
export type Job_Operator_Implements_Arr_Rel_Insert_Input = {
  data: Array<Job_Operator_Implements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Operator_Implements_On_Conflict>;
};

/** order by avg() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_operator_implements". All fields are combined with a logical 'AND'. */
export type Job_Operator_Implements_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Operator_Implements_Bool_Exp>>;
  _not?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Operator_Implements_Bool_Exp>>;
  implement?: InputMaybe<Implements_Bool_Exp>;
  implementId?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_operator_implements" */
export enum Job_Operator_Implements_Constraint {
  /** unique or primary key constraint on columns "job_id", "user_id", "implement_id" */
  JobOperatorImplementsPkey = 'job_operator_implements_pkey'
}

/** input type for inserting data into table "job_operator_implements" */
export type Job_Operator_Implements_Insert_Input = {
  implement?: InputMaybe<Implements_Obj_Rel_Insert_Input>;
  implementId?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Max_Order_By = {
  implementId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Min_Order_By = {
  implementId?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_operator_implements" */
export type Job_Operator_Implements_Mutation_Response = {
  __typename?: 'job_operator_implements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Operator_Implements>;
};

/** on_conflict condition type for table "job_operator_implements" */
export type Job_Operator_Implements_On_Conflict = {
  constraint: Job_Operator_Implements_Constraint;
  update_columns?: Array<Job_Operator_Implements_Update_Column>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};

/** Ordering options when selecting data from "job_operator_implements". */
export type Job_Operator_Implements_Order_By = {
  implement?: InputMaybe<Implements_Order_By>;
  implementId?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "job_operator_implements" */
export enum Job_Operator_Implements_Select_Column {
  /** column name */
  ImplementId = 'implementId',
  /** column name */
  JobId = 'jobId',
  /** column name */
  UserId = 'userId'
}

/** order by stddev() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_operator_implements" */
export type Job_Operator_Implements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Operator_Implements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Operator_Implements_Stream_Cursor_Value_Input = {
  implementId?: InputMaybe<Scalars['uuid']['input']>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "job_operator_implements" (current role has no relevant permissions) */
export enum Job_Operator_Implements_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_operator_implements" */
export type Job_Operator_Implements_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_operator_vehicles" */
export type Job_Operator_Vehicles = {
  __typename?: 'job_operator_vehicles';
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  userId: Scalars['Int']['output'];
  /** An object relationship */
  vehicle: Vehicles;
  vehicleId: Scalars['uuid']['output'];
};

/** order by aggregate values of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Operator_Vehicles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Operator_Vehicles_Max_Order_By>;
  min?: InputMaybe<Job_Operator_Vehicles_Min_Order_By>;
  stddev?: InputMaybe<Job_Operator_Vehicles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Operator_Vehicles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Operator_Vehicles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Operator_Vehicles_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Operator_Vehicles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Operator_Vehicles_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Operator_Vehicles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Arr_Rel_Insert_Input = {
  data: Array<Job_Operator_Vehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Operator_Vehicles_On_Conflict>;
};

/** order by avg() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_operator_vehicles". All fields are combined with a logical 'AND'. */
export type Job_Operator_Vehicles_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Operator_Vehicles_Bool_Exp>>;
  _not?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Operator_Vehicles_Bool_Exp>>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicles_Bool_Exp>;
  vehicleId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_operator_vehicles" */
export enum Job_Operator_Vehicles_Constraint {
  /** unique or primary key constraint on columns "job_id", "vehicle_id", "user_id" */
  JobOperatorVehiclesPkey = 'job_operator_vehicles_pkey'
}

/** input type for inserting data into table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Insert_Input = {
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  vehicle?: InputMaybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Max_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vehicleId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Min_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vehicleId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Mutation_Response = {
  __typename?: 'job_operator_vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Operator_Vehicles>;
};

/** on_conflict condition type for table "job_operator_vehicles" */
export type Job_Operator_Vehicles_On_Conflict = {
  constraint: Job_Operator_Vehicles_Constraint;
  update_columns?: Array<Job_Operator_Vehicles_Update_Column>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "job_operator_vehicles". */
export type Job_Operator_Vehicles_Order_By = {
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicles_Order_By>;
  vehicleId?: InputMaybe<Order_By>;
};

/** select columns of table "job_operator_vehicles" */
export enum Job_Operator_Vehicles_Select_Column {
  /** column name */
  JobId = 'jobId',
  /** column name */
  UserId = 'userId',
  /** column name */
  VehicleId = 'vehicleId'
}

/** order by stddev() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Operator_Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Operator_Vehicles_Stream_Cursor_Value_Input = {
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by sum() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "job_operator_vehicles" (current role has no relevant permissions) */
export enum Job_Operator_Vehicles_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_operator_vehicles" */
export type Job_Operator_Vehicles_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_operators" */
export type Job_Operators = {
  __typename?: 'job_operators';
  /** An array relationship */
  implements: Array<Job_Operator_Implements>;
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  teamLead?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['Int']['output'];
  /** An array relationship */
  vehicles: Array<Job_Operator_Vehicles>;
};


/** columns and relationships of "job_operators" */
export type Job_OperatorsImplementsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Implements_Order_By>>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};


/** columns and relationships of "job_operators" */
export type Job_OperatorsVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Vehicles_Order_By>>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};

/** order by aggregate values of table "job_operators" */
export type Job_Operators_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Operators_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Operators_Max_Order_By>;
  min?: InputMaybe<Job_Operators_Min_Order_By>;
  stddev?: InputMaybe<Job_Operators_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Operators_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Operators_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Operators_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Operators_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Operators_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Operators_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_operators" */
export type Job_Operators_Arr_Rel_Insert_Input = {
  data: Array<Job_Operators_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Operators_On_Conflict>;
};

/** order by avg() on columns of table "job_operators" */
export type Job_Operators_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_operators". All fields are combined with a logical 'AND'. */
export type Job_Operators_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Operators_Bool_Exp>>;
  _not?: InputMaybe<Job_Operators_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Operators_Bool_Exp>>;
  implements?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  teamLead?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  vehicles?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};

/** unique or primary key constraints on table "job_operators" */
export enum Job_Operators_Constraint {
  /** unique or primary key constraint on columns "job_id", "user_id" */
  JobOperatorsPkey = 'job_operators_pkey'
}

/** input type for inserting data into table "job_operators" */
export type Job_Operators_Insert_Input = {
  implements?: InputMaybe<Job_Operator_Implements_Arr_Rel_Insert_Input>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  teamLead?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  vehicles?: InputMaybe<Job_Operator_Vehicles_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "job_operators" */
export type Job_Operators_Max_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_operators" */
export type Job_Operators_Min_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_operators" */
export type Job_Operators_Mutation_Response = {
  __typename?: 'job_operators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Operators>;
};

/** on_conflict condition type for table "job_operators" */
export type Job_Operators_On_Conflict = {
  constraint: Job_Operators_Constraint;
  update_columns?: Array<Job_Operators_Update_Column>;
  where?: InputMaybe<Job_Operators_Bool_Exp>;
};

/** Ordering options when selecting data from "job_operators". */
export type Job_Operators_Order_By = {
  implements_aggregate?: InputMaybe<Job_Operator_Implements_Aggregate_Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  teamLead?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  vehicles_aggregate?: InputMaybe<Job_Operator_Vehicles_Aggregate_Order_By>;
};

/** primary key columns input for table: job_operators */
export type Job_Operators_Pk_Columns_Input = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};

/** select columns of table "job_operators" */
export enum Job_Operators_Select_Column {
  /** column name */
  JobId = 'jobId',
  /** column name */
  TeamLead = 'teamLead',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "job_operators" */
export type Job_Operators_Set_Input = {
  teamLead?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by stddev() on columns of table "job_operators" */
export type Job_Operators_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_operators" */
export type Job_Operators_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_operators" */
export type Job_Operators_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_operators" */
export type Job_Operators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Operators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Operators_Stream_Cursor_Value_Input = {
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  teamLead?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "job_operators" */
export type Job_Operators_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "job_operators" */
export enum Job_Operators_Update_Column {
  /** column name */
  TeamLead = 'teamLead'
}

export type Job_Operators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Operators_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Operators_Bool_Exp;
};

/** order by var_pop() on columns of table "job_operators" */
export type Job_Operators_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_operators" */
export type Job_Operators_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_operators" */
export type Job_Operators_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_products" */
export type Job_Products = {
  __typename?: 'job_products';
  /** An object relationship */
  job: Jobs;
  jobId: Scalars['bigint']['output'];
  locked: Scalars['Boolean']['output'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "job_products" */
export type Job_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Products_Max_Order_By>;
  min?: InputMaybe<Job_Products_Min_Order_By>;
  stddev?: InputMaybe<Job_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_products" */
export type Job_Products_Arr_Rel_Insert_Input = {
  data: Array<Job_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Products_On_Conflict>;
};

/** order by avg() on columns of table "job_products" */
export type Job_Products_Avg_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_products". All fields are combined with a logical 'AND'. */
export type Job_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Products_Bool_Exp>>;
  _not?: InputMaybe<Job_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Products_Bool_Exp>>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  jobId?: InputMaybe<Bigint_Comparison_Exp>;
  locked?: InputMaybe<Boolean_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_products" */
export enum Job_Products_Constraint {
  /** unique or primary key constraint on columns "job_id", "product_id" */
  JobProductsPkey = 'job_products_pkey'
}

/** input type for inserting data into table "job_products" */
export type Job_Products_Insert_Input = {
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "job_products" */
export type Job_Products_Max_Order_By = {
  jobId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "job_products" */
export type Job_Products_Min_Order_By = {
  jobId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_products" */
export type Job_Products_Mutation_Response = {
  __typename?: 'job_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Products>;
};

/** on_conflict condition type for table "job_products" */
export type Job_Products_On_Conflict = {
  constraint: Job_Products_Constraint;
  update_columns?: Array<Job_Products_Update_Column>;
  where?: InputMaybe<Job_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "job_products". */
export type Job_Products_Order_By = {
  job?: InputMaybe<Jobs_Order_By>;
  jobId?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_products */
export type Job_Products_Pk_Columns_Input = {
  jobId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};

/** select columns of table "job_products" */
export enum Job_Products_Select_Column {
  /** column name */
  JobId = 'jobId',
  /** column name */
  Locked = 'locked',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "job_products" */
export type Job_Products_Set_Input = {
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "job_products" */
export type Job_Products_Stddev_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "job_products" */
export type Job_Products_Stddev_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "job_products" */
export type Job_Products_Stddev_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_products" */
export type Job_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Products_Stream_Cursor_Value_Input = {
  jobId?: InputMaybe<Scalars['bigint']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "job_products" */
export type Job_Products_Sum_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** update columns of table "job_products" */
export enum Job_Products_Update_Column {
  /** column name */
  Locked = 'locked',
  /** column name */
  Value = 'value'
}

export type Job_Products_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Products_Bool_Exp;
};

/** order by var_pop() on columns of table "job_products" */
export type Job_Products_Var_Pop_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "job_products" */
export type Job_Products_Var_Samp_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "job_products" */
export type Job_Products_Variance_Order_By = {
  jobId?: InputMaybe<Order_By>;
};

export enum Job_Status_Enum {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InProgress = 'in_progress',
  PartlyCompleted = 'partly_completed',
  Pending = 'pending',
  PendingCompletion = 'pending_completion',
  Ready = 'ready'
}

/** Boolean expression to compare columns of type "job_status_enum". All fields are combined with logical 'AND'. */
export type Job_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Status_Enum>;
  _in?: InputMaybe<Array<Job_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Job_Status_Enum>;
  _nin?: InputMaybe<Array<Job_Status_Enum>>;
};

/** columns and relationships of "jobs" */
export type Jobs = {
  __typename?: 'jobs';
  /** An object relationship */
  assignedUser: Users;
  assignedUserId: Scalars['bigint']['output'];
  completedAt?: Maybe<Scalars['timestamptz']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  extras: Array<Job_Extras>;
  /** An array relationship */
  fields: Array<Job_Fields>;
  from?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  implements: Array<Job_Operator_Implements>;
  instructions?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  locations: Array<Job_Locations>;
  /** An object relationship */
  operation?: Maybe<Operations>;
  operationId?: Maybe<Scalars['bigint']['output']>;
  operationName: Scalars['String']['output'];
  /** An array relationship */
  operators: Array<Job_Operators>;
  /** An array relationship */
  products: Array<Job_Products>;
  status: Job_Status_Enum;
  to?: Maybe<Scalars['timestamptz']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['bigint']['output'];
  /** An array relationship */
  vehicles: Array<Job_Operator_Vehicles>;
};


/** columns and relationships of "jobs" */
export type JobsExtrasArgs = {
  distinct_on?: InputMaybe<Array<Job_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Extras_Order_By>>;
  where?: InputMaybe<Job_Extras_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsFieldsArgs = {
  distinct_on?: InputMaybe<Array<Job_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Fields_Order_By>>;
  where?: InputMaybe<Job_Fields_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsImplementsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Implements_Order_By>>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsLocationsArgs = {
  distinct_on?: InputMaybe<Array<Job_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Locations_Order_By>>;
  where?: InputMaybe<Job_Locations_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsOperatorsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operators_Order_By>>;
  where?: InputMaybe<Job_Operators_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsProductsArgs = {
  distinct_on?: InputMaybe<Array<Job_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Products_Order_By>>;
  where?: InputMaybe<Job_Products_Bool_Exp>;
};


/** columns and relationships of "jobs" */
export type JobsVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Vehicles_Order_By>>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};

/** aggregated selection of "jobs" */
export type Jobs_Aggregate = {
  __typename?: 'jobs_aggregate';
  aggregate?: Maybe<Jobs_Aggregate_Fields>;
  nodes: Array<Jobs>;
};

export type Jobs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Jobs_Aggregate_Bool_Exp_Count>;
};

export type Jobs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Jobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "jobs" */
export type Jobs_Aggregate_Fields = {
  __typename?: 'jobs_aggregate_fields';
  avg?: Maybe<Jobs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Jobs_Max_Fields>;
  min?: Maybe<Jobs_Min_Fields>;
  stddev?: Maybe<Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Jobs_Sum_Fields>;
  var_pop?: Maybe<Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Jobs_Var_Samp_Fields>;
  variance?: Maybe<Jobs_Variance_Fields>;
};


/** aggregate fields of "jobs" */
export type Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "jobs" */
export type Jobs_Aggregate_Order_By = {
  avg?: InputMaybe<Jobs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Jobs_Max_Order_By>;
  min?: InputMaybe<Jobs_Min_Order_By>;
  stddev?: InputMaybe<Jobs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Jobs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Jobs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Jobs_Sum_Order_By>;
  var_pop?: InputMaybe<Jobs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Jobs_Var_Samp_Order_By>;
  variance?: InputMaybe<Jobs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "jobs" */
export type Jobs_Arr_Rel_Insert_Input = {
  data: Array<Jobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** aggregate avg on columns */
export type Jobs_Avg_Fields = {
  __typename?: 'jobs_avg_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "jobs" */
export type Jobs_Avg_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "jobs". All fields are combined with a logical 'AND'. */
export type Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Jobs_Bool_Exp>>;
  _not?: InputMaybe<Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Jobs_Bool_Exp>>;
  assignedUser?: InputMaybe<Users_Bool_Exp>;
  assignedUserId?: InputMaybe<Bigint_Comparison_Exp>;
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  extras?: InputMaybe<Job_Extras_Bool_Exp>;
  fields?: InputMaybe<Job_Fields_Bool_Exp>;
  from?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  implements?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  locations?: InputMaybe<Job_Locations_Bool_Exp>;
  operation?: InputMaybe<Operations_Bool_Exp>;
  operationId?: InputMaybe<Bigint_Comparison_Exp>;
  operationName?: InputMaybe<String_Comparison_Exp>;
  operators?: InputMaybe<Job_Operators_Bool_Exp>;
  products?: InputMaybe<Job_Products_Bool_Exp>;
  status?: InputMaybe<Job_Status_Enum_Comparison_Exp>;
  to?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Bigint_Comparison_Exp>;
  vehicles?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};

/** unique or primary key constraints on table "jobs" */
export enum Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobsPkey = 'jobs_pkey'
}

/** input type for incrementing numeric columns in table "jobs" */
export type Jobs_Inc_Input = {
  assignedUserId?: InputMaybe<Scalars['bigint']['input']>;
  operationId?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "jobs" */
export type Jobs_Insert_Input = {
  assignedUserId?: InputMaybe<Scalars['bigint']['input']>;
  completedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  extras?: InputMaybe<Job_Extras_Arr_Rel_Insert_Input>;
  fields?: InputMaybe<Job_Fields_Arr_Rel_Insert_Input>;
  from?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  implements?: InputMaybe<Job_Operator_Implements_Arr_Rel_Insert_Input>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Job_Locations_Arr_Rel_Insert_Input>;
  operation?: InputMaybe<Operations_Obj_Rel_Insert_Input>;
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  operationName?: InputMaybe<Scalars['String']['input']>;
  operators?: InputMaybe<Job_Operators_Arr_Rel_Insert_Input>;
  products?: InputMaybe<Job_Products_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Job_Status_Enum>;
  to?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['bigint']['input']>;
  vehicles?: InputMaybe<Job_Operator_Vehicles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Jobs_Max_Fields = {
  __typename?: 'jobs_max_fields';
  assignedUserId?: Maybe<Scalars['bigint']['output']>;
  completedAt?: Maybe<Scalars['timestamptz']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  from?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  operationId?: Maybe<Scalars['bigint']['output']>;
  operationName?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['timestamptz']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "jobs" */
export type Jobs_Max_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  operationName?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Jobs_Min_Fields = {
  __typename?: 'jobs_min_fields';
  assignedUserId?: Maybe<Scalars['bigint']['output']>;
  completedAt?: Maybe<Scalars['timestamptz']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  from?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  operationId?: Maybe<Scalars['bigint']['output']>;
  operationName?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['timestamptz']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "jobs" */
export type Jobs_Min_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  operationName?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "jobs" */
export type Jobs_Mutation_Response = {
  __typename?: 'jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Jobs>;
};

/** input type for inserting object relation for remote table "jobs" */
export type Jobs_Obj_Rel_Insert_Input = {
  data: Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** on_conflict condition type for table "jobs" */
export type Jobs_On_Conflict = {
  constraint: Jobs_Constraint;
  update_columns?: Array<Jobs_Update_Column>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "jobs". */
export type Jobs_Order_By = {
  assignedUser?: InputMaybe<Users_Order_By>;
  assignedUserId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  extras_aggregate?: InputMaybe<Job_Extras_Aggregate_Order_By>;
  fields_aggregate?: InputMaybe<Job_Fields_Aggregate_Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  implements_aggregate?: InputMaybe<Job_Operator_Implements_Aggregate_Order_By>;
  instructions?: InputMaybe<Order_By>;
  locations_aggregate?: InputMaybe<Job_Locations_Aggregate_Order_By>;
  operation?: InputMaybe<Operations_Order_By>;
  operationId?: InputMaybe<Order_By>;
  operationName?: InputMaybe<Order_By>;
  operators_aggregate?: InputMaybe<Job_Operators_Aggregate_Order_By>;
  products_aggregate?: InputMaybe<Job_Products_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  vehicles_aggregate?: InputMaybe<Job_Operator_Vehicles_Aggregate_Order_By>;
};

/** primary key columns input for table: jobs */
export type Jobs_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "jobs" */
export enum Jobs_Select_Column {
  /** column name */
  AssignedUserId = 'assignedUserId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  OperationId = 'operationId',
  /** column name */
  OperationName = 'operationName',
  /** column name */
  Status = 'status',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "jobs" */
export type Jobs_Set_Input = {
  assignedUserId?: InputMaybe<Scalars['bigint']['input']>;
  completedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  from?: InputMaybe<Scalars['timestamptz']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  operationName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Job_Status_Enum>;
  to?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Jobs_Stddev_Fields = {
  __typename?: 'jobs_stddev_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "jobs" */
export type Jobs_Stddev_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Jobs_Stddev_Pop_Fields = {
  __typename?: 'jobs_stddev_pop_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "jobs" */
export type Jobs_Stddev_Pop_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Jobs_Stddev_Samp_Fields = {
  __typename?: 'jobs_stddev_samp_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "jobs" */
export type Jobs_Stddev_Samp_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "jobs" */
export type Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobs_Stream_Cursor_Value_Input = {
  assignedUserId?: InputMaybe<Scalars['bigint']['input']>;
  completedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  from?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  operationName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Job_Status_Enum>;
  to?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Jobs_Sum_Fields = {
  __typename?: 'jobs_sum_fields';
  assignedUserId?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  operationId?: Maybe<Scalars['bigint']['output']>;
  userId?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "jobs" */
export type Jobs_Sum_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "jobs" */
export enum Jobs_Update_Column {
  /** column name */
  AssignedUserId = 'assignedUserId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  From = 'from',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  OperationId = 'operationId',
  /** column name */
  OperationName = 'operationName',
  /** column name */
  Status = 'status',
  /** column name */
  To = 'to'
}

export type Jobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Jobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Jobs_Var_Pop_Fields = {
  __typename?: 'jobs_var_pop_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "jobs" */
export type Jobs_Var_Pop_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Jobs_Var_Samp_Fields = {
  __typename?: 'jobs_var_samp_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "jobs" */
export type Jobs_Var_Samp_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Jobs_Variance_Fields = {
  __typename?: 'jobs_variance_fields';
  assignedUserId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  operationId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "jobs" */
export type Jobs_Variance_Order_By = {
  assignedUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  api_accept_invite?: Maybe<Api_Accept_Invite_Output>;
  api_sign_up_user?: Maybe<Api_Sign_Up_User_Output>;
  /** delete data from the table: "farms" */
  delete_farms?: Maybe<Farms_Mutation_Response>;
  /** delete single row from the table: "farms" */
  delete_farms_by_pk?: Maybe<Farms>;
  /** delete data from the table: "high_res_map_annotations" */
  delete_high_res_map_annotations?: Maybe<High_Res_Map_Annotations_Mutation_Response>;
  /** delete single row from the table: "high_res_map_annotations" */
  delete_high_res_map_annotations_by_pk?: Maybe<High_Res_Map_Annotations>;
  /** delete data from the table: "high_res_map_layers" */
  delete_high_res_map_layers?: Maybe<High_Res_Map_Layers_Mutation_Response>;
  /** delete single row from the table: "high_res_map_layers" */
  delete_high_res_map_layers_by_pk?: Maybe<High_Res_Map_Layers>;
  /** delete data from the table: "high_res_maps" */
  delete_high_res_maps?: Maybe<High_Res_Maps_Mutation_Response>;
  /** delete single row from the table: "high_res_maps" */
  delete_high_res_maps_by_pk?: Maybe<High_Res_Maps>;
  /** delete data from the table: "job_extras" */
  delete_job_extras?: Maybe<Job_Extras_Mutation_Response>;
  /** delete single row from the table: "job_extras" */
  delete_job_extras_by_pk?: Maybe<Job_Extras>;
  /** delete data from the table: "job_fields" */
  delete_job_fields?: Maybe<Job_Fields_Mutation_Response>;
  /** delete single row from the table: "job_fields" */
  delete_job_fields_by_pk?: Maybe<Job_Fields>;
  /** delete data from the table: "job_locations" */
  delete_job_locations?: Maybe<Job_Locations_Mutation_Response>;
  /** delete single row from the table: "job_locations" */
  delete_job_locations_by_pk?: Maybe<Job_Locations>;
  /** delete data from the table: "job_operator_implements" */
  delete_job_operator_implements?: Maybe<Job_Operator_Implements_Mutation_Response>;
  /** delete single row from the table: "job_operator_implements" */
  delete_job_operator_implements_by_pk?: Maybe<Job_Operator_Implements>;
  /** delete data from the table: "job_operator_vehicles" */
  delete_job_operator_vehicles?: Maybe<Job_Operator_Vehicles_Mutation_Response>;
  /** delete single row from the table: "job_operator_vehicles" */
  delete_job_operator_vehicles_by_pk?: Maybe<Job_Operator_Vehicles>;
  /** delete data from the table: "job_operators" */
  delete_job_operators?: Maybe<Job_Operators_Mutation_Response>;
  /** delete single row from the table: "job_operators" */
  delete_job_operators_by_pk?: Maybe<Job_Operators>;
  /** delete data from the table: "job_products" */
  delete_job_products?: Maybe<Job_Products_Mutation_Response>;
  /** delete single row from the table: "job_products" */
  delete_job_products_by_pk?: Maybe<Job_Products>;
  /** delete data from the table: "jobs" */
  delete_jobs?: Maybe<Jobs_Mutation_Response>;
  /** delete single row from the table: "jobs" */
  delete_jobs_by_pk?: Maybe<Jobs>;
  /** delete data from the table: "operation_extras" */
  delete_operation_extras?: Maybe<Operation_Extras_Mutation_Response>;
  /** delete single row from the table: "operation_extras" */
  delete_operation_extras_by_pk?: Maybe<Operation_Extras>;
  /** delete data from the table: "operation_products" */
  delete_operation_products?: Maybe<Operation_Products_Mutation_Response>;
  /** delete single row from the table: "operation_products" */
  delete_operation_products_by_pk?: Maybe<Operation_Products>;
  /** delete data from the table: "user_invites" */
  delete_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** delete single row from the table: "user_invites" */
  delete_user_invites_by_pk?: Maybe<User_Invites>;
  /** insert data into the table: "farms" */
  insert_farms?: Maybe<Farms_Mutation_Response>;
  /** insert a single row into the table: "farms" */
  insert_farms_one?: Maybe<Farms>;
  /** insert data into the table: "field_season_shot" */
  insert_field_season_shot?: Maybe<Field_Season_Shot_Mutation_Response>;
  /** insert a single row into the table: "field_season_shot" */
  insert_field_season_shot_one?: Maybe<Field_Season_Shot>;
  /** insert data into the table: "fields" */
  insert_fields?: Maybe<Fields_Mutation_Response>;
  /** insert a single row into the table: "fields" */
  insert_fields_one?: Maybe<Fields>;
  /** insert data into the table: "high_res_map_annotations" */
  insert_high_res_map_annotations?: Maybe<High_Res_Map_Annotations_Mutation_Response>;
  /** insert a single row into the table: "high_res_map_annotations" */
  insert_high_res_map_annotations_one?: Maybe<High_Res_Map_Annotations>;
  /** insert data into the table: "high_res_map_layers" */
  insert_high_res_map_layers?: Maybe<High_Res_Map_Layers_Mutation_Response>;
  /** insert a single row into the table: "high_res_map_layers" */
  insert_high_res_map_layers_one?: Maybe<High_Res_Map_Layers>;
  /** insert data into the table: "high_res_maps" */
  insert_high_res_maps?: Maybe<High_Res_Maps_Mutation_Response>;
  /** insert a single row into the table: "high_res_maps" */
  insert_high_res_maps_one?: Maybe<High_Res_Maps>;
  /** insert data into the table: "implements" */
  insert_implements?: Maybe<Implements_Mutation_Response>;
  /** insert a single row into the table: "implements" */
  insert_implements_one?: Maybe<Implements>;
  /** insert data into the table: "inventory" */
  insert_inventory?: Maybe<Inventory_Mutation_Response>;
  /** insert a single row into the table: "inventory" */
  insert_inventory_one?: Maybe<Inventory>;
  /** insert data into the table: "job_extras" */
  insert_job_extras?: Maybe<Job_Extras_Mutation_Response>;
  /** insert a single row into the table: "job_extras" */
  insert_job_extras_one?: Maybe<Job_Extras>;
  /** insert data into the table: "job_fields" */
  insert_job_fields?: Maybe<Job_Fields_Mutation_Response>;
  /** insert a single row into the table: "job_fields" */
  insert_job_fields_one?: Maybe<Job_Fields>;
  /** insert data into the table: "job_locations" */
  insert_job_locations?: Maybe<Job_Locations_Mutation_Response>;
  /** insert a single row into the table: "job_locations" */
  insert_job_locations_one?: Maybe<Job_Locations>;
  /** insert data into the table: "job_operator_implements" */
  insert_job_operator_implements?: Maybe<Job_Operator_Implements_Mutation_Response>;
  /** insert a single row into the table: "job_operator_implements" */
  insert_job_operator_implements_one?: Maybe<Job_Operator_Implements>;
  /** insert data into the table: "job_operator_vehicles" */
  insert_job_operator_vehicles?: Maybe<Job_Operator_Vehicles_Mutation_Response>;
  /** insert a single row into the table: "job_operator_vehicles" */
  insert_job_operator_vehicles_one?: Maybe<Job_Operator_Vehicles>;
  /** insert data into the table: "job_operators" */
  insert_job_operators?: Maybe<Job_Operators_Mutation_Response>;
  /** insert a single row into the table: "job_operators" */
  insert_job_operators_one?: Maybe<Job_Operators>;
  /** insert data into the table: "job_products" */
  insert_job_products?: Maybe<Job_Products_Mutation_Response>;
  /** insert a single row into the table: "job_products" */
  insert_job_products_one?: Maybe<Job_Products>;
  /** insert data into the table: "jobs" */
  insert_jobs?: Maybe<Jobs_Mutation_Response>;
  /** insert a single row into the table: "jobs" */
  insert_jobs_one?: Maybe<Jobs>;
  /** insert data into the table: "operation_extras" */
  insert_operation_extras?: Maybe<Operation_Extras_Mutation_Response>;
  /** insert a single row into the table: "operation_extras" */
  insert_operation_extras_one?: Maybe<Operation_Extras>;
  /** insert data into the table: "operation_products" */
  insert_operation_products?: Maybe<Operation_Products_Mutation_Response>;
  /** insert a single row into the table: "operation_products" */
  insert_operation_products_one?: Maybe<Operation_Products>;
  /** insert data into the table: "operations" */
  insert_operations?: Maybe<Operations_Mutation_Response>;
  /** insert a single row into the table: "operations" */
  insert_operations_one?: Maybe<Operations>;
  /** insert data into the table: "polygons" */
  insert_polygons?: Maybe<Polygons_Mutation_Response>;
  /** insert a single row into the table: "polygons" */
  insert_polygons_one?: Maybe<Polygons>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "user_invites" */
  insert_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** insert a single row into the table: "user_invites" */
  insert_user_invites_one?: Maybe<User_Invites>;
  /** insert data into the table: "user_prefs" */
  insert_user_prefs?: Maybe<User_Prefs_Mutation_Response>;
  /** insert a single row into the table: "user_prefs" */
  insert_user_prefs_one?: Maybe<User_Prefs>;
  /** insert data into the table: "user_profiles" */
  insert_user_profiles?: Maybe<User_Profiles_Mutation_Response>;
  /** insert a single row into the table: "user_profiles" */
  insert_user_profiles_one?: Maybe<User_Profiles>;
  /** insert data into the table: "user_tax_rates" */
  insert_user_tax_rates?: Maybe<User_Tax_Rates_Mutation_Response>;
  /** insert a single row into the table: "user_tax_rates" */
  insert_user_tax_rates_one?: Maybe<User_Tax_Rates>;
  /** insert data into the table: "vehicles" */
  insert_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** insert a single row into the table: "vehicles" */
  insert_vehicles_one?: Maybe<Vehicles>;
  /** insert data into the table: "warehouses" */
  insert_warehouses?: Maybe<Warehouses_Mutation_Response>;
  /** insert a single row into the table: "warehouses" */
  insert_warehouses_one?: Maybe<Warehouses>;
  required?: Maybe<Scalars['Int']['output']>;
  /** update data of the table: "farms" */
  update_farms?: Maybe<Farms_Mutation_Response>;
  /** update single row of the table: "farms" */
  update_farms_by_pk?: Maybe<Farms>;
  /** update multiples rows of table: "farms" */
  update_farms_many?: Maybe<Array<Maybe<Farms_Mutation_Response>>>;
  /** update data of the table: "field_season_shot" */
  update_field_season_shot?: Maybe<Field_Season_Shot_Mutation_Response>;
  /** update single row of the table: "field_season_shot" */
  update_field_season_shot_by_pk?: Maybe<Field_Season_Shot>;
  /** update multiples rows of table: "field_season_shot" */
  update_field_season_shot_many?: Maybe<Array<Maybe<Field_Season_Shot_Mutation_Response>>>;
  /** update data of the table: "high_res_map_annotations" */
  update_high_res_map_annotations?: Maybe<High_Res_Map_Annotations_Mutation_Response>;
  /** update single row of the table: "high_res_map_annotations" */
  update_high_res_map_annotations_by_pk?: Maybe<High_Res_Map_Annotations>;
  /** update multiples rows of table: "high_res_map_annotations" */
  update_high_res_map_annotations_many?: Maybe<Array<Maybe<High_Res_Map_Annotations_Mutation_Response>>>;
  /** update data of the table: "high_res_map_layers" */
  update_high_res_map_layers?: Maybe<High_Res_Map_Layers_Mutation_Response>;
  /** update single row of the table: "high_res_map_layers" */
  update_high_res_map_layers_by_pk?: Maybe<High_Res_Map_Layers>;
  /** update multiples rows of table: "high_res_map_layers" */
  update_high_res_map_layers_many?: Maybe<Array<Maybe<High_Res_Map_Layers_Mutation_Response>>>;
  /** update data of the table: "high_res_maps" */
  update_high_res_maps?: Maybe<High_Res_Maps_Mutation_Response>;
  /** update single row of the table: "high_res_maps" */
  update_high_res_maps_by_pk?: Maybe<High_Res_Maps>;
  /** update multiples rows of table: "high_res_maps" */
  update_high_res_maps_many?: Maybe<Array<Maybe<High_Res_Maps_Mutation_Response>>>;
  /** update data of the table: "implements" */
  update_implements?: Maybe<Implements_Mutation_Response>;
  /** update single row of the table: "implements" */
  update_implements_by_pk?: Maybe<Implements>;
  /** update multiples rows of table: "implements" */
  update_implements_many?: Maybe<Array<Maybe<Implements_Mutation_Response>>>;
  /** update data of the table: "inventory" */
  update_inventory?: Maybe<Inventory_Mutation_Response>;
  /** update single row of the table: "inventory" */
  update_inventory_by_pk?: Maybe<Inventory>;
  /** update multiples rows of table: "inventory" */
  update_inventory_many?: Maybe<Array<Maybe<Inventory_Mutation_Response>>>;
  /** update data of the table: "job_extras" */
  update_job_extras?: Maybe<Job_Extras_Mutation_Response>;
  /** update single row of the table: "job_extras" */
  update_job_extras_by_pk?: Maybe<Job_Extras>;
  /** update multiples rows of table: "job_extras" */
  update_job_extras_many?: Maybe<Array<Maybe<Job_Extras_Mutation_Response>>>;
  /** update data of the table: "job_locations" */
  update_job_locations?: Maybe<Job_Locations_Mutation_Response>;
  /** update single row of the table: "job_locations" */
  update_job_locations_by_pk?: Maybe<Job_Locations>;
  /** update multiples rows of table: "job_locations" */
  update_job_locations_many?: Maybe<Array<Maybe<Job_Locations_Mutation_Response>>>;
  /** update data of the table: "job_operators" */
  update_job_operators?: Maybe<Job_Operators_Mutation_Response>;
  /** update single row of the table: "job_operators" */
  update_job_operators_by_pk?: Maybe<Job_Operators>;
  /** update multiples rows of table: "job_operators" */
  update_job_operators_many?: Maybe<Array<Maybe<Job_Operators_Mutation_Response>>>;
  /** update data of the table: "job_products" */
  update_job_products?: Maybe<Job_Products_Mutation_Response>;
  /** update single row of the table: "job_products" */
  update_job_products_by_pk?: Maybe<Job_Products>;
  /** update multiples rows of table: "job_products" */
  update_job_products_many?: Maybe<Array<Maybe<Job_Products_Mutation_Response>>>;
  /** update data of the table: "jobs" */
  update_jobs?: Maybe<Jobs_Mutation_Response>;
  /** update single row of the table: "jobs" */
  update_jobs_by_pk?: Maybe<Jobs>;
  /** update multiples rows of table: "jobs" */
  update_jobs_many?: Maybe<Array<Maybe<Jobs_Mutation_Response>>>;
  /** update data of the table: "operation_extras" */
  update_operation_extras?: Maybe<Operation_Extras_Mutation_Response>;
  /** update single row of the table: "operation_extras" */
  update_operation_extras_by_pk?: Maybe<Operation_Extras>;
  /** update multiples rows of table: "operation_extras" */
  update_operation_extras_many?: Maybe<Array<Maybe<Operation_Extras_Mutation_Response>>>;
  /** update data of the table: "operation_products" */
  update_operation_products?: Maybe<Operation_Products_Mutation_Response>;
  /** update single row of the table: "operation_products" */
  update_operation_products_by_pk?: Maybe<Operation_Products>;
  /** update multiples rows of table: "operation_products" */
  update_operation_products_many?: Maybe<Array<Maybe<Operation_Products_Mutation_Response>>>;
  /** update data of the table: "operations" */
  update_operations?: Maybe<Operations_Mutation_Response>;
  /** update single row of the table: "operations" */
  update_operations_by_pk?: Maybe<Operations>;
  /** update multiples rows of table: "operations" */
  update_operations_many?: Maybe<Array<Maybe<Operations_Mutation_Response>>>;
  /** update data of the table: "polygons" */
  update_polygons?: Maybe<Polygons_Mutation_Response>;
  /** update single row of the table: "polygons" */
  update_polygons_by_pk?: Maybe<Polygons>;
  /** update multiples rows of table: "polygons" */
  update_polygons_many?: Maybe<Array<Maybe<Polygons_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<Reports_Mutation_Response>>>;
  /** update data of the table: "user_prefs" */
  update_user_prefs?: Maybe<User_Prefs_Mutation_Response>;
  /** update single row of the table: "user_prefs" */
  update_user_prefs_by_pk?: Maybe<User_Prefs>;
  /** update multiples rows of table: "user_prefs" */
  update_user_prefs_many?: Maybe<Array<Maybe<User_Prefs_Mutation_Response>>>;
  /** update data of the table: "user_profiles" */
  update_user_profiles?: Maybe<User_Profiles_Mutation_Response>;
  /** update single row of the table: "user_profiles" */
  update_user_profiles_by_pk?: Maybe<User_Profiles>;
  /** update multiples rows of table: "user_profiles" */
  update_user_profiles_many?: Maybe<Array<Maybe<User_Profiles_Mutation_Response>>>;
  /** update data of the table: "user_tax_rates" */
  update_user_tax_rates?: Maybe<User_Tax_Rates_Mutation_Response>;
  /** update single row of the table: "user_tax_rates" */
  update_user_tax_rates_by_pk?: Maybe<User_Tax_Rates>;
  /** update multiples rows of table: "user_tax_rates" */
  update_user_tax_rates_many?: Maybe<Array<Maybe<User_Tax_Rates_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vehicles" */
  update_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** update single row of the table: "vehicles" */
  update_vehicles_by_pk?: Maybe<Vehicles>;
  /** update multiples rows of table: "vehicles" */
  update_vehicles_many?: Maybe<Array<Maybe<Vehicles_Mutation_Response>>>;
  /** update data of the table: "warehouses" */
  update_warehouses?: Maybe<Warehouses_Mutation_Response>;
  /** update single row of the table: "warehouses" */
  update_warehouses_by_pk?: Maybe<Warehouses>;
  /** update multiples rows of table: "warehouses" */
  update_warehouses_many?: Maybe<Array<Maybe<Warehouses_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootApi_Accept_InviteArgs = {
  input?: InputMaybe<Api_Accept_Invite_Input>;
};


/** mutation root */
export type Mutation_RootApi_Sign_Up_UserArgs = {
  input?: InputMaybe<Api_Sign_Up_User_Input>;
};


/** mutation root */
export type Mutation_RootDelete_FarmsArgs = {
  where: Farms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_High_Res_Map_AnnotationsArgs = {
  where: High_Res_Map_Annotations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_High_Res_Map_Annotations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_High_Res_Map_LayersArgs = {
  where: High_Res_Map_Layers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_High_Res_Map_Layers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_High_Res_MapsArgs = {
  where: High_Res_Maps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_High_Res_Maps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_ExtrasArgs = {
  where: Job_Extras_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Extras_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_FieldsArgs = {
  where: Job_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Fields_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  seasonShotId: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_LocationsArgs = {
  where: Job_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Locations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_Operator_ImplementsArgs = {
  where: Job_Operator_Implements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Operator_Implements_By_PkArgs = {
  implementId: Scalars['uuid']['input'];
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_Operator_VehiclesArgs = {
  where: Job_Operator_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Operator_Vehicles_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
  vehicleId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_OperatorsArgs = {
  where: Job_Operators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Operators_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Job_ProductsArgs = {
  where: Job_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Products_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_JobsArgs = {
  where: Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Jobs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_ExtrasArgs = {
  where: Operation_Extras_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Extras_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_ProductsArgs = {
  where: Operation_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Products_By_PkArgs = {
  operationId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_InvitesArgs = {
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Invites_By_PkArgs = {
  invite_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_FarmsArgs = {
  objects: Array<Farms_Insert_Input>;
  on_conflict?: InputMaybe<Farms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farms_OneArgs = {
  object: Farms_Insert_Input;
  on_conflict?: InputMaybe<Farms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Season_ShotArgs = {
  objects: Array<Field_Season_Shot_Insert_Input>;
  on_conflict?: InputMaybe<Field_Season_Shot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Season_Shot_OneArgs = {
  object: Field_Season_Shot_Insert_Input;
  on_conflict?: InputMaybe<Field_Season_Shot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FieldsArgs = {
  objects: Array<Fields_Insert_Input>;
  on_conflict?: InputMaybe<Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fields_OneArgs = {
  object: Fields_Insert_Input;
  on_conflict?: InputMaybe<Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_High_Res_Map_AnnotationsArgs = {
  objects: Array<High_Res_Map_Annotations_Insert_Input>;
  on_conflict?: InputMaybe<High_Res_Map_Annotations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_High_Res_Map_Annotations_OneArgs = {
  object: High_Res_Map_Annotations_Insert_Input;
  on_conflict?: InputMaybe<High_Res_Map_Annotations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_High_Res_Map_LayersArgs = {
  objects: Array<High_Res_Map_Layers_Insert_Input>;
  on_conflict?: InputMaybe<High_Res_Map_Layers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_High_Res_Map_Layers_OneArgs = {
  object: High_Res_Map_Layers_Insert_Input;
  on_conflict?: InputMaybe<High_Res_Map_Layers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_High_Res_MapsArgs = {
  objects: Array<High_Res_Maps_Insert_Input>;
  on_conflict?: InputMaybe<High_Res_Maps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_High_Res_Maps_OneArgs = {
  object: High_Res_Maps_Insert_Input;
  on_conflict?: InputMaybe<High_Res_Maps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImplementsArgs = {
  objects: Array<Implements_Insert_Input>;
  on_conflict?: InputMaybe<Implements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Implements_OneArgs = {
  object: Implements_Insert_Input;
  on_conflict?: InputMaybe<Implements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InventoryArgs = {
  objects: Array<Inventory_Insert_Input>;
  on_conflict?: InputMaybe<Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inventory_OneArgs = {
  object: Inventory_Insert_Input;
  on_conflict?: InputMaybe<Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_ExtrasArgs = {
  objects: Array<Job_Extras_Insert_Input>;
  on_conflict?: InputMaybe<Job_Extras_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Extras_OneArgs = {
  object: Job_Extras_Insert_Input;
  on_conflict?: InputMaybe<Job_Extras_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_FieldsArgs = {
  objects: Array<Job_Fields_Insert_Input>;
  on_conflict?: InputMaybe<Job_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Fields_OneArgs = {
  object: Job_Fields_Insert_Input;
  on_conflict?: InputMaybe<Job_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_LocationsArgs = {
  objects: Array<Job_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Job_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Locations_OneArgs = {
  object: Job_Locations_Insert_Input;
  on_conflict?: InputMaybe<Job_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Operator_ImplementsArgs = {
  objects: Array<Job_Operator_Implements_Insert_Input>;
  on_conflict?: InputMaybe<Job_Operator_Implements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Operator_Implements_OneArgs = {
  object: Job_Operator_Implements_Insert_Input;
  on_conflict?: InputMaybe<Job_Operator_Implements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Operator_VehiclesArgs = {
  objects: Array<Job_Operator_Vehicles_Insert_Input>;
  on_conflict?: InputMaybe<Job_Operator_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Operator_Vehicles_OneArgs = {
  object: Job_Operator_Vehicles_Insert_Input;
  on_conflict?: InputMaybe<Job_Operator_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_OperatorsArgs = {
  objects: Array<Job_Operators_Insert_Input>;
  on_conflict?: InputMaybe<Job_Operators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Operators_OneArgs = {
  object: Job_Operators_Insert_Input;
  on_conflict?: InputMaybe<Job_Operators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_ProductsArgs = {
  objects: Array<Job_Products_Insert_Input>;
  on_conflict?: InputMaybe<Job_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Products_OneArgs = {
  object: Job_Products_Insert_Input;
  on_conflict?: InputMaybe<Job_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobsArgs = {
  objects: Array<Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Jobs_OneArgs = {
  object: Jobs_Insert_Input;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_ExtrasArgs = {
  objects: Array<Operation_Extras_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Extras_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Extras_OneArgs = {
  object: Operation_Extras_Insert_Input;
  on_conflict?: InputMaybe<Operation_Extras_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_ProductsArgs = {
  objects: Array<Operation_Products_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Products_OneArgs = {
  object: Operation_Products_Insert_Input;
  on_conflict?: InputMaybe<Operation_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OperationsArgs = {
  objects: Array<Operations_Insert_Input>;
  on_conflict?: InputMaybe<Operations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operations_OneArgs = {
  object: Operations_Insert_Input;
  on_conflict?: InputMaybe<Operations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PolygonsArgs = {
  objects: Array<Polygons_Insert_Input>;
  on_conflict?: InputMaybe<Polygons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Polygons_OneArgs = {
  object: Polygons_Insert_Input;
  on_conflict?: InputMaybe<Polygons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: Array<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InvitesArgs = {
  objects: Array<User_Invites_Insert_Input>;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invites_OneArgs = {
  object: User_Invites_Insert_Input;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PrefsArgs = {
  objects: Array<User_Prefs_Insert_Input>;
  on_conflict?: InputMaybe<User_Prefs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Prefs_OneArgs = {
  object: User_Prefs_Insert_Input;
  on_conflict?: InputMaybe<User_Prefs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ProfilesArgs = {
  objects: Array<User_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<User_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Profiles_OneArgs = {
  object: User_Profiles_Insert_Input;
  on_conflict?: InputMaybe<User_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Tax_RatesArgs = {
  objects: Array<User_Tax_Rates_Insert_Input>;
  on_conflict?: InputMaybe<User_Tax_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Tax_Rates_OneArgs = {
  object: User_Tax_Rates_Insert_Input;
  on_conflict?: InputMaybe<User_Tax_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VehiclesArgs = {
  objects: Array<Vehicles_Insert_Input>;
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicles_OneArgs = {
  object: Vehicles_Insert_Input;
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WarehousesArgs = {
  objects: Array<Warehouses_Insert_Input>;
  on_conflict?: InputMaybe<Warehouses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Warehouses_OneArgs = {
  object: Warehouses_Insert_Input;
  on_conflict?: InputMaybe<Warehouses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_FarmsArgs = {
  _set?: InputMaybe<Farms_Set_Input>;
  where: Farms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farms_By_PkArgs = {
  _set?: InputMaybe<Farms_Set_Input>;
  pk_columns: Farms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farms_ManyArgs = {
  updates: Array<Farms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Season_ShotArgs = {
  _inc?: InputMaybe<Field_Season_Shot_Inc_Input>;
  _set?: InputMaybe<Field_Season_Shot_Set_Input>;
  where: Field_Season_Shot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Season_Shot_By_PkArgs = {
  _inc?: InputMaybe<Field_Season_Shot_Inc_Input>;
  _set?: InputMaybe<Field_Season_Shot_Set_Input>;
  pk_columns: Field_Season_Shot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Season_Shot_ManyArgs = {
  updates: Array<Field_Season_Shot_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Map_AnnotationsArgs = {
  _inc?: InputMaybe<High_Res_Map_Annotations_Inc_Input>;
  _set?: InputMaybe<High_Res_Map_Annotations_Set_Input>;
  where: High_Res_Map_Annotations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Map_Annotations_By_PkArgs = {
  _inc?: InputMaybe<High_Res_Map_Annotations_Inc_Input>;
  _set?: InputMaybe<High_Res_Map_Annotations_Set_Input>;
  pk_columns: High_Res_Map_Annotations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Map_Annotations_ManyArgs = {
  updates: Array<High_Res_Map_Annotations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Map_LayersArgs = {
  _set?: InputMaybe<High_Res_Map_Layers_Set_Input>;
  where: High_Res_Map_Layers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Map_Layers_By_PkArgs = {
  _set?: InputMaybe<High_Res_Map_Layers_Set_Input>;
  pk_columns: High_Res_Map_Layers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Map_Layers_ManyArgs = {
  updates: Array<High_Res_Map_Layers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_MapsArgs = {
  _set?: InputMaybe<High_Res_Maps_Set_Input>;
  where: High_Res_Maps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Maps_By_PkArgs = {
  _set?: InputMaybe<High_Res_Maps_Set_Input>;
  pk_columns: High_Res_Maps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_High_Res_Maps_ManyArgs = {
  updates: Array<High_Res_Maps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImplementsArgs = {
  _inc?: InputMaybe<Implements_Inc_Input>;
  _set?: InputMaybe<Implements_Set_Input>;
  where: Implements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Implements_By_PkArgs = {
  _inc?: InputMaybe<Implements_Inc_Input>;
  _set?: InputMaybe<Implements_Set_Input>;
  pk_columns: Implements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Implements_ManyArgs = {
  updates: Array<Implements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InventoryArgs = {
  _inc?: InputMaybe<Inventory_Inc_Input>;
  _set?: InputMaybe<Inventory_Set_Input>;
  where: Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inventory_By_PkArgs = {
  _inc?: InputMaybe<Inventory_Inc_Input>;
  _set?: InputMaybe<Inventory_Set_Input>;
  pk_columns: Inventory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inventory_ManyArgs = {
  updates: Array<Inventory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_ExtrasArgs = {
  _set?: InputMaybe<Job_Extras_Set_Input>;
  where: Job_Extras_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Extras_By_PkArgs = {
  _set?: InputMaybe<Job_Extras_Set_Input>;
  pk_columns: Job_Extras_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Extras_ManyArgs = {
  updates: Array<Job_Extras_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_LocationsArgs = {
  _set?: InputMaybe<Job_Locations_Set_Input>;
  where: Job_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Locations_By_PkArgs = {
  _set?: InputMaybe<Job_Locations_Set_Input>;
  pk_columns: Job_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Locations_ManyArgs = {
  updates: Array<Job_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_OperatorsArgs = {
  _set?: InputMaybe<Job_Operators_Set_Input>;
  where: Job_Operators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Operators_By_PkArgs = {
  _set?: InputMaybe<Job_Operators_Set_Input>;
  pk_columns: Job_Operators_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Operators_ManyArgs = {
  updates: Array<Job_Operators_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_ProductsArgs = {
  _set?: InputMaybe<Job_Products_Set_Input>;
  where: Job_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Products_By_PkArgs = {
  _set?: InputMaybe<Job_Products_Set_Input>;
  pk_columns: Job_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Products_ManyArgs = {
  updates: Array<Job_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobsArgs = {
  _inc?: InputMaybe<Jobs_Inc_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  where: Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_By_PkArgs = {
  _inc?: InputMaybe<Jobs_Inc_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  pk_columns: Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Jobs_ManyArgs = {
  updates: Array<Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_ExtrasArgs = {
  _set?: InputMaybe<Operation_Extras_Set_Input>;
  where: Operation_Extras_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Extras_By_PkArgs = {
  _set?: InputMaybe<Operation_Extras_Set_Input>;
  pk_columns: Operation_Extras_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Extras_ManyArgs = {
  updates: Array<Operation_Extras_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_ProductsArgs = {
  where: Operation_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Products_By_PkArgs = {
  pk_columns: Operation_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Products_ManyArgs = {
  updates: Array<Operation_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OperationsArgs = {
  _inc?: InputMaybe<Operations_Inc_Input>;
  _set?: InputMaybe<Operations_Set_Input>;
  where: Operations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operations_By_PkArgs = {
  _inc?: InputMaybe<Operations_Inc_Input>;
  _set?: InputMaybe<Operations_Set_Input>;
  pk_columns: Operations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operations_ManyArgs = {
  updates: Array<Operations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PolygonsArgs = {
  _inc?: InputMaybe<Polygons_Inc_Input>;
  _set?: InputMaybe<Polygons_Set_Input>;
  where: Polygons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Polygons_By_PkArgs = {
  _inc?: InputMaybe<Polygons_Inc_Input>;
  _set?: InputMaybe<Polygons_Set_Input>;
  pk_columns: Polygons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Polygons_ManyArgs = {
  updates: Array<Polygons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _append?: InputMaybe<Reports_Append_Input>;
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _append?: InputMaybe<Reports_Append_Input>;
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: Array<Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PrefsArgs = {
  _inc?: InputMaybe<User_Prefs_Inc_Input>;
  _set?: InputMaybe<User_Prefs_Set_Input>;
  where: User_Prefs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Prefs_By_PkArgs = {
  _inc?: InputMaybe<User_Prefs_Inc_Input>;
  _set?: InputMaybe<User_Prefs_Set_Input>;
  pk_columns: User_Prefs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Prefs_ManyArgs = {
  updates: Array<User_Prefs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ProfilesArgs = {
  _set?: InputMaybe<User_Profiles_Set_Input>;
  where: User_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Profiles_By_PkArgs = {
  _set?: InputMaybe<User_Profiles_Set_Input>;
  pk_columns: User_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Profiles_ManyArgs = {
  updates: Array<User_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Tax_RatesArgs = {
  _inc?: InputMaybe<User_Tax_Rates_Inc_Input>;
  _set?: InputMaybe<User_Tax_Rates_Set_Input>;
  where: User_Tax_Rates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Tax_Rates_By_PkArgs = {
  _inc?: InputMaybe<User_Tax_Rates_Inc_Input>;
  _set?: InputMaybe<User_Tax_Rates_Set_Input>;
  pk_columns: User_Tax_Rates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Tax_Rates_ManyArgs = {
  updates: Array<User_Tax_Rates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VehiclesArgs = {
  _inc?: InputMaybe<Vehicles_Inc_Input>;
  _set?: InputMaybe<Vehicles_Set_Input>;
  where: Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_By_PkArgs = {
  _inc?: InputMaybe<Vehicles_Inc_Input>;
  _set?: InputMaybe<Vehicles_Set_Input>;
  pk_columns: Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_ManyArgs = {
  updates: Array<Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WarehousesArgs = {
  _inc?: InputMaybe<Warehouses_Inc_Input>;
  _set?: InputMaybe<Warehouses_Set_Input>;
  where: Warehouses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Warehouses_By_PkArgs = {
  _inc?: InputMaybe<Warehouses_Inc_Input>;
  _set?: InputMaybe<Warehouses_Set_Input>;
  pk_columns: Warehouses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Warehouses_ManyArgs = {
  updates: Array<Warehouses_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "operation_extras" */
export type Operation_Extras = {
  __typename?: 'operation_extras';
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  operation: Operations;
  operationId: Scalars['bigint']['output'];
  unit: Chargeable_Units_Enum;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "operation_extras" */
export type Operation_Extras_Aggregate_Order_By = {
  avg?: InputMaybe<Operation_Extras_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Operation_Extras_Max_Order_By>;
  min?: InputMaybe<Operation_Extras_Min_Order_By>;
  stddev?: InputMaybe<Operation_Extras_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Operation_Extras_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Operation_Extras_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Operation_Extras_Sum_Order_By>;
  var_pop?: InputMaybe<Operation_Extras_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Operation_Extras_Var_Samp_Order_By>;
  variance?: InputMaybe<Operation_Extras_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "operation_extras" */
export type Operation_Extras_Arr_Rel_Insert_Input = {
  data: Array<Operation_Extras_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Extras_On_Conflict>;
};

/** order by avg() on columns of table "operation_extras" */
export type Operation_Extras_Avg_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "operation_extras". All fields are combined with a logical 'AND'. */
export type Operation_Extras_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Extras_Bool_Exp>>;
  _not?: InputMaybe<Operation_Extras_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Extras_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operation?: InputMaybe<Operations_Bool_Exp>;
  operationId?: InputMaybe<Bigint_Comparison_Exp>;
  unit?: InputMaybe<Chargeable_Units_Enum_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_extras" */
export enum Operation_Extras_Constraint {
  /** unique or primary key constraint on columns "name", "id" */
  OperationExtrasIdNameKey = 'operation_extras_id_name_key',
  /** unique or primary key constraint on columns "id" */
  OperationExtrasPkey = 'operation_extras_pkey'
}

/** input type for inserting data into table "operation_extras" */
export type Operation_Extras_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  operation?: InputMaybe<Operations_Obj_Rel_Insert_Input>;
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  unit?: InputMaybe<Chargeable_Units_Enum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "operation_extras" */
export type Operation_Extras_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "operation_extras" */
export type Operation_Extras_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operationId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "operation_extras" */
export type Operation_Extras_Mutation_Response = {
  __typename?: 'operation_extras_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Extras>;
};

/** on_conflict condition type for table "operation_extras" */
export type Operation_Extras_On_Conflict = {
  constraint: Operation_Extras_Constraint;
  update_columns?: Array<Operation_Extras_Update_Column>;
  where?: InputMaybe<Operation_Extras_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_extras". */
export type Operation_Extras_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operation?: InputMaybe<Operations_Order_By>;
  operationId?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_extras */
export type Operation_Extras_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "operation_extras" */
export enum Operation_Extras_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OperationId = 'operationId',
  /** column name */
  Unit = 'unit',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "operation_extras" */
export type Operation_Extras_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Chargeable_Units_Enum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "operation_extras" */
export type Operation_Extras_Stddev_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "operation_extras" */
export type Operation_Extras_Stddev_Pop_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "operation_extras" */
export type Operation_Extras_Stddev_Samp_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "operation_extras" */
export type Operation_Extras_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Extras_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Extras_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  unit?: InputMaybe<Chargeable_Units_Enum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "operation_extras" */
export type Operation_Extras_Sum_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** update columns of table "operation_extras" */
export enum Operation_Extras_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Unit = 'unit',
  /** column name */
  Value = 'value'
}

export type Operation_Extras_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Extras_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Extras_Bool_Exp;
};

/** order by var_pop() on columns of table "operation_extras" */
export type Operation_Extras_Var_Pop_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "operation_extras" */
export type Operation_Extras_Var_Samp_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "operation_extras" */
export type Operation_Extras_Variance_Order_By = {
  operationId?: InputMaybe<Order_By>;
};

/** One to Many relational mapping Operation -> Products */
export type Operation_Products = {
  __typename?: 'operation_products';
  operationId: Scalars['bigint']['output'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid']['output'];
  userId: Scalars['Int']['output'];
};

/** order by aggregate values of table "operation_products" */
export type Operation_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Operation_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Operation_Products_Max_Order_By>;
  min?: InputMaybe<Operation_Products_Min_Order_By>;
  stddev?: InputMaybe<Operation_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Operation_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Operation_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Operation_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Operation_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Operation_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Operation_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "operation_products" */
export type Operation_Products_Arr_Rel_Insert_Input = {
  data: Array<Operation_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Products_On_Conflict>;
};

/** order by avg() on columns of table "operation_products" */
export type Operation_Products_Avg_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "operation_products". All fields are combined with a logical 'AND'. */
export type Operation_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Products_Bool_Exp>>;
  _not?: InputMaybe<Operation_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Products_Bool_Exp>>;
  operationId?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Uuid_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_products" */
export enum Operation_Products_Constraint {
  /** unique or primary key constraint on columns "product_id", "operation_id" */
  OperationProductsPkey = 'operation_products_pkey'
}

/** input type for inserting data into table "operation_products" */
export type Operation_Products_Insert_Input = {
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "operation_products" */
export type Operation_Products_Max_Order_By = {
  operationId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "operation_products" */
export type Operation_Products_Min_Order_By = {
  operationId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "operation_products" */
export type Operation_Products_Mutation_Response = {
  __typename?: 'operation_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Products>;
};

/** on_conflict condition type for table "operation_products" */
export type Operation_Products_On_Conflict = {
  constraint: Operation_Products_Constraint;
  update_columns?: Array<Operation_Products_Update_Column>;
  where?: InputMaybe<Operation_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_products". */
export type Operation_Products_Order_By = {
  operationId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_products */
export type Operation_Products_Pk_Columns_Input = {
  operationId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};

/** select columns of table "operation_products" */
export enum Operation_Products_Select_Column {
  /** column name */
  OperationId = 'operationId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UserId = 'userId'
}

/** order by stddev() on columns of table "operation_products" */
export type Operation_Products_Stddev_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "operation_products" */
export type Operation_Products_Stddev_Pop_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "operation_products" */
export type Operation_Products_Stddev_Samp_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "operation_products" */
export type Operation_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Products_Stream_Cursor_Value_Input = {
  operationId?: InputMaybe<Scalars['bigint']['input']>;
  productId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "operation_products" */
export type Operation_Products_Sum_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "operation_products" (current role has no relevant permissions) */
export enum Operation_Products_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type Operation_Products_Updates = {
  /** filter the rows which have to be updated */
  where: Operation_Products_Bool_Exp;
};

/** order by var_pop() on columns of table "operation_products" */
export type Operation_Products_Var_Pop_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "operation_products" */
export type Operation_Products_Var_Samp_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "operation_products" */
export type Operation_Products_Variance_Order_By = {
  operationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "operations" */
export type Operations = {
  __typename?: 'operations';
  billingRate: Scalars['numeric']['output'];
  billingUnit: Chargeable_Units_Enum;
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  extras: Array<Operation_Extras>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  products: Array<Operation_Products>;
  status: Entity_Status_Enum;
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['Int']['output'];
};


/** columns and relationships of "operations" */
export type OperationsExtrasArgs = {
  distinct_on?: InputMaybe<Array<Operation_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operation_Extras_Order_By>>;
  where?: InputMaybe<Operation_Extras_Bool_Exp>;
};


/** columns and relationships of "operations" */
export type OperationsJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "operations" */
export type OperationsJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


/** columns and relationships of "operations" */
export type OperationsProductsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operation_Products_Order_By>>;
  where?: InputMaybe<Operation_Products_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "operations". All fields are combined with a logical 'AND'. */
export type Operations_Bool_Exp = {
  _and?: InputMaybe<Array<Operations_Bool_Exp>>;
  _not?: InputMaybe<Operations_Bool_Exp>;
  _or?: InputMaybe<Array<Operations_Bool_Exp>>;
  billingRate?: InputMaybe<Numeric_Comparison_Exp>;
  billingUnit?: InputMaybe<Chargeable_Units_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  extras?: InputMaybe<Operation_Extras_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  jobs?: InputMaybe<Jobs_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  products?: InputMaybe<Operation_Products_Bool_Exp>;
  status?: InputMaybe<Entity_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "operations" */
export enum Operations_Constraint {
  /** unique or primary key constraint on columns "id" */
  OperationsPkey = 'operations_pkey'
}

/** input type for incrementing numeric columns in table "operations" */
export type Operations_Inc_Input = {
  billingRate?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "operations" */
export type Operations_Insert_Input = {
  billingRate?: InputMaybe<Scalars['numeric']['input']>;
  billingUnit?: InputMaybe<Chargeable_Units_Enum>;
  description?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Operation_Extras_Arr_Rel_Insert_Input>;
  jobs?: InputMaybe<Jobs_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Operation_Products_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "operations" */
export type Operations_Mutation_Response = {
  __typename?: 'operations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Operations>;
};

/** input type for inserting object relation for remote table "operations" */
export type Operations_Obj_Rel_Insert_Input = {
  data: Operations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operations_On_Conflict>;
};

/** on_conflict condition type for table "operations" */
export type Operations_On_Conflict = {
  constraint: Operations_Constraint;
  update_columns?: Array<Operations_Update_Column>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

/** Ordering options when selecting data from "operations". */
export type Operations_Order_By = {
  billingRate?: InputMaybe<Order_By>;
  billingUnit?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  extras_aggregate?: InputMaybe<Operation_Extras_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Operation_Products_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operations */
export type Operations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "operations" */
export enum Operations_Select_Column {
  /** column name */
  BillingRate = 'billingRate',
  /** column name */
  BillingUnit = 'billingUnit',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "operations" */
export type Operations_Set_Input = {
  billingRate?: InputMaybe<Scalars['numeric']['input']>;
  billingUnit?: InputMaybe<Chargeable_Units_Enum>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "operations" */
export type Operations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operations_Stream_Cursor_Value_Input = {
  billingRate?: InputMaybe<Scalars['numeric']['input']>;
  billingUnit?: InputMaybe<Chargeable_Units_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "operations" */
export enum Operations_Update_Column {
  /** column name */
  BillingRate = 'billingRate',
  /** column name */
  BillingUnit = 'billingUnit',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type'
}

export type Operations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operations_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export enum Ownership_Types_Enum {
  Other = 'other',
  Owned = 'owned',
  Rented = 'rented'
}

/** Boolean expression to compare columns of type "ownership_types_enum". All fields are combined with logical 'AND'. */
export type Ownership_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ownership_Types_Enum>;
  _in?: InputMaybe<Array<Ownership_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Ownership_Types_Enum>;
  _nin?: InputMaybe<Array<Ownership_Types_Enum>>;
};

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type Point_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['point']['input']>;
  _gt?: InputMaybe<Scalars['point']['input']>;
  _gte?: InputMaybe<Scalars['point']['input']>;
  _in?: InputMaybe<Array<Scalars['point']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['point']['input']>;
  _lte?: InputMaybe<Scalars['point']['input']>;
  _neq?: InputMaybe<Scalars['point']['input']>;
  _nin?: InputMaybe<Array<Scalars['point']['input']>>;
};

/** Boolean expression to compare columns of type "polygon". All fields are combined with logical 'AND'. */
export type Polygon_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['polygon']['input']>;
  _gt?: InputMaybe<Scalars['polygon']['input']>;
  _gte?: InputMaybe<Scalars['polygon']['input']>;
  _in?: InputMaybe<Array<Scalars['polygon']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['polygon']['input']>;
  _lte?: InputMaybe<Scalars['polygon']['input']>;
  _neq?: InputMaybe<Scalars['polygon']['input']>;
  _nin?: InputMaybe<Array<Scalars['polygon']['input']>>;
};

/** columns and relationships of "polygons" */
export type Polygons = {
  __typename?: 'polygons';
  /** An object relationship */
  defaultFarm: Farms;
  defaultFarmId: Scalars['bigint']['output'];
  geometry?: Maybe<Scalars['polygon']['output']>;
  geometry_wkt?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  seasonShots: Array<Field_Season_Shot>;
  size?: Maybe<Scalars['float8']['output']>;
};


/** columns and relationships of "polygons" */
export type PolygonsSeasonShotsArgs = {
  distinct_on?: InputMaybe<Array<Field_Season_Shot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Season_Shot_Order_By>>;
  where?: InputMaybe<Field_Season_Shot_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "polygons". All fields are combined with a logical 'AND'. */
export type Polygons_Bool_Exp = {
  _and?: InputMaybe<Array<Polygons_Bool_Exp>>;
  _not?: InputMaybe<Polygons_Bool_Exp>;
  _or?: InputMaybe<Array<Polygons_Bool_Exp>>;
  defaultFarm?: InputMaybe<Farms_Bool_Exp>;
  defaultFarmId?: InputMaybe<Bigint_Comparison_Exp>;
  geometry?: InputMaybe<Polygon_Comparison_Exp>;
  geometry_wkt?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  seasonShots?: InputMaybe<Field_Season_Shot_Bool_Exp>;
  size?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "polygons" */
export enum Polygons_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolygonsPkey = 'polygons_pkey'
}

/** input type for incrementing numeric columns in table "polygons" */
export type Polygons_Inc_Input = {
  size?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "polygons" */
export type Polygons_Insert_Input = {
  defaultFarm?: InputMaybe<Farms_Obj_Rel_Insert_Input>;
  defaultFarmId?: InputMaybe<Scalars['bigint']['input']>;
  geometry?: InputMaybe<Scalars['polygon']['input']>;
  geometry_wkt?: InputMaybe<Scalars['String']['input']>;
  seasonShots?: InputMaybe<Field_Season_Shot_Arr_Rel_Insert_Input>;
  size?: InputMaybe<Scalars['float8']['input']>;
};

/** response of any mutation on the table "polygons" */
export type Polygons_Mutation_Response = {
  __typename?: 'polygons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Polygons>;
};

/** input type for inserting object relation for remote table "polygons" */
export type Polygons_Obj_Rel_Insert_Input = {
  data: Polygons_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Polygons_On_Conflict>;
};

/** on_conflict condition type for table "polygons" */
export type Polygons_On_Conflict = {
  constraint: Polygons_Constraint;
  update_columns?: Array<Polygons_Update_Column>;
  where?: InputMaybe<Polygons_Bool_Exp>;
};

/** Ordering options when selecting data from "polygons". */
export type Polygons_Order_By = {
  defaultFarm?: InputMaybe<Farms_Order_By>;
  defaultFarmId?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  geometry_wkt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seasonShots_aggregate?: InputMaybe<Field_Season_Shot_Aggregate_Order_By>;
  size?: InputMaybe<Order_By>;
};

/** primary key columns input for table: polygons */
export type Polygons_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "polygons" */
export enum Polygons_Select_Column {
  /** column name */
  DefaultFarmId = 'defaultFarmId',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  GeometryWkt = 'geometry_wkt',
  /** column name */
  Id = 'id',
  /** column name */
  Size = 'size'
}

/** input type for updating data in table "polygons" */
export type Polygons_Set_Input = {
  geometry?: InputMaybe<Scalars['polygon']['input']>;
  geometry_wkt?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['float8']['input']>;
};

/** Streaming cursor of the table "polygons" */
export type Polygons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Polygons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Polygons_Stream_Cursor_Value_Input = {
  defaultFarmId?: InputMaybe<Scalars['bigint']['input']>;
  geometry?: InputMaybe<Scalars['polygon']['input']>;
  geometry_wkt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  size?: InputMaybe<Scalars['float8']['input']>;
};

/** update columns of table "polygons" */
export enum Polygons_Update_Column {
  /** column name */
  Geometry = 'geometry',
  /** column name */
  GeometryWkt = 'geometry_wkt',
  /** column name */
  Size = 'size'
}

export type Polygons_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Polygons_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Polygons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Polygons_Bool_Exp;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  chargeableUnit: Chargeable_Units_Enum;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  jobs: Array<Job_Products>;
  name: Scalars['String']['output'];
  pricePerUnit: Scalars['numeric']['output'];
  status: Entity_Status_Enum;
  userId: Scalars['Int']['output'];
};


/** columns and relationships of "products" */
export type ProductsJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Products_Order_By>>;
  where?: InputMaybe<Job_Products_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  chargeableUnit?: InputMaybe<Chargeable_Units_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobs?: InputMaybe<Job_Products_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pricePerUnit?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<Entity_Status_Enum_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  pricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  chargeableUnit?: InputMaybe<Chargeable_Units_Enum>;
  jobs?: InputMaybe<Job_Products_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  pricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pricePerUnit?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pricePerUnit?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  chargeableUnit?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Products_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  ChargeableUnit = 'chargeableUnit',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  chargeableUnit?: InputMaybe<Chargeable_Units_Enum>;
  name?: InputMaybe<Scalars['String']['input']>;
  pricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  chargeableUnit?: InputMaybe<Chargeable_Units_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pricePerUnit?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  pricePerUnit?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  ChargeableUnit = 'chargeableUnit',
  /** column name */
  Name = 'name',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  Status = 'status'
}

export type Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  api_login_user?: Maybe<Api_Login_User_Output>;
  /** fetch data from the table: "chargeable_units" */
  chargeable_units: Array<Chargeable_Units>;
  /** fetch data from the table: "chargeable_units" using primary key columns */
  chargeable_units_by_pk?: Maybe<Chargeable_Units>;
  /** fetch data from the table: "farms" */
  farms: Array<Farms>;
  /** fetch data from the table: "farms" using primary key columns */
  farms_by_pk?: Maybe<Farms>;
  /** fetch data from the table: "field_season_shot" */
  field_season_shot: Array<Field_Season_Shot>;
  /** fetch data from the table: "field_season_shot" using primary key columns */
  field_season_shot_by_pk?: Maybe<Field_Season_Shot>;
  /** fetch data from the table: "fields" */
  fields: Array<Fields>;
  /** fetch data from the table: "fields" using primary key columns */
  fields_by_pk?: Maybe<Fields>;
  /** fetch data from the table: "high_res_map_annotations" */
  high_res_map_annotations: Array<High_Res_Map_Annotations>;
  /** fetch data from the table: "high_res_map_annotations" using primary key columns */
  high_res_map_annotations_by_pk?: Maybe<High_Res_Map_Annotations>;
  /** fetch data from the table: "high_res_map_layers" */
  high_res_map_layers: Array<High_Res_Map_Layers>;
  /** fetch data from the table: "high_res_map_layers" using primary key columns */
  high_res_map_layers_by_pk?: Maybe<High_Res_Map_Layers>;
  /** fetch data from the table: "high_res_maps" */
  high_res_maps: Array<High_Res_Maps>;
  /** fetch data from the table: "high_res_maps" using primary key columns */
  high_res_maps_by_pk?: Maybe<High_Res_Maps>;
  /** fetch data from the table: "implements" */
  implements: Array<Implements>;
  /** fetch aggregated fields from the table: "implements" */
  implements_aggregate: Implements_Aggregate;
  /** fetch data from the table: "implements" using primary key columns */
  implements_by_pk?: Maybe<Implements>;
  /** fetch data from the table: "inventory" */
  inventory: Array<Inventory>;
  /** fetch aggregated fields from the table: "inventory" */
  inventory_aggregate: Inventory_Aggregate;
  /** fetch data from the table: "inventory" using primary key columns */
  inventory_by_pk?: Maybe<Inventory>;
  /** fetch data from the table: "job_extras" */
  job_extras: Array<Job_Extras>;
  /** fetch data from the table: "job_extras" using primary key columns */
  job_extras_by_pk?: Maybe<Job_Extras>;
  /** fetch data from the table: "job_fields" */
  job_fields: Array<Job_Fields>;
  /** fetch data from the table: "job_fields" using primary key columns */
  job_fields_by_pk?: Maybe<Job_Fields>;
  /** fetch data from the table: "job_locations" */
  job_locations: Array<Job_Locations>;
  /** fetch data from the table: "job_locations" using primary key columns */
  job_locations_by_pk?: Maybe<Job_Locations>;
  /** fetch data from the table: "job_operator_implements" */
  job_operator_implements: Array<Job_Operator_Implements>;
  /** fetch data from the table: "job_operator_implements" using primary key columns */
  job_operator_implements_by_pk?: Maybe<Job_Operator_Implements>;
  /** fetch data from the table: "job_operator_vehicles" */
  job_operator_vehicles: Array<Job_Operator_Vehicles>;
  /** fetch data from the table: "job_operator_vehicles" using primary key columns */
  job_operator_vehicles_by_pk?: Maybe<Job_Operator_Vehicles>;
  /** fetch data from the table: "job_operators" */
  job_operators: Array<Job_Operators>;
  /** fetch data from the table: "job_operators" using primary key columns */
  job_operators_by_pk?: Maybe<Job_Operators>;
  /** fetch data from the table: "job_products" */
  job_products: Array<Job_Products>;
  /** fetch data from the table: "job_products" using primary key columns */
  job_products_by_pk?: Maybe<Job_Products>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table: "operation_extras" */
  operation_extras: Array<Operation_Extras>;
  /** fetch data from the table: "operation_extras" using primary key columns */
  operation_extras_by_pk?: Maybe<Operation_Extras>;
  /** fetch data from the table: "operation_products" */
  operation_products: Array<Operation_Products>;
  /** fetch data from the table: "operation_products" using primary key columns */
  operation_products_by_pk?: Maybe<Operation_Products>;
  /** fetch data from the table: "operations" */
  operations: Array<Operations>;
  /** fetch data from the table: "operations" using primary key columns */
  operations_by_pk?: Maybe<Operations>;
  /** fetch data from the table: "polygons" */
  polygons: Array<Polygons>;
  /** fetch data from the table: "polygons" using primary key columns */
  polygons_by_pk?: Maybe<Polygons>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  required?: Maybe<Scalars['Int']['output']>;
  /** fetch data from the table: "team_members" */
  team_members: Array<Team_Members>;
  /** fetch data from the table: "team_members" using primary key columns */
  team_members_by_pk?: Maybe<Team_Members>;
  /** fetch data from the table: "user_invites" */
  user_invites: Array<User_Invites>;
  /** fetch aggregated fields from the table: "user_invites" */
  user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table: "user_prefs" */
  user_prefs: Array<User_Prefs>;
  /** fetch data from the table: "user_prefs" using primary key columns */
  user_prefs_by_pk?: Maybe<User_Prefs>;
  /** fetch data from the table: "user_profiles" */
  user_profiles: Array<User_Profiles>;
  /** fetch data from the table: "user_profiles" using primary key columns */
  user_profiles_by_pk?: Maybe<User_Profiles>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "user_tax_rates" */
  user_tax_rates: Array<User_Tax_Rates>;
  /** fetch data from the table: "user_tax_rates" using primary key columns */
  user_tax_rates_by_pk?: Maybe<User_Tax_Rates>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vehicles" */
  vehicles: Array<Vehicles>;
  /** fetch aggregated fields from the table: "vehicles" */
  vehicles_aggregate: Vehicles_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicles_by_pk?: Maybe<Vehicles>;
  /** fetch data from the table: "warehouses" */
  warehouses: Array<Warehouses>;
  /** fetch data from the table: "warehouses" using primary key columns */
  warehouses_by_pk?: Maybe<Warehouses>;
};


export type Query_RootApi_Login_UserArgs = {
  input?: InputMaybe<Api_Login_User_Input>;
};


export type Query_RootChargeable_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Chargeable_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chargeable_Units_Order_By>>;
  where?: InputMaybe<Chargeable_Units_Bool_Exp>;
};


export type Query_RootChargeable_Units_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootFarmsArgs = {
  distinct_on?: InputMaybe<Array<Farms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farms_Order_By>>;
  where?: InputMaybe<Farms_Bool_Exp>;
};


export type Query_RootFarms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootField_Season_ShotArgs = {
  distinct_on?: InputMaybe<Array<Field_Season_Shot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Season_Shot_Order_By>>;
  where?: InputMaybe<Field_Season_Shot_Bool_Exp>;
};


export type Query_RootField_Season_Shot_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootFieldsArgs = {
  distinct_on?: InputMaybe<Array<Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fields_Order_By>>;
  where?: InputMaybe<Fields_Bool_Exp>;
};


export type Query_RootFields_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHigh_Res_Map_AnnotationsArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Map_Annotations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Map_Annotations_Order_By>>;
  where?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
};


export type Query_RootHigh_Res_Map_Annotations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHigh_Res_Map_LayersArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Map_Layers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Map_Layers_Order_By>>;
  where?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
};


export type Query_RootHigh_Res_Map_Layers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHigh_Res_MapsArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Maps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Maps_Order_By>>;
  where?: InputMaybe<High_Res_Maps_Bool_Exp>;
};


export type Query_RootHigh_Res_Maps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootImplementsArgs = {
  distinct_on?: InputMaybe<Array<Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Implements_Order_By>>;
  where?: InputMaybe<Implements_Bool_Exp>;
};


export type Query_RootImplements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Implements_Order_By>>;
  where?: InputMaybe<Implements_Bool_Exp>;
};


export type Query_RootImplements_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootInventoryArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Inventory_Order_By>>;
  where?: InputMaybe<Inventory_Bool_Exp>;
};


export type Query_RootInventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Inventory_Order_By>>;
  where?: InputMaybe<Inventory_Bool_Exp>;
};


export type Query_RootInventory_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootJob_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Job_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Extras_Order_By>>;
  where?: InputMaybe<Job_Extras_Bool_Exp>;
};


export type Query_RootJob_Extras_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  name: Scalars['String']['input'];
};


export type Query_RootJob_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Job_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Fields_Order_By>>;
  where?: InputMaybe<Job_Fields_Bool_Exp>;
};


export type Query_RootJob_Fields_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  seasonShotId: Scalars['bigint']['input'];
};


export type Query_RootJob_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Job_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Locations_Order_By>>;
  where?: InputMaybe<Job_Locations_Bool_Exp>;
};


export type Query_RootJob_Locations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootJob_Operator_ImplementsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Implements_Order_By>>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};


export type Query_RootJob_Operator_Implements_By_PkArgs = {
  implementId: Scalars['uuid']['input'];
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};


export type Query_RootJob_Operator_VehiclesArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Vehicles_Order_By>>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};


export type Query_RootJob_Operator_Vehicles_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
  vehicleId: Scalars['uuid']['input'];
};


export type Query_RootJob_OperatorsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operators_Order_By>>;
  where?: InputMaybe<Job_Operators_Bool_Exp>;
};


export type Query_RootJob_Operators_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};


export type Query_RootJob_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Job_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Products_Order_By>>;
  where?: InputMaybe<Job_Products_Bool_Exp>;
};


export type Query_RootJob_Products_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};


export type Query_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Query_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Query_RootJobs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootOperation_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Operation_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operation_Extras_Order_By>>;
  where?: InputMaybe<Operation_Extras_Bool_Exp>;
};


export type Query_RootOperation_Extras_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOperation_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operation_Products_Order_By>>;
  where?: InputMaybe<Operation_Products_Bool_Exp>;
};


export type Query_RootOperation_Products_By_PkArgs = {
  operationId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};


export type Query_RootOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};


export type Query_RootOperations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPolygonsArgs = {
  distinct_on?: InputMaybe<Array<Polygons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Polygons_Order_By>>;
  where?: InputMaybe<Polygons_Bool_Exp>;
};


export type Query_RootPolygons_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootTeam_MembersArgs = {
  distinct_on?: InputMaybe<Array<Team_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Members_Order_By>>;
  where?: InputMaybe<Team_Members_Bool_Exp>;
};


export type Query_RootTeam_Members_By_PkArgs = {
  ownerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type Query_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_By_PkArgs = {
  invite_id: Scalars['String']['input'];
};


export type Query_RootUser_PrefsArgs = {
  distinct_on?: InputMaybe<Array<User_Prefs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Prefs_Order_By>>;
  where?: InputMaybe<User_Prefs_Bool_Exp>;
};


export type Query_RootUser_Prefs_By_PkArgs = {
  userId: Scalars['Int']['input'];
};


export type Query_RootUser_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<User_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Profiles_Order_By>>;
  where?: InputMaybe<User_Profiles_Bool_Exp>;
};


export type Query_RootUser_Profiles_By_PkArgs = {
  userId: Scalars['Int']['input'];
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  role: Roles_Enum;
  user_id: Scalars['Int']['input'];
};


export type Query_RootUser_Tax_RatesArgs = {
  distinct_on?: InputMaybe<Array<User_Tax_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tax_Rates_Order_By>>;
  where?: InputMaybe<User_Tax_Rates_Bool_Exp>;
};


export type Query_RootUser_Tax_Rates_By_PkArgs = {
  label: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Query_RootVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Query_RootVehicles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootWarehousesArgs = {
  distinct_on?: InputMaybe<Array<Warehouses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Warehouses_Order_By>>;
  where?: InputMaybe<Warehouses_Bool_Exp>;
};


export type Query_RootWarehouses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};

/** contains snapshot reports */
export type Reports = {
  __typename?: 'reports';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  jsonSummary?: Maybe<Scalars['jsonb']['output']>;
  link: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  seasonShot: Field_Season_Shot;
  seasonShotId: Scalars['bigint']['output'];
  tag: Scalars['uuid']['output'];
};


/** contains snapshot reports */
export type ReportsJsonSummaryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reports_Append_Input = {
  jsonSummary?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reports_Bool_Exp>>;
  _not?: InputMaybe<Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reports_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  jsonSummary?: InputMaybe<Jsonb_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  seasonShot?: InputMaybe<Field_Season_Shot_Bool_Exp>;
  seasonShotId?: InputMaybe<Bigint_Comparison_Exp>;
  tag?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey',
  /** unique or primary key constraint on columns "tag" */
  ReportsTagKey = 'reports_tag_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reports_Delete_At_Path_Input = {
  jsonSummary?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reports_Delete_Elem_Input = {
  jsonSummary?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reports_Delete_Key_Input = {
  jsonSummary?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  jsonSummary?: InputMaybe<Scalars['jsonb']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  seasonShot?: InputMaybe<Field_Season_Shot_Obj_Rel_Insert_Input>;
  seasonShotId?: InputMaybe<Scalars['bigint']['input']>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  constraint: Reports_Constraint;
  update_columns?: Array<Reports_Update_Column>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jsonSummary?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  seasonShot?: InputMaybe<Field_Season_Shot_Order_By>;
  seasonShotId?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reports_Prepend_Input = {
  jsonSummary?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JsonSummary = 'jsonSummary',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  SeasonShotId = 'seasonShotId',
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  jsonSummary?: InputMaybe<Scalars['jsonb']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  jsonSummary?: InputMaybe<Scalars['jsonb']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  seasonShotId?: InputMaybe<Scalars['bigint']['input']>;
  tag?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  JsonSummary = 'jsonSummary',
  /** column name */
  Link = 'link'
}

export type Reports_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reports_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reports_Bool_Exp;
};

export enum Roles_Enum {
  Contractor = 'contractor',
  Farmer = 'farmer',
  Manager = 'manager',
  Operator = 'operator'
}

/** Boolean expression to compare columns of type "roles_enum". All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Roles_Enum>;
  _in?: InputMaybe<Array<Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Roles_Enum>;
  _nin?: InputMaybe<Array<Roles_Enum>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "chargeable_units" */
  chargeable_units: Array<Chargeable_Units>;
  /** fetch data from the table: "chargeable_units" using primary key columns */
  chargeable_units_by_pk?: Maybe<Chargeable_Units>;
  /** fetch data from the table in a streaming manner: "chargeable_units" */
  chargeable_units_stream: Array<Chargeable_Units>;
  /** fetch data from the table: "farms" */
  farms: Array<Farms>;
  /** fetch data from the table: "farms" using primary key columns */
  farms_by_pk?: Maybe<Farms>;
  /** fetch data from the table in a streaming manner: "farms" */
  farms_stream: Array<Farms>;
  /** fetch data from the table: "field_season_shot" */
  field_season_shot: Array<Field_Season_Shot>;
  /** fetch data from the table: "field_season_shot" using primary key columns */
  field_season_shot_by_pk?: Maybe<Field_Season_Shot>;
  /** fetch data from the table in a streaming manner: "field_season_shot" */
  field_season_shot_stream: Array<Field_Season_Shot>;
  /** fetch data from the table: "fields" */
  fields: Array<Fields>;
  /** fetch data from the table: "fields" using primary key columns */
  fields_by_pk?: Maybe<Fields>;
  /** fetch data from the table in a streaming manner: "fields" */
  fields_stream: Array<Fields>;
  /** fetch data from the table: "high_res_map_annotations" */
  high_res_map_annotations: Array<High_Res_Map_Annotations>;
  /** fetch data from the table: "high_res_map_annotations" using primary key columns */
  high_res_map_annotations_by_pk?: Maybe<High_Res_Map_Annotations>;
  /** fetch data from the table in a streaming manner: "high_res_map_annotations" */
  high_res_map_annotations_stream: Array<High_Res_Map_Annotations>;
  /** fetch data from the table: "high_res_map_layers" */
  high_res_map_layers: Array<High_Res_Map_Layers>;
  /** fetch data from the table: "high_res_map_layers" using primary key columns */
  high_res_map_layers_by_pk?: Maybe<High_Res_Map_Layers>;
  /** fetch data from the table in a streaming manner: "high_res_map_layers" */
  high_res_map_layers_stream: Array<High_Res_Map_Layers>;
  /** fetch data from the table: "high_res_maps" */
  high_res_maps: Array<High_Res_Maps>;
  /** fetch data from the table: "high_res_maps" using primary key columns */
  high_res_maps_by_pk?: Maybe<High_Res_Maps>;
  /** fetch data from the table in a streaming manner: "high_res_maps" */
  high_res_maps_stream: Array<High_Res_Maps>;
  /** fetch data from the table: "implements" */
  implements: Array<Implements>;
  /** fetch aggregated fields from the table: "implements" */
  implements_aggregate: Implements_Aggregate;
  /** fetch data from the table: "implements" using primary key columns */
  implements_by_pk?: Maybe<Implements>;
  /** fetch data from the table in a streaming manner: "implements" */
  implements_stream: Array<Implements>;
  /** fetch data from the table: "inventory" */
  inventory: Array<Inventory>;
  /** fetch aggregated fields from the table: "inventory" */
  inventory_aggregate: Inventory_Aggregate;
  /** fetch data from the table: "inventory" using primary key columns */
  inventory_by_pk?: Maybe<Inventory>;
  /** fetch data from the table in a streaming manner: "inventory" */
  inventory_stream: Array<Inventory>;
  /** fetch data from the table: "job_extras" */
  job_extras: Array<Job_Extras>;
  /** fetch data from the table: "job_extras" using primary key columns */
  job_extras_by_pk?: Maybe<Job_Extras>;
  /** fetch data from the table in a streaming manner: "job_extras" */
  job_extras_stream: Array<Job_Extras>;
  /** fetch data from the table: "job_fields" */
  job_fields: Array<Job_Fields>;
  /** fetch data from the table: "job_fields" using primary key columns */
  job_fields_by_pk?: Maybe<Job_Fields>;
  /** fetch data from the table in a streaming manner: "job_fields" */
  job_fields_stream: Array<Job_Fields>;
  /** fetch data from the table: "job_locations" */
  job_locations: Array<Job_Locations>;
  /** fetch data from the table: "job_locations" using primary key columns */
  job_locations_by_pk?: Maybe<Job_Locations>;
  /** fetch data from the table in a streaming manner: "job_locations" */
  job_locations_stream: Array<Job_Locations>;
  /** fetch data from the table: "job_operator_implements" */
  job_operator_implements: Array<Job_Operator_Implements>;
  /** fetch data from the table: "job_operator_implements" using primary key columns */
  job_operator_implements_by_pk?: Maybe<Job_Operator_Implements>;
  /** fetch data from the table in a streaming manner: "job_operator_implements" */
  job_operator_implements_stream: Array<Job_Operator_Implements>;
  /** fetch data from the table: "job_operator_vehicles" */
  job_operator_vehicles: Array<Job_Operator_Vehicles>;
  /** fetch data from the table: "job_operator_vehicles" using primary key columns */
  job_operator_vehicles_by_pk?: Maybe<Job_Operator_Vehicles>;
  /** fetch data from the table in a streaming manner: "job_operator_vehicles" */
  job_operator_vehicles_stream: Array<Job_Operator_Vehicles>;
  /** fetch data from the table: "job_operators" */
  job_operators: Array<Job_Operators>;
  /** fetch data from the table: "job_operators" using primary key columns */
  job_operators_by_pk?: Maybe<Job_Operators>;
  /** fetch data from the table in a streaming manner: "job_operators" */
  job_operators_stream: Array<Job_Operators>;
  /** fetch data from the table: "job_products" */
  job_products: Array<Job_Products>;
  /** fetch data from the table: "job_products" using primary key columns */
  job_products_by_pk?: Maybe<Job_Products>;
  /** fetch data from the table in a streaming manner: "job_products" */
  job_products_stream: Array<Job_Products>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table in a streaming manner: "jobs" */
  jobs_stream: Array<Jobs>;
  /** fetch data from the table: "operation_extras" */
  operation_extras: Array<Operation_Extras>;
  /** fetch data from the table: "operation_extras" using primary key columns */
  operation_extras_by_pk?: Maybe<Operation_Extras>;
  /** fetch data from the table in a streaming manner: "operation_extras" */
  operation_extras_stream: Array<Operation_Extras>;
  /** fetch data from the table: "operation_products" */
  operation_products: Array<Operation_Products>;
  /** fetch data from the table: "operation_products" using primary key columns */
  operation_products_by_pk?: Maybe<Operation_Products>;
  /** fetch data from the table in a streaming manner: "operation_products" */
  operation_products_stream: Array<Operation_Products>;
  /** fetch data from the table: "operations" */
  operations: Array<Operations>;
  /** fetch data from the table: "operations" using primary key columns */
  operations_by_pk?: Maybe<Operations>;
  /** fetch data from the table in a streaming manner: "operations" */
  operations_stream: Array<Operations>;
  /** fetch data from the table: "polygons" */
  polygons: Array<Polygons>;
  /** fetch data from the table: "polygons" using primary key columns */
  polygons_by_pk?: Maybe<Polygons>;
  /** fetch data from the table in a streaming manner: "polygons" */
  polygons_stream: Array<Polygons>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** fetch data from the table: "team_members" */
  team_members: Array<Team_Members>;
  /** fetch data from the table: "team_members" using primary key columns */
  team_members_by_pk?: Maybe<Team_Members>;
  /** fetch data from the table in a streaming manner: "team_members" */
  team_members_stream: Array<Team_Members>;
  /** fetch data from the table: "user_invites" */
  user_invites: Array<User_Invites>;
  /** fetch aggregated fields from the table: "user_invites" */
  user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table in a streaming manner: "user_invites" */
  user_invites_stream: Array<User_Invites>;
  /** fetch data from the table: "user_prefs" */
  user_prefs: Array<User_Prefs>;
  /** fetch data from the table: "user_prefs" using primary key columns */
  user_prefs_by_pk?: Maybe<User_Prefs>;
  /** fetch data from the table in a streaming manner: "user_prefs" */
  user_prefs_stream: Array<User_Prefs>;
  /** fetch data from the table: "user_profiles" */
  user_profiles: Array<User_Profiles>;
  /** fetch data from the table: "user_profiles" using primary key columns */
  user_profiles_by_pk?: Maybe<User_Profiles>;
  /** fetch data from the table in a streaming manner: "user_profiles" */
  user_profiles_stream: Array<User_Profiles>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** fetch data from the table: "user_tax_rates" */
  user_tax_rates: Array<User_Tax_Rates>;
  /** fetch data from the table: "user_tax_rates" using primary key columns */
  user_tax_rates_by_pk?: Maybe<User_Tax_Rates>;
  /** fetch data from the table in a streaming manner: "user_tax_rates" */
  user_tax_rates_stream: Array<User_Tax_Rates>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vehicles" */
  vehicles: Array<Vehicles>;
  /** fetch aggregated fields from the table: "vehicles" */
  vehicles_aggregate: Vehicles_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicles_by_pk?: Maybe<Vehicles>;
  /** fetch data from the table in a streaming manner: "vehicles" */
  vehicles_stream: Array<Vehicles>;
  /** fetch data from the table: "warehouses" */
  warehouses: Array<Warehouses>;
  /** fetch data from the table: "warehouses" using primary key columns */
  warehouses_by_pk?: Maybe<Warehouses>;
  /** fetch data from the table in a streaming manner: "warehouses" */
  warehouses_stream: Array<Warehouses>;
};


export type Subscription_RootChargeable_UnitsArgs = {
  distinct_on?: InputMaybe<Array<Chargeable_Units_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chargeable_Units_Order_By>>;
  where?: InputMaybe<Chargeable_Units_Bool_Exp>;
};


export type Subscription_RootChargeable_Units_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootChargeable_Units_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chargeable_Units_Stream_Cursor_Input>>;
  where?: InputMaybe<Chargeable_Units_Bool_Exp>;
};


export type Subscription_RootFarmsArgs = {
  distinct_on?: InputMaybe<Array<Farms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farms_Order_By>>;
  where?: InputMaybe<Farms_Bool_Exp>;
};


export type Subscription_RootFarms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFarms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Farms_Stream_Cursor_Input>>;
  where?: InputMaybe<Farms_Bool_Exp>;
};


export type Subscription_RootField_Season_ShotArgs = {
  distinct_on?: InputMaybe<Array<Field_Season_Shot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Season_Shot_Order_By>>;
  where?: InputMaybe<Field_Season_Shot_Bool_Exp>;
};


export type Subscription_RootField_Season_Shot_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootField_Season_Shot_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Season_Shot_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Season_Shot_Bool_Exp>;
};


export type Subscription_RootFieldsArgs = {
  distinct_on?: InputMaybe<Array<Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fields_Order_By>>;
  where?: InputMaybe<Fields_Bool_Exp>;
};


export type Subscription_RootFields_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFields_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Fields_Stream_Cursor_Input>>;
  where?: InputMaybe<Fields_Bool_Exp>;
};


export type Subscription_RootHigh_Res_Map_AnnotationsArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Map_Annotations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Map_Annotations_Order_By>>;
  where?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
};


export type Subscription_RootHigh_Res_Map_Annotations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHigh_Res_Map_Annotations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<High_Res_Map_Annotations_Stream_Cursor_Input>>;
  where?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
};


export type Subscription_RootHigh_Res_Map_LayersArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Map_Layers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Map_Layers_Order_By>>;
  where?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
};


export type Subscription_RootHigh_Res_Map_Layers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHigh_Res_Map_Layers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<High_Res_Map_Layers_Stream_Cursor_Input>>;
  where?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
};


export type Subscription_RootHigh_Res_MapsArgs = {
  distinct_on?: InputMaybe<Array<High_Res_Maps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<High_Res_Maps_Order_By>>;
  where?: InputMaybe<High_Res_Maps_Bool_Exp>;
};


export type Subscription_RootHigh_Res_Maps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHigh_Res_Maps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<High_Res_Maps_Stream_Cursor_Input>>;
  where?: InputMaybe<High_Res_Maps_Bool_Exp>;
};


export type Subscription_RootImplementsArgs = {
  distinct_on?: InputMaybe<Array<Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Implements_Order_By>>;
  where?: InputMaybe<Implements_Bool_Exp>;
};


export type Subscription_RootImplements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Implements_Order_By>>;
  where?: InputMaybe<Implements_Bool_Exp>;
};


export type Subscription_RootImplements_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootImplements_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Implements_Stream_Cursor_Input>>;
  where?: InputMaybe<Implements_Bool_Exp>;
};


export type Subscription_RootInventoryArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Inventory_Order_By>>;
  where?: InputMaybe<Inventory_Bool_Exp>;
};


export type Subscription_RootInventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Inventory_Order_By>>;
  where?: InputMaybe<Inventory_Bool_Exp>;
};


export type Subscription_RootInventory_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootInventory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Inventory_Stream_Cursor_Input>>;
  where?: InputMaybe<Inventory_Bool_Exp>;
};


export type Subscription_RootJob_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Job_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Extras_Order_By>>;
  where?: InputMaybe<Job_Extras_Bool_Exp>;
};


export type Subscription_RootJob_Extras_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  name: Scalars['String']['input'];
};


export type Subscription_RootJob_Extras_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Extras_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Extras_Bool_Exp>;
};


export type Subscription_RootJob_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Job_Fields_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Fields_Order_By>>;
  where?: InputMaybe<Job_Fields_Bool_Exp>;
};


export type Subscription_RootJob_Fields_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  seasonShotId: Scalars['bigint']['input'];
};


export type Subscription_RootJob_Fields_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Fields_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Fields_Bool_Exp>;
};


export type Subscription_RootJob_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Job_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Locations_Order_By>>;
  where?: InputMaybe<Job_Locations_Bool_Exp>;
};


export type Subscription_RootJob_Locations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootJob_Locations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Locations_Bool_Exp>;
};


export type Subscription_RootJob_Operator_ImplementsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Implements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Implements_Order_By>>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};


export type Subscription_RootJob_Operator_Implements_By_PkArgs = {
  implementId: Scalars['uuid']['input'];
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};


export type Subscription_RootJob_Operator_Implements_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Operator_Implements_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Operator_Implements_Bool_Exp>;
};


export type Subscription_RootJob_Operator_VehiclesArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Vehicles_Order_By>>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};


export type Subscription_RootJob_Operator_Vehicles_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
  vehicleId: Scalars['uuid']['input'];
};


export type Subscription_RootJob_Operator_Vehicles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Operator_Vehicles_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};


export type Subscription_RootJob_OperatorsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operators_Order_By>>;
  where?: InputMaybe<Job_Operators_Bool_Exp>;
};


export type Subscription_RootJob_Operators_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  userId: Scalars['Int']['input'];
};


export type Subscription_RootJob_Operators_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Operators_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Operators_Bool_Exp>;
};


export type Subscription_RootJob_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Job_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Products_Order_By>>;
  where?: InputMaybe<Job_Products_Bool_Exp>;
};


export type Subscription_RootJob_Products_By_PkArgs = {
  jobId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};


export type Subscription_RootJob_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Products_Bool_Exp>;
};


export type Subscription_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootJobs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootJobs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};


export type Subscription_RootOperation_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Operation_Extras_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operation_Extras_Order_By>>;
  where?: InputMaybe<Operation_Extras_Bool_Exp>;
};


export type Subscription_RootOperation_Extras_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOperation_Extras_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Operation_Extras_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Extras_Bool_Exp>;
};


export type Subscription_RootOperation_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operation_Products_Order_By>>;
  where?: InputMaybe<Operation_Products_Bool_Exp>;
};


export type Subscription_RootOperation_Products_By_PkArgs = {
  operationId: Scalars['bigint']['input'];
  productId: Scalars['uuid']['input'];
};


export type Subscription_RootOperation_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Operation_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Products_Bool_Exp>;
};


export type Subscription_RootOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};


export type Subscription_RootOperations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootOperations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Operations_Stream_Cursor_Input>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};


export type Subscription_RootPolygonsArgs = {
  distinct_on?: InputMaybe<Array<Polygons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Polygons_Order_By>>;
  where?: InputMaybe<Polygons_Bool_Exp>;
};


export type Subscription_RootPolygons_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPolygons_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Polygons_Stream_Cursor_Input>>;
  where?: InputMaybe<Polygons_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootTeam_MembersArgs = {
  distinct_on?: InputMaybe<Array<Team_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Members_Order_By>>;
  where?: InputMaybe<Team_Members_Bool_Exp>;
};


export type Subscription_RootTeam_Members_By_PkArgs = {
  ownerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type Subscription_RootTeam_Members_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Members_Bool_Exp>;
};


export type Subscription_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_By_PkArgs = {
  invite_id: Scalars['String']['input'];
};


export type Subscription_RootUser_Invites_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_PrefsArgs = {
  distinct_on?: InputMaybe<Array<User_Prefs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Prefs_Order_By>>;
  where?: InputMaybe<User_Prefs_Bool_Exp>;
};


export type Subscription_RootUser_Prefs_By_PkArgs = {
  userId: Scalars['Int']['input'];
};


export type Subscription_RootUser_Prefs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Prefs_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Prefs_Bool_Exp>;
};


export type Subscription_RootUser_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<User_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Profiles_Order_By>>;
  where?: InputMaybe<User_Profiles_Bool_Exp>;
};


export type Subscription_RootUser_Profiles_By_PkArgs = {
  userId: Scalars['Int']['input'];
};


export type Subscription_RootUser_Profiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Profiles_Bool_Exp>;
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  role: Roles_Enum;
  user_id: Scalars['Int']['input'];
};


export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Tax_RatesArgs = {
  distinct_on?: InputMaybe<Array<User_Tax_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tax_Rates_Order_By>>;
  where?: InputMaybe<User_Tax_Rates_Bool_Exp>;
};


export type Subscription_RootUser_Tax_Rates_By_PkArgs = {
  label: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type Subscription_RootUser_Tax_Rates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Tax_Rates_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Tax_Rates_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicles_Order_By>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootVehicles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVehicles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vehicles_Stream_Cursor_Input>>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootWarehousesArgs = {
  distinct_on?: InputMaybe<Array<Warehouses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Warehouses_Order_By>>;
  where?: InputMaybe<Warehouses_Bool_Exp>;
};


export type Subscription_RootWarehouses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootWarehouses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Warehouses_Stream_Cursor_Input>>;
  where?: InputMaybe<Warehouses_Bool_Exp>;
};

/** columns and relationships of "team_members" */
export type Team_Members = {
  __typename?: 'team_members';
  ownerId: Scalars['Int']['output'];
  /** An object relationship */
  teamMember: Users;
  userId: Scalars['Int']['output'];
};

/** order by aggregate values of table "team_members" */
export type Team_Members_Aggregate_Order_By = {
  avg?: InputMaybe<Team_Members_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Members_Max_Order_By>;
  min?: InputMaybe<Team_Members_Min_Order_By>;
  stddev?: InputMaybe<Team_Members_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Team_Members_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Team_Members_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Team_Members_Sum_Order_By>;
  var_pop?: InputMaybe<Team_Members_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Team_Members_Var_Samp_Order_By>;
  variance?: InputMaybe<Team_Members_Variance_Order_By>;
};

/** order by avg() on columns of table "team_members" */
export type Team_Members_Avg_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "team_members". All fields are combined with a logical 'AND'. */
export type Team_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Members_Bool_Exp>>;
  _not?: InputMaybe<Team_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Members_Bool_Exp>>;
  ownerId?: InputMaybe<Int_Comparison_Exp>;
  teamMember?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "team_members" */
export type Team_Members_Max_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "team_members" */
export type Team_Members_Min_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "team_members". */
export type Team_Members_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  teamMember?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "team_members" */
export enum Team_Members_Select_Column {
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UserId = 'userId'
}

/** order by stddev() on columns of table "team_members" */
export type Team_Members_Stddev_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "team_members" */
export type Team_Members_Stddev_Pop_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "team_members" */
export type Team_Members_Stddev_Samp_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "team_members" */
export type Team_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Members_Stream_Cursor_Value_Input = {
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "team_members" */
export type Team_Members_Sum_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "team_members" */
export type Team_Members_Var_Pop_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "team_members" */
export type Team_Members_Var_Samp_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "team_members" */
export type Team_Members_Variance_Order_By = {
  ownerId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user_invites" */
export type User_Invites = {
  __typename?: 'user_invites';
  invite_id: Scalars['String']['output'];
  invitee_email: Scalars['String']['output'];
  invitee_role: Roles_Enum;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "user_invites" */
export type User_Invites_Aggregate = {
  __typename?: 'user_invites_aggregate';
  aggregate?: Maybe<User_Invites_Aggregate_Fields>;
  nodes: Array<User_Invites>;
};

/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_Fields = {
  __typename?: 'user_invites_aggregate_fields';
  avg?: Maybe<User_Invites_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Invites_Max_Fields>;
  min?: Maybe<User_Invites_Min_Fields>;
  stddev?: Maybe<User_Invites_Stddev_Fields>;
  stddev_pop?: Maybe<User_Invites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Invites_Stddev_Samp_Fields>;
  sum?: Maybe<User_Invites_Sum_Fields>;
  var_pop?: Maybe<User_Invites_Var_Pop_Fields>;
  var_samp?: Maybe<User_Invites_Var_Samp_Fields>;
  variance?: Maybe<User_Invites_Variance_Fields>;
};


/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Invites_Avg_Fields = {
  __typename?: 'user_invites_avg_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_invites". All fields are combined with a logical 'AND'. */
export type User_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<User_Invites_Bool_Exp>>;
  _not?: InputMaybe<User_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<User_Invites_Bool_Exp>>;
  invite_id?: InputMaybe<String_Comparison_Exp>;
  invitee_email?: InputMaybe<String_Comparison_Exp>;
  invitee_role?: InputMaybe<Roles_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invites" */
export enum User_Invites_Constraint {
  /** unique or primary key constraint on columns "invite_id" */
  UserInvitesPkey = 'user_invites_pkey',
  /** unique or primary key constraint on columns "user_id", "invitee_email" */
  UserInvitesUserIdInviteeEmailKey = 'user_invites_user_id_invitee_email_key'
}

/** input type for inserting data into table "user_invites" */
export type User_Invites_Insert_Input = {
  invitee_email?: InputMaybe<Scalars['String']['input']>;
  invitee_role?: InputMaybe<Roles_Enum>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Invites_Max_Fields = {
  __typename?: 'user_invites_max_fields';
  invite_id?: Maybe<Scalars['String']['output']>;
  invitee_email?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type User_Invites_Min_Fields = {
  __typename?: 'user_invites_min_fields';
  invite_id?: Maybe<Scalars['String']['output']>;
  invitee_email?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "user_invites" */
export type User_Invites_Mutation_Response = {
  __typename?: 'user_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Invites>;
};

/** on_conflict condition type for table "user_invites" */
export type User_Invites_On_Conflict = {
  constraint: User_Invites_Constraint;
  update_columns?: Array<User_Invites_Update_Column>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invites". */
export type User_Invites_Order_By = {
  invite_id?: InputMaybe<Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  invitee_role?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_invites" */
export enum User_Invites_Select_Column {
  /** column name */
  InviteId = 'invite_id',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeRole = 'invitee_role',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type User_Invites_Stddev_Fields = {
  __typename?: 'user_invites_stddev_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Invites_Stddev_Pop_Fields = {
  __typename?: 'user_invites_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Invites_Stddev_Samp_Fields = {
  __typename?: 'user_invites_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_invites" */
export type User_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Invites_Stream_Cursor_Value_Input = {
  invite_id?: InputMaybe<Scalars['String']['input']>;
  invitee_email?: InputMaybe<Scalars['String']['input']>;
  invitee_role?: InputMaybe<Roles_Enum>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Invites_Sum_Fields = {
  __typename?: 'user_invites_sum_fields';
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** placeholder for update columns of table "user_invites" (current role has no relevant permissions) */
export enum User_Invites_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type User_Invites_Var_Pop_Fields = {
  __typename?: 'user_invites_var_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Invites_Var_Samp_Fields = {
  __typename?: 'user_invites_var_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Invites_Variance_Fields = {
  __typename?: 'user_invites_variance_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Stores user default preferences */
export type User_Prefs = {
  __typename?: 'user_prefs';
  currency?: Maybe<Scalars['String']['output']>;
  fieldColor?: Maybe<Scalars['String']['output']>;
  fieldUnit?: Maybe<Scalars['String']['output']>;
  /** geo point where map spans to by default */
  geoLocation?: Maybe<Scalars['point']['output']>;
  /** payment instructions added to invoice pdf */
  invoicePayInfo?: Maybe<Scalars['String']['output']>;
  /** number of days before next invoice */
  invoiceTerms?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "user_prefs". All fields are combined with a logical 'AND'. */
export type User_Prefs_Bool_Exp = {
  _and?: InputMaybe<Array<User_Prefs_Bool_Exp>>;
  _not?: InputMaybe<User_Prefs_Bool_Exp>;
  _or?: InputMaybe<Array<User_Prefs_Bool_Exp>>;
  currency?: InputMaybe<String_Comparison_Exp>;
  fieldColor?: InputMaybe<String_Comparison_Exp>;
  fieldUnit?: InputMaybe<String_Comparison_Exp>;
  geoLocation?: InputMaybe<Point_Comparison_Exp>;
  invoicePayInfo?: InputMaybe<String_Comparison_Exp>;
  invoiceTerms?: InputMaybe<Int_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_prefs" */
export enum User_Prefs_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserPrefsPkey = 'user_prefs_pkey'
}

/** input type for incrementing numeric columns in table "user_prefs" */
export type User_Prefs_Inc_Input = {
  /** number of days before next invoice */
  invoiceTerms?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_prefs" */
export type User_Prefs_Insert_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  fieldColor?: InputMaybe<Scalars['String']['input']>;
  fieldUnit?: InputMaybe<Scalars['String']['input']>;
  /** geo point where map spans to by default */
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  /** payment instructions added to invoice pdf */
  invoicePayInfo?: InputMaybe<Scalars['String']['input']>;
  /** number of days before next invoice */
  invoiceTerms?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "user_prefs" */
export type User_Prefs_Mutation_Response = {
  __typename?: 'user_prefs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Prefs>;
};

/** on_conflict condition type for table "user_prefs" */
export type User_Prefs_On_Conflict = {
  constraint: User_Prefs_Constraint;
  update_columns?: Array<User_Prefs_Update_Column>;
  where?: InputMaybe<User_Prefs_Bool_Exp>;
};

/** Ordering options when selecting data from "user_prefs". */
export type User_Prefs_Order_By = {
  currency?: InputMaybe<Order_By>;
  fieldColor?: InputMaybe<Order_By>;
  fieldUnit?: InputMaybe<Order_By>;
  geoLocation?: InputMaybe<Order_By>;
  invoicePayInfo?: InputMaybe<Order_By>;
  invoiceTerms?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_prefs */
export type User_Prefs_Pk_Columns_Input = {
  userId: Scalars['Int']['input'];
};

/** select columns of table "user_prefs" */
export enum User_Prefs_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  FieldColor = 'fieldColor',
  /** column name */
  FieldUnit = 'fieldUnit',
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  InvoicePayInfo = 'invoicePayInfo',
  /** column name */
  InvoiceTerms = 'invoiceTerms',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_prefs" */
export type User_Prefs_Set_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  fieldColor?: InputMaybe<Scalars['String']['input']>;
  fieldUnit?: InputMaybe<Scalars['String']['input']>;
  /** geo point where map spans to by default */
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  /** payment instructions added to invoice pdf */
  invoicePayInfo?: InputMaybe<Scalars['String']['input']>;
  /** number of days before next invoice */
  invoiceTerms?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_prefs" */
export type User_Prefs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Prefs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Prefs_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  fieldColor?: InputMaybe<Scalars['String']['input']>;
  fieldUnit?: InputMaybe<Scalars['String']['input']>;
  /** geo point where map spans to by default */
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  /** payment instructions added to invoice pdf */
  invoicePayInfo?: InputMaybe<Scalars['String']['input']>;
  /** number of days before next invoice */
  invoiceTerms?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "user_prefs" */
export enum User_Prefs_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  FieldColor = 'fieldColor',
  /** column name */
  FieldUnit = 'fieldUnit',
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  InvoicePayInfo = 'invoicePayInfo',
  /** column name */
  InvoiceTerms = 'invoiceTerms',
  /** column name */
  Timezone = 'timezone'
}

export type User_Prefs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Prefs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Prefs_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Prefs_Bool_Exp;
};

/** Stores additional information about users */
export type User_Profiles = {
  __typename?: 'user_profiles';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "user_profiles". All fields are combined with a logical 'AND'. */
export type User_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Profiles_Bool_Exp>>;
  _not?: InputMaybe<User_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Profiles_Bool_Exp>>;
  address1?: InputMaybe<String_Comparison_Exp>;
  address2?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  taxNumber?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_profiles" */
export enum User_Profiles_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserProfilesPkey = 'user_profiles_pkey'
}

/** input type for inserting data into table "user_profiles" */
export type User_Profiles_Insert_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "user_profiles" */
export type User_Profiles_Mutation_Response = {
  __typename?: 'user_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Profiles>;
};

/** on_conflict condition type for table "user_profiles" */
export type User_Profiles_On_Conflict = {
  constraint: User_Profiles_Constraint;
  update_columns?: Array<User_Profiles_Update_Column>;
  where?: InputMaybe<User_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_profiles". */
export type User_Profiles_Order_By = {
  address1?: InputMaybe<Order_By>;
  address2?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  taxNumber?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_profiles */
export type User_Profiles_Pk_Columns_Input = {
  userId: Scalars['Int']['input'];
};

/** select columns of table "user_profiles" */
export enum User_Profiles_Select_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  Country = 'country',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  State = 'state',
  /** column name */
  TaxNumber = 'taxNumber',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_profiles" */
export type User_Profiles_Set_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_profiles" */
export type User_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Profiles_Stream_Cursor_Value_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "user_profiles" */
export enum User_Profiles_Update_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  Country = 'country',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  State = 'state',
  /** column name */
  TaxNumber = 'taxNumber'
}

export type User_Profiles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Profiles_Bool_Exp;
};

/** Roles for each user – many-to-many */
export type User_Roles = {
  __typename?: 'user_roles';
  role: Roles_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** order by aggregate values of table "user_roles" */
export type User_Roles_Aggregate_Order_By = {
  avg?: InputMaybe<User_Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Roles_Max_Order_By>;
  min?: InputMaybe<User_Roles_Min_Order_By>;
  stddev?: InputMaybe<User_Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Roles_Sum_Order_By>;
  var_pop?: InputMaybe<User_Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Roles_Variance_Order_By>;
};

/** order by avg() on columns of table "user_roles" */
export type User_Roles_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  role?: InputMaybe<Roles_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "user_roles" */
export type User_Roles_Max_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_roles" */
export type User_Roles_Min_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  role?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "user_roles" */
export type User_Roles_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "user_roles" */
export type User_Roles_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "user_roles" */
export type User_Roles_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Roles_Enum>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "user_roles" */
export type User_Roles_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "user_roles" */
export type User_Roles_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "user_roles" */
export type User_Roles_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "user_roles" */
export type User_Roles_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Stores different tax rates for each user */
export type User_Tax_Rates = {
  __typename?: 'user_tax_rates';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['float8']['output'];
  userId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "user_tax_rates". All fields are combined with a logical 'AND'. */
export type User_Tax_Rates_Bool_Exp = {
  _and?: InputMaybe<Array<User_Tax_Rates_Bool_Exp>>;
  _not?: InputMaybe<User_Tax_Rates_Bool_Exp>;
  _or?: InputMaybe<Array<User_Tax_Rates_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rate?: InputMaybe<Float8_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_tax_rates" */
export enum User_Tax_Rates_Constraint {
  /** unique or primary key constraint on columns "label", "user_id" */
  UserTaxRatesPkey = 'user_tax_rates_pkey'
}

/** input type for incrementing numeric columns in table "user_tax_rates" */
export type User_Tax_Rates_Inc_Input = {
  rate?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "user_tax_rates" */
export type User_Tax_Rates_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['float8']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "user_tax_rates" */
export type User_Tax_Rates_Mutation_Response = {
  __typename?: 'user_tax_rates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Tax_Rates>;
};

/** on_conflict condition type for table "user_tax_rates" */
export type User_Tax_Rates_On_Conflict = {
  constraint: User_Tax_Rates_Constraint;
  update_columns?: Array<User_Tax_Rates_Update_Column>;
  where?: InputMaybe<User_Tax_Rates_Bool_Exp>;
};

/** Ordering options when selecting data from "user_tax_rates". */
export type User_Tax_Rates_Order_By = {
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_tax_rates */
export type User_Tax_Rates_Pk_Columns_Input = {
  label: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

/** select columns of table "user_tax_rates" */
export enum User_Tax_Rates_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_tax_rates" */
export type User_Tax_Rates_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['float8']['input']>;
};

/** Streaming cursor of the table "user_tax_rates" */
export type User_Tax_Rates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Tax_Rates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Tax_Rates_Stream_Cursor_Value_Input = {
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['float8']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "user_tax_rates" */
export enum User_Tax_Rates_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate'
}

export type User_Tax_Rates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Tax_Rates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Tax_Rates_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Tax_Rates_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  prefs?: Maybe<User_Prefs>;
  /** An object relationship */
  profile?: Maybe<User_Profiles>;
  /** An array relationship */
  roles: Array<User_Roles>;
  /** An array relationship */
  teamMembers: Array<Team_Members>;
  /** An array relationship */
  teams: Array<Team_Members>;
  username: Scalars['String']['output'];
};


/** columns and relationships of "users" */
export type UsersRolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeamMembersArgs = {
  distinct_on?: InputMaybe<Array<Team_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Members_Order_By>>;
  where?: InputMaybe<Team_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeamsArgs = {
  distinct_on?: InputMaybe<Array<Team_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Members_Order_By>>;
  where?: InputMaybe<Team_Members_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isEmailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  prefs?: InputMaybe<User_Prefs_Bool_Exp>;
  profile?: InputMaybe<User_Profiles_Bool_Exp>;
  roles?: InputMaybe<User_Roles_Bool_Exp>;
  teamMembers?: InputMaybe<Team_Members_Bool_Exp>;
  teams?: InputMaybe<Team_Members_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isEmailVerified?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  prefs?: InputMaybe<User_Prefs_Order_By>;
  profile?: InputMaybe<User_Profiles_Order_By>;
  roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
  teamMembers_aggregate?: InputMaybe<Team_Members_Aggregate_Order_By>;
  teams_aggregate?: InputMaybe<Team_Members_Aggregate_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmailVerified = 'isEmailVerified',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "vehicles" */
export type Vehicles = {
  __typename?: 'vehicles';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  jobs: Array<Job_Operator_Vehicles>;
  length?: Maybe<Scalars['numeric']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownership: Ownership_Types_Enum;
  registrationNumber: Scalars['String']['output'];
  status: Entity_Status_Enum;
  userId: Scalars['Int']['output'];
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "vehicles" */
export type VehiclesJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Operator_Vehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Operator_Vehicles_Order_By>>;
  where?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
};

/** aggregated selection of "vehicles" */
export type Vehicles_Aggregate = {
  __typename?: 'vehicles_aggregate';
  aggregate?: Maybe<Vehicles_Aggregate_Fields>;
  nodes: Array<Vehicles>;
};

/** aggregate fields of "vehicles" */
export type Vehicles_Aggregate_Fields = {
  __typename?: 'vehicles_aggregate_fields';
  avg?: Maybe<Vehicles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vehicles_Max_Fields>;
  min?: Maybe<Vehicles_Min_Fields>;
  stddev?: Maybe<Vehicles_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicles_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicles_Sum_Fields>;
  var_pop?: Maybe<Vehicles_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicles_Var_Samp_Fields>;
  variance?: Maybe<Vehicles_Variance_Fields>;
};


/** aggregate fields of "vehicles" */
export type Vehicles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Vehicles_Avg_Fields = {
  __typename?: 'vehicles_avg_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "vehicles". All fields are combined with a logical 'AND'. */
export type Vehicles_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicles_Bool_Exp>>;
  _not?: InputMaybe<Vehicles_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicles_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobs?: InputMaybe<Job_Operator_Vehicles_Bool_Exp>;
  length?: InputMaybe<Numeric_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ownership?: InputMaybe<Ownership_Types_Enum_Comparison_Exp>;
  registrationNumber?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Entity_Status_Enum_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  weight?: InputMaybe<Numeric_Comparison_Exp>;
  width?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicles" */
export enum Vehicles_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehiclesPkey = 'vehicles_pkey'
}

/** input type for incrementing numeric columns in table "vehicles" */
export type Vehicles_Inc_Input = {
  length?: InputMaybe<Scalars['numeric']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "vehicles" */
export type Vehicles_Insert_Input = {
  brand?: InputMaybe<Scalars['String']['input']>;
  jobs?: InputMaybe<Job_Operator_Vehicles_Arr_Rel_Insert_Input>;
  length?: InputMaybe<Scalars['numeric']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: InputMaybe<Ownership_Types_Enum>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Vehicles_Max_Fields = {
  __typename?: 'vehicles_max_fields';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  length?: Maybe<Scalars['numeric']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Vehicles_Min_Fields = {
  __typename?: 'vehicles_min_fields';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  length?: Maybe<Scalars['numeric']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "vehicles" */
export type Vehicles_Mutation_Response = {
  __typename?: 'vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicles>;
};

/** input type for inserting object relation for remote table "vehicles" */
export type Vehicles_Obj_Rel_Insert_Input = {
  data: Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "vehicles" */
export type Vehicles_On_Conflict = {
  constraint: Vehicles_Constraint;
  update_columns?: Array<Vehicles_Update_Column>;
  where?: InputMaybe<Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicles". */
export type Vehicles_Order_By = {
  brand?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Operator_Vehicles_Aggregate_Order_By>;
  length?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownership?: InputMaybe<Order_By>;
  registrationNumber?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vehicles */
export type Vehicles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vehicles" */
export enum Vehicles_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Length = 'length',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  Ownership = 'ownership',
  /** column name */
  RegistrationNumber = 'registrationNumber',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
  /** column name */
  Weight = 'weight',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "vehicles" */
export type Vehicles_Set_Input = {
  brand?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['numeric']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: InputMaybe<Ownership_Types_Enum>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Vehicles_Stddev_Fields = {
  __typename?: 'vehicles_stddev_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Vehicles_Stddev_Pop_Fields = {
  __typename?: 'vehicles_stddev_pop_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Vehicles_Stddev_Samp_Fields = {
  __typename?: 'vehicles_stddev_samp_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "vehicles" */
export type Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicles_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  length?: InputMaybe<Scalars['numeric']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: InputMaybe<Ownership_Types_Enum>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Entity_Status_Enum>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['numeric']['input']>;
  width?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Vehicles_Sum_Fields = {
  __typename?: 'vehicles_sum_fields';
  length?: Maybe<Scalars['numeric']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['numeric']['output']>;
  width?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "vehicles" */
export enum Vehicles_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  Length = 'length',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  Ownership = 'ownership',
  /** column name */
  RegistrationNumber = 'registrationNumber',
  /** column name */
  Status = 'status',
  /** column name */
  Weight = 'weight',
  /** column name */
  Width = 'width'
}

export type Vehicles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vehicles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicles_Var_Pop_Fields = {
  __typename?: 'vehicles_var_pop_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Vehicles_Var_Samp_Fields = {
  __typename?: 'vehicles_var_samp_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Vehicles_Variance_Fields = {
  __typename?: 'vehicles_variance_fields';
  length?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "warehouses" */
export type Warehouses = {
  __typename?: 'warehouses';
  address?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  farm?: Maybe<Farms>;
  farmId?: Maybe<Scalars['bigint']['output']>;
  geoLocation?: Maybe<Scalars['point']['output']>;
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "warehouses". All fields are combined with a logical 'AND'. */
export type Warehouses_Bool_Exp = {
  _and?: InputMaybe<Array<Warehouses_Bool_Exp>>;
  _not?: InputMaybe<Warehouses_Bool_Exp>;
  _or?: InputMaybe<Array<Warehouses_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  farm?: InputMaybe<Farms_Bool_Exp>;
  farmId?: InputMaybe<Bigint_Comparison_Exp>;
  geoLocation?: InputMaybe<Point_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "warehouses" */
export enum Warehouses_Constraint {
  /** unique or primary key constraint on columns "id" */
  WarehousesPkey = 'warehouses_pkey'
}

/** input type for incrementing numeric columns in table "warehouses" */
export type Warehouses_Inc_Input = {
  farmId?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "warehouses" */
export type Warehouses_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  farm?: InputMaybe<Farms_Obj_Rel_Insert_Input>;
  farmId?: InputMaybe<Scalars['bigint']['input']>;
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "warehouses" */
export type Warehouses_Mutation_Response = {
  __typename?: 'warehouses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Warehouses>;
};

/** input type for inserting object relation for remote table "warehouses" */
export type Warehouses_Obj_Rel_Insert_Input = {
  data: Warehouses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Warehouses_On_Conflict>;
};

/** on_conflict condition type for table "warehouses" */
export type Warehouses_On_Conflict = {
  constraint: Warehouses_Constraint;
  update_columns?: Array<Warehouses_Update_Column>;
  where?: InputMaybe<Warehouses_Bool_Exp>;
};

/** Ordering options when selecting data from "warehouses". */
export type Warehouses_Order_By = {
  address?: InputMaybe<Order_By>;
  farm?: InputMaybe<Farms_Order_By>;
  farmId?: InputMaybe<Order_By>;
  geoLocation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: warehouses */
export type Warehouses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "warehouses" */
export enum Warehouses_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  FarmId = 'farmId',
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "warehouses" */
export type Warehouses_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  farmId?: InputMaybe<Scalars['bigint']['input']>;
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "warehouses" */
export type Warehouses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Warehouses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Warehouses_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  farmId?: InputMaybe<Scalars['bigint']['input']>;
  geoLocation?: InputMaybe<Scalars['point']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "warehouses" */
export enum Warehouses_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  FarmId = 'farmId',
  /** column name */
  GeoLocation = 'geoLocation',
  /** column name */
  Name = 'name'
}

export type Warehouses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Warehouses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Warehouses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Warehouses_Bool_Exp;
};

export type LoginQueryVariables = Exact<{
  input?: InputMaybe<Api_Login_User_Input>;
}>;


export type LoginQuery = { __typename?: 'query_root', api_login_user?: { __typename?: 'api_login_user_output', token: string, id: number } | null };

export type InsertFieldMapAnnotationMutationVariables = Exact<{
  input: High_Res_Map_Annotations_Insert_Input;
}>;


export type InsertFieldMapAnnotationMutation = { __typename?: 'mutation_root', insert_high_res_map_annotations_one?: { __typename?: 'high_res_map_annotations', id: any } | null };

export type UpdateFieldMapAnnotationMutationVariables = Exact<{
  pk_columns_input: High_Res_Map_Annotations_Pk_Columns_Input;
  set_input?: InputMaybe<High_Res_Map_Annotations_Set_Input>;
}>;


export type UpdateFieldMapAnnotationMutation = { __typename?: 'mutation_root', update_high_res_map_annotations_by_pk?: { __typename?: 'high_res_map_annotations', id: any } | null };

export type DeleteFieldMapAnnotationMutationVariables = Exact<{
  id: Scalars['bigint']['input'];
}>;


export type DeleteFieldMapAnnotationMutation = { __typename?: 'mutation_root', delete_high_res_map_annotations_by_pk?: { __typename?: 'high_res_map_annotations', id: any } | null };

export type GetHighResMapQueryVariables = Exact<{
  id: Scalars['bigint']['input'];
}>;


export type GetHighResMapQuery = { __typename?: 'query_root', high_res_maps_by_pk?: { __typename?: 'high_res_maps', id: any, name?: string | null, link?: string | null, ms_link?: string | null, ms_rapid_link?: string | null, rgb_link?: string | null, rgb_rapid_link?: string | null, captured_at?: any | null, createdAt: any } | null };

export type GetFieldMapLayersQueryVariables = Exact<{
  where?: InputMaybe<High_Res_Map_Layers_Bool_Exp>;
}>;


export type GetFieldMapLayersQuery = { __typename?: 'query_root', high_res_map_layers: Array<{ __typename?: 'high_res_map_layers', id: any, name?: string | null, link: string, createdAt: any }> };

export type GetFieldMapLayerQueryVariables = Exact<{
  id: Scalars['bigint']['input'];
}>;


export type GetFieldMapLayerQuery = { __typename?: 'query_root', high_res_map_layers_by_pk?: { __typename?: 'high_res_map_layers', id: any, name?: string | null, link: string, createdAt: any } | null };

export type GetFieldMapAnnotationsQueryVariables = Exact<{
  where?: InputMaybe<High_Res_Map_Annotations_Bool_Exp>;
}>;


export type GetFieldMapAnnotationsQuery = { __typename?: 'query_root', high_res_map_annotations: Array<{ __typename?: 'high_res_map_annotations', id: any, name: string, area?: any | null, geometry: string, description?: string | null, createdAt: any }> };

export type GetFieldMapAnnotationQueryVariables = Exact<{
  id: Scalars['bigint']['input'];
}>;


export type GetFieldMapAnnotationQuery = { __typename?: 'query_root', high_res_map_annotations_by_pk?: { __typename?: 'high_res_map_annotations', id: any, name: string, area?: any | null, geometry: string, description?: string | null, createdAt: any } | null };

export type InsertFarmMutationVariables = Exact<{
  input: Farms_Insert_Input;
}>;


export type InsertFarmMutation = { __typename?: 'mutation_root', insert_farms_one?: { __typename?: 'farms', id: any } | null };

export type InsertFieldSeasonShotMutationVariables = Exact<{
  input: Field_Season_Shot_Insert_Input;
}>;


export type InsertFieldSeasonShotMutation = { __typename?: 'mutation_root', insert_field_season_shot_one?: { __typename?: 'field_season_shot', id: any, fieldId: any } | null };

export type GetFarmsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFarmsQuery = { __typename?: 'query_root', farms: Array<{ __typename?: 'farms', id: any, name: string }> };

export type GetFieldsQueryVariables = Exact<{
  where?: InputMaybe<Field_Season_Shot_Bool_Exp>;
}>;


export type GetFieldsQuery = { __typename?: 'query_root', field_season_shot: Array<{ __typename?: 'field_season_shot', id: any, name?: string | null, cropName: string, polygon: { __typename?: 'polygons', geometry?: any | null, geometry_wkt?: string | null } }> };

export type GetFieldQueryVariables = Exact<{
  id: Scalars['bigint']['input'];
}>;


export type GetFieldQuery = { __typename?: 'query_root', field_season_shot_by_pk?: { __typename?: 'field_season_shot', id: any, name?: string | null, season: string, cropName: string, polygon: { __typename?: 'polygons', size?: any | null, geometry?: any | null, geometry_wkt?: string | null } } | null };

export type GetFieldMapsQueryVariables = Exact<{
  where?: InputMaybe<High_Res_Maps_Bool_Exp>;
}>;


export type GetFieldMapsQuery = { __typename?: 'query_root', high_res_maps: Array<{ __typename?: 'high_res_maps', id: any, name?: string | null, link?: string | null, ms_link?: string | null, ms_rapid_link?: string | null, rgb_link?: string | null, rgb_rapid_link?: string | null, captured_at?: any | null, createdAt: any }> };

export type GetFieldReportsQueryVariables = Exact<{
  where?: InputMaybe<Reports_Bool_Exp>;
}>;


export type GetFieldReportsQuery = { __typename?: 'query_root', reports: Array<{ __typename?: 'reports', id: any, name?: string | null, createdAt: any, link: string }> };

export type GetFarmNameQueryVariables = Exact<{
  where?: InputMaybe<Farms_Bool_Exp>;
}>;


export type GetFarmNameQuery = { __typename?: 'query_root', farms: Array<{ __typename?: 'farms', id: any, name: string }> };


export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"api_login_user_input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"api_login_user"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<LoginQuery, LoginQueryVariables>;
export const InsertFieldMapAnnotationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InsertFieldMapAnnotation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"high_res_map_annotations_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_high_res_map_annotations_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<InsertFieldMapAnnotationMutation, InsertFieldMapAnnotationMutationVariables>;
export const UpdateFieldMapAnnotationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFieldMapAnnotation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pk_columns_input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"high_res_map_annotations_pk_columns_input"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"set_input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"high_res_map_annotations_set_input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"update_high_res_map_annotations_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pk_columns"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pk_columns_input"}}},{"kind":"Argument","name":{"kind":"Name","value":"_set"},"value":{"kind":"Variable","name":{"kind":"Name","value":"set_input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateFieldMapAnnotationMutation, UpdateFieldMapAnnotationMutationVariables>;
export const DeleteFieldMapAnnotationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteFieldMapAnnotation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"delete_high_res_map_annotations_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<DeleteFieldMapAnnotationMutation, DeleteFieldMapAnnotationMutationVariables>;
export const GetHighResMapDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetHighResMap"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"high_res_maps_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"link"}},{"kind":"Field","name":{"kind":"Name","value":"ms_link"}},{"kind":"Field","name":{"kind":"Name","value":"ms_rapid_link"}},{"kind":"Field","name":{"kind":"Name","value":"rgb_link"}},{"kind":"Field","name":{"kind":"Name","value":"rgb_rapid_link"}},{"kind":"Field","name":{"kind":"Name","value":"captured_at"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetHighResMapQuery, GetHighResMapQueryVariables>;
export const GetFieldMapLayersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFieldMapLayers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"high_res_map_layers_bool_exp"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"high_res_map_layers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"link"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetFieldMapLayersQuery, GetFieldMapLayersQueryVariables>;
export const GetFieldMapLayerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFieldMapLayer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"high_res_map_layers_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"link"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetFieldMapLayerQuery, GetFieldMapLayerQueryVariables>;
export const GetFieldMapAnnotationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFieldMapAnnotations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"high_res_map_annotations_bool_exp"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"high_res_map_annotations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"area"}},{"kind":"Field","name":{"kind":"Name","value":"geometry"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetFieldMapAnnotationsQuery, GetFieldMapAnnotationsQueryVariables>;
export const GetFieldMapAnnotationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFieldMapAnnotation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"high_res_map_annotations_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"area"}},{"kind":"Field","name":{"kind":"Name","value":"geometry"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetFieldMapAnnotationQuery, GetFieldMapAnnotationQueryVariables>;
export const InsertFarmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InsertFarm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"farms_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_farms_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<InsertFarmMutation, InsertFarmMutationVariables>;
export const InsertFieldSeasonShotDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InsertFieldSeasonShot"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"field_season_shot_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_field_season_shot_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fieldId"}}]}}]}}]} as unknown as DocumentNode<InsertFieldSeasonShotMutation, InsertFieldSeasonShotMutationVariables>;
export const GetFarmsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFarms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"farms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetFarmsQuery, GetFarmsQueryVariables>;
export const GetFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFields"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"field_season_shot_bool_exp"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field_season_shot"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}},{"kind":"Argument","name":{"kind":"Name","value":"distinct_on"},"value":{"kind":"EnumValue","value":"fieldId"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"cropName"}},{"kind":"Field","name":{"kind":"Name","value":"polygon"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"geometry"}},{"kind":"Field","name":{"kind":"Name","value":"geometry_wkt"}}]}}]}}]}}]} as unknown as DocumentNode<GetFieldsQuery, GetFieldsQueryVariables>;
export const GetFieldDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetField"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"bigint"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"field_season_shot_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"season"}},{"kind":"Field","name":{"kind":"Name","value":"cropName"}},{"kind":"Field","name":{"kind":"Name","value":"polygon"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"size"}},{"kind":"Field","name":{"kind":"Name","value":"geometry"}},{"kind":"Field","name":{"kind":"Name","value":"geometry_wkt"}}]}}]}}]}}]} as unknown as DocumentNode<GetFieldQuery, GetFieldQueryVariables>;
export const GetFieldMapsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFieldMaps"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"high_res_maps_bool_exp"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"high_res_maps"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"link"}},{"kind":"Field","name":{"kind":"Name","value":"ms_link"}},{"kind":"Field","name":{"kind":"Name","value":"ms_rapid_link"}},{"kind":"Field","name":{"kind":"Name","value":"rgb_link"}},{"kind":"Field","name":{"kind":"Name","value":"rgb_rapid_link"}},{"kind":"Field","name":{"kind":"Name","value":"captured_at"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetFieldMapsQuery, GetFieldMapsQueryVariables>;
export const GetFieldReportsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFieldReports"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"reports_bool_exp"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reports"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"link"}}]}}]}}]} as unknown as DocumentNode<GetFieldReportsQuery, GetFieldReportsQueryVariables>;
export const GetFarmNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFarmName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"farms_bool_exp"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"farms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetFarmNameQuery, GetFarmNameQueryVariables>;