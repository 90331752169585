import JSZip from "jszip";
import {saveAs} from "file-saver";
import booleanIntersects from "@turf/boolean-intersects";
import type {Point, Polygon, Position} from "geojson";
import {
  type Units,
  type Feature,
  area as turfArea,
  bbox as turfBbox,
  buffer as turfBuffer,
  polygon as turfPolygon,
  destination as turfDestination,
} from "@turf/turf";

export const generateMissionKmz = (
  sw: number,
  fr: number,
  imW: number,
  imH: number,
  scan_type: string,
  gsd: number,
  ol_perc: number,
  sl_perc: number,
  ftr: Polygon,
  fileName: string,
  enumValue: number,
  enumSubValue: number,
  payloadEnumValue: number,
) => {
  let high_alt_points = 0;
  let placemarks = "";
  let placemarks_wpml = "";
  // let distance_interval = 0;
  let kml = "";
  let skip_index = 0;
  let time_interval_val = 0;
  let wpml = "";
  let fs_height = 0;
  let rs_height = 0;
  let orient_min = "up";
  const placemarks_arr: Position[] = [];
  const options: {units: Units} = {
    units: "kilometers",
  };

  const createMissionKmz = async () => {
    // Calculate or get Total Area of Feature
    const area = turfArea(ftr) / 4046.8564224;
    let distance_pts = 0;

    if (scan_type === "rapid") {
      fs_height = 120;
      rs_height = 12;
      const gsd_rs = (sw * fs_height * 100) / (fr * imW);
      const dw = (gsd_rs * imW) / 100;
      const dh = (gsd_rs * imH) / 100;
      const sidelap = (dw * (1 - 40 / 100)) / 1000;
      const overlap = (dh * (1 - 40 / 100)) / 1000;
      time_interval_val = (overlap * 1000) / 15;
      if (area > 0 && area <= 10) {
        distance_pts = 20 / 1000;
      } else if (area > 10 && area <= 50) {
        distance_pts = 30 / 1000;
      } else if (area > 50 && area <= 100) {
        distance_pts = 40 / 1000;
      } else if (area > 100 && area <= 150) {
        distance_pts = 60 / 1000;
      } else if (area > 150) {
        distance_pts = 80 / 1000;
      }
      full_scout(sidelap, overlap, ftr);
      high_alt_points = placemarks_arr.length;
      rapid_scout(distance_pts, distance_pts, ftr);
    } else if (scan_type === "full") {
      fs_height = (gsd * fr * imW) / (sw * 100);
      const dw = (gsd * imW) / 100;
      const dh = (gsd * imH) / 100;
      const sidelap = (dw * (1 - sl_perc / 100)) / 1000;
      const overlap = (dh * (1 - ol_perc / 100)) / 1000;
      full_scout(sidelap, overlap, ftr);
      high_alt_points = placemarks_arr.length;
    }

    make_kml_wpml();

    await downloadKmz();
  };

  const full_scout = (sidelap: number, overlap: number, feature: Polygon) => {
    // 0 to top
    // 180 to bottom
    // 90 to right
    // -90 to left
    // 60 from starting point

    const temp_arr: Position[] = [];

    let last_added_point: Position | null = null;
    let min_0 = 0;
    let min_1 = 0;
    let max_0 = 0;
    let max_1 = 0;
    let distance = 0.0001;
    let bearing = 60;

    const buffer_coords = feature.coordinates[0];

    if (
      !(
        buffer_coords[0].toString() ===
        buffer_coords[buffer_coords.length - 1].toString()
      )
    ) {
      // push first coord array to last position for turf polygon
      buffer_coords.push(buffer_coords[0]);
    }

    const buffered = turfPolygon([buffer_coords]);

    const bbox = turfBbox(buffered);

    min_0 = bbox[0];
    min_1 = bbox[1];
    max_0 = bbox[2];
    max_1 = bbox[3];

    let destination = turfDestination(
      [min_0, min_1],
      distance,
      bearing,
      options,
    );

    if (booleanIntersects(buffered, destination)) {
      addPlaceMark(destination.geometry.coordinates, fs_height);

      temp_arr.push(destination.geometry.coordinates);

      last_added_point = destination.geometry.coordinates;
    }

    let grid_flag = true;

    while (grid_flag) {
      if (orient_min === "up") {
        bearing = 0;
        distance = overlap;
      } else if (orient_min === "down") {
        bearing = 180;
        distance = overlap;
      } else if (orient_min === "right_down") {
        bearing = 90;
        distance = sidelap;
        orient_min = "down";
      } else if (orient_min === "right_up") {
        bearing = 90;
        distance = sidelap;
        orient_min = "up";
      }

      const new_point = turfDestination(
        destination,
        distance,
        bearing,
        options,
      );

      destination = new_point;

      if (new_point.geometry.coordinates[1] > max_1) {
        if (orient_min == "up") {
          orient_min = "right_down";
        }
      }
      if (new_point.geometry.coordinates[1] < min_1) {
        if (orient_min == "down") {
          orient_min = "right_up";
        }
      }
      if (booleanIntersects(buffered, new_point)) {
        temp_arr.push(destination.geometry.coordinates);

        if (last_added_point) {
          if (last_added_point[0] !== new_point.geometry.coordinates[0]) {
            addPlaceMark(temp_arr[temp_arr.length - 2], fs_height);

            addPlaceMark(new_point.geometry.coordinates, fs_height);

            last_added_point = new_point.geometry.coordinates;
          }
        } else {
          addPlaceMark(new_point.geometry.coordinates, fs_height);

          last_added_point = new_point.geometry.coordinates;
        }
      }
      if (
        new_point.geometry.coordinates[0] > max_0 &&
        new_point.geometry.coordinates[1] > max_1
      ) {
        grid_flag = false;
      }
    }

    if (
      !(
        temp_arr[temp_arr.length - 2][0] ==
          last_added_point?.[last_added_point.length - 2][0] &&
        temp_arr[temp_arr.length - 2][1] ==
          last_added_point?.[last_added_point.length - 2][1]
      )
    ) {
      addPlaceMark(temp_arr[temp_arr.length - 1], fs_height);
    }
  };

  function rapid_scout(sidelap, overlap, feature) {
    // 0 to top
    // 180 to bottom
    // 90 to right
    // -90 to left
    // 60 from starting point
    let min_0 = 0;
    let min_1 = 0;
    let max_0 = 0;
    let max_1 = 0;
    let distance = 0.0001;
    let bearing = 60;

    const buffer_coords = feature.coordinates[0];
    if (
      !(
        buffer_coords[0].toString() ===
        buffer_coords[buffer_coords.length - 1].toString()
      )
    ) {
      // push first coord array to last position for turf polygon
      buffer_coords.push(buffer_coords[0]);
    }

    // const buffer_size = overlap/2;
    let buffer_size = overlap;

    if (parseInt(buffer_size) < 40 / 1000) {
      buffer_size = 40 / 1000;
    }

    const buffered = turfBuffer(turfPolygon([buffer_coords]), -buffer_size, {
      units: "kilometers",
    });

    const bbox = turfBbox(buffered);

    min_0 = bbox[0];
    min_1 = bbox[1];
    max_0 = bbox[2];
    max_1 = bbox[3];

    let destination: Feature<Point> | null = null;

    if (orient_min == "right_down" || orient_min == "down") {
      destination = turfDestination([max_0, min_1], distance, bearing, options);
      orient_min = "up";
    } else if (orient_min == "right_up" || orient_min == "right_up") {
      destination = turfDestination([max_0, max_1], distance, bearing, options);
      orient_min = "down";
    }

    if (destination && booleanIntersects(buffered, destination)) {
      addPlaceMark(destination.geometry.coordinates, rs_height);
    }

    let grid_flag = true;

    while (grid_flag) {
      if (orient_min === "up") {
        bearing = 0;
        distance = overlap;
      } else if (orient_min === "down") {
        bearing = 180;
        distance = overlap;
      } else if (orient_min === "left_down") {
        bearing = -90;
        distance = sidelap;
        orient_min = "down";
      } else if (orient_min === "left_up") {
        bearing = -90;
        distance = sidelap;
        orient_min = "up";
      }

      if (destination) {
        const new_point = turfDestination(
          destination.geometry.coordinates,
          distance,
          bearing,
          options,
        );

        destination = new_point;

        if (new_point.geometry.coordinates[1] > max_1) {
          if (orient_min == "up") {
            orient_min = "left_down";
          }
        }
        if (new_point.geometry.coordinates[1] < min_1) {
          if (orient_min == "down") {
            orient_min = "left_up";
          }
        }
        if (booleanIntersects(buffered, new_point)) {
          addPlaceMark(new_point.geometry.coordinates, rs_height);
        }
        if (
          new_point.geometry.coordinates[0] < min_0 &&
          new_point.geometry.coordinates[1] < min_1
        ) {
          grid_flag = false;
        }
      }
    }
  }

  const make_kml_wpml = () => {
    let prefix = "";

    for (let index = 0; index < placemarks_arr.length; index++) {
      // console.log(placemarks_arr[index][2]);
      if (placemarks_arr[index][2] === fs_height) {
        prefix = "HA";
      } else {
        prefix = "LA";
      }
      if (index == 0) {
        placemarks = "<Placemark>";
        placemarks += "<Point>";
        placemarks += "<coordinates>";
        placemarks += placemarks_arr[index][0] + "," + placemarks_arr[index][1];
        placemarks += "</coordinates>";
        placemarks += "</Point>";
        placemarks += "<wpml:index>0</wpml:index>";
        placemarks +=
          "<wpml:ellipsoidHeight>" +
          placemarks_arr[index][2] +
          "</wpml:ellipsoidHeight>";
        placemarks +=
          "<wpml:height>" + placemarks_arr[index][2] + "</wpml:height>";
        placemarks += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
        placemarks += "<wpml:waypointHeadingParam>";
        placemarks +=
          "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
        placemarks +=
          "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
        placemarks +=
          "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
        placemarks +=
          "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
        placemarks +=
          "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
        placemarks += "</wpml:waypointHeadingParam>";
        placemarks += "<wpml:waypointTurnParam>";
        placemarks +=
          "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
        placemarks +=
          "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
        placemarks += "</wpml:waypointTurnParam>";
        placemarks += "<wpml:useStraightLine>1</wpml:useStraightLine>";
        placemarks += "<wpml:actionGroup>";
        placemarks +=
          "<wpml:actionGroupId>" + (index + 1) + "</wpml:actionGroupId>";
        placemarks +=
          "<wpml:actionGroupStartIndex>0</wpml:actionGroupStartIndex>";
        placemarks += "<wpml:actionGroupEndIndex>2</wpml:actionGroupEndIndex>";
        placemarks += "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
        placemarks += "<wpml:actionTrigger>";
        placemarks +=
          "<wpml:actionTriggerType>multipleTiming</wpml:actionTriggerType>";
        placemarks +=
          "<wpml:actionTriggerParam>" +
          Math.floor(time_interval_val) +
          "</wpml:actionTriggerParam>";
        placemarks += "</wpml:actionTrigger>";
        placemarks += "<wpml:action>";
        placemarks += "<wpml:actionId>0</wpml:actionId>";
        placemarks +=
          "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
        placemarks += "<wpml:actionActuatorFuncParam>";
        placemarks +=
          "<wpml:fileSuffix>" +
          prefix +
          "-waypoint" +
          (index + 1) +
          "-" +
          placemarks_arr[index][2] +
          "m</wpml:fileSuffix>";
        placemarks +=
          "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
        placemarks +=
          "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
        placemarks += "</wpml:actionActuatorFuncParam>";
        placemarks += "</wpml:action>";
        placemarks += "</wpml:actionGroup>";
        // placemarks += "<wpml:actionGroup>";
        // placemarks += "<wpml:actionGroupId>" + placemarks_arr.length + "</wpml:actionGroupId>";
        // placemarks += "<wpml:actionGroupStartIndex>0</wpml:actionGroupStartIndex>";
        // placemarks += "<wpml:actionGroupEndIndex>" + high_alt_points + "</wpml:actionGroupEndIndex>";
        // placemarks += "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
        // placemarks += "<wpml:actionTrigger>";
        // placemarks += "<wpml:actionTriggerType>multipleTiming</wpml:actionTriggerType>";
        // placemarks += "<wpml:actionTriggerParam>89</wpml:actionTriggerParam>";
        // placemarks += "</wpml:actionTrigger>";
        // placemarks += "<wpml:action>";
        // placemarks += "<wpml:actionId>0</wpml:actionId>";
        // placemarks += "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
        // placemarks += "<wpml:actionActuatorFuncParam>";
        // placemarks += "<wpml:fileSuffix>" + prefix + "-waypoint" + (index + 1) + "-" + placemarks_arr[index][2] + "m</wpml:fileSuffix>";
        // placemarks += "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
        // placemarks += "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
        // placemarks += "</wpml:actionActuatorFuncParam>";
        // placemarks += "</wpml:action>";
        // placemarks += "</wpml:actionGroup>";
        placemarks += "<wpml:isRisky>0</wpml:isRisky>";
        placemarks += "</Placemark>";

        placemarks_wpml = "<Placemark>";
        placemarks_wpml += "<Point>";
        placemarks_wpml += "<coordinates>";
        placemarks_wpml +=
          placemarks_arr[index][0] + "," + placemarks_arr[index][1];
        placemarks_wpml += "</coordinates>";
        placemarks_wpml += "</Point>";
        placemarks_wpml += "<wpml:index>0</wpml:index>";
        placemarks_wpml +=
          "<wpml:executeHeight>" +
          placemarks_arr[index][2] +
          "</wpml:executeHeight>";
        placemarks_wpml += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
        placemarks_wpml += "<wpml:waypointHeadingParam>";
        placemarks_wpml +=
          "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
        placemarks_wpml +=
          "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
        placemarks_wpml +=
          "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
        placemarks_wpml +=
          "<wpml:waypointHeadingAngleEnable>0</wpml:waypointHeadingAngleEnable>";
        placemarks_wpml +=
          "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
        placemarks_wpml +=
          "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
        placemarks_wpml += "</wpml:waypointHeadingParam>";
        placemarks_wpml += "<wpml:waypointTurnParam>";
        placemarks_wpml +=
          "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
        placemarks_wpml +=
          "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
        placemarks_wpml += "</wpml:waypointTurnParam>";
        placemarks_wpml += "<wpml:useStraightLine>1</wpml:useStraightLine>";
        // placemarks_wpml += "<wpml:actionGroup>";
        // placemarks_wpml += "<wpml:actionGroupId>" + placemarks_arr.length + "</wpml:actionGroupId>";
        // placemarks_wpml += "<wpml:actionGroupStartIndex>0</wpml:actionGroupStartIndex>";
        // placemarks_wpml += "<wpml:actionGroupEndIndex>" + high_alt_points + "</wpml:actionGroupEndIndex>";
        // placemarks_wpml += "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
        // placemarks_wpml += "<wpml:actionTrigger>";
        // placemarks_wpml += "<wpml:actionTriggerType>multipleDistance</wpml:actionTriggerType>";
        // placemarks_wpml += "<wpml:actionTriggerParam>89</wpml:actionTriggerParam>";
        // placemarks_wpml += "</wpml:actionTrigger>";
        // placemarks_wpml += "<wpml:action>";
        // placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
        // placemarks_wpml += "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
        // placemarks_wpml += "<wpml:actionActuatorFuncParam>";
        // placemarks_wpml += "<wpml:fileSuffix>" + prefix + "-waypoint" + (index + 1) + "-" + placemarks_arr[index][2] + "m</wpml:fileSuffix>";
        // placemarks_wpml += "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
        // placemarks_wpml += "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
        // placemarks_wpml += "</wpml:actionActuatorFuncParam>";
        // placemarks_wpml += "</wpml:action>";
        // placemarks_wpml += "</wpml:actionGroup>";
        placemarks_wpml += "<wpml:actionGroup>";
        placemarks_wpml +=
          "<wpml:actionGroupId>" + (index + 1) + "</wpml:actionGroupId>";
        placemarks_wpml +=
          "<wpml:actionGroupStartIndex>0</wpml:actionGroupStartIndex>";
        placemarks_wpml +=
          "<wpml:actionGroupEndIndex>2</wpml:actionGroupEndIndex>";
        placemarks_wpml +=
          "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
        placemarks_wpml += "<wpml:actionTrigger>";
        placemarks_wpml +=
          "<wpml:actionTriggerType>multipleTiming</wpml:actionTriggerType>";
        placemarks_wpml +=
          "<wpml:actionTriggerParam>" +
          Math.floor(time_interval_val) +
          "</wpml:actionTriggerParam>";
        placemarks_wpml += "</wpml:actionTrigger>";
        placemarks_wpml += "<wpml:action>";
        placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
        placemarks_wpml +=
          "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
        placemarks_wpml += "<wpml:actionActuatorFuncParam>";
        placemarks_wpml +=
          "<wpml:fileSuffix>" +
          prefix +
          "-waypoint" +
          (index + 1) +
          "-" +
          placemarks_arr[index][2] +
          "m</wpml:fileSuffix>";
        placemarks_wpml +=
          "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
        placemarks_wpml +=
          "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
        placemarks_wpml += "</wpml:actionActuatorFuncParam>";
        placemarks_wpml += "</wpml:action>";
        placemarks_wpml += "</wpml:actionGroup>";
        placemarks_wpml += "<wpml:waypointGimbalHeadingParam>";
        placemarks_wpml +=
          "<wpml:waypointGimbalPitchAngle>0</wpml:waypointGimbalPitchAngle>";
        placemarks_wpml +=
          "<wpml:waypointGimbalYawAngle>0</wpml:waypointGimbalYawAngle>";
        placemarks_wpml += "</wpml:waypointGimbalHeadingParam>";
        placemarks_wpml += "<wpml:isRisky>0</wpml:isRisky>";
        placemarks_wpml += "</Placemark>";

        placemarks += "<Placemark>";
        placemarks += "<Point>";
        placemarks += "<coordinates>";
        placemarks +=
          placemarks_arr[index][0] +
          "," +
          (placemarks_arr[index][1] + 0.000088);
        placemarks += "</coordinates>";
        placemarks += "</Point>";
        placemarks += "<wpml:index>1</wpml:index>";
        placemarks +=
          "<wpml:ellipsoidHeight>" +
          placemarks_arr[index][2] +
          "</wpml:ellipsoidHeight>";
        placemarks +=
          "<wpml:height>" + placemarks_arr[index][2] + "</wpml:height>";
        placemarks += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
        placemarks += "<wpml:waypointHeadingParam>";
        placemarks +=
          "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
        placemarks +=
          "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
        placemarks +=
          "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
        placemarks +=
          "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
        placemarks +=
          "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
        placemarks += "</wpml:waypointHeadingParam>";
        placemarks += "<wpml:waypointTurnParam>";
        placemarks +=
          "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
        placemarks +=
          "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
        placemarks += "</wpml:waypointTurnParam>";
        placemarks += "<wpml:gimbalPitchAngle>-90</wpml:gimbalPitchAngle>";
        placemarks += "<wpml:useStraightLine>1</wpml:useStraightLine>";
        placemarks += "<wpml:actionGroup>";
        placemarks += "<wpml:actionGroupId>1</wpml:actionGroupId>";
        placemarks +=
          "<wpml:actionGroupStartIndex>1</wpml:actionGroupStartIndex>";
        placemarks += "<wpml:actionGroupEndIndex>1</wpml:actionGroupEndIndex>";
        placemarks += "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
        placemarks += "<wpml:actionTrigger>";
        placemarks +=
          "<wpml:actionTriggerType>reachPoint</wpml:actionTriggerType>";
        placemarks += "</wpml:actionTrigger>";
        placemarks += "<wpml:action>";
        placemarks += "<wpml:actionId>0</wpml:actionId>";
        placemarks +=
          "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
        placemarks += "<wpml:actionActuatorFuncParam>";
        placemarks +=
          "<wpml:fileSuffix>" +
          prefix +
          "-waypoint" +
          (index + 2) +
          "-" +
          placemarks_arr[index][2] +
          "m</wpml:fileSuffix>";
        placemarks +=
          "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
        placemarks +=
          "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
        placemarks += "</wpml:actionActuatorFuncParam>";
        placemarks += "</wpml:action>";
        placemarks += "</wpml:actionGroup>";
        placemarks += "<wpml:isRisky>0</wpml:isRisky>";
        placemarks += "</Placemark>";

        placemarks_wpml += "<Placemark>";
        placemarks_wpml += "<Point>";
        placemarks_wpml += "<coordinates>";
        placemarks_wpml +=
          placemarks_arr[index][0] +
          "," +
          (placemarks_arr[index][1] + 0.000088);
        placemarks_wpml += "</coordinates>";
        placemarks_wpml += "</Point>";
        placemarks_wpml += "<wpml:index>1</wpml:index>";
        placemarks_wpml +=
          "<wpml:executeHeight>" +
          placemarks_arr[index][2] +
          "</wpml:executeHeight>";
        placemarks_wpml += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
        placemarks_wpml += "<wpml:waypointHeadingParam>";
        placemarks_wpml +=
          "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
        placemarks_wpml +=
          "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
        placemarks_wpml +=
          "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
        placemarks_wpml +=
          "<wpml:waypointHeadingAngleEnable>0</wpml:waypointHeadingAngleEnable>";
        placemarks_wpml +=
          "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
        placemarks_wpml +=
          "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
        placemarks_wpml += "</wpml:waypointHeadingParam>";
        placemarks_wpml += "<wpml:waypointTurnParam>";
        placemarks_wpml +=
          "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
        placemarks_wpml +=
          "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
        placemarks_wpml += "</wpml:waypointTurnParam>";
        placemarks_wpml += "<wpml:gimbalPitchAngle>-90</wpml:gimbalPitchAngle>";
        placemarks_wpml += "<wpml:useStraightLine>1</wpml:useStraightLine>";
        placemarks_wpml += "<wpml:actionGroupId>1</wpml:actionGroupId>";
        placemarks_wpml +=
          "<wpml:actionGroupStartIndex>1</wpml:actionGroupStartIndex>";
        placemarks_wpml +=
          "<wpml:actionGroupEndIndex>1</wpml:actionGroupEndIndex>";
        placemarks_wpml +=
          "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
        placemarks_wpml += "<wpml:actionTrigger>";
        placemarks_wpml +=
          "<wpml:actionTriggerType>reachPoint</wpml:actionTriggerType>";
        placemarks_wpml += "</wpml:actionTrigger>";
        placemarks_wpml += "<wpml:action>";
        placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
        placemarks_wpml +=
          "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
        placemarks_wpml += "<wpml:actionActuatorFuncParam>";
        placemarks_wpml +=
          "<wpml:fileSuffix>" +
          prefix +
          "-waypoint" +
          (index + 2) +
          "-" +
          placemarks_arr[index][2] +
          "m</wpml:fileSuffix>";
        placemarks_wpml +=
          "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
        placemarks_wpml +=
          "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
        placemarks_wpml += "</wpml:actionActuatorFuncParam>";
        placemarks_wpml += "</wpml:action>";
        placemarks_wpml += "</wpml:actionGroup>";
        placemarks_wpml += "<wpml:waypointGimbalHeadingParam>";
        placemarks_wpml +=
          "<wpml:waypointGimbalPitchAngle>0</wpml:waypointGimbalPitchAngle>";
        placemarks_wpml +=
          "<wpml:waypointGimbalYawAngle>0</wpml:waypointGimbalYawAngle>";
        placemarks_wpml += "</wpml:waypointGimbalHeadingParam>";
        placemarks_wpml += "<wpml:isRisky>0</wpml:isRisky>";
        placemarks_wpml += "</Placemark>";
        skip_index = 2;
      } else {
        if (prefix === "HA") {
          placemarks += "<Placemark>";
          placemarks += "<Point>";
          placemarks += "<coordinates>";
          placemarks +=
            placemarks_arr[index][0] + "," + placemarks_arr[index][1];
          placemarks += "</coordinates>";
          placemarks += "</Point>";
          placemarks += "<wpml:index>" + (index + 1) + "</wpml:index>";
          placemarks +=
            "<wpml:ellipsoidHeight>" +
            placemarks_arr[index][2] +
            "</wpml:ellipsoidHeight>";
          placemarks +=
            "<wpml:height>" + placemarks_arr[index][2] + "</wpml:height>";
          placemarks += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
          placemarks += "<wpml:waypointHeadingParam>";
          placemarks +=
            "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
          placemarks +=
            "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
          placemarks +=
            "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
          placemarks +=
            "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
          placemarks +=
            "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
          placemarks += "</wpml:waypointHeadingParam>";
          placemarks += "<wpml:waypointTurnParam>";
          placemarks +=
            "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
          placemarks +=
            "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
          placemarks += "</wpml:waypointTurnParam>";
          placemarks += "<wpml:gimbalPitchAngle>-90</wpml:gimbalPitchAngle>";
          placemarks += "<wpml:useStraightLine>1</wpml:useStraightLine>";
          if (skip_index !== index + 1) {
            // console.log("HA: " + index);
            skip_index = index + 2;
            // console.log(index + 1);
            // console.log(skip_index);
            if (skip_index <= high_alt_points + 1) {
              // console.log("Interval: " + (index + 1) + " - " + (index + 2));
              placemarks += "<wpml:actionGroup>";
              placemarks +=
                "<wpml:actionGroupId>" + (index + 1) + "</wpml:actionGroupId>";
              placemarks +=
                "<wpml:actionGroupStartIndex>" +
                (index + 1) +
                "</wpml:actionGroupStartIndex>";
              placemarks +=
                "<wpml:actionGroupEndIndex>" +
                (index + 2) +
                "</wpml:actionGroupEndIndex>";
              placemarks +=
                "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
              placemarks += "<wpml:actionTrigger>";
              placemarks +=
                "<wpml:actionTriggerType>multipleTiming</wpml:actionTriggerType>";
              placemarks +=
                "<wpml:actionTriggerParam>" +
                Math.floor(time_interval_val) +
                "</wpml:actionTriggerParam>";
              placemarks += "</wpml:actionTrigger>";
              placemarks += "<wpml:action>";
              placemarks += "<wpml:actionId>0</wpml:actionId>";
              placemarks +=
                "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
              placemarks += "<wpml:actionActuatorFuncParam>";
              placemarks +=
                "<wpml:fileSuffix>" +
                prefix +
                "-waypoint" +
                (index + 2) +
                "-" +
                placemarks_arr[index][2] +
                "m</wpml:fileSuffix>";
              placemarks +=
                "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
              placemarks +=
                "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
              placemarks += "</wpml:actionActuatorFuncParam>";
              placemarks += "</wpml:action>";
              placemarks += "</wpml:actionGroup>";
              placemarks += "<wpml:isRisky>0</wpml:isRisky>";
              placemarks += "</Placemark>";
            } else {
              placemarks += "<wpml:actionGroup>";
              placemarks += "<wpml:action>";
              placemarks += "<wpml:actionId>0</wpml:actionId>";
              placemarks +=
                "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
              placemarks += "<wpml:actionActuatorFuncParam>";
              placemarks +=
                "<wpml:fileSuffix>" +
                prefix +
                "-waypoint" +
                (index + 2) +
                "-" +
                placemarks_arr[index][2] +
                "m</wpml:fileSuffix>";
              placemarks +=
                "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
              placemarks +=
                "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
              placemarks += "</wpml:actionActuatorFuncParam>";
              placemarks += "</wpml:action>";
              placemarks += "</wpml:actionGroup>";
              placemarks += "<wpml:isRisky>0</wpml:isRisky>";
              placemarks += "</Placemark>";
            }
          } else {
            placemarks += "<wpml:actionGroup>";
            placemarks += "<wpml:action>";
            placemarks += "<wpml:actionId>0</wpml:actionId>";
            placemarks +=
              "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
            placemarks += "<wpml:actionActuatorFuncParam>";
            placemarks +=
              "<wpml:fileSuffix>" +
              prefix +
              "-waypoint" +
              (index + 2) +
              "-" +
              placemarks_arr[index][2] +
              "m</wpml:fileSuffix>";
            placemarks +=
              "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
            placemarks +=
              "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
            placemarks += "</wpml:actionActuatorFuncParam>";
            placemarks += "</wpml:action>";
            placemarks += "</wpml:actionGroup>";
            placemarks += "<wpml:isRisky>0</wpml:isRisky>";
            placemarks += "</Placemark>";
          }

          placemarks_wpml += "<Placemark>";
          placemarks_wpml += "<Point>";
          placemarks_wpml += "<coordinates>";
          placemarks_wpml +=
            placemarks_arr[index][0] + "," + placemarks_arr[index][1];
          placemarks_wpml += "</coordinates>";
          placemarks_wpml += "</Point>";
          placemarks_wpml += "<wpml:index>" + (index + 1) + "</wpml:index>";
          placemarks_wpml +=
            "<wpml:executeHeight>" +
            placemarks_arr[index][2] +
            "</wpml:executeHeight>";
          placemarks_wpml += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
          placemarks_wpml += "<wpml:waypointHeadingParam>";
          placemarks_wpml +=
            "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
          placemarks_wpml +=
            "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
          placemarks_wpml +=
            "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
          placemarks_wpml +=
            "<wpml:waypointHeadingAngleEnable>0</wpml:waypointHeadingAngleEnable>";
          placemarks_wpml +=
            "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
          placemarks_wpml +=
            "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
          placemarks_wpml += "</wpml:waypointHeadingParam>";
          placemarks_wpml += "<wpml:waypointTurnParam>";
          placemarks_wpml +=
            "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
          placemarks_wpml +=
            "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
          placemarks_wpml += "</wpml:waypointTurnParam>";
          placemarks_wpml +=
            "<wpml:gimbalPitchAngle>-90</wpml:gimbalPitchAngle>";
          placemarks_wpml += "<wpml:useStraightLine>1</wpml:useStraightLine>";
          if (skip_index !== index + 1) {
            // console.log("HA: " + index);
            skip_index = index + 2;
            if (skip_index <= high_alt_points + 1) {
              placemarks_wpml += "<wpml:actionGroup>";
              placemarks_wpml +=
                "<wpml:actionGroupId>" + (index + 1) + "</wpml:actionGroupId>";
              placemarks_wpml +=
                "<wpml:actionGroupStartIndex>" +
                (index + 1) +
                "</wpml:actionGroupStartIndex>";
              placemarks_wpml +=
                "<wpml:actionGroupEndIndex>" +
                (index + 2) +
                "</wpml:actionGroupEndIndex>";
              placemarks_wpml +=
                "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
              placemarks_wpml += "<wpml:actionTrigger>";
              placemarks_wpml +=
                "<wpml:actionTriggerType>multipleTiming</wpml:actionTriggerType>";
              placemarks_wpml +=
                "<wpml:actionTriggerParam>" +
                Math.floor(time_interval_val) +
                "</wpml:actionTriggerParam>";
              placemarks_wpml += "</wpml:actionTrigger>";
              placemarks_wpml += "<wpml:action>";
              placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
              placemarks_wpml +=
                "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
              placemarks_wpml += "<wpml:actionActuatorFuncParam>";
              placemarks_wpml +=
                "<wpml:fileSuffix>" +
                prefix +
                "-waypoint" +
                (index + 2) +
                "-" +
                placemarks_arr[index][2] +
                "m</wpml:fileSuffix>";
              placemarks_wpml +=
                "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
              placemarks_wpml +=
                "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
              placemarks_wpml += "</wpml:actionActuatorFuncParam>";
              placemarks_wpml += "</wpml:action>";
              placemarks_wpml += "</wpml:actionGroup>";
            } else {
              placemarks_wpml += "<wpml:actionGroup>";
              placemarks_wpml += "<wpml:action>";
              placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
              placemarks_wpml +=
                "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
              placemarks_wpml += "<wpml:actionActuatorFuncParam>";
              placemarks_wpml +=
                "<wpml:fileSuffix>" +
                prefix +
                "-waypoint" +
                (index + 2) +
                "-" +
                placemarks_arr[index][2] +
                "m</wpml:fileSuffix>";
              placemarks_wpml +=
                "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
              placemarks_wpml +=
                "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
              placemarks_wpml += "</wpml:actionActuatorFuncParam>";
              placemarks_wpml += "</wpml:action>";
              placemarks_wpml += "</wpml:actionGroup>";
            }
          } else {
            placemarks_wpml += "<wpml:actionGroup>";
            placemarks_wpml += "<wpml:action>";
            placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
            placemarks_wpml +=
              "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
            placemarks_wpml += "<wpml:actionActuatorFuncParam>";
            placemarks_wpml +=
              "<wpml:fileSuffix>" +
              prefix +
              "-waypoint" +
              (index + 2) +
              "-" +
              placemarks_arr[index][2] +
              "m</wpml:fileSuffix>";
            placemarks_wpml +=
              "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
            placemarks_wpml +=
              "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
            placemarks_wpml += "</wpml:actionActuatorFuncParam>";
            placemarks_wpml += "</wpml:action>";
            placemarks_wpml += "</wpml:actionGroup>";
          }
          placemarks_wpml += "<wpml:waypointGimbalHeadingParam>";
          placemarks_wpml +=
            "<wpml:waypointGimbalPitchAngle>0</wpml:waypointGimbalPitchAngle>";
          placemarks_wpml +=
            "<wpml:waypointGimbalYawAngle>0</wpml:waypointGimbalYawAngle>";
          placemarks_wpml += "</wpml:waypointGimbalHeadingParam>";
          placemarks_wpml += "<wpml:isRisky>0</wpml:isRisky>";
          placemarks_wpml += "</Placemark>";
        } else if (prefix === "LA") {
          placemarks += "<Placemark>";
          placemarks += "<Point>";
          placemarks += "<coordinates>";
          placemarks +=
            placemarks_arr[index][0] + "," + placemarks_arr[index][1];
          placemarks += "</coordinates>";
          placemarks += "</Point>";
          placemarks += "<wpml:index>" + (index + 1) + "</wpml:index>";
          placemarks +=
            "<wpml:ellipsoidHeight>" +
            placemarks_arr[index][2] +
            "</wpml:ellipsoidHeight>";
          placemarks +=
            "<wpml:height>" + placemarks_arr[index][2] + "</wpml:height>";
          placemarks += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
          placemarks += "<wpml:waypointHeadingParam>";
          placemarks +=
            "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
          placemarks +=
            "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
          placemarks +=
            "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
          placemarks +=
            "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
          placemarks +=
            "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
          placemarks += "</wpml:waypointHeadingParam>";
          placemarks += "<wpml:waypointTurnParam>";
          placemarks +=
            "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
          placemarks +=
            "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
          placemarks += "</wpml:waypointTurnParam>";
          placemarks += "<wpml:gimbalPitchAngle>-90</wpml:gimbalPitchAngle>";
          placemarks += "<wpml:useStraightLine>1</wpml:useStraightLine>";
          placemarks += "<wpml:actionGroup>";
          placemarks +=
            "<wpml:actionGroupId>" + (index + 1) + "</wpml:actionGroupId>";
          placemarks +=
            "<wpml:actionGroupStartIndex>" +
            (index + 1) +
            "</wpml:actionGroupStartIndex>";
          placemarks +=
            "<wpml:actionGroupEndIndex>" +
            (index + 1) +
            "</wpml:actionGroupEndIndex>";
          placemarks += "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
          placemarks += "<wpml:actionTrigger>";
          placemarks +=
            "<wpml:actionTriggerType>reachPoint</wpml:actionTriggerType>";
          placemarks += "</wpml:actionTrigger>";
          placemarks += "<wpml:action>";
          placemarks += "<wpml:actionId>0</wpml:actionId>";
          placemarks +=
            "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
          placemarks += "<wpml:actionActuatorFuncParam>";
          placemarks +=
            "<wpml:fileSuffix>" +
            prefix +
            "-waypoint" +
            (index + 2) +
            "-" +
            placemarks_arr[index][2] +
            "m</wpml:fileSuffix>";
          placemarks +=
            "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
          placemarks +=
            "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
          placemarks += "</wpml:actionActuatorFuncParam>";
          placemarks += "</wpml:action>";
          placemarks += "</wpml:actionGroup>";
          placemarks += "<wpml:isRisky>0</wpml:isRisky>";
          placemarks += "</Placemark>";

          placemarks_wpml += "<Placemark>";
          placemarks_wpml += "<Point>";
          placemarks_wpml += "<coordinates>";
          placemarks_wpml +=
            placemarks_arr[index][0] + "," + placemarks_arr[index][1];
          placemarks_wpml += "</coordinates>";
          placemarks_wpml += "</Point>";
          placemarks_wpml += "<wpml:index>" + (index + 1) + "</wpml:index>";
          placemarks_wpml +=
            "<wpml:executeHeight>" +
            placemarks_arr[index][2] +
            "</wpml:executeHeight>";
          placemarks_wpml += "<wpml:waypointSpeed>15</wpml:waypointSpeed>";
          placemarks_wpml += "<wpml:waypointHeadingParam>";
          placemarks_wpml +=
            "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
          placemarks_wpml +=
            "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
          placemarks_wpml +=
            "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
          placemarks_wpml +=
            "<wpml:waypointHeadingAngleEnable>0</wpml:waypointHeadingAngleEnable>";
          placemarks_wpml +=
            "<wpml:waypointHeadingPathMode>followBadArc</wpml:waypointHeadingPathMode>";
          placemarks_wpml +=
            "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
          placemarks_wpml += "</wpml:waypointHeadingParam>";
          placemarks_wpml += "<wpml:waypointTurnParam>";
          placemarks_wpml +=
            "<wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>";
          placemarks_wpml +=
            "<wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>";
          placemarks_wpml += "</wpml:waypointTurnParam>";
          placemarks_wpml +=
            "<wpml:gimbalPitchAngle>-90</wpml:gimbalPitchAngle>";
          placemarks_wpml += "<wpml:useStraightLine>1</wpml:useStraightLine>";
          placemarks_wpml += "<wpml:actionGroup>";
          placemarks_wpml +=
            "<wpml:actionGroupId>" + (index + 1) + "</wpml:actionGroupId>";
          placemarks_wpml +=
            "<wpml:actionGroupStartIndex>" +
            (index + 1) +
            "</wpml:actionGroupStartIndex>";
          placemarks_wpml +=
            "<wpml:actionGroupEndIndex>" +
            (index + 1) +
            "</wpml:actionGroupEndIndex>";
          placemarks_wpml +=
            "<wpml:actionGroupMode>sequence</wpml:actionGroupMode>";
          placemarks_wpml += "<wpml:actionTrigger>";
          placemarks_wpml +=
            "<wpml:actionTriggerType>reachPoint</wpml:actionTriggerType>";
          placemarks_wpml += "</wpml:actionTrigger>";
          placemarks_wpml += "<wpml:action>";
          placemarks_wpml += "<wpml:actionId>0</wpml:actionId>";
          placemarks_wpml +=
            "<wpml:actionActuatorFunc>takePhoto</wpml:actionActuatorFunc>";
          placemarks_wpml += "<wpml:actionActuatorFuncParam>";
          placemarks_wpml +=
            "<wpml:fileSuffix>" +
            prefix +
            "-waypoint" +
            (index + 2) +
            "-" +
            placemarks_arr[index][2] +
            "m</wpml:fileSuffix>";
          placemarks_wpml +=
            "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
          placemarks_wpml +=
            "<wpml:useGlobalPayloadLensIndex>0</wpml:useGlobalPayloadLensIndex>";
          placemarks_wpml += "</wpml:actionActuatorFuncParam>";
          placemarks_wpml += "</wpml:action>";
          placemarks_wpml += "</wpml:actionGroup>";
          placemarks_wpml += "<wpml:waypointGimbalHeadingParam>";
          placemarks_wpml +=
            "<wpml:waypointGimbalPitchAngle>0</wpml:waypointGimbalPitchAngle>";
          placemarks_wpml +=
            "<wpml:waypointGimbalYawAngle>0</wpml:waypointGimbalYawAngle>";
          placemarks_wpml += "</wpml:waypointGimbalHeadingParam>";
          placemarks_wpml += "<wpml:isRisky>0</wpml:isRisky>";
          placemarks_wpml += "</Placemark>";
        }
      }
    }
    kml = '<?xml version="1.0" encoding="UTF-8"?>';
    kml +=
      '<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.4">';
    kml += "<Document>";
    kml += "<wpml:createTime>1715080542166</wpml:createTime>";
    kml += "<wpml:updateTime>1715081059202</wpml:updateTime>";
    kml += "<wpml:missionConfig>";
    kml += "<wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>";
    kml += "<wpml:finishAction>goHome</wpml:finishAction>";
    kml += "<wpml:exitOnRCLost>executeLostAction</wpml:exitOnRCLost>";
    kml += "<wpml:executeRCLostAction>goBack</wpml:executeRCLostAction>";
    kml += "<wpml:takeOffSecurityHeight>20</wpml:takeOffSecurityHeight>";
    kml += "<wpml:globalTransitionalSpeed>15</wpml:globalTransitionalSpeed>";
    kml += "<wpml:droneInfo>";
    kml += "<wpml:droneEnumValue>" + enumValue + "</wpml:droneEnumValue>";
    kml +=
      "<wpml:droneSubEnumValue>" + enumSubValue + "</wpml:droneSubEnumValue>";
    kml += "</wpml:droneInfo>";
    kml += "<wpml:payloadInfo>";
    kml +=
      "<wpml:payloadEnumValue>" + payloadEnumValue + "</wpml:payloadEnumValue>";
    kml += "<wpml:payloadSubEnumValue>0</wpml:payloadSubEnumValue>";
    kml += "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
    kml += "</wpml:payloadInfo>";
    kml += "</wpml:missionConfig>";
    kml += "<Folder>";
    kml += "<wpml:templateType>waypoint</wpml:templateType>";
    kml += "<wpml:templateId>0</wpml:templateId>";
    kml += "<wpml:waylineCoordinateSysParam>";
    kml += "<wpml:coordinateMode>WGS84</wpml:coordinateMode>";
    kml += "<wpml:heightMode>relativeToStartPoint</wpml:heightMode>";
    kml += "<wpml:positioningType>GPS</wpml:positioningType>";
    kml += "</wpml:waylineCoordinateSysParam>";
    kml += "<wpml:autoFlightSpeed>15</wpml:autoFlightSpeed>";
    kml += "<wpml:globalHeight>100</wpml:globalHeight>";
    kml += "<wpml:caliFlightEnable>0</wpml:caliFlightEnable>";
    kml += "<wpml:gimbalPitchMode>usePointSetting</wpml:gimbalPitchMode>";
    kml += "<wpml:globalWaypointHeadingParam>";
    kml += "<wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>";
    kml += "<wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>";
    kml +=
      "<wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>";
    kml += "<wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>";
    kml += "</wpml:globalWaypointHeadingParam>";
    kml +=
      "<wpml:globalWaypointTurnMode>toPointAndPassWithContinuityCurvature</wpml:globalWaypointTurnMode>";
    kml += "<wpml:globalUseStraightLine>1</wpml:globalUseStraightLine>";
    kml += placemarks;
    kml += "<wpml:payloadParam>";
    kml += "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
    kml += "<wpml:meteringMode>average</wpml:meteringMode>";
    kml += "<wpml:dewarpingEnable>0</wpml:dewarpingEnable>";
    kml += "<wpml:returnMode>singleReturnStrongest</wpml:returnMode>";
    kml += "<wpml:samplingRate>240000</wpml:samplingRate>";
    kml += "<wpml:scanningMode>nonRepetitive</wpml:scanningMode>";
    kml += "<wpml:modelColoringEnable>0</wpml:modelColoringEnable>";
    kml += "</wpml:payloadParam>";
    kml += "</Folder>";
    kml += "</Document>";
    kml += "</kml>";

    wpml = '<?xml version="1.0" encoding="UTF-8"?>';
    wpml +=
      '<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.4">';
    wpml += "<Document>";
    wpml += "<wpml:missionConfig>";
    wpml += "<wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>";
    wpml += "<wpml:finishAction>goHome</wpml:finishAction>";
    wpml += "<wpml:exitOnRCLost>executeLostAction</wpml:exitOnRCLost>";
    wpml += "<wpml:executeRCLostAction>goBack</wpml:executeRCLostAction>";
    wpml += "<wpml:takeOffSecurityHeight>20</wpml:takeOffSecurityHeight>";
    wpml += "<wpml:globalTransitionalSpeed>15</wpml:globalTransitionalSpeed>";
    wpml += "<wpml:droneInfo>";
    wpml += "<wpml:droneEnumValue>" + enumValue + "</wpml:droneEnumValue>";
    wpml +=
      "<wpml:droneSubEnumValue>" + enumSubValue + "</wpml:droneSubEnumValue>";
    wpml += "</wpml:droneInfo>";
    wpml += "<wpml:payloadInfo>";
    wpml +=
      "<wpml:payloadEnumValue>" + payloadEnumValue + "</wpml:payloadEnumValue>";
    wpml += "<wpml:payloadSubEnumValue>0</wpml:payloadSubEnumValue>";
    wpml += "<wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>";
    wpml += "</wpml:payloadInfo>";
    wpml += "</wpml:missionConfig>";
    wpml += "<Folder>";
    wpml += "<wpml:templateId>0</wpml:templateId>";
    wpml +=
      "<wpml:executeHeightMode>relativeToStartPoint</wpml:executeHeightMode>";
    wpml += "<wpml:waylineId>0</wpml:waylineId>";
    wpml += "<wpml:distance>1633.82556152344</wpml:distance>";
    wpml += "<wpml:duration>345.256020069122</wpml:duration>";
    wpml += "<wpml:autoFlightSpeed>15</wpml:autoFlightSpeed>";
    wpml += placemarks_wpml;
    wpml += "</Folder>";
    wpml += "</Document>";
    wpml += "</kml>";
  };

  const addPlaceMark = (coords: Position, alt: number) => {
    if (
      placemarks_arr.length > 0 &&
      placemarks_arr[placemarks_arr.length - 1][2] !== alt
    ) {
      const prev_point: number[] = [];

      prev_point.push(coords[0]);
      prev_point.push(coords[1] + 0.00000000629099);

      prev_point.push(placemarks_arr[placemarks_arr.length - 1][2]);
      placemarks_arr.push(prev_point);

      coords.push(alt);
      placemarks_arr.push(coords);
    } else {
      if (
        placemarks_arr.length > 0 &&
        placemarks_arr[placemarks_arr.length - 1][0] === coords[0] &&
        placemarks_arr[placemarks_arr.length - 1][1] === coords[1] &&
        placemarks_arr[placemarks_arr.length - 1][2] === alt
      ) {
        const new_pnt: number[] = [];

        new_pnt.push(coords[0]);
        new_pnt.push(coords[1] + 0.00000000629099);
        new_pnt.push(alt);

        placemarks_arr.push(new_pnt);
      } else {
        coords.push(alt);
        placemarks_arr.push(coords);
      }
    }
  };

  const downloadKmz = async () => {
    const zip = new JSZip();
    const wpmz = zip.folder("wpmz");

    if (wpmz) {
      wpmz.file("template.kml", kml);
      wpmz.file("waylines.wpml", wpml);

      const content = await zip.generateAsync({type: "blob"});

      saveAs(content, fileName + ".kmz");
    }
  };

  return createMissionKmz();
};
