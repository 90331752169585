const reportTypes = {
  potato: [
    {
      label: "Stem Count",
      value: "stem_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  sweet_potato: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  onion: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  carrot: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  garlic: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  ginger: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  radish: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  turnip: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cassava: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  yam: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  beetroot: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  taro: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  canola: [
    {
      label: "Pod Count",
      value: "pod_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  soybean: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  lentils: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  beans: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  peas: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  chickpeas: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  peanuts: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cowpeas: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  maize: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  sorghum: [],
  millet: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  rice: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cotton: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  sugarcane: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  wheat: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  tomato: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  chilli: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  barley: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  sunflower: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  grape: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  apple: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cabbage: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  sugarbeet: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  coffee: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  mango: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  citrus: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  banana: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Plant count",
      value: "plant_count",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Plant Heath",
      value: "plant_heath",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  pear: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  peach: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cherry: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  almond: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  walnut: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cashew: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  pistachio: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  hazelnut: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  kiwi: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  date_tree: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  cucumber: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  okra: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  bell_peppers: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  eggplants: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  strawberry: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  oat: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  rye: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  flax: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  buckwheat: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
  berries: [
    {
      label: "Disease Detection",
      value: "disease_detection",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Flower Count",
      value: "flower_count",
      level: "leaf",
      scanTypes: ["rapid"],
      gsdRgb: 0.1,
      gsdMS: 0.2,
      frontalLap: 80,
      sideLap: 80,
    },
    {
      label: "Post emergence Weed Detection",
      value: "post_emergence_weed_detection",
      level: "plant",
      scanTypes: ["full", "rapid"],
      gsdRgb: 0.6,
      gsdMS: 1.0,
      frontalLap: 60,
      sideLap: 60,
    },
    {
      label: "Elevation Map",
      value: "elevation_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Pre Emergence Weed Detection",
      value: "pre_emergence_weed_detection",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Nitrogen Map (For Fertilization)",
      value: "nitrogen_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Organic Matter Map",
      value: "organic_matter_map",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Health NDVI - NDRE",
      value: "crop_health_ndvi_-_ndre",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Crop Lodgeing ",
      value: "crop_lodgeing",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
    {
      label: "Water Loging",
      value: "water_loging",
      level: "patch",
      scanTypes: ["full"],
      gsdRgb: 2.0,
      gsdMS: 4.0,
      frontalLap: 40,
      sideLap: 40,
    },
  ],
};

export default reportTypes;
