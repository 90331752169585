import React from "react";
import {DateTime} from "luxon";
import {shallowEqual} from "react-redux";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {
  mapSelected,
  mapUnselected,
  selectMap,
} from "../../redux/fieldTimelineSlice";

interface Props {
  maps: Array<{id: string; createdAt: string}>;
  month: string;
}

/* =============================================================================
<FieldViewTimelineItem />
============================================================================= */
const FieldViewTimelineItem: React.FC<Props> = ({maps, month}) => {
  const dispatch = useAppDispatch();
  const selectedMap = useAppSelector(selectMap, shallowEqual);

  const _handleItemClick = (id: string) => () => {
    if (id === selectedMap) {
      dispatch(mapUnselected());
    } else {
      dispatch(mapSelected(id));
    }
  };

  return (
    <div className="basis-96 shrink-0 grow border-r border-black">
      <div className="flex pt-5 pb-0.5 px-2 bg-[#EDF2F7]">
        {maps.map(map => (
          <div key={map.id} className="px-2">
            <button
              type="button"
              className={`min-w-28 py-1.5 block px-6 rounded-full text-sm font-medium ${map.id === selectedMap ? "bg-orange-500 text-white" : "bg-white text-black"}`}
              onClick={_handleItemClick(map.id)}>
              {DateTime.fromISO(map.createdAt).toFormat("LLL dd")}
            </button>
            <div
              className={`w-2 h-2 mt-0.5 mx-auto rounded-full ${map.id === selectedMap ? "bg-orange-500" : "bg-white"}`}
            />
          </div>
        ))}
      </div>
      <p className="py-1 px-4 bg-[#CDD5DD] text-xs text-center">{month}</p>
    </div>
  );
};

/* Export
============================================================================= */
export default FieldViewTimelineItem;
