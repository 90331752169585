import {gql} from "../../__generated__/gql";

export const GET_FARM_NAME = gql(/* GraphQL */ `
  query GetFarmName($where: farms_bool_exp) {
    farms(where: $where) {
      id
      name
    }
  }
`);
