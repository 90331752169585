import {
  concat,
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";

import {API_URL} from "./secrets";

const httpLink = new HttpLink({uri: API_URL});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({headers = {}}) => {
    const token = localStorage.getItem("@auth/token");
    const _headers: Record<string, string> = {...headers};

    if (token) {
      _headers.authorization = `Bearer ${token}`;
    }

    return {headers: _headers};
  });

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});
