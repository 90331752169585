import React, {
  useState,
  useCallback,
  type FormEvent,
  type ChangeEvent,
} from "react";
import {useMutation} from "@apollo/client";

import {Button, ErrorMessage, TextInput} from "../../common";
import CloseIcon from "../../assets/icons/edit-close.svg";

import {ADD_FARM} from "../api/mutations";
import {GET_FARMS} from "../api/queries";
import {errorMessage} from "../../util/functions";
import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {selectUser} from "../../auth/redux/authSlice";
import {actionClosed} from "../redux/farmSlice";

/* =============================================================================
<AddFarm />
============================================================================= */
const AddFarm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const user = useAppSelector(selectUser, (a, b) => a?.id === b?.id);

  const [addFarm, {loading, error}] = useMutation(ADD_FARM, {
    refetchQueries: [GET_FARMS],
  });

  const disabled = !name || !user?.id || loading;

  const _handleCloseClick = useCallback(() => {
    dispatch(actionClosed());
  }, [dispatch]);

  const _handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event?.target.value);
    },
    [],
  );

  const _handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event?.target.value);
    },
    [],
  );

  const _handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await addFarm({
        variables: {
          input: {
            name,
            description,
            userId: user?.id,
          },
        },
      });

      dispatch(actionClosed());
    } catch (e) {
      // TODO
    }
  };

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="w-[360px] px-9 pb-10 pt-2">
        <h1 className="text-xl font-medium text-center">Create new farm</h1>
        <form
          className="w-full grid grid-cols-1 gap-y-5 mt-9"
          onSubmit={_handleSubmit}>
          <TextInput
            label="Name"
            value={name}
            className="py-3.5"
            placeholder="eg: Potato Farm"
            onChange={_handleNameChange}
          />
          <TextInput
            label="Description"
            value={description}
            className="py-3.5"
            placeholder="Optional"
            onChange={_handleDescriptionChange}
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={disabled}
            className="w-full mt-4">
            Create
          </Button>
          {error && (
            <ErrorMessage
              className="mx-4 mt-4"
              message={errorMessage(error.message)}
            />
          )}
        </form>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default AddFarm;
