import React, {useCallback, useMemo, useState, type ChangeEvent} from "react";
import {shallowEqual} from "react-redux";
import {area as turfArea} from "@turf/turf";
import {useMutation, useQuery} from "@apollo/client";

import {
  Button,
  Select,
  TextArea,
  TextInput,
  ErrorMessage,
} from "../../../common";
import AddFieldHeader from "./AddFieldHeader";

import cropTypes from "../../../static/cropTypes";
import ownershipTypes from "../../../static/ownershipTypes";
import {ADD_FIELD} from "../../api/mutations";
import {GET_FARMS, GET_FIELDS} from "../../api/queries";
import {
  errorMessage,
  polygonToStr,
  polygonToWkt,
} from "../../../util/functions";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {polygonRemoved, selectPolygon} from "../../redux/addFieldSlice";
import {selectPolygon as selectUploadPolygon} from "../../../upload/redux/uploadSlice";
import {
  farmSelected,
  actionClosed,
  uploadSelected,
  selectSelectedFarm,
} from "../../redux/farmSlice";

/* =============================================================================
<AddField />
============================================================================= */
const AddField: React.FC = () => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>("");
  const [crop, setCrop] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [season, setSeason] = useState<string>("");
  const [ownership, setOwnership] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [seedingRate, setSeedingRate] = useState<string>("");

  const farm = useAppSelector(selectSelectedFarm, shallowEqual);
  const polygon = useAppSelector(selectPolygon, shallowEqual);
  const uploadPolygon = useAppSelector(selectUploadPolygon, shallowEqual);

  const {data: farmsData} = useQuery(GET_FARMS);
  const [addField, {loading, error}] = useMutation(ADD_FIELD, {
    refetchQueries: [GET_FIELDS],
  });

  const disabled =
    !name || !farm || !season || !polygon || !ownership || loading;

  const farmOptions = useMemo(() => {
    if (farmsData?.farms) {
      return farmsData.farms.map(farm => ({
        label: `${farm.name}`,
        value: `${farm.id}`,
      }));
    }

    return [];
  }, [farmsData]);

  const seasonOptions = useMemo(() => {
    const year = new Date().getFullYear();
    const years: number[] = [];

    for (let i = 0; i < 10; i++) {
      years.push(year - i);
    }

    return years.map(year => ({
      label: year.toString(),
      value: year.toString(),
    }));
  }, []);

  const _handleFarmChange = useCallback(
    (value: string) => {
      dispatch(farmSelected(value));
    },
    [dispatch],
  );

  const _handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event?.target.value);
    },
    [],
  );

  const _handleNotesChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNotes(event?.target.value);
    },
    [],
  );

  const _handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event?.target.value);
    },
    [],
  );

  const _handleSeedingRateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSeedingRate(event?.target.value);
    },
    [],
  );

  const _handleSubmit = async event => {
    event.preventDefault();

    if (!polygon) {
      return;
    }

    try {
      const areaInAcres = turfArea(polygon) / 4046.8564224;

      await addField({
        variables: {
          input: {
            name,
            notes,
            field: {
              data: {
                defaultFarmId: farm,
              },
            },
            farmId: farm,
            season,
            polygon: {
              data: {
                defaultFarmId: farm,
                geometry_wkt: polygonToWkt(polygon),
                geometry: polygonToStr(polygon),
                size: areaInAcres,
              },
            },
            cropName: crop,
            description,
            seeding_rate: seedingRate,
            ownershipType: ownership,
          },
        },
      });

      // Clear state
      dispatch(polygonRemoved());

      if (
        uploadPolygon &&
        polygonToWkt(uploadPolygon) === polygonToWkt(polygon)
      ) {
        // Return to upload
        dispatch(uploadSelected());
      } else {
        dispatch(actionClosed());
      }
    } catch (e) {
      // TODO
    }
  };

  if (polygon) {
    return (
      <div className="w-[20.625rem] absolute top-24 bottom-5 right-6 flex flex-col pb-4 rounded-2xl bg-white z-10 app-shadow">
        <AddFieldHeader />
        <form
          className="h-full flex flex-col mt-9 overflow-y-hidden"
          onSubmit={_handleSubmit}>
          <div className="h-full grid grid-cols-1 gap-y-6 px-4 mb-7 overflow-y-auto">
            <TextInput
              label="Field Name"
              value={name}
              className="py-3"
              placeholder="e.g. My rice field"
              onChange={_handleNameChange}
            />
            <TextInput
              label="Description"
              value={description}
              className="py-3"
              placeholder="Optional"
              onChange={_handleDescriptionChange}
            />
            <Select
              label="Ownership"
              value={ownership}
              options={ownershipTypes}
              className="py-3"
              placeholder="Select ownership"
              onChange={setOwnership}
            />
            <Select
              label="Crop"
              value={crop}
              options={cropTypes}
              className="py-3"
              placeholder="Select crop"
              onChange={setCrop}
            />
            <TextInput
              type="number"
              label="Seeding rate (per acre)"
              value={seedingRate}
              className="py-3"
              placeholder="Optional"
              onChange={_handleSeedingRateChange}
            />
            <Select
              label="Season"
              value={season}
              options={seasonOptions}
              className="py-3"
              placeholder="Select season"
              onChange={setSeason}
            />
            <Select
              label="Farm"
              value={farm ? `${farm}` : ""}
              options={farmOptions}
              className="py-3"
              placeholder="Select farm"
              onChange={_handleFarmChange}
            />
            <TextArea
              label="Notes"
              value={notes}
              className="py-3"
              placeholder="Type here"
              onChange={_handleNotesChange}
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={disabled}
            className="mx-4 mt-auto">
            Done
          </Button>
          {error && (
            <ErrorMessage
              className="mx-4 mt-4"
              message={errorMessage(error.message)}
            />
          )}
        </form>
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default AddField;
