import React from "react";
import {useNavigate} from "react-router-dom";
import {shallowEqual} from "react-redux";

import UploadIcon from "../../../assets/icons/edit-upload.svg";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {uploadSelected, selectAction} from "../../redux/farmSlice";
import {selectUploadId as selectMosaicUploadId} from "../../../upload/redux/uploadMosaicPartsSlice";
import {selectTotalBytes as selectTotalRawBytes} from "../../../upload/redux/uploadRawFilesSlice";
import {
  selectFarm as selectUploadFarm,
  selectField as selectUploadField,
} from "../../../upload/redux/uploadSlice";

/* =============================================================================
<UploadButton />
============================================================================= */
const UploadButton: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const action = useAppSelector(selectAction, shallowEqual);
  const uploadFarm = useAppSelector(selectUploadFarm, shallowEqual);
  const uploadField = useAppSelector(selectUploadField, shallowEqual);
  const totalRawBytes = useAppSelector(selectTotalRawBytes, shallowEqual);
  const mosaicUploadId = useAppSelector(selectMosaicUploadId, shallowEqual);

  const _handleClick = () => {
    // Upload dialog
    if (action === "upload") {
      return;
    }

    // Resume raw upload
    if (totalRawBytes > 0 && uploadFarm && uploadField) {
      navigate(`/upload/raw`);
      return;
    }

    // Resume mosaic upload
    if (mosaicUploadId) {
      navigate(`/upload/mosaic`);
      return;
    }

    // New upload
    dispatch(uploadSelected());
  };

  return (
    <button
      type="button"
      className="flex items-center px-4 py-3 rounded-lg bg-white app-shadow"
      onClick={_handleClick}>
      <UploadIcon />
      <span className="ml-2 font-medium text-sm">Upload Data</span>
    </button>
  );
};

/* Export
============================================================================= */
export default UploadButton;
