import React from "react";
import {shallowEqual} from "react-redux";

import FieldDetails from "./FieldDetails";
import SelectAction from "./SelectAction";
import AddFarm from "./AddFarm";
import AddField from "./AddField";
import UploadData from "./UploadData";
import Notifications from "./Notifications";
import GenerateReports from "./GenerateReports";
import GenerateMissionFiles from "./GenerateMissionFiles";

import {useAppSelector} from "../../util/hooks";

import {selectAction} from "../redux/farmSlice";

/* =============================================================================
<ActionView />
============================================================================= */
const ActionView: React.FC = () => {
  const action = useAppSelector(selectAction, shallowEqual);

  switch (action) {
    case "field-details":
      return <FieldDetails />;

    case "process-data":
      return <SelectAction />;

    case "upload":
      return <UploadData />;

    case "add-farm":
      return <AddFarm />;

    case "add-field":
      return <AddField />;

    case "notifications":
      return <Notifications />;

    case "generate-reports":
      return <GenerateReports />;

    case "generate-mission-files":
      return <GenerateMissionFiles />;

    default:
      return null;
  }
};

/* Export
============================================================================= */
export default ActionView;
