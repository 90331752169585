import React, {useCallback, useEffect, useMemo, useState} from "react";
import wkt from "wkt";
import Switch from "react-switch";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import CheckIcon from "../../../assets/icons/edit-check.svg";
import MapLineIcon from "../../../assets/icons/map-line.svg";
import MapPointIcon from "../../../assets/icons/map-point.svg";
import MapPolygonIcon from "../../../assets/icons/map-polygon.svg";

import {GET_FIELD_MAP_ANNOTATIONS} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {selectMap} from "../../redux/fieldTimelineSlice";
import {
  annotationsHidden,
  annotationsSelected,
  annotationsVisible,
  selectAnnotationsVisible,
  selectSelectedAnnotations,
} from "../../redux/fieldAnnotationsSlice";

/* =============================================================================
<FieldViewAnalysisAnnotations />
============================================================================= */
const FieldViewAnalysisAnnotations: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const mapId = useAppSelector(selectMap, shallowEqual);
  const showAnnotations = useAppSelector(
    selectAnnotationsVisible,
    shallowEqual,
  );
  const selectedAnnotations = useAppSelector(
    selectSelectedAnnotations,
    (a, b) => a.toString() === b.toString(),
  );

  const {data} = useQuery(GET_FIELD_MAP_ANNOTATIONS, {
    variables: {
      where: {
        highResMapId: {
          _eq: mapId,
        },
      },
    },
  });

  const annotationOptions = useMemo(() => {
    const options: Array<{label: string; value: string; geometry: string}> = [];

    if (data?.high_res_map_annotations.length) {
      data.high_res_map_annotations.forEach(annotation => {
        options.push({
          label: annotation.name ?? `Annotation ${annotation.id}`,
          value: `${annotation.id}`,
          geometry: annotation.geometry,
        });
      });
    }

    return options;
  }, [data]);

  // Select all annotations at start
  useEffect(() => {
    dispatch(
      annotationsSelected(annotationOptions.map(option => option.value)),
    );
  }, [annotationOptions, dispatch]);

  const _handleSwitchChange = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(annotationsVisible());
        dispatch(
          annotationsSelected(annotationOptions.map(option => option.value)),
        );
      } else {
        dispatch(annotationsHidden());
      }
    },
    [dispatch, annotationOptions],
  );

  const _handleSelectedAnnotationsChanged = useCallback(
    (value: string) => {
      const isSelected = selectedAnnotations.includes(value);

      if (isSelected) {
        dispatch(
          annotationsSelected(selectedAnnotations.filter(itm => itm !== value)),
        );
      } else {
        dispatch(annotationsSelected([...selectedAnnotations, value]));
      }
    },
    [dispatch, selectedAnnotations],
  );

  const _handleViewMoreClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const _handleViewLessClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="mt-4 px-4 py-5 rounded-lg shadow">
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-medium">Annotations</h2>
        <Switch
          checked={showAnnotations}
          width={30}
          height={18}
          handleDiameter={14}
          onColor="#000000"
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={_handleSwitchChange}
        />
      </div>
      {isOpen ? (
        <>
          <div className="mt-6">
            {annotationOptions.map(option => {
              const geometry = wkt.parse(option.geometry);

              let geometryIcon: React.ReactElement | null = null;

              if (geometry.type === "Point") {
                geometryIcon = <MapPointIcon width={12} height={12} />;
              }

              if (geometry.type === "LineString") {
                geometryIcon = <MapLineIcon width={12} height={12} />;
              }

              if (geometry.type === "Polygon") {
                geometryIcon = <MapPolygonIcon width={12} height={12} />;
              }

              return (
                <button
                  key={option.value}
                  type="button"
                  className="flex items-center mb-4"
                  onClick={() =>
                    _handleSelectedAnnotationsChanged(option.value)
                  }>
                  <span className="w-3.5 h-3.5 flex justify-center items-center rounded border border-black">
                    {selectedAnnotations.includes(option.value) && (
                      <CheckIcon width={10} height={7} />
                    )}
                  </span>
                  <span className="ml-2">{geometryIcon}</span>
                  <span className="ml-1 text-sm font-medium text-gray-900">
                    {option.label}
                  </span>
                </button>
              );
            })}
          </div>
          <button
            type="button"
            className="text-xs font-medium text-[#787694]"
            onClick={_handleViewLessClick}>
            view less
          </button>
        </>
      ) : (
        <button
          type="button"
          className="mt-2.5 text-xs font-medium text-[#787694]"
          onClick={_handleViewMoreClick}>
          view more
        </button>
      )}
    </div>
  );
};

/* Export
============================================================================= */
export default FieldViewAnalysisAnnotations;
