import "react-toastify/dist/ReactToastify.min.css";
import React from "react";
import {Routes, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {withSentryReactRouterV6Routing} from "@sentry/react";

import {RequireAuth, RequireNoAuth} from "../common";
import UploadRawObserver from "../upload/components/UploadRaw/UploadRawObserver";
import UploadMosaicObserver from "../upload/components/UploadMosaic/UploadMosaicObserver";
import NotificationsListener from "../home/components/Notifications/NotificationsListener";

import LoginScreen from "../auth/screens/LoginScreen";
import HomeScreen from "../home/screens/HomeScreen";
import FieldViewScreen from "../field/screens/FieldViewScreen";
import UploadRawScreen from "../upload/screens/UploadRawScreen";
import UploadMosaicScreen from "../upload/screens/UploadMosaicScreen";
import NoMatchScreen from "../common/NoMatch";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

/* =============================================================================
<AppNavigation />
============================================================================= */
const AppNavigation: React.FC = () => {
  return (
    <>
      <SentryRoutes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <HomeScreen />
            </RequireAuth>
          }
        />
        <Route
          path="login"
          element={
            <RequireNoAuth>
              <LoginScreen />
            </RequireNoAuth>
          }
        />
        <Route
          path="fields/:fieldId"
          element={
            <RequireAuth>
              <FieldViewScreen />
            </RequireAuth>
          }
        />
        <Route
          path="upload/raw"
          element={
            <RequireAuth>
              <UploadRawScreen />
            </RequireAuth>
          }
        />
        <Route
          path="upload/mosaic"
          element={
            <RequireAuth>
              <UploadMosaicScreen />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NoMatchScreen />} />
      </SentryRoutes>
      <UploadRawObserver />
      <UploadMosaicObserver />
      <NotificationsListener />
      <ToastContainer
        theme="light"
        position="top-center"
        autoClose={3000}
        closeOnClick={true}
        pauseOnHover={true}
        hideProgressBar={true}
        bodyClassName="text-black font-poppins"
      />
    </>
  );
};

/* Export
============================================================================= */
export default AppNavigation;
