import React from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import {
  BrowserRouter,
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from "react-router-dom";

import "./config/firebase";
import App from "./App";

Sentry.init({
  dsn: "https://d4ec1c3cb77647542be86b50f093e287@o4506994062786560.ingest.us.sentry.io/4506994065604608",
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("app") as HTMLDivElement;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
