import React from "react";

import FieldViewAnalysisScanType from "./FieldViewAnalysisScanType";
import FieldViewAnalysisAnnotations from "./FieldViewAnalysisAnnotations";
import FieldViewAnalysisImageType from "./FieldViewAnalysisImageType";
import FieldViewAnalysisType from "./FieldViewAnalysisType";

import {useAppSelector} from "../../../util/hooks";

import {selectMap} from "../../redux/fieldTimelineSlice";

/* =============================================================================
<FieldViewAnalysis />
============================================================================= */
const FieldViewAnalysis: React.FC = () => {
  const map = useAppSelector(selectMap, (a, b) => Boolean(a) === Boolean(b));

  if (map) {
    return (
      <div className="h-full min-w-[313px] pt-2 px-3.5 pb-6 overflow-y-auto app-shadow">
        <FieldViewAnalysisScanType />
        <FieldViewAnalysisAnnotations />
        <FieldViewAnalysisImageType />
        <FieldViewAnalysisType />
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldViewAnalysis;
