import React, {type PropsWithChildren} from "react";
import {shallowEqual} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

import {useAppSelector} from "../util/hooks";

import {selectIsAuthenticated} from "../auth/redux/authSlice";

/* =============================================================================
<RequireNoAuth />
============================================================================= */
const RequireNoAuth: React.FC<PropsWithChildren> = ({children}) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated, shallowEqual);

  const from = location.state?.from?.pathname || "/";

  if (isAuthenticated) {
    // Redirect them to the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={from} replace />;
  }

  return children;
};

/* Export
============================================================================= */
export default RequireNoAuth;
