const ownershipTypes = [
  {
    label: "Rented",
    value: "rented",
  },
  {
    label: "Owned",
    value: "owned",
  },
  {
    label: "Other",
    value: "other",
  },
];

export default ownershipTypes;
