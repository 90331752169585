import {gql} from "../../__generated__/gql";

export const ADD_FIELD_MAP_ANNOTATION = gql(/* GraphQL */ `
  mutation InsertFieldMapAnnotation(
    $input: high_res_map_annotations_insert_input!
  ) {
    insert_high_res_map_annotations_one(object: $input) {
      id
    }
  }
`);

export const UPDATE_FIELD_MAP_ANNOTATION = gql(/* GraphQL */ `
  mutation UpdateFieldMapAnnotation(
    $pk_columns_input: high_res_map_annotations_pk_columns_input!
    $set_input: high_res_map_annotations_set_input
  ) {
    update_high_res_map_annotations_by_pk(
      pk_columns: $pk_columns_input
      _set: $set_input
    ) {
      id
    }
  }
`);

export const DELETE_FIELD_MAP_ANNOTATION = gql(/* GraphQL */ `
  mutation DeleteFieldMapAnnotation($id: bigint!) {
    delete_high_res_map_annotations_by_pk(id: $id) {
      id
    }
  }
`);
