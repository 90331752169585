import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {shallowEqual} from "react-redux";

import {useAppSelector} from "../../../util/hooks";

import {selectStatus} from "../../../upload/redux/uploadSlice";
import {
  selectTotalBytes as selectTotalRawBytes,
  selectUploadedBytes as selectUploadedRawBytes,
} from "../../../upload/redux/uploadRawFilesSlice";
import {
  selectTotalBytes as selectTotalMosaicBytes,
  selectUploadedBytes as selectUploadedMosaicBytes,
} from "../../../upload/redux/uploadMosaicPartsSlice";

/* =============================================================================
<UploadProgressIndicator />
============================================================================= */
const UploadProgressIndicator: React.FC = () => {
  const navigate = useNavigate();

  const status = useAppSelector(selectStatus, shallowEqual);
  const totalRawBytes = useAppSelector(selectTotalRawBytes, shallowEqual);
  const totalMosaicBytes = useAppSelector(selectTotalMosaicBytes, shallowEqual);
  const totalRawBytesUploaded = useAppSelector(
    selectUploadedRawBytes,
    shallowEqual,
  );
  const totalMosaicBytesUploaded = useAppSelector(
    selectUploadedMosaicBytes,
    shallowEqual,
  );

  const showProgress = status.includes("upload");
  const hasRawUpload = totalRawBytes > 0;
  const hasMosaicUpload = totalMosaicBytes > 0;
  const totalBytes = totalRawBytes || totalMosaicBytes;
  const totalUploadedBytes = totalRawBytesUploaded || totalMosaicBytesUploaded;

  const _handleClick = useCallback(() => {
    if (hasRawUpload) {
      navigate("/upload/raw");
      return;
    }

    if (hasMosaicUpload) {
      navigate("/upload/mosaic");
      return;
    }
  }, [hasRawUpload, hasMosaicUpload, navigate]);

  if (showProgress) {
    const progress = ((totalUploadedBytes * 100) / totalBytes).toFixed();
    const hasPaused =
      status === "raw-upload-paused" || status === "mosaic-upload-paused";
    const hasFailed =
      status === "raw-upload-failed" ||
      status === "raw-upload-aborted" ||
      status === "mosaic-upload-failed" ||
      status === "mosaic-upload-aborted";

    return (
      <div className="relative">
        <button
          type="button"
          role="progressbar"
          style={{
            background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),conic-gradient(#FF7E1D ${progress}%, transparent 0)`,
          }}
          className="w-12 h-12 flex items-center justify-center rounded-full"
          onClick={_handleClick}>
          <span
            className={`${hasFailed ? "text-red-600" : hasPaused ? "text-yellow-600" : "text-black"} text-xs text-center font-semibold`}>
            {progress}%
          </span>
        </button>
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default UploadProgressIndicator;
