import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import {DateTime} from "luxon";

import {type RootState} from "../../redux/helpers";

export interface Notification {
  id: string;
  title: string;
  body: string;
  data: Record<string, string>;
  read: boolean;
  createdAt: number;
}

const notificationsAdapter = createEntityAdapter<Notification>({
  sortComparer: (a, b) => b.createdAt - a.createdAt,
});

// Initial state

const initialState = notificationsAdapter.getInitialState();

// Slice
const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    notificationsFetched(state, action: PayloadAction<Notification[]>) {
      notificationsAdapter.setAll(state, action.payload);
    },
    notificationAdded(state, action: PayloadAction<Notification>) {
      notificationsAdapter.addOne(state, action.payload);
    },
    notificationRead(state, action: PayloadAction<string>) {
      notificationsAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          read: true,
        },
      });
    },
    allNotificationRead(state) {
      notificationsAdapter.updateMany(
        state,
        state.ids.map(id => ({
          id,
          changes: {
            read: true,
          },
        })),
      );
    },
  },
});

// Actions
export const {
  notificationsFetched,
  notificationAdded,
  notificationRead,
  allNotificationRead,
} = notificationsSlice.actions;

// Reducer
export default notificationsSlice.reducer;

// Selectors

export const {
  selectAll: selectNotifications,
  selectIds: selectNotificationsIds,
  selectById: selectNotificationById,
  selectTotal: selectTotalNotifications,
} = notificationsAdapter.getSelectors<RootState>(
  state => state.notifications ?? initialState,
);

export const selectReadNotifications = (state: RootState) =>
  selectNotifications(state).filter(notification => notification.read);

export const selectUnreadNotifications = (state: RootState) =>
  selectNotifications(state).filter(notification => !notification.read);

export const selectNotificationsWithDates = (state: RootState) => {
  const notificationsWithDates: Array<{type: string; data: string}> = [];

  selectNotifications(state).forEach(notification => {
    const date = DateTime.fromMillis(notification.createdAt).toLocaleString(
      DateTime.DATE_MED,
    );

    if (notificationsWithDates.find(item => item.data === date)) {
      notificationsWithDates.push({
        type: "item",
        data: notification.id,
      });
    } else {
      notificationsWithDates.push({
        type: "header",
        data: date,
      });
      notificationsWithDates.push({
        type: "item",
        data: notification.id,
      });
    }
  });

  return notificationsWithDates;
};
