import React from "react";

import SelectFarm from "./SelectFarm";
import UploadButton from "./UploadButton";
import AddFarmButton from "./AddFarmButton";
import AddFieldSelect from "./AddFieldSelect";
// import PolygonsSwitch from "./PolygonsSwitch";
import NotificationsAndLogout from "./NotificationsAndLogout";
import UploadProgressIndicator from "./UploadProgressIndicator";
import AppLogo from "../../../assets/images/app-logo.svg";

/* =============================================================================
<Header />
============================================================================= */
const Header: React.FC = () => {
  return (
    <div className="flex absolute top-0 left-0 right-0 bg-transparent justify-between p-6 pl-7 z-10">
      <AppLogo width={159} height={29} fill="#FFFFFF" />
      <div className="grid grid-flow-col gap-x-4">
        <UploadProgressIndicator />
        <SelectFarm />
        <AddFarmButton />
        <AddFieldSelect />
        <UploadButton />
        {/* <PolygonsSwitch /> */}
        <NotificationsAndLogout />
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default Header;
