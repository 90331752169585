import type React from "react";
import {useCallback, useEffect, useRef} from "react";
import {shallowEqual} from "react-redux";
import {isCancel} from "axios";

import {request, uploader} from "../../../util/upload";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {selectBlob} from "../../redux/uploadMosaicBlobSlice";
import {
  mosaicUploadFailed,
  mosaicUploadAborted,
  completeMosaicUpload,
  selectFarm as selectUploadFarm,
  selectField as selectUploadField,
  selectFile as selectUploadFile,
  selectStatus as selectUploadStatus,
} from "../../redux/uploadSlice";
import {
  partUploaded,
  partUploadStarted,
  selectTotalBytes,
  selectPartToUpload,
  selectUploadedBytes,
  selectUploadingPart,
} from "../../redux/uploadMosaicPartsSlice";

/* =============================================================================
<UploadMosaicObserver />
============================================================================= */
const UploadMosaicObserver: React.FC = () => {
  const dispatch = useAppDispatch();
  const controller = useRef<AbortController | null>(null);

  const uploadFarm = useAppSelector(selectUploadFarm, shallowEqual);
  const uploadField = useAppSelector(selectUploadField, shallowEqual);
  const uploadFile = useAppSelector(selectUploadFile, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);
  const uploadedBytes = useAppSelector(selectUploadedBytes, shallowEqual);
  const uploadStatus = useAppSelector(selectUploadStatus, shallowEqual);
  const partToUpload = useAppSelector(selectPartToUpload, shallowEqual);
  const uploadingPart = useAppSelector(selectUploadingPart, shallowEqual);
  const uploadingBlob = useAppSelector(selectBlob, shallowEqual);

  // Prevent window close by user when uploading
  useEffect(() => {
    if (uploadingBlob) {
      window.addEventListener("beforeunload", _handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", _handleBeforeUnload);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingBlob]);

  // Resume upload on mount - web app closed during previous upload
  useEffect(() => {
    if (uploadingPart && !uploadingBlob) {
      dispatch(mosaicUploadAborted());
    }
  }, [uploadingPart, uploadingBlob, dispatch]);

  // Select next uploading part
  useEffect(() => {
    if (partToUpload && uploadStatus === "mosaic-uploading") {
      dispatch(partUploadStarted(partToUpload.id));
    }
  }, [partToUpload, uploadStatus, dispatch]);

  // Upload part to presigned URL
  useEffect(() => {
    if (uploadStatus === "mosaic-uploading" && uploadingPart && uploadingBlob) {
      (async () => {
        try {
          // Get presigned URL
          const {url: presignedURL} = await request({
            url: "/mosaic",
            method: "PUT",
            data: {
              farm: uploadFarm,
              field: uploadField,
              file: uploadFile,
              part: uploadingPart.id,
              uploadId: uploadingPart.uploadId,
            },
          });

          // Abort controller ref
          controller.current = new AbortController();

          // Upload blob
          const payload = await uploader({
            url: presignedURL,
            data: uploadingBlob.slice(
              uploadingPart.startAt,
              uploadingPart.endAt,
            ),
            signal: controller.current.signal,
          });

          // Remove abort controller ref
          controller.current = null;

          // Mark part as uploaded
          dispatch(
            partUploaded({
              id: uploadingPart.id,
              etag: payload.headers.etag,
            }),
          );
        } catch (e) {
          // Remove abort controller ref
          controller.current = null;

          // Upload failed when not cancelled
          if (!isCancel(e)) {
            dispatch(mosaicUploadFailed());
          }
        }
      })();
    }
  }, [
    uploadFarm,
    uploadField,
    uploadFile,
    uploadStatus,
    uploadingPart,
    uploadingBlob,
    dispatch,
  ]);

  // Cancel ongoing request on status change
  useEffect(() => {
    if (controller.current && uploadStatus !== "mosaic-uploading") {
      controller.current.abort();
    }
  }, [uploadStatus, controller]);

  // Complete upload
  useEffect(() => {
    if (totalBytes > 0 && uploadedBytes === totalBytes) {
      dispatch(completeMosaicUpload());
    }
  }, [totalBytes, uploadedBytes, dispatch]);

  const _handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to abort the upload?");
  }, []);

  return null;
};

/* Export
============================================================================= */
export default UploadMosaicObserver;
