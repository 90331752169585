import React from "react";

import MousePointerIcon from "../../../assets/icons/edit-mouse-pointer.svg";

import {useAppSelector} from "../../../util/hooks";

import {selectGeometry} from "../../redux/fieldMapEditorSlice";
import {selectSelectedAnnotation} from "../../redux/fieldAnnotationsSlice";

/* =============================================================================
<FieldViewAnnotationsEditorPlaceholder />
============================================================================= */
const FieldViewAnnotationsEditorPlaceholder: React.FC = () => {
  const geometry = useAppSelector(
    selectGeometry,
    (a, b) => Boolean(a) === Boolean(b),
  );
  const selectedAnnotation = useAppSelector(
    selectSelectedAnnotation,
    (a, b) => Boolean(a) === Boolean(b),
  );

  if (!geometry && !selectedAnnotation) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center px-2.5">
        <MousePointerIcon width={40} height={40} />
        <p className="text-center mt-5">
          Select an object to view its properties or modify it
        </p>
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldViewAnnotationsEditorPlaceholder;
