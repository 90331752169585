import React, {useEffect} from "react";

import FieldViewHeader from "../components/FieldViewHeader";
import FieldViewTimeline from "../components/FieldViewTimeline";
import FieldViewMap from "../components/FieldViewMap";
import FieldViewAnalysis from "../components/FieldViewAnalysis";
import FieldViewAnnotationsEditor from "../components/FieldViewAnnotationsEditor";

import {useAppDispatch} from "../../util/hooks";

import {layerUnselected, mapUnselected} from "../redux/fieldTimelineSlice";

/* =============================================================================
<FieldViewScreen />
============================================================================= */
const FieldViewScreen: React.FC = () => {
  const dispatch = useAppDispatch();

  // Clear timeline state
  useEffect(
    () => () => {
      dispatch(mapUnselected());
      dispatch(layerUnselected());
    },
    [dispatch],
  );

  return (
    <div id="Field-View" className="w-full h-full flex flex-col">
      <FieldViewHeader />
      <FieldViewTimeline />
      <div className="flex flex-1 overflow-y-hidden">
        <FieldViewAnalysis />
        <FieldViewMap />
        <FieldViewAnnotationsEditor />
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default FieldViewScreen;
