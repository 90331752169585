import React, {useEffect, useState} from "react";

import NotificationsHeader from "./NotificationsHeader";
import NotificationsTab from "./NotificationsTab";
import NotificationsList from "./NotificationsList";
import NotificationsActivityList from "./NotificationsActivityList";

import {useAppDispatch} from "../../../util/hooks";
import {markAllNotificationsAsRead} from "../../../config/notifications";

import {allNotificationRead} from "../../redux/notificationsSlice";

/* =============================================================================
<Notifications />
============================================================================= */
const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<"notifications" | "activity">("notifications");

  // Read all on unmount
  useEffect(
    () => () => {
      markAllNotificationsAsRead()
        .then(() => {
          dispatch(allNotificationRead());
        })
        .catch(() => {});
    },
    [dispatch],
  );

  return (
    <div className="w-[21.25rem] absolute top-24 bottom-5 right-6 flex flex-col pb-4 rounded-2xl bg-white z-10 app-shadow">
      <NotificationsHeader />
      <NotificationsTab value={tab} onChange={setTab} />
      {tab === "notifications" && <NotificationsList />}
      {tab === "activity" && <NotificationsActivityList />}
    </div>
  );

  return null;
};

/* Export
============================================================================= */
export default Notifications;
