import React, {forwardRef} from "react";
import {cls} from "../util/functions";

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  _size?: "sm" | "md" | "lg";
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

/* =============================================================================
<TextArea />
============================================================================= */
const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {_size = "md", label, className, labelProps, containerProps, ...rest},
    ref,
  ) => {
    return (
      <div
        {...containerProps}
        className={cls(`
        ${classes.container.base}
        ${containerProps?.className}
      `)}>
        {label && (
          <label
            {...labelProps}
            className={cls(`
            ${classes.label.base}
            ${classes.label.size[_size]}
            ${labelProps?.className}
          `)}>
            {label}
          </label>
        )}
        <textarea
          ref={ref}
          className={cls(`
            ${classes.textarea.base}
            ${classes.textarea.size[_size]}
            ${className}
          `)}
          {...rest}
        />
      </div>
    );
  },
);

const classes = {
  container: {
    base: "w-full",
  },
  label: {
    base: "block font-medium text-black",
    size: {
      sm: "text-xs mb-2.5",
      md: "text-sm mb-3",
      lg: "text-lg mb-4",
    },
  },
  textarea: {
    base: "block w-full rounded-md border-0 ring-1 ring-inset ring-[#CDD5DD] text-black shadow-sm placeholder:text-[#ACB6C0] focus:ring-2 focus:ring-inset",
    size: {
      sm: "px-3 py-2 text-xs",
      md: "px-4 py-2.5 text-sm",
      lg: "px-5 py-3 text-base",
    },
  },
};

TextArea.displayName = "TextArea";

/* Export
============================================================================= */
export default TextArea;
