import {useEffect, useState} from "react";
import {shallowEqual} from "react-redux";
import {
  getToken,
  onMessage,
  deleteToken,
  type MessagePayload,
} from "firebase/messaging";

import request from "../../../util/request";
import {messaging} from "../../../config/firebase";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";
import {FIREBASE_VAPID_KEY, NOTIFICATION_URL} from "../../../config/secrets";
import {
  addNotification,
  getNotifications,
  removeNotifications,
} from "../../../config/notifications";

import {selectIsAuthenticated} from "../../../auth/redux/authSlice";
import {
  notificationAdded,
  notificationsFetched,
} from "../../redux/notificationsSlice";

/* =============================================================================
<NotificationsListener />
============================================================================= */
const NotificationsListener = () => {
  const dispatch = useAppDispatch();
  const [notificationsAllowed, setNotificationsAllowed] = useState(false);

  const isAuthenticated = useAppSelector(selectIsAuthenticated, shallowEqual);

  // Request user permission on login and unregister token on logout
  useEffect(() => {
    if (isAuthenticated) {
      _requestPermission();
    } else {
      _unregisterToken();
    }
  }, [isAuthenticated]);

  // Register FCM token
  useEffect(() => {
    if (notificationsAllowed) {
      _registerToken();
    }
  }, [notificationsAllowed]);

  // Fetch notifications on login and remove notifications on logout
  useEffect(() => {
    if (isAuthenticated) {
      _fetchNotifications();
    } else {
      removeNotifications().catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch notifications on tab focus
  useEffect(() => {
    if (isAuthenticated) {
      const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
          _fetchNotifications();
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange,
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for foreground notifications
  useEffect(() => {
    if (notificationsAllowed) {
      const unsubscribe = onMessage(messaging, _handleForegroundNotification);
      return unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsAllowed]);

  // Request notifications permission
  const _requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();

      setNotificationsAllowed(permission === "granted");
    } catch (e) {
      setNotificationsAllowed(false);
    }
  };

  // Fetch notifications from storage
  const _fetchNotifications = async () => {
    try {
      const notifications = await getNotifications();

      dispatch(notificationsFetched(notifications));
    } catch (e) {
      // TODO
    }
  };

  // Register token to the api
  const _registerToken = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
      });

      const oldToken = localStorage.getItem(FCM_TOKEN_KEY);

      if (token !== oldToken) {
        await request(
          {
            url: "/register",
            method: "POST",
            data: {
              token,
            },
          },
          NOTIFICATION_URL,
        );

        localStorage.setItem(FCM_TOKEN_KEY, token);
      }
    } catch (e) {
      // Couldn't register token
    }
  };

  // Delete token and reset permission
  const _unregisterToken = async () => {
    try {
      await deleteToken(messaging);

      localStorage.removeItem(FCM_TOKEN_KEY);
    } catch (e) {
      // Couldn't unregister token
    }
    setNotificationsAllowed(false);
  };

  // Handle foreground notification
  const _handleForegroundNotification = async (message: MessagePayload) => {
    try {
      const notification = {
        id: message.messageId,
        title: message.notification?.title || "",
        body: message.notification?.body || "",
        data: message.data || {},
        read: false,
        createdAt: Date.now(),
      };

      await addNotification(notification); // Update storage

      dispatch(notificationAdded(notification)); // update redux
    } catch (e) {
      // TODO
    }
  };

  return null;
};

const FCM_TOKEN_KEY = "@fcm/token";

/* Export
============================================================================= */
export default NotificationsListener;
