import {configureStore} from "@reduxjs/toolkit";
import storage from "redux-persist-indexeddb-storage";
import {createReduxEnhancer} from "@sentry/react";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import rootReducer from "./reducer";
import {loggedOut} from "../auth/redux/authSlice";
import {blobAdded} from "../upload/redux/uploadMosaicBlobSlice";
import {blobsAdded, blobsUpdated} from "../upload/redux/uploadRawBlobsSlice";

const sentryReduxEnhancer = createReduxEnhancer();

// Reset reducer on logout
const appReducer: typeof rootReducer = (state, action) =>
  rootReducer(action.type === loggedOut.type ? undefined : state, action);

const persistConfig = {
  key: "root",
  storage: storage("@lensDB"),
  blacklist: [
    "addField",
    "notifications",
    "fieldTimeline",
    "fieldMapEditor",
    "uploadRawBlobs",
    "fieldAnnotations",
    "uploadMosaicBlob",
  ],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // Ignore redux-persist
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          // Ignore blobs
          blobAdded.type,
          blobsAdded.type,
          blobsUpdated.type,
        ],
        ignoredPaths: ["uploadRawBlobs.blobs", "uploadMosaicBlob.blob"],
      },
    }),
});

export const persistor = persistStore(store);
