import "ol/ol.css";
import React, {useEffect, useMemo, useRef} from "react";

import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import BingMaps from "ol/source/BingMaps";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import type {Geometry} from "ol/geom";
import type {Feature, MapBrowserEvent} from "ol";

import {BING_MAPS_API_KEY} from "../../../config/secrets";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {
  fileSelected,
  fileUnselected,
  selectUploadMapPoints,
} from "../../redux/uploadRawFilesSlice";

/* =============================================================================
<UploadRawMap />
============================================================================= */
const UploadRawMap: React.FC = () => {
  const map = useRef<Map>();
  const dispatch = useAppDispatch();

  const uploadFiles = useAppSelector(selectUploadMapPoints, (a, b) => {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      const prevFile = a[i];
      const nextFile = b[i];

      if (prevFile.id !== nextFile.id) {
        return false;
      }
      if (prevFile.selected !== nextFile.selected) {
        return false;
      }
      if (prevFile.uploaded !== nextFile.uploaded) {
        return false;
      }
      if (prevFile.uploading !== nextFile.uploading) {
        return false;
      }
      if (prevFile.geometry?.lat !== nextFile.geometry?.lat) {
        return false;
      }
      if (prevFile.geometry?.lng !== nextFile.geometry?.lng) {
        return false;
      }
      if (prevFile.geometry?.alt !== nextFile.geometry?.alt) {
        return false;
      }
      if (prevFile.createdAt !== nextFile.createdAt) {
        return false;
      }
    }

    return true;
  });

  // Read points as features
  const uploadPoints = useMemo(() => {
    const features: Feature<Geometry>[] = [];

    if (uploadFiles?.length) {
      uploadFiles.forEach(uploadFile => {
        if (uploadFile.geometry) {
          features.push(
            new GeoJSON().readFeature({
              type: "Feature",
              properties: {
                id: uploadFile.id,
                status: uploadFile.selected
                  ? "selected"
                  : uploadFile.uploaded
                    ? "uploaded"
                    : uploadFile.uploading
                      ? "uploading"
                      : "pending",
                createdAt: uploadFile.createdAt,
              },
              geometry: {
                type: "Point",
                coordinates: [
                  uploadFile.geometry.lng,
                  uploadFile.geometry.lat,
                  uploadFile.geometry.alt,
                ],
              },
            }),
          );
        }
      });
    }

    return features;
  }, [uploadFiles]);

  // Initialize map
  useEffect(() => {
    // Add ol map
    map.current = new Map({
      target: "UploadRawMap",
      layers: [
        // Base layer
        new TileLayer({
          source: new BingMaps({
            key: BING_MAPS_API_KEY,
            imagerySet: "AerialWithLabelsOnDemand",
          }),
          preload: Infinity,
        }),
        // Upload points layer
        new WebGLPointsLayer({
          style: uploadPointsStyle,
          source: new VectorSource(),
        }),
      ],
      view: new View({
        center: [-98.5795, 39.8282],
        zoom: 16,
        projection: "EPSG:4326",
      }),
      controls: [],
    });

    /**
     *  Add click event listener
     */

    const _handleMapClick = (event: MapBrowserEvent<never>) => {
      const feature = map.current?.forEachFeatureAtPixel(
        event.pixel,
        ftr => ftr,
      );

      if (feature) {
        const {id, status} = feature.getProperties();

        if (status === "selected") {
          // Unselect point
          dispatch(fileUnselected(`${id}`));
        } else {
          // Select point
          dispatch(fileSelected(`${id}`));
        }
      }
    };

    map.current.on("click", _handleMapClick);

    return () => {
      map.current?.removeEventListener("click", _handleMapClick);
      map.current?.dispose();
    };
  }, [dispatch]);

  // Set upload points layer
  useEffect(() => {
    // Get vector source
    const source = map.current
      ?.getAllLayers()[1]
      ?.getSource() as VectorSource | null;

    // Reset vector source
    source?.clear();

    if (uploadPoints.length) {
      // Update vector source
      source?.addFeatures(uploadPoints);

      // Get source extent
      const extent = source?.getExtent();

      if (extent) {
        // Fit upload points on map
        map.current?.getView().fit(extent, {
          duration: 200,
          padding: [100, 100, 100, 100],
        });
      }
    }
  }, [uploadPoints]);

  return <div id="UploadRawMap" className="w-full h-full z-0" />;
};

/**
 * Styles
 */

const uploadPointsStyle = {
  "circle-radius": 6,
  "circle-opacity": 1,
  // "circle-fill-color": "#000",
  "circle-fill-color": [
    "case",
    ["==", ["get", "status"], "selected"],
    "#000000",
    ["==", ["get", "status"], "uploaded"],
    "#FF7300",
    ["==", ["get", "status"], "uploading"],
    "#FFC107",
    "#3DA2FF",
  ],
  "circle-stroke-color": "#FFFFFF",
  "circle-stroke-width": 1.5,
  "circle-displacement": [0, 0],
};

/* Export
============================================================================= */
export default UploadRawMap;
