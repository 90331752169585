import React from "react";
import PuffLoader from "react-spinners/ClipLoader";

import {cls} from "../util/functions";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/* =============================================================================
<SuspenseFallback />
============================================================================= */
const SuspenseFallback: React.FC<Props> = ({className}) => {
  return (
    <div
      className={cls(`
      w-full h-full flex items-center justify-center
      ${className}
    `)}>
      <PuffLoader
        color="#FC5500"
        loading={true}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

/* Export
============================================================================= */
export default SuspenseFallback;
