import React from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {ErrorBoundary} from "@sentry/react";
import {ApolloProvider} from "@apollo/client";

import {ErrorBoundaryFallback} from "./common";
import AppNavigation from "./navigation/AppNavigation";

import {client} from "./config/apollo";
import {store, persistor} from "./redux/store";

/* =============================================================================
<App />
============================================================================= */
const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ErrorBoundary showDialog={true} fallback={<ErrorBoundaryFallback />}>
            <AppNavigation />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

/* Export
============================================================================= */
export default App;
