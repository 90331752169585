import React, {forwardRef} from "react";
import ClipLoader from "react-spinners/ClipLoader";

import {cls} from "../util/functions";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  pill?: boolean;
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "secondary" | "danger";
  loading?: boolean;
  loadingText?: string;
  loadingIcon?: React.ReactElement;
}

/* =============================================================================
<Button />
============================================================================= */
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      pill,
      size = "md",
      variant = "primary",
      loading,
      children,
      className,
      loadingText = "Processing...",
      loadingIcon = (
        <ClipLoader
          color="#FFFFFF"
          loading={true}
          size={20}
          className="mr-2"
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ),
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={cls(`
          ${classes.base}
          ${classes.size[size]}
          ${classes.variant[variant]}
          ${pill && classes.pill}
          ${rest.disabled && classes.disabled}
          ${className}
        `)}
        {...rest}>
        {loading ? (
          <>
            {loadingIcon}
            {loadingText}
          </>
        ) : (
          children
        )}
      </button>
    );
  },
);

const classes = {
  base: "flex justify-center items-center text-center font-semibold focus:outline-none",
  disabled: "bg-[#BABCBF] cursor-not-allowed",
  pill: "rounded-full",
  size: {
    sm: "px-4 py-2 text-sm rounded-md",
    md: "px-6 py-3.5 text-sm rounded-md",
    lg: "px-8 py-4 text-xl rounded-[10px]",
  },
  variant: {
    primary:
      "bg-black hover:bg-gray-900 focus:ring-2 focus:ring-black focus:ring-opacity-50 text-white",
    secondary:
      "bg-[#DBE4EC] hover:bg-[#d0dce6] focus:ring-2 focus:ring-[#d0dce6] focus:ring-opacity-50 text-gray-900",
    danger:
      "bg-red-600 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white",
  },
};

Button.displayName = "Button";

Button.defaultProps = {
  type: "button",
};

/* Export
============================================================================= */
export default Button;
