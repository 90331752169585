import {initializeApp} from "firebase/app";
import {getMessaging} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC5tp6hLBW8tO3dvnbiV7CpOWUUO6QV-q0",
  authDomain: "lensai-2ddd0.firebaseapp.com",
  projectId: "lensai-2ddd0",
  storageBucket: "lensai-2ddd0.appspot.com",
  messagingSenderId: "321361603729",
  appId: "1:321361603729:web:f840ab360ea16626f6e330",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
