import React from "react";
import wkt from "wkt";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import * as tokml from "@maphubs/tokml";
import {useParams} from "react-router-dom";
import {shallowEqual} from "react-redux";
import {ArrowDownOnSquareIcon} from "@heroicons/react/24/outline";
import {useLazyQuery, useQuery} from "@apollo/client";

import MapLineIcon from "../../../assets/icons/map-line.svg";
import MapPointIcon from "../../../assets/icons/map-point.svg";
import MapPolygonIcon from "../../../assets/icons/map-polygon.svg";
import MousePointerIcon from "../../../assets/icons/edit-mouse-pointer.svg";

import cropTypes from "../../../static/cropTypes";
import {GET_FIELD} from "../../../home/api/queries";
import {GET_FIELD_MAP_ANNOTATIONS} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {selectMap} from "../../redux/fieldTimelineSlice";
import {annotationUnselected} from "../../redux/fieldAnnotationsSlice";
import {
  toolSelected,
  toolUnselected,
  selectGeometry,
} from "../../redux/fieldMapEditorSlice";

/* =============================================================================
<FieldViewAnnotationsEditorTools />
============================================================================= */
const FieldViewAnnotationsEditorTools: React.FC = () => {
  const dispatch = useAppDispatch();
  const {fieldId} = useParams();

  const mapId = useAppSelector(selectMap, shallowEqual);
  const geometry = useAppSelector(
    selectGeometry,
    (a, b) => Boolean(a) === Boolean(b),
  );

  const {data: fieldData} = useQuery(GET_FIELD, {
    variables: {
      id: fieldId,
    },
  });
  const [getFieldAnnotations] = useLazyQuery(GET_FIELD_MAP_ANNOTATIONS);

  const disabled = Boolean(geometry);

  const field = fieldData?.field_season_shot_by_pk;
  const cropType = cropTypes.find(ct => ct.value === field?.cropName);
  const cropName = cropType ? cropType.label : field?.cropName;

  const _handleMousePointerClick = () => {
    dispatch(toolUnselected());
    dispatch(annotationUnselected());
  };

  const _handlePolygonClick = () => {
    dispatch(toolSelected("Polygon"));
    dispatch(annotationUnselected());
  };

  const _handleLineClick = () => {
    dispatch(toolSelected("LineString"));
    dispatch(annotationUnselected());
  };

  const _handlePointClick = () => {
    dispatch(toolSelected("Point"));
    dispatch(annotationUnselected());
  };

  const _handleExportClick = async () => {
    try {
      if (mapId) {
        const payload = await getFieldAnnotations({
          variables: {
            where: {
              highResMapId: {
                _eq: mapId,
              },
            },
          },
        });

        if (payload.data?.high_res_map_annotations.length) {
          const geojsonData = {
            type: "FeatureCollection",
            features: payload.data.high_res_map_annotations.map(
              fieldAnnotation => ({
                type: "Feature",
                geometry: wkt.parse(fieldAnnotation.geometry),
                properties: {
                  name: `${fieldAnnotation.name}`,
                  area: fieldAnnotation.area,
                  description: `${fieldAnnotation.description}`,
                },
              }),
            ),
          };

          const documentName = `${field?.name} - ${cropName} annotations`;

          const kml = tokml(geojsonData, {
            name: "name",
            area: "area",
            description: "description",
            documentName,
            documentDescription: `Annotations for ${field?.name} - ${cropName} exported from https://lense.farmevo.ai`,
          });

          const zip = new JSZip();

          zip.file(`${documentName}.kml`, kml);

          const kmz = await zip.generateAsync({type: "blob"});

          saveAs(kmz, `${documentName}.kmz`);
        }
      }
    } catch (e) {
      // TODO
    }
  };

  return (
    <div className="absolute top-4 left-0 flex flex-col pb-2.5 rounded-3xl bg-white z-50">
      <button
        type="button"
        className="pt-2.5 px-1.5"
        onClick={_handleMousePointerClick}>
        <MousePointerIcon width={18} height={18} />
      </button>
      <button
        type="button"
        disabled={disabled}
        className={`pt-2.5 px-1.5 ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={_handlePolygonClick}>
        <MapPolygonIcon width={18} height={18} />
      </button>
      <button
        type="button"
        disabled={disabled}
        className={`pt-2.5 px-1.5 ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={_handleLineClick}>
        <MapLineIcon width={18} height={18} />
      </button>
      <button
        type="button"
        disabled={disabled}
        className={`pt-2.5 px-1.5 ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={_handlePointClick}>
        <MapPointIcon width={18} height={18} />
      </button>
      <button
        type="button"
        disabled={disabled}
        className={`pt-2.5 px-1.5 ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={_handleExportClick}>
        <ArrowDownOnSquareIcon
          className="h-5 w-5 text-black"
          aria-hidden="true"
        />
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default FieldViewAnnotationsEditorTools;
