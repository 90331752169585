import React from "react";

import UploadMosaicHeader from "../components/UploadMosaic/UploadMosaicHeader";
import UploadMosaicProgress from "../components/UploadMosaic/UploadMosaicProgress";
import UploadMosaicMap from "../components/UploadMosaic/UploadMosaicMap";
import UploadMosaicDialogs from "../components/UploadMosaic/UploadMosaicDialogs";

/* =============================================================================
<UploadMosaicScreen />
============================================================================= */
const UploadMosaicScreen: React.FC = () => {
  return (
    <div id="Upload-Mosaic" className="w-full h-full flex flex-col">
      <UploadMosaicHeader />
      <UploadMosaicProgress />
      <UploadMosaicMap />
      <UploadMosaicDialogs />
    </div>
  );
};

/* Export
============================================================================= */
export default UploadMosaicScreen;
