import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import type {Polygon} from "geojson";

import {type RootState} from "../../redux/helpers";

// Initial state

interface AddFieldState {
  tool: "Polygon" | "Circle" | null;
  polygon: Polygon | null;
}

const initialState: AddFieldState = {
  tool: null,
  polygon: null,
};

// Slice
const addFieldSlice = createSlice({
  name: "addField",
  initialState,
  reducers: {
    toolSelected(state, action: PayloadAction<AddFieldState["tool"]>) {
      state.tool = action.payload;
    },
    toolUnselected(state) {
      state.tool = null;
    },
    polygonAdded(state, action: PayloadAction<Polygon>) {
      state.polygon = action.payload;
    },
    polygonRemoved(state) {
      state.polygon = null;
    },
  },
});

// Actions
export const {toolSelected, toolUnselected, polygonAdded, polygonRemoved} =
  addFieldSlice.actions;

// Reducer
export default addFieldSlice.reducer;

// Selectors

export const selectTool = (state: RootState) => state.addField?.tool ?? null;

export const selectPolygon = (state: RootState) =>
  state.addField?.polygon ?? null;
