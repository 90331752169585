const cropTypes = [
  {
    label: "Almond",
    value: "almond",
  },
  {
    label: "Apple",
    value: "apple",
  },
  {
    label: "Banana",
    value: "banana",
  },
  {
    label: "Barley",
    value: "barley",
  },
  {
    label: "Beans",
    value: "beans",
  },
  {
    label: "Beetroot",
    value: "beetroot",
  },
  {
    label: "Bell Peppers",
    value: "bell_peppers",
  },
  {
    label: "Berries",
    value: "berries",
  },
  {
    label: "Buckwheat",
    value: "buckwheat",
  },
  {
    label: "Cabbage",
    value: "cabbage",
  },
  {
    label: "Canola",
    value: "canola",
  },
  {
    label: "Carrot",
    value: "carrot",
  },
  {
    label: "Cassava",
    value: "cassava",
  },
  {
    label: "Cashew",
    value: "cashew",
  },
  {
    label: "Cherri",
    value: "cherry",
  },
  {
    label: "Chickpeas",
    value: "chickpeas",
  },
  {
    label: "Chilli",
    value: "chilli",
  },
  {
    label: "Citrus",
    value: "citrus",
  },
  {
    label: "Coffee",
    value: "coffee",
  },
  {
    label: "Cotton",
    value: "cotton",
  },
  {
    label: "Cowpeas",
    value: "cowpeas",
  },
  {
    label: "Cucumber",
    value: "cucumber",
  },
  {
    label: "Date Tree",
    value: "date_tree",
  },
  {
    label: "Eggplants",
    value: "eggplants",
  },
  {
    label: "Flax",
    value: "flax",
  },
  {
    label: "Garlic",
    value: "garlic",
  },
  {
    label: "Ginger",
    value: "ginger",
  },
  {
    label: "Grapes",
    value: "grapes",
  },
  {
    label: "Hazelnut",
    value: "hazelnut",
  },
  {
    label: "Kiwi",
    value: "kiwi",
  },
  {
    label: "Lentils",
    value: "lentils",
  },
  {
    label: "Maize",
    value: "maize",
  },
  {
    label: "Mango",
    value: "mango",
  },
  {
    label: "Millet",
    value: "millet",
  },
  {
    label: "Onion",
    value: "onion",
  },
  {
    label: "Oat",
    value: "oat",
  },
  {
    label: "Okra",
    value: "okra",
  },
  {
    label: "Peach",
    value: "peach",
  },
  {
    label: "Peanut",
    value: "peanuts",
  },
  {
    label: "Pear",
    value: "pear",
  },
  {
    label: "Peas",
    value: "peas",
  },
  {
    label: "Pistachio",
    value: "pistachio",
  },
  {
    label: "Potato",
    value: "potato",
  },
  {
    label: "Radish",
    value: "radish",
  },
  {
    label: "Rapeseed",
    value: "rapeseed",
  },
  {
    label: "Rice",
    value: "rice",
  },
  {
    label: "Rye",
    value: "rye",
  },
  {
    label: "Soybean",
    value: "soybean",
  },
  {
    label: "Sorghum",
    value: "sorghum",
  },
  {
    label: "Strawberry",
    value: "strawberry",
  },
  {
    label: "Sugarcane",
    value: "sugarcane",
  },
  {
    label: "Sugarbeet",
    value: "sugarbeet",
  },
  {
    label: "Sunflower",
    value: "sunflower",
  },
  {
    label: "Sweet Potato",
    value: "sweet_potato",
  },
  {
    label: "Taro",
    value: "taro",
  },
  {
    label: "Tomato",
    value: "tomato",
  },
  {
    label: "Turnip",
    value: "turnip",
  },
  {
    label: "Walnut",
    value: "walnut",
  },
  {
    label: "Wheat",
    value: "wheat",
  },
  {
    label: "Yam",
    value: "yam",
  },
];

export default cropTypes;
