import React, {Fragment, useCallback, useRef} from "react";
import {toast} from "react-toastify";
import {directoryOpen} from "browser-fs-access";
import {Dialog, Transition} from "@headlessui/react";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";

import {useAppDispatch} from "../../../../util/hooks";

import {reSelectRawData, rawUploadStarted} from "../../../redux/uploadSlice";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

/* =============================================================================
<UploadRawAbortedDialog />
============================================================================= */
const UploadRawAbortedDialog: React.FC<Props> = ({isVisible, onClose}) => {
  const dispatch = useAppDispatch();
  const cancelButtonRef = useRef(null);

  const _handleCancelClick = () => {
    onClose();
  };

  const _handleRetryClick = useCallback(async () => {
    try {
      const blobsInDirectory = (await directoryOpen({
        id: `raw-data`,
        mode: "read",
        recursive: true,
        skipDirectory: entry => entry.name[0] === ".",
      })) as File[];

      const result = await dispatch(reSelectRawData(blobsInDirectory));

      if (reSelectRawData.rejected.match(result)) {
        throw result.error;
      }

      dispatch(rawUploadStarted());
      onClose();
    } catch (e) {
      toast(e.message);
    }
  }, [onClose, dispatch]);

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900">
                        Upload Aborted
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          A previous upload was aborted please try again with
                          the same directory to continue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-black px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 sm:ml-3 sm:w-auto"
                    onClick={_handleRetryClick}>
                    Retry
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={_handleCancelClick}
                    ref={cancelButtonRef}>
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

/* Export
============================================================================= */
export default UploadRawAbortedDialog;
