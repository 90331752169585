import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface FieldMapEditorState {
  tool: "Polygon" | "LineString" | "Point" | null;
  geometry: string | null;
}

const initialState: FieldMapEditorState = {
  tool: null,
  geometry: null,
};

// Slice
const fieldMapEditorSlice = createSlice({
  name: "fieldMapEditor",
  initialState,
  reducers: {
    toolSelected(state, action: PayloadAction<FieldMapEditorState["tool"]>) {
      state.tool = action.payload;
    },
    toolUnselected(state) {
      state.tool = null;
    },
    geometryAdded(state, action: PayloadAction<string>) {
      state.geometry = action.payload;
    },
    geometryRemoved(state) {
      state.geometry = null;
    },
  },
});

// Actions
export const {toolSelected, toolUnselected, geometryAdded, geometryRemoved} =
  fieldMapEditorSlice.actions;

// Reducer
export default fieldMapEditorSlice.reducer;

// Selectors

export const selectTool = (state: RootState) =>
  state.fieldMapEditor?.tool ?? null;

export const selectGeometry = (state: RootState) =>
  state.fieldMapEditor?.geometry ?? null;
