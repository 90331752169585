import {combineReducers} from "@reduxjs/toolkit";

import mapReducer from "../home/redux/mapSlice";
import authReducer from "../auth/redux/authSlice";
import farmReducer from "../home/redux/farmSlice";
import uploadReducer from "../upload/redux/uploadSlice";
import addFieldReducer from "../home/redux/addFieldSlice";
import notificationsReducer from "../home/redux/notificationsSlice";
import fieldTimelineReducer from "../field/redux/fieldTimelineSlice";
import fieldMapEditorReducer from "../field/redux/fieldMapEditorSlice";
import uploadRawFilesReducer from "../upload/redux/uploadRawFilesSlice";
import uploadRawBlobsReducer from "../upload/redux/uploadRawBlobsSlice";
import fieldAnnotationsReducer from "../field/redux/fieldAnnotationsSlice";
import uploadMosaicBlobReducer from "../upload/redux/uploadMosaicBlobSlice";
import uploadMosaicPartsReducer from "../upload/redux/uploadMosaicPartsSlice";

const rootReducer = combineReducers({
  map: mapReducer,
  auth: authReducer,
  farm: farmReducer,
  upload: uploadReducer,
  addField: addFieldReducer,
  notifications: notificationsReducer,
  fieldTimeline: fieldTimelineReducer,
  fieldMapEditor: fieldMapEditorReducer,
  uploadRawFiles: uploadRawFilesReducer,
  uploadRawBlobs: uploadRawBlobsReducer,
  fieldAnnotations: fieldAnnotationsReducer,
  uploadMosaicBlob: uploadMosaicBlobReducer,
  uploadMosaicParts: uploadMosaicPartsReducer,
});

export default rootReducer;
