import React, {useCallback} from "react";

import CloseIcon from "../../../assets/icons/edit-close.svg";
import AppFieldArt from "../../../assets/images/app-field-art.webp";

import {useAppDispatch} from "../../../util/hooks";

import {actionClosed} from "../../redux/farmSlice";

/* =============================================================================
<FieldDetailsHeader />
============================================================================= */
const FieldDetailsHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const _handleCloseClick = useCallback(() => {
    dispatch(actionClosed());
  }, [dispatch]);

  return (
    <>
      <button
        type="button"
        className="block ml-auto px-2 pt-2 pb-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <img src={AppFieldArt} width="160px" height="auto" className="mx-auto" />
    </>
  );
};

/* Export
============================================================================= */
export default FieldDetailsHeader;
