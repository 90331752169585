import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";
import {rawUploadCancelled} from "./uploadSlice";

export interface UploadRawFile {
  id: string;
  name: string;
  path: string;
  size: number;
  files: string[];
  geometry?: {
    lat: number;
    lng: number;
    alt: number;
  };
  selected: boolean;
  uploaded: boolean;
  uploading: boolean;
  createdAt: number;
}

const uploadRawFilesAdapter = createEntityAdapter<UploadRawFile>({
  sortComparer: (a, b) => a.createdAt - b.createdAt,
});

// Initial state

const initialState = uploadRawFilesAdapter.getInitialState();

// Slice
const uploadRawFilesSlice = createSlice({
  name: "uploadRawFiles",
  initialState,
  reducers: {
    filesAdded(state, action: PayloadAction<UploadRawFile[]>) {
      uploadRawFilesAdapter.setAll(state, action.payload);
    },
    filesUpdated(state, action: PayloadAction<UploadRawFile[]>) {
      uploadRawFilesAdapter.addMany(state, action.payload);
    },
    filesRemoved(state, action: PayloadAction<string[]>) {
      uploadRawFilesAdapter.removeMany(state, action.payload);
    },
    fileSelected(state, action: PayloadAction<string>) {
      uploadRawFilesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          selected: true,
        },
      });
    },
    fileUnselected(state, action: PayloadAction<string>) {
      uploadRawFilesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          selected: false,
        },
      });
    },
    fileUploaded(state, action: PayloadAction<string>) {
      uploadRawFilesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          uploaded: true,
          uploading: false,
        },
      });
    },
    fileUploadStarted(state, action: PayloadAction<string>) {
      uploadRawFilesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          uploading: true,
        },
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(rawUploadCancelled, state => {
      uploadRawFilesAdapter.removeAll(state);
    });
  },
});

// Actions
export const {
  filesAdded,
  filesUpdated,
  filesRemoved,
  fileSelected,
  fileUnselected,
  fileUploaded,
  fileUploadStarted,
} = uploadRawFilesSlice.actions;

// Reducer
export default uploadRawFilesSlice.reducer;

// Selectors

export const {
  selectAll: selectUploadFiles,
  selectIds: selectUploadFilesIds,
  selectById: selectUploadFileById,
  selectTotal: selectTotalUploadFiles,
} = uploadRawFilesAdapter.getSelectors<RootState>(
  state => state.uploadRawFiles ?? initialState,
);

export const selectSelectedFiles = (state: RootState) =>
  selectUploadFiles(state).filter(file => file.selected);

export const selectUploadedFiles = (state: RootState) =>
  selectUploadFiles(state).filter(file => file.uploaded);

export const selectUploadMapPoints = (state: RootState) =>
  selectUploadFiles(state).filter(file => Boolean(file.geometry));

export const selectTotalBytes = (state: RootState) =>
  selectUploadFiles(state)
    .map(file => file.size)
    .reduce((a, b) => a + b, 0);

export const selectUploadedBytes = (state: RootState) =>
  selectUploadedFiles(state)
    .map(file => file.size)
    .reduce((a, b) => a + b, 0);

export const selectUploadingFile = (state: RootState) =>
  selectUploadFiles(state).find(file => file.uploading);

export const selectFileToUpload = (state: RootState) => {
  if (selectUploadingFile(state)) {
    return null;
  }

  return selectUploadFiles(state).find(file => !file.uploaded);
};
