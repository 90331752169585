import React, {useCallback} from "react";
import {DateTime} from "luxon";

import FileIcon from "../../../../assets/icons/file-pdf.svg";

interface Props {
  id: string;
  name?: string | null;
  link: string;
  createdAt: string;
}

/* =============================================================================
<FieldReportsItem />
============================================================================= */
const FieldReportsItem: React.FC<Props> = ({name, link, createdAt}) => {
  const _handleClick = useCallback(() => {
    window.open(link, "blank");
  }, [link]);

  return (
    <li
      className="flex items-center mt-3 p-3 rounded-lg cursor-pointer shadow"
      onClick={_handleClick}>
      <FileIcon />
      <span className="flex-1 text-sm font-medium truncate ml-2.5">{name}</span>
      <div className="w-[6px] h-[6px] mx-3 bg-orange-500 rounded-full" />
      <span className="text-xs text-gray-500 font-normal">
        {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_MED)}
      </span>
    </li>
  );
};

/* Export
============================================================================= */
export default FieldReportsItem;
