import type React from "react";
import {useCallback, useEffect, useRef} from "react";
import {shallowEqual} from "react-redux";
import {isCancel} from "axios";

import {request, uploader} from "../../../util/upload";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {selectBlobsById} from "../../redux/uploadRawBlobsSlice";
import {
  rawUploadFailed,
  rawUploadAborted,
  rawUploadCompleted,
  selectFarm as selectUploadFarm,
  selectField as selectUploadField,
  selectDate as selectUploadDate,
  selectStatus as selectUploadStatus,
} from "../../redux/uploadSlice";
import {
  fileUploaded,
  fileUploadStarted,
  selectTotalBytes,
  selectFileToUpload,
  selectUploadedBytes,
  selectUploadingFile,
} from "../../redux/uploadRawFilesSlice";

/* =============================================================================
<UploadRawObserver />
============================================================================= */
const UploadRawObserver: React.FC = () => {
  const dispatch = useAppDispatch();
  const controller = useRef<AbortController | null>(null);

  const uploadFarm = useAppSelector(selectUploadFarm, shallowEqual);
  const uploadField = useAppSelector(selectUploadField, shallowEqual);
  const uploadDate = useAppSelector(selectUploadDate, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);
  const uploadedBytes = useAppSelector(selectUploadedBytes, shallowEqual);
  const uploadStatus = useAppSelector(selectUploadStatus, shallowEqual);
  const fileToUpload = useAppSelector(selectFileToUpload, shallowEqual);
  const uploadingFile = useAppSelector(selectUploadingFile, shallowEqual);
  const uploadingBlobs = useAppSelector(
    state => selectBlobsById(state, {id: uploadingFile?.id}),
    shallowEqual,
  );

  // Prevent window close by user when uploading
  useEffect(() => {
    if (uploadingBlobs?.length) {
      window.addEventListener("beforeunload", _handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", _handleBeforeUnload);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingBlobs]);

  // Resume upload on mount - web app closed during previous upload
  useEffect(() => {
    if (uploadingFile && !uploadingBlobs?.length) {
      dispatch(rawUploadAborted());
    }
  }, [uploadingFile, uploadingBlobs, dispatch]);

  // Select next uploading file
  useEffect(() => {
    if (fileToUpload && uploadStatus === "raw-uploading") {
      dispatch(fileUploadStarted(fileToUpload.id));
    }
  }, [fileToUpload, uploadStatus, dispatch]);

  // Upload file to presigned URL
  useEffect(() => {
    if (
      uploadStatus === "raw-uploading" &&
      uploadingFile &&
      uploadingBlobs?.length
    ) {
      (async () => {
        try {
          // Get presigned URLs
          const presignedURLs = await request({
            url: "/raw",
            method: "PUT",
            data: {
              farm: uploadFarm,
              field: uploadField,
              date: uploadDate,
              files: uploadingBlobs.map(blob => blob.name),
            },
          });

          // Abort controller ref
          controller.current = new AbortController();

          // Upload blobs in parallel
          await Promise.all(
            uploadingBlobs.map(blob =>
              uploader({
                url: presignedURLs[blob.name],
                data: blob,
                signal: controller.current?.signal,
              }),
            ),
          );

          // Remove abort controller ref
          controller.current = null;

          // Mark file as uploaded
          dispatch(fileUploaded(uploadingFile.id));
        } catch (e) {
          // Remove abort controller ref
          controller.current = null;

          // Upload failed when not cancelled
          if (!isCancel(e)) {
            dispatch(rawUploadFailed());
          }
        }
      })();
    }
  }, [
    uploadFarm,
    uploadField,
    uploadDate,
    uploadStatus,
    uploadingFile,
    uploadingBlobs,
    dispatch,
  ]);

  // Cancel ongoing request on status change
  useEffect(() => {
    if (controller.current && uploadStatus !== "raw-uploading") {
      controller.current.abort();
    }
  }, [uploadStatus, controller]);

  // Mark as uploaded
  useEffect(() => {
    if (totalBytes > 0 && uploadedBytes === totalBytes) {
      dispatch(rawUploadCompleted());
    }
  }, [totalBytes, uploadedBytes, dispatch]);

  const _handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to abort the upload?");
  }, []);

  return null;
};

/* Export
============================================================================= */
export default UploadRawObserver;
