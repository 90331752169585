import React, {useState} from "react";

import NotificationIcon from "../../../../assets/icons/edit-bell.svg";
import NotificationAltIcon from "../../../../assets/icons/edit-bell-alt.svg";
import LogoutIcon from "../../../../assets/icons/edit-logout.svg";
import LogoutDialog from "./LogoutDialog";

import {useAppDispatch, useAppSelector} from "../../../../util/hooks";

import {notificationsSelected} from "../../../redux/farmSlice";
import {selectUnreadNotifications} from "../../../redux/notificationsSlice";

/* =============================================================================
<NotificationsAndLogout />
============================================================================= */
const NotificationsAndLogout: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const unreadNotifications = useAppSelector(
    selectUnreadNotifications,
    (a, b) => a.length === b.length,
  );

  const _handleNotificationClick = () => {
    Notification.requestPermission().catch(() => {
      // TODO
    });

    dispatch(notificationsSelected());
  };

  const _handleLogoutClick = async () => {
    setShowLogoutDialog(true);
  };

  const _handleLogoutDialogClose = async () => {
    setShowLogoutDialog(false);
  };

  return (
    <div className="grid grid-flow-col gap-x-7 gap-y-2 px-4 py-3 rounded-lg bg-white app-shadow">
      <button type="button" onClick={_handleNotificationClick}>
        {unreadNotifications.length > 0 ? (
          <NotificationAltIcon />
        ) : (
          <NotificationIcon />
        )}
      </button>
      <button type="button" onClick={_handleLogoutClick}>
        <LogoutIcon />
      </button>
      <LogoutDialog
        isVisible={showLogoutDialog}
        onClose={_handleLogoutDialogClose}
      />
    </div>
  );
};

/* Export
============================================================================= */
export default NotificationsAndLogout;
