import React, {useCallback} from "react";
import {Helmet} from "react-helmet";
import {useQuery} from "@apollo/client";
import {Link, useParams, useNavigate} from "react-router-dom";

import AppLogo from "../../assets/images/app-logo.svg";
import CloseIcon from "../../assets/icons/edit-close.svg";

import cropTypes from "../../static/cropTypes";
import {GET_FIELD} from "../../home/api/queries";

/* =============================================================================
<FieldViewHeader />
============================================================================= */
const FieldViewHeader: React.FC = () => {
  const navigate = useNavigate();
  const {fieldId} = useParams();
  const {data} = useQuery(GET_FIELD, {
    variables: {
      id: fieldId,
    },
  });

  const field = data?.field_season_shot_by_pk;
  const cropType = cropTypes.find(ct => ct.value === field?.cropName);
  const cropName = cropType ? cropType.label : field?.cropName;

  const _handleCloseClick = useCallback(() => {
    navigate("/", {
      replace: true,
    });
  }, [navigate]);

  return (
    <div className="w-full h-11 flex-none flex items-center bg-black">
      <Helmet>
        <title>Field View | Lense AI</title>
      </Helmet>
      <Link to="/" className="ml-6 mr-5">
        <AppLogo width={109} height={20} fill="#ffffff" />
      </Link>
      <div className="w-px h-7 bg-[#515151]" />
      <h1 className="flex-1 ml-5 text-white">
        {field ? `${field.name} - ${cropName}` : "Please select a farm"}
      </h1>
      <button type="button" className="ml-6 mr-4" onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default FieldViewHeader;
