import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface FieldAnnotationsState {
  annotationsVisible: boolean;
  selectedAnnotation: string | null;
  selectedAnnotations: string[];
}

const initialState: FieldAnnotationsState = {
  annotationsVisible: true,
  selectedAnnotation: null,
  selectedAnnotations: [],
};

// Slice
const fieldAnnotationsSlice = createSlice({
  name: "fieldAnnotations",
  initialState,
  reducers: {
    annotationsHidden(state) {
      state.annotationsVisible = false;
    },
    annotationsVisible(state) {
      state.annotationsVisible = true;
    },
    annotationSelected(state, action: PayloadAction<string>) {
      state.selectedAnnotation = action.payload;
    },
    annotationUnselected(state) {
      state.selectedAnnotation = null;
    },
    annotationsSelected(state, action: PayloadAction<string[]>) {
      state.selectedAnnotations = action.payload;
    },
  },
});

// Actions
export const {
  annotationsHidden,
  annotationsVisible,
  annotationSelected,
  annotationUnselected,
  annotationsSelected,
} = fieldAnnotationsSlice.actions;

// Reducer
export default fieldAnnotationsSlice.reducer;

// Selectors

export const selectAnnotationsVisible = (state: RootState) =>
  state.fieldAnnotations?.annotationsVisible ?? false;

export const selectSelectedAnnotation = (state: RootState) =>
  state.fieldAnnotations?.selectedAnnotation ?? null;

export const selectSelectedAnnotations = (state: RootState) =>
  state.fieldAnnotations?.selectedAnnotations ?? [];
