import React, {useCallback, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import UploadMosaicCancelDialog from "./UploadMosaicCancelDialog";
import HomeIcon from "../../../assets/icons/edit-home.svg";
import CloseIcon from "../../../assets/icons/edit-close.svg";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";
import {GET_FARM_NAME} from "../../api/queries";

import {
  selectUploadId,
  selectTotalBytes,
} from "../../redux/uploadMosaicPartsSlice";
import {
  mosaicUploadCancelled,
  selectFarm,
  selectStatus,
} from "../../redux/uploadSlice";

/* =============================================================================
<UploadMosaicHeader />
============================================================================= */
const UploadMosaicHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const farmId = useAppSelector(selectFarm, shallowEqual);
  const status = useAppSelector(selectStatus, shallowEqual);
  const uploadId = useAppSelector(selectUploadId, shallowEqual);
  const totalBytes = useAppSelector(
    selectTotalBytes,
    (a, b) => Boolean(a) === Boolean(b),
  );

  const {data} = useQuery(GET_FARM_NAME, {
    variables: {
      where: {
        id: {_eq: farmId},
      },
    },
  });

  const farm = data?.farms[0];

  const _handleCloseClick = useCallback(() => {
    // Upload finished
    if (status === "mosaic-uploaded") {
      dispatch(mosaicUploadCancelled());
      navigate("/", {
        replace: true,
      });
      return;
    }

    // Upload pending
    if (totalBytes > 0 && uploadId) {
      setCancelDialogOpen(true);
      return;
    }

    // Upload not started
    if (totalBytes > 0) {
      dispatch(mosaicUploadCancelled());
      navigate("/", {
        replace: true,
      });
      return;
    }

    // Navigate to home in other cases
    navigate("/", {
      replace: true,
    });
  }, [status, uploadId, totalBytes, navigate, dispatch]);

  const _handleCancelDialogClose = useCallback(() => {
    setCancelDialogOpen(false);
  }, []);

  return (
    <div className="w-full h-11 flex-none flex items-center bg-black">
      <Helmet>
        <title>Upload | Lense AI</title>
      </Helmet>
      <Link to="/" className="ml-6 mr-5">
        <HomeIcon />
      </Link>
      <div className="w-px h-7 bg-[#515151]" />
      <h1 className="flex-1 ml-5 text-white">
        {farm ? farm.name : "Please select a farm"}
      </h1>
      <button type="button" className="ml-6 mr-4" onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <UploadMosaicCancelDialog
        isVisible={cancelDialogOpen}
        onClose={_handleCancelDialogClose}
      />
    </div>
  );
};

/* Export
============================================================================= */
export default UploadMosaicHeader;
