import React from "react";

import FieldReports from "./FieldReports";
import FieldDetailsInfo from "./FieldDetailsInfo";
import FieldDetailsHeader from "./FieldDetailsHeader";
import FieldDetailsFooter from "./FieldDetailsFooter";

/* =============================================================================
<FieldDetails />
============================================================================= */
const FieldDetails: React.FC = () => {
  return (
    <div className="w-[20.625rem] absolute top-24 right-6 bottom-5 flex flex-col pb-4 rounded-2xl bg-white z-10 app-shadow">
      <FieldDetailsHeader />
      <FieldDetailsInfo />
      <FieldReports />
      <FieldDetailsFooter />
    </div>
  );
};

/* Export
============================================================================= */
export default FieldDetails;
