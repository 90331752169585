import React from "react";

import NotificationsActivityListEmpty from "./NotificationsActivityListEmpty";

/* =============================================================================
<NotificationsActivityList />
============================================================================= */
const NotificationsActivityList: React.FC = () => {
  return <NotificationsActivityListEmpty />;
};

/* Export
============================================================================= */
export default NotificationsActivityList;
