import React, {type FormEvent, useCallback, useState, useMemo} from "react";
import {DateTime} from "luxon";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import {Button, Select} from "../../../common";
import CloseIcon from "../../../assets/icons/edit-close.svg";
import cropTypes from "../../../static/cropTypes";
import reportTypes from "../../../static/reportTypes";

import {getHighResMapDate} from "../../../util/functions";
import {GET_FIELD, GET_FIELD_MAPS} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {actionClosed, selectSelectedField} from "../../redux/farmSlice";

/* =============================================================================
<GenerateReports />
============================================================================= */
const GenerateReports: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedMap, setSelectedMap] = useState("");
  const [selectedReport, setSelectedReport] = useState("");
  const selectedField = useAppSelector(selectSelectedField, shallowEqual);

  const {data: fieldData} = useQuery(GET_FIELD, {
    variables: {
      id: selectedField,
    },
  });

  const {data: fieldMapsData} = useQuery(GET_FIELD_MAPS, {
    variables: {
      where: {
        seasonShotId: {_eq: selectedField},
        status: {_eq: "ready"},
      },
    },
  });

  const field = fieldData?.field_season_shot_by_pk;

  const maps = useMemo(
    () =>
      fieldMapsData?.high_res_maps.map(map => {
        const id = map.id ?? "";
        const name = map.name ?? "";
        const createdAt = getHighResMapDate(map);

        return {
          label: (
            <span>
              {name}{" "}
              <span className="text-[#85919C]">{`(${DateTime.fromJSDate(createdAt).toLocaleString(DateTime.DATE_SHORT)})`}</span>
            </span>
          ),
          value: id,
        };
      }),
    [fieldMapsData],
  );

  const fieldReportTypes = useMemo(() => {
    const cropName = cropTypes.find(ct => ct.value === field?.cropName)?.value;

    if (cropName && reportTypes[cropName]) {
      return reportTypes[cropName];
    }

    const allFieldReportTypes: Array<{label: string; value: string}> = [];

    Object.keys(reportTypes).forEach(reportTypeName => {
      const rts = reportTypes[reportTypeName];

      rts.forEach(rt => {
        const isAdded = allFieldReportTypes.find(itm => itm.value === rt.value);

        if (!isAdded) {
          allFieldReportTypes.push(rt);
        }
      });
    });

    return allFieldReportTypes;
  }, [field]);

  const _handleCloseClick = useCallback(() => {
    dispatch(actionClosed());
  }, [dispatch]);

  const _handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="w-[360px] px-9 pb-10 pt-2">
        <h1 className="text-xl font-medium text-center">
          Generate field reports
        </h1>
        <form
          className="w-full grid grid-cols-1 gap-y-5 mt-9"
          onSubmit={_handleSubmit}>
          <Select
            label="Select Report"
            value={selectedReport}
            options={fieldReportTypes}
            className="py-3.5"
            placeholder="Select here"
            onChange={setSelectedReport}
          />
          {!!maps?.length && (
            <Select
              label="Select Map"
              value={selectedMap}
              // @ts-expect-error <span> as label<string> parameter
              options={maps}
              className="py-3.5"
              placeholder="Select here"
              onChange={setSelectedMap}
            />
          )}
          <Button type="submit" variant="primary" className="w-full mt-4">
            Generate
          </Button>
        </form>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default GenerateReports;
