import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";
import {mosaicUploadCancelled} from "./uploadSlice";

export interface UploadMosaicPart {
  id: number;
  startAt: number;
  endAt: number;
  size: number;
  etag?: string | null;
  uploadId?: string | null;
  uploaded: boolean;
  uploading: boolean;
}

const uploadMosaicPartsAdapter = createEntityAdapter<UploadMosaicPart>({
  sortComparer: (a, b) => a.id - b.id,
});

// Initial state

const initialState = uploadMosaicPartsAdapter.getInitialState();

// Slice
const uploadMosaicPartsSlice = createSlice({
  name: "uploadMosaicParts",
  initialState,
  reducers: {
    partsAdded(state, action: PayloadAction<UploadMosaicPart[]>) {
      uploadMosaicPartsAdapter.setAll(state, action.payload);
    },
    partsUpdated(state, action: PayloadAction<Partial<UploadMosaicPart>>) {
      uploadMosaicPartsAdapter.updateMany(
        state,
        state.ids.map(id => ({
          id,
          changes: action.payload,
        })),
      );
    },
    partsRemoved(state) {
      uploadMosaicPartsAdapter.removeAll(state);
    },
    partUploaded(state, action: PayloadAction<{id: number; etag: string}>) {
      uploadMosaicPartsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          etag: action.payload.etag,
          uploaded: true,
          uploading: false,
        },
      });
    },
    partUploadStarted(state, action: PayloadAction<number>) {
      uploadMosaicPartsAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          uploading: true,
        },
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(mosaicUploadCancelled, state => {
      uploadMosaicPartsAdapter.removeAll(state);
    });
  },
});

// Actions
export const {
  partsAdded,
  partsUpdated,
  partsRemoved,
  partUploaded,
  partUploadStarted,
} = uploadMosaicPartsSlice.actions;

// Reducer
export default uploadMosaicPartsSlice.reducer;

// Selectors

export const {
  selectAll: selectUploadParts,
  selectIds: selectUploadPartsIds,
  selectById: selectUploadPartById,
  selectTotal: selectTotalUploadParts,
} = uploadMosaicPartsAdapter.getSelectors<RootState>(
  state => state.uploadMosaicParts ?? initialState,
);

export const selectTotalBytes = (state: RootState) =>
  selectUploadParts(state)
    .map(part => part.size)
    .reduce((a, b) => a + b, 0);

export const selectUploadedParts = (state: RootState) =>
  selectUploadParts(state).filter(part => part.uploaded);

export const selectUploadedBytes = (state: RootState) =>
  selectUploadedParts(state)
    .map(part => part.size)
    .reduce((a, b) => a + b, 0);

export const selectUploadingPart = (state: RootState) =>
  selectUploadParts(state).find(part => part.uploading);

export const selectPartToUpload = (state: RootState) => {
  if (selectUploadingPart(state)) {
    return null;
  }

  return selectUploadParts(state).find(part => !part.uploaded);
};

export const selectUploadId = (state: RootState) =>
  selectUploadParts(state)[0]?.uploadId;
