import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice, type SerializedError} from "@reduxjs/toolkit";
import {parse as parseGeoTiff} from "geoblaze";
import type {FeatureCollection, Polygon} from "geojson";
import {bbox as turfBbox, bboxPolygon as turfBboxPolygon} from "@turf/turf";

import {type RootState, createAppAsyncThunk} from "../../redux/helpers";
import {request} from "../../util/upload";
import {blobAdded} from "./uploadMosaicBlobSlice";
import {parseMosaicData, parseRawData} from "../../util/functions";
import {blobsAdded, blobsRemoved, blobsUpdated} from "./uploadRawBlobsSlice";
import {
  filesAdded,
  filesRemoved,
  filesUpdated,
  selectUploadFiles,
  selectUploadFilesIds,
  selectUploadedFiles,
  selectUploadingFile,
  type UploadRawFile,
} from "./uploadRawFilesSlice";
import {
  partsAdded,
  partsUpdated,
  selectUploadId,
  selectUploadingPart,
  selectUploadParts,
} from "./uploadMosaicPartsSlice";

// Initial state

interface UploadState {
  farm: string | null;
  field: string | null;
  date: number | null;
  file: string | null;
  polygon: Polygon | null;
  directory: string | null;
  reportTypes: string | null;
  createBoundary: boolean;
  status:
    | "idle"
    // Raw uploads
    | "raw-finished"
    | "raw-uploaded"
    | "raw-uploading"
    | "raw-processed"
    | "raw-processing"
    | "raw-upload-paused"
    | "raw-upload-failed"
    | "raw-upload-aborted"
    | "raw-process-failed"
    // Mosaic uploads
    | "mosaic-uploaded"
    | "mosaic-uploading"
    | "mosaic-processed"
    | "mosaic-processing"
    | "mosaic-upload-paused"
    | "mosaic-upload-failed"
    | "mosaic-upload-aborted"
    | "mosaic-process-failed";
  error: SerializedError | null;
}

const initialState: UploadState = {
  farm: null,
  field: null,
  date: null,
  file: null,
  polygon: null,
  directory: null,
  reportTypes: null,
  createBoundary: false,
  status: "idle",
  error: null,
};

// Async actions

export const selectRawData = createAppAsyncThunk<
  {date: number; polygon: Polygon; directory: string},
  File[]
>("upload/selectRawData", async (blobs, {dispatch}) => {
  // Parse raw data
  const {files: uploadFiles, blobs: uploadBlobs} = await parseRawData(blobs);

  const date = [...uploadFiles].sort((a, b) => a.createdAt - b.createdAt)[0]
    .createdAt;

  // Set upload files
  dispatch(filesAdded(uploadFiles));

  // Set upload blobs
  dispatch(blobsAdded(uploadBlobs));

  const featureCollection: FeatureCollection = {
    type: "FeatureCollection",
    features: uploadFiles
      .filter(file => Boolean(file.geometry))
      .map(file => ({
        type: "Feature",
        id: file.id,
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [
            file.geometry?.lng || 0,
            file.geometry?.lat || 0,
            file.geometry?.alt || 0,
          ],
        },
      })),
  };

  const bboxPolygon = turfBboxPolygon(turfBbox(featureCollection));

  const response = {
    date,
    polygon: bboxPolygon.geometry,
    directory: uploadFiles[0]?.path?.split("/")?.shift() ?? "",
  };

  return response;
});

export const reSelectRawData = createAppAsyncThunk<void, File[]>(
  "upload/reSelectRawData",
  async (blobs, {dispatch, getState}) => {
    const {blobs: uploadBlobs} = await parseRawData(blobs);

    // Check for file (not uploaded) with empty blobs in the previous upload
    const invalidPreviousFile = selectUploadFiles(getState()).find(
      file => !file.uploaded && !uploadBlobs[file.id]?.length,
    );

    // Previous file must contain at least one blob
    if (invalidPreviousFile) {
      throw new Error(
        "Please make sure that you selected right directory to continue this upload.",
      );
    }

    // Set upload blobs
    dispatch(blobsAdded(uploadBlobs));
  },
);

export const uploadRawData = createAppAsyncThunk<
  {farm: string; field: string},
  {farm: string; field: string}
>("upload/uploadRawData", async ({farm, field}, {getState}) => {
  // Parse raw data

  const date = selectDate(getState());

  await request({
    url: "/raw/validate",
    method: "POST",
    data: {
      farm,
      field,
      date,
    },
  });

  const response = {
    farm,
    field,
  };

  return response;
});

export const uploadMoreRawData = createAppAsyncThunk<void, File[]>(
  "upload/uploadMoreRawData",
  async (blobs, {dispatch, getState}) => {
    // Parse raw data
    const {files: uploadFiles, blobs: uploadBlobs} = await parseRawData(blobs);

    const previousUploadFiles = selectUploadFilesIds(getState());

    // Filter out previous files (if any)
    const newUploadFiles = uploadFiles.filter(
      file => !previousUploadFiles.includes(file.id),
    );

    if (!newUploadFiles.length) {
      throw new Error(
        "Please select a directory that is not already uploaded.",
      );
    }

    // Filter out previous blobs (if any)
    const newUploadBlobs = {};

    newUploadFiles.forEach(file => {
      newUploadBlobs[file.id] = uploadBlobs[file.id];
    });

    // Update upload files
    dispatch(filesUpdated(newUploadFiles));

    // Update upload blobs
    dispatch(blobsUpdated(newUploadBlobs));
  },
);

export const deleteRawFiles = createAppAsyncThunk<void, UploadRawFile[]>(
  "upload/deleteFiles",
  async (files: UploadRawFile[], {dispatch, getState}) => {
    const state = getState();

    const status = selectStatus(state);
    const fileIds = files.map(file => file.id);
    const uploadedFiles = files.filter(file => file.uploaded || file.uploading);
    const uploadingFile = files.find(file => file.uploading);

    // Pause upload if we have to delete the uploading file
    if (uploadingFile && status == "raw-uploading") {
      dispatch(rawUploadPaused());
    }

    // Delete uploaded files
    if (uploadedFiles.length) {
      const farm = selectFarm(state);
      const field = selectField(state);
      const date = selectDate(state);

      const uploadedBlobs = uploadedFiles
        .map(file => file.files)
        .reduce((a, b) => [...a, ...b], []);

      await request({
        url: "/raw",
        method: "DELETE",
        params: {
          farm,
          field,
          date,
          files: uploadedBlobs,
        },
      });
    }

    // Remove upload files
    dispatch(filesRemoved(fileIds));

    // Remove upload blobs
    dispatch(blobsRemoved(fileIds));

    // Resume upload
    if (status === "raw-uploading" && uploadingFile) {
      dispatch(rawUploadStarted());
    }
  },
);

export const cancelRawUpload = createAppAsyncThunk(
  "upload/cancelUpload",
  async (_, {dispatch, getState}) => {
    const state = getState();

    const status = selectStatus(state);
    const uploadingFile = selectUploadingFile(state);

    // Pause upload if we have to delete the uploading file
    if (uploadingFile && status == "raw-uploading") {
      dispatch(rawUploadPaused());
    }

    const farm = selectFarm(state);
    const field = selectField(state);
    const date = selectDate(state);

    await request({
      url: "/raw",
      method: "DELETE",
      params: {
        farm,
        field,
        date,
      },
    });

    dispatch(rawUploadCancelled());
  },
);

export const finishRawUpload = createAppAsyncThunk(
  "upload/finishRawUpload",
  async (_, {getState}) => {
    const state = getState();

    const farm = selectFarm(state);
    const date = selectDate(state);
    const field = selectField(state);
    const reportTypes = selectReportTypes(state);
    const uploadedFiles = selectUploadedFiles(state);
    const createBoundary = selectCreateBoundary(state);

    const scanTypes: string[] = ["full"];
    const imageTypes: string[] = [];

    // Add rapid scan type
    if (uploadedFiles.find(file => file.files.find(f => f.includes("_LA")))) {
      scanTypes.push("rapid");
    }

    // Add jpg image type
    if (
      uploadedFiles.find(file =>
        file.files.find(f => f.toLocaleLowerCase().endsWith(".jpg")),
      )
    ) {
      imageTypes.push("rgb");
    }

    // Add tif image type
    if (
      uploadedFiles.find(file =>
        file.files.find(f => f.toLocaleLowerCase().endsWith(".tif")),
      )
    ) {
      imageTypes.push("ms");
    }

    await request({
      url: "/raw/complete",
      method: "POST",
      data: {
        farm,
        date,
        field,
        scanTypes,
        imageTypes,
        reportTypes: reportTypes ? reportTypes.split(",") : [],
        createBoundary,
      },
    });
  },
);

export const selectMosaicData = createAppAsyncThunk<
  {date: number; file: string; polygon: Polygon},
  File
>("upload/selectMosaicData", async (blob, {dispatch}) => {
  // Parse mosaic data
  const {parts: uploadParts, blob: uploadBlob} = await parseMosaicData(blob);

  const date = uploadBlob.lastModified;

  // Set upload parts
  dispatch(partsAdded(uploadParts));

  // Set upload blob
  dispatch(blobAdded(uploadBlob));

  const georaster = await parseGeoTiff(blob);

  const bboxPolygon = turfBboxPolygon([
    georaster.xmin,
    georaster.ymin,
    georaster.xmax,
    georaster.ymax,
  ]);

  const response = {
    date,
    file: uploadBlob.name,
    polygon: bboxPolygon.geometry,
  };

  return response;
});

export const reSelectMosaicData = createAppAsyncThunk<void, File>(
  "upload/reSelectMosaicData",
  async (blob, {dispatch, getState}) => {
    const {blob: uploadBlob} = await parseMosaicData(blob);

    const state = getState();
    const file = selectFile(state);
    const size = selectUploadParts(state)
      .map(part => part.size)
      .reduce((a, b) => a + b, 0);

    // Invalid file selected
    if (blob.name !== file || blob.size !== size) {
      throw new Error(
        "Please make sure that you selected the same GeoTIFF file to continue this upload.",
      );
    }

    // Set upload blob
    dispatch(blobAdded(uploadBlob));
  },
);

export const uploadMosaicData = createAppAsyncThunk<
  {farm: string; field: string},
  {farm: string; field: string}
>("upload/uploadMosaicData", async ({farm, field}, {dispatch, getState}) => {
  const file = selectFile(getState());

  const payload = await request({
    url: "/mosaic",
    method: "POST",
    data: {
      farm,
      field,
      file,
    },
  });

  // Set upload id of parts
  dispatch(partsUpdated({uploadId: payload.uploadId}));

  return {
    farm,
    field,
  };
});

export const cancelMosaicUpload = createAppAsyncThunk(
  "upload/cancelMosaicUpload",
  async (_, {dispatch, getState}) => {
    const state = getState();

    const status = selectStatus(state);
    const uploadingPart = selectUploadingPart(state);

    // Pause upload if we have to delete the uploading part
    if (uploadingPart && status == "mosaic-uploading") {
      dispatch(mosaicUploadPaused());
    }

    const farm = selectFarm(state);
    const field = selectField(state);
    const file = selectFile(state);
    const uploadId = selectUploadId(state);

    await request({
      url: "/mosaic",
      method: "DELETE",
      params: {
        farm,
        field,
        file,
        uploadId,
      },
    });

    // Upload cancelled
    dispatch(mosaicUploadCancelled());
  },
);

export const completeMosaicUpload = createAppAsyncThunk(
  "upload/completeMosaicUpload",
  async (_, {dispatch, getState}) => {
    const state = getState();

    const farm = selectFarm(state);
    const field = selectField(state);
    const file = selectFile(state);
    const uploadId = selectUploadId(state);
    const uploadParts = selectUploadParts(state);
    const reportTypes = selectReportTypes(state);
    const createBoundary = selectCreateBoundary(state);

    await request({
      url: "/mosaic/complete",
      method: "POST",
      data: {
        farm,
        file,
        field,
        parts: uploadParts.map(uploadPart => ({
          id: uploadPart.id,
          etag: uploadPart.etag,
        })),
        uploadId,
        reportTypes,
        createBoundary,
      },
    });

    // Remove upload id and etag from parts
    dispatch(
      partsUpdated({
        etag: null,
        uploadId: null,
      }),
    );
  },
);

// Slice
const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    reportTypesChanged: (state, action: PayloadAction<string>) => {
      state.reportTypes = action.payload;
    },
    createBoundaryChanged: (state, action: PayloadAction<boolean>) => {
      state.createBoundary = action.payload;
    },
    rawUploadStarted(state) {
      state.status = "raw-uploading";
    },
    rawUploadPaused(state) {
      state.status = "raw-upload-paused";
    },
    rawUploadFailed(state) {
      state.status = "raw-upload-failed";
    },
    rawUploadAborted(state) {
      state.status = "raw-upload-aborted";
    },
    rawUploadCompleted(state) {
      state.status = "raw-uploaded";
    },
    rawUploadCancelled(state) {
      state.farm = null;
      state.date = null;
      state.polygon = null;
      state.directory = null;
      state.reportTypes = null;
      state.createBoundary = false;
      state.status = "idle";
      state.error = null;
    },
    mosaicUploadStarted(state) {
      state.status = "mosaic-uploading";
    },
    mosaicUploadPaused(state) {
      state.status = "mosaic-upload-paused";
    },
    mosaicUploadFailed(state) {
      state.status = "mosaic-upload-failed";
    },
    mosaicUploadAborted(state) {
      state.status = "mosaic-upload-aborted";
    },
    mosaicUploadCancelled(state) {
      state.farm = null;
      state.date = null;
      state.file = null;
      state.polygon = null;
      state.reportTypes = null;
      state.createBoundary = false;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      // SelectRawData
      .addCase(selectRawData.pending, state => {
        state.status = "raw-processing";
        state.error = null;
      })
      .addCase(selectRawData.fulfilled, (state, action) => {
        state.status = "raw-processed";
        state.date = action.payload.date;
        state.polygon = action.payload.polygon;
        state.directory = action.payload.directory;
      })
      .addCase(selectRawData.rejected, (state, action) => {
        state.status = "raw-process-failed";
        state.error = action.error;
      })
      // UploadRawData
      .addCase(uploadRawData.pending, state => {
        state.status = "raw-processing";
        state.error = null;
      })
      .addCase(uploadRawData.fulfilled, (state, action) => {
        state.status = "raw-processed";
        state.farm = action.payload.farm;
        state.field = action.payload.field;
      })
      .addCase(uploadRawData.rejected, (state, action) => {
        state.status = "raw-process-failed";
        state.error = action.error;
      })
      // FinishRawUpload
      .addCase(finishRawUpload.pending, state => {
        state.status = "raw-processing";
        state.error = null;
      })
      .addCase(finishRawUpload.fulfilled, state => {
        state.status = "raw-finished";
      })
      .addCase(finishRawUpload.rejected, (state, action) => {
        state.status = "raw-process-failed";
        state.error = action.error;
      })
      // SelectMosaicData
      .addCase(selectMosaicData.pending, state => {
        state.status = "mosaic-processing";
        state.error = null;
      })
      .addCase(selectMosaicData.fulfilled, (state, action) => {
        state.status = "mosaic-processed";
        state.date = action.payload.date;
        state.file = action.payload.file;
        state.polygon = action.payload.polygon;
      })
      .addCase(selectMosaicData.rejected, (state, action) => {
        state.status = "mosaic-process-failed";
        state.error = action.error;
      })
      // UploadMosaicData
      .addCase(uploadMosaicData.pending, state => {
        state.status = "mosaic-processing";
        state.error = null;
      })
      .addCase(uploadMosaicData.fulfilled, (state, action) => {
        state.status = "mosaic-processed";
        state.farm = action.payload.farm;
        state.field = action.payload.field;
      })
      .addCase(uploadMosaicData.rejected, (state, action) => {
        state.status = "mosaic-process-failed";
        state.error = action.error;
      })
      // CompleteMosaicUpload
      .addCase(completeMosaicUpload.pending, state => {
        state.status = "mosaic-processing";
        state.error = null;
      })
      .addCase(completeMosaicUpload.fulfilled, state => {
        state.status = "mosaic-uploaded";
      })
      .addCase(completeMosaicUpload.rejected, (state, action) => {
        state.status = "mosaic-process-failed";
        state.error = action.error;
      });
  },
});

// Actions
export const {
  reportTypesChanged,
  createBoundaryChanged,
  rawUploadStarted,
  rawUploadPaused,
  rawUploadFailed,
  rawUploadAborted,
  rawUploadCompleted,
  rawUploadCancelled,
  mosaicUploadStarted,
  mosaicUploadPaused,
  mosaicUploadFailed,
  mosaicUploadAborted,
  mosaicUploadCancelled,
} = uploadSlice.actions;

// Reducer
export default uploadSlice.reducer;

// Selectors

export const selectFarm = (state: RootState) => state.upload?.farm ?? null;

export const selectField = (state: RootState) => state.upload?.field ?? null;

export const selectDate = (state: RootState) => state.upload?.date ?? null;

export const selectFile = (state: RootState) => state.upload?.file ?? null;

export const selectPolygon = (state: RootState) =>
  state.upload?.polygon ?? null;

export const selectDirectory = (state: RootState) =>
  state.upload?.directory ?? null;

export const selectReportTypes = (state: RootState) =>
  state.upload?.reportTypes ?? null;

export const selectCreateBoundary = (state: RootState) =>
  state.upload?.createBoundary ?? false;

export const selectStatus = (state: RootState) => state.upload?.status ?? "";

export const selectError = (state: RootState) => state.upload?.error ?? null;
