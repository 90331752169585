import React from "react";

import CloseIcon from "../../../assets/icons/edit-close.svg";

import {useAppDispatch} from "../../../util/hooks";

import {actionClosed} from "../../redux/farmSlice";

/* =============================================================================
<NotificationsHeader />
============================================================================= */
const NotificationsHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const _handleCloseClick = () => {
    dispatch(actionClosed());
  };

  return (
    <div className="flex items-center justify-between">
      <h1 className="px-4 pt-4 pb-3 font-medium text-base">Farm Details</h1>
      <button
        type="button"
        className="block px-3 pt-4 pb-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default NotificationsHeader;
