import {createSlice} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface MapState {
  type: "map" | "satellite";
  showPolygons: boolean;
}

const initialState: MapState = {
  type: "satellite",
  showPolygons: true,
};

// Slice
const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    mapTypeSelected(state) {
      state.type = "map";
    },
    satelliteTypeSelected(state) {
      state.type = "satellite";
    },
    polygonsShown(state) {
      state.showPolygons = true;
    },
    polygonsHidden(state) {
      state.showPolygons = false;
    },
  },
});

// Actions
export const {
  mapTypeSelected,
  satelliteTypeSelected,
  polygonsShown,
  polygonsHidden,
} = mapSlice.actions;

// Reducer
export default mapSlice.reducer;

// Selectors

export const selectType = (state: RootState) => state.map?.type ?? "";

export const selectShowPolygons = (state: RootState) =>
  state.map?.showPolygons ?? false;
