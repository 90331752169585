import React from "react";
import {shallowEqual} from "react-redux";

import FieldViewAnnotationsEditorTools from "./FieldViewAnnotationsEditorTools";
import FieldViewAnnotationsEditorForm from "./FieldViewAnnotationsEditorForm";
import FieldViewAnnotationsEditorPlaceholder from "./FieldViewAnnotationsEditorPlaceholder";

import {useAppSelector} from "../../../util/hooks";

import {selectMap} from "../../redux/fieldTimelineSlice";
import {selectAnnotationsVisible} from "../../redux/fieldAnnotationsSlice";

/* =============================================================================
<FieldViewAnnotationsEditor />
============================================================================= */
const FieldViewAnnotationsEditor: React.FC = () => {
  const map = useAppSelector(selectMap, (a, b) => Boolean(a) === Boolean(b));
  const annotationsVisible = useAppSelector(
    selectAnnotationsVisible,
    shallowEqual,
  );

  if (map && annotationsVisible) {
    return (
      <div className="h-full w-[350px] relative ml-[-40px] pl-10 bg-transparent">
        <div className="h-full pt-2 px-3.5 pb-6 overflow-y-auto bg-white app-shadow">
          <FieldViewAnnotationsEditorTools />
          <FieldViewAnnotationsEditorForm />
          <FieldViewAnnotationsEditorPlaceholder />
        </div>
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldViewAnnotationsEditor;
