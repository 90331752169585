import React from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import FieldReportsItem from "./FieldReportsItem";

import {useAppSelector} from "../../../../util/hooks";
import {GET_FIELD_REPORTS} from "../../../api/queries";

import {selectSelectedField} from "../../../redux/farmSlice";

/* =============================================================================
<FieldReports />
============================================================================= */
const FieldReports: React.FC = () => {
  const selectedField = useAppSelector(selectSelectedField, shallowEqual);
  const {data} = useQuery(GET_FIELD_REPORTS, {
    variables: {
      where: {
        seasonShotId: {_eq: selectedField},
      },
    },
  });

  const reports = data?.reports;

  if (reports?.length) {
    return (
      <>
        <span className="text-sm text-zinc-500 font-medium mt-7 mx-4">
          Reports
        </span>
        <div className="flex-1 px-4 mt-2 overflow-y-auto">
          {reports.map(({id, name, link, createdAt}) => (
            <FieldReportsItem
              id={id}
              key={id}
              name={name}
              link={link}
              createdAt={createdAt}
            />
          ))}
        </div>
      </>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldReports;
