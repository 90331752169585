import React, {useCallback, useEffect, useState} from "react";
import wkt from "wkt";
import {shallowEqual} from "react-redux";
import {useLazyQuery, useMutation} from "@apollo/client";
import {area as turfArea, lineDistance as turfLineDistance} from "@turf/turf";

import {Button, ErrorMessage, TextArea, TextInput} from "../../../../common";
import FieldViewAnnotationsEditorFormHeader from "./FieldViewAnnotationsEditorFormHeader";

import {errorMessage} from "../../../../util/functions";
import {useAppDispatch, useAppSelector} from "../../../../util/hooks";
import {
  GET_FIELD_MAP_ANNOTATION,
  GET_FIELD_MAP_ANNOTATIONS,
} from "../../../api/queries";

import {selectMap} from "../../../redux/fieldTimelineSlice";
import {
  geometryRemoved,
  selectGeometry,
} from "../../../redux/fieldMapEditorSlice";
import {
  annotationUnselected,
  selectSelectedAnnotation,
} from "../../../redux/fieldAnnotationsSlice";
import {
  ADD_FIELD_MAP_ANNOTATION,
  DELETE_FIELD_MAP_ANNOTATION,
  UPDATE_FIELD_MAP_ANNOTATION,
} from "../../../api/mutations";

/* =============================================================================
<FieldViewAnnotationsEditorForm />
============================================================================= */
const FieldViewAnnotationsEditorForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coordinates, setCoordinates] = useState([]);

  const mapId = useAppSelector(selectMap, shallowEqual);
  const geometry = useAppSelector(selectGeometry, shallowEqual);
  const selectedAnnotation = useAppSelector(
    selectSelectedAnnotation,
    shallowEqual,
  );

  const [
    getAnnotation,
    {loading: getAnnotationLoading, error: getAnnotationError},
  ] = useLazyQuery(GET_FIELD_MAP_ANNOTATION);
  const [
    addAnnotation,
    {loading: addAnnotationLoading, error: addAnnotationError},
  ] = useMutation(ADD_FIELD_MAP_ANNOTATION, {
    refetchQueries: [GET_FIELD_MAP_ANNOTATIONS],
  });
  const [
    updateAnnotation,
    {loading: updateAnnotationLoading, error: updateAnnotationError},
  ] = useMutation(UPDATE_FIELD_MAP_ANNOTATION, {
    refetchQueries: [GET_FIELD_MAP_ANNOTATIONS],
  });
  const [
    deleteAnnotation,
    {loading: deleteAnnotationLoading, error: deleteAnnotationError},
  ] = useMutation(DELETE_FIELD_MAP_ANNOTATION, {
    refetchQueries: [GET_FIELD_MAP_ANNOTATIONS],
  });

  const error =
    getAnnotationError ||
    addAnnotationError ||
    updateAnnotationError ||
    deleteAnnotationError;
  const loading =
    getAnnotationLoading ||
    addAnnotationLoading ||
    updateAnnotationLoading ||
    deleteAnnotationLoading;
  const disabled = loading || !title;

  // Get selected annotation
  useEffect(() => {
    if (selectedAnnotation) {
      (async () => {
        const res = await getAnnotation({
          variables: {id: selectedAnnotation},
        });

        if (res.data?.high_res_map_annotations_by_pk) {
          const parsedGeometry = wkt.parse(
            res.data.high_res_map_annotations_by_pk.geometry,
          );

          setType(parsedGeometry.type);
          setTitle(res.data.high_res_map_annotations_by_pk.name);
          setDescription(
            res.data.high_res_map_annotations_by_pk.description || "",
          );
          setCoordinates(parsedGeometry.coordinates);
        }
      })();

      return;
    }

    if (geometry) {
      const parsedGeometry = wkt.parse(geometry);

      setType(parsedGeometry.type);
      setTitle("");
      setDescription("");
      setCoordinates(parsedGeometry.coordinates);
      return;
    }

    setType("");
    setTitle("");
    setDescription("");
    setCoordinates([]);
  }, [geometry, selectedAnnotation, getAnnotation]);

  const _handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event?.target.value);
    },
    [],
  );

  const _handleDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event?.target.value);
    },
    [],
  );

  const _handleDeleteClick = useCallback(async () => {
    // Delete selected annotation
    if (selectedAnnotation) {
      const res = await deleteAnnotation({
        variables: {
          id: selectedAnnotation,
        },
      });

      if (!res.errors?.length) {
        dispatch(annotationUnselected());
      }

      return;
    }

    // Reset geometry
    if (geometry) {
      dispatch(geometryRemoved());
      return;
    }
  }, [geometry, selectedAnnotation, deleteAnnotation, dispatch]);

  const _handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Update annotation
    if (selectedAnnotation) {
      const res = await updateAnnotation({
        variables: {
          pk_columns_input: {id: selectedAnnotation},
          set_input: {
            name: title,
            description,
          },
        },
      });

      if (!res.errors?.length) {
        dispatch(annotationUnselected());
      }

      return;
    }

    // Add annotation
    if (geometry && mapId) {
      let area = 0;

      if (type === "Polygon") {
        area = +(turfArea({type, coordinates}) / 4046.8564224).toFixed(2);
      }

      if (type === "LineString") {
        area = +turfLineDistance(
          // @ts-expect-error LineString as type
          {type, coordinates},
          {units: "meters"},
        ).toFixed(2);
      }

      const res = await addAnnotation({
        variables: {
          input: {
            name: title,
            area,
            description,
            geometry,
            highResMapId: mapId,
          },
        },
      });

      if (!res.errors?.length) {
        dispatch(geometryRemoved());
      }
      return;
    }
  };

  if (geometry || selectedAnnotation) {
    return (
      <div className="w-full h-full flex flex-col">
        <FieldViewAnnotationsEditorFormHeader
          type={type}
          onDeleteClick={_handleDeleteClick}
        />
        <form className="flex-1 flex flex-col mt-9" onSubmit={_handleSubmit}>
          <div className="grid grid-cols-1 gap-y-6">
            <TextInput
              label="Title"
              value={title}
              className="py-3"
              placeholder="Type here"
              onChange={_handleTitleChange}
            />
            <TextArea
              label="Description"
              value={description}
              className="py-3"
              placeholder="Type here"
              onChange={_handleDescriptionChange}
            />
            {type === "Point" && (
              <>
                <TextInput
                  label="Latitude"
                  value={`${coordinates[1]}`}
                  readOnly={true}
                  className="py-3"
                />
                <TextInput
                  label="Longitude"
                  value={`${coordinates[0]}`}
                  readOnly={true}
                  className="py-3"
                />
              </>
            )}
            <div>
              <h4 className="mb-3 text-sm font-medium text-black">
                Directions
              </h4>
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${getDestination({type, coordinates})}`}
                target="blank"
                className="text-sm underline text-blue-500">
                https://www.google.com/maps/dir/...
              </a>
            </div>
          </div>
          <Button
            type="submit"
            variant="secondary"
            loading={loading}
            disabled={disabled}
            className="w-full mt-auto">
            Save
          </Button>
          {error && (
            <ErrorMessage
              className="mx-4 mt-4"
              message={errorMessage(error.message)}
            />
          )}
        </form>
      </div>
    );
  }

  return null;
};

const getDestination = geometry => {
  if (geometry?.type === "Point") {
    return `${geometry?.coordinates[1]},${geometry?.coordinates[0]}`;
  }

  if (geometry?.type === "Polygon") {
    return `${geometry?.coordinates[0][0][1]},${geometry?.coordinates[0][0][0]}`;
  }

  if (geometry?.type === "LineString") {
    return `${geometry?.coordinates[0][1]},${geometry?.coordinates[0][0]}`;
  }

  return "";
};

/* Export
============================================================================= */
export default FieldViewAnnotationsEditorForm;
