import React from "react";
import {useNavigate} from "react-router-dom";
import {shallowEqual} from "react-redux";

import PlusIcon from "../../assets/icons/edit-plus-2.svg";
import CloseIcon from "../../assets/icons/edit-close.svg";

import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {selectUploadId as selectMosaicUploadId} from "../../upload/redux/uploadMosaicPartsSlice";
import {selectTotalBytes as selectTotalRawBytes} from "../../upload/redux/uploadRawFilesSlice";
import {
  selectFarm as selectUploadFarm,
  selectField as selectUploadField,
} from "../../upload/redux/uploadSlice";
import {
  actionClosed,
  uploadSelected,
  generateReportsSelected,
  generateMissionFilesSelected,
  selectAction,
} from "../redux/farmSlice";

/* =============================================================================
<SelectAction />
============================================================================= */
const SelectAction: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const action = useAppSelector(selectAction, shallowEqual);
  const uploadFarm = useAppSelector(selectUploadFarm, shallowEqual);
  const uploadField = useAppSelector(selectUploadField, shallowEqual);
  const totalRawBytes = useAppSelector(selectTotalRawBytes, shallowEqual);
  const mosaicUploadId = useAppSelector(selectMosaicUploadId, shallowEqual);

  const _handleCloseClick = () => {
    dispatch(actionClosed());
  };

  const _handleUploadDataClick = () => {
    // Upload dialog
    if (action === "upload") {
      return;
    }

    // Resume raw upload
    if (totalRawBytes > 0 && uploadFarm && uploadField) {
      navigate(`/upload/raw`);
      return;
    }

    // Resume mosaic upload
    if (mosaicUploadId) {
      navigate(`/upload/mosaic`);
      return;
    }

    // New upload
    dispatch(uploadSelected());
  };

  const _handleGenerateReportsClick = () => {
    dispatch(generateReportsSelected());
  };

  const _handleGenerateMissionFilesClick = () => {
    dispatch(generateMissionFilesSelected());
  };

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="px-10 pb-11">
        <h1 className="text-xl font-semibold text-center">
          Select your action
        </h1>
        <div className="flex flex-col sm:flex-row">
          <div className="w-80 sm:w-48 md:w-56 lg:w-72 pt-10">
            <button
              type="button"
              className="block w-full pt-6 pb-5 rounded-2xl bg-[#47584E]"
              onClick={_handleUploadDataClick}>
              <h2 className="text-2xl font-semibold text-center text-white leading-9">
                Upload
                <br />
                Field Data
              </h2>
              <div className="w-12 h-12 flex items-center justify-center mx-auto mt-4 rounded-full bg-[#394940]">
                <PlusIcon />
              </div>
            </button>
            <p className="font-medium text-sm text-zinc-500 mt-9">
              If you haven&apos;t uploaded data for this field before,{" "}
              <b>this is your initial step.</b>
            </p>
            <p className="font-medium text-sm text-zinc-500 mt-5">
              Uploading field data is essential for report generation and
              further field analysis.
            </p>
          </div>
          <div className="w-80 sm:w-48 md:w-56 lg:w-72 pt-10 sm:mx-6 md:mx-8 lg:mx-11">
            <button
              type="button"
              className="block w-full pt-6 pb-5 rounded-2xl bg-[#FF9437]"
              onClick={_handleGenerateReportsClick}>
              <h2 className="text-2xl font-semibold text-center text-white leading-9">
                Generate
                <br />
                Field Reports
              </h2>
              <div className="w-12 h-12 flex items-center justify-center mx-auto mt-4 rounded-full bg-[#F57E25]">
                <PlusIcon />
              </div>
            </button>
            <p className="font-medium text-sm text-zinc-500 mt-9">
              This action works when you have previously uploaded data for this
              field.
            </p>
            <p className="font-medium text-sm text-zinc-500 mt-5">
              Otherwise, it is crucial to upload field data first before
              generating any reports.
            </p>
          </div>
          <div className="w-80 sm:w-48 md:w-56 lg:w-72 pt-10">
            <button
              type="button"
              className="block w-full pt-6 pb-5 rounded-2xl bg-blue-400"
              onClick={_handleGenerateMissionFilesClick}>
              <h2 className="text-2xl font-semibold text-center text-white leading-9">
                Generate
                <br />
                Mission Files
              </h2>
              <div className="w-12 h-12 flex items-center justify-center mx-auto mt-4 rounded-full bg-blue-500">
                <PlusIcon />
              </div>
            </button>
            <p className="font-medium text-sm text-zinc-500 mt-9">
              This action will help you generate mission files for your drone.
            </p>
            <p className="font-medium text-sm text-zinc-500 mt-5">
              In order to get good results it is important to acquire data at
              given parameters
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default SelectAction;
