import React, {useEffect, useMemo, useRef} from "react";
import {DateTime} from "luxon";
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";

import FieldViewTimelineItem from "./FieldViewTimelineItem";
import FieldViewTimelineDefaultSelector from "./FieldViewTimelineDefaultSelector";

import {GET_FIELD_MAPS} from "../../../home/api/queries";
import {getHighResMapDate} from "../../../util/functions";

type TimelineItem = {
  maps: Array<{id: string; createdAt: string}>;
  month: string;
};

/* =============================================================================
<FieldViewTimeline />
============================================================================= */
const FieldViewTimeline: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const {fieldId} = useParams();

  const {data, error, loading} = useQuery(GET_FIELD_MAPS, {
    variables: {
      where: {
        seasonShotId: {_eq: fieldId},
        status: {_eq: "ready"},
      },
    },
  });

  // Add horizontal scroll with mouse wheel
  useEffect(() => {
    const containerRef = container.current;

    const _handleWheelEvent = (event: WheelEvent) => {
      if (containerRef) {
        if (event.deltaY > 0) {
          containerRef.scrollLeft += 100;
          event.preventDefault();
        } else {
          containerRef.scrollLeft -= 100;
          event.preventDefault();
        }
      }
    };

    containerRef?.addEventListener("wheel", _handleWheelEvent);

    return () => {
      containerRef?.removeEventListener("wheel", _handleWheelEvent);
    };
  }, []);

  const highResMaps = useMemo(() => {
    const maps = data?.high_res_maps.slice().map(highResMap => {
      const timestamp = getHighResMapDate(highResMap).getTime();

      return {
        ...highResMap,
        timestamp,
      };
    });

    maps?.sort((a, b) => a.timestamp - b.timestamp);

    return maps;
  }, [data]);

  const timeline = useMemo(() => {
    const items: TimelineItem[] = [];

    highResMaps?.forEach(highResMap => {
      const date = DateTime.fromMillis(highResMap.timestamp);

      const month = date.toFormat("LLLL yyyy");

      const item = items.find(item => item.month === month);

      if (item) {
        item.maps.push({
          id: `${highResMap.id}`,
          createdAt: date.toISO() ?? "",
        });
      } else {
        items.push({
          maps: [
            {
              id: `${highResMap.id}`,
              createdAt: date.toISO() ?? "",
            },
          ],
          month,
        });
      }
    });

    return items;
  }, [highResMaps]);

  if (error) {
    return (
      <p className="w-full h-20 flex justify-center items-center text-center bg-[#EDF2F7]">
        Something went wrong please try again later
      </p>
    );
  }

  if (!timeline.length) {
    return (
      <p className="w-full h-20 flex justify-center items-center text-center bg-[#EDF2F7]">
        {loading
          ? "Fetching field maps..."
          : "You will see the high res maps for this field here"}
      </p>
    );
  }

  return (
    <div ref={container} className="w-full flex overflow-x-auto bg-[#EDF2F7]">
      {timeline.map(renderItem)}
      <FieldViewTimelineDefaultSelector maps={highResMaps || []} />
    </div>
  );
};

const renderItem = (item: TimelineItem) => (
  <FieldViewTimelineItem key={item.month} maps={item.maps} month={item.month} />
);

/* Export
============================================================================= */
export default FieldViewTimeline;
