import React, {useCallback, useMemo} from "react";
import {
  default as ReactSelect,
  type Props as ReactSelectProps,
} from "react-select";

import {cls} from "../util/functions";

interface Props extends ReactSelectProps {
  label?: string;
  size?: "sm" | "md" | "lg";
  value?: string;
  options: Array<{label: string; value: string}>;
  className?: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onChange?: (v: string) => void;
}

/* =============================================================================
<Select />
============================================================================= */
const Select: React.FC<Props> = ({
  size = "md",
  label,
  value,
  options,
  className,
  labelProps,
  containerProps,
  onChange,
  ...rest
}) => {
  const isMulti = rest.isMulti;

  const _value = useMemo(() => {
    if (isMulti) {
      const values = value?.split(",");

      return options.filter(option => values?.includes(option.value));
    }

    return options?.find(option => option.value === value);
  }, [isMulti, value, options]);

  const _handleChange = useCallback(
    newValue => {
      if (typeof onChange === "function") {
        if (isMulti) {
          onChange(newValue.map(v => v.value).join(","));
        } else {
          onChange(newValue.value);
        }
      }
    },
    [isMulti, onChange],
  );

  return (
    <div
      {...containerProps}
      className={cls(`
        ${classes.container.base}
        ${containerProps?.className}
      `)}>
      {label && (
        <label
          {...labelProps}
          className={cls(`
            ${classes.label.base}
            ${classes.label.size[size]}
            ${labelProps?.className}
          `)}>
          {label}
        </label>
      )}
      <ReactSelect
        styles={{
          input: baseStyles => ({
            ...baseStyles,
            margin: "0px 0px",
            padding: "0px 0px",
          }),
          control: baseStyles => ({
            ...baseStyles,
            minHeight: 0,
            borderRadius: 6,
          }),
          valueContainer: baseStyles => ({
            ...baseStyles,
            padding: "0px 8px 0px 0px",
          }),
          dropdownIndicator: baseStyles => ({
            ...baseStyles,
            padding: "0px 0px 0px 8px",
          }),
        }}
        classNames={{
          control: () =>
            cls(`
              ${classes.select.base}
              ${classes.select.size[size]}
              ${className}
            `),
        }}
        value={_value}
        options={options}
        onChange={_handleChange}
        {...rest}
      />
    </div>
  );
};

const classes = {
  container: {
    base: "w-full",
  },
  label: {
    base: "block font-medium text-black",
    size: {
      sm: "text-xs mb-2.5",
      md: "text-sm mb-3",
      lg: "text-lg mb-4",
    },
  },
  select: {
    base: "relative w-full flex items-center rounded-md border-0 ring-inset ring-[#CDD5DD] text-black shadow-sm focus:ring-2 focus:ring-inset",
    size: {
      sm: "px-3 py-2 text-xs",
      md: "px-4 py-2.5 text-sm",
      lg: "px-5 py-3 text-base",
    },
  },
};

/* Export
============================================================================= */
export default Select;
