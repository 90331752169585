import type React from "react";
import {useEffect} from "react";
import {shallowEqual} from "react-redux";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {mapSelected, selectMap} from "../../redux/fieldTimelineSlice";

interface Props {
  maps: Array<{id: string}>;
}

/* =============================================================================
<FieldViewTimelineDefaultSelector />
============================================================================= */
const FieldViewTimelineDefaultSelector: React.FC<Props> = ({maps}) => {
  const dispatch = useAppDispatch();
  const selectedMap = useAppSelector(selectMap, shallowEqual);

  // Select last map as default
  useEffect(() => {
    if (maps.length && !selectedMap) {
      dispatch(mapSelected(maps[maps.length - 1].id.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps]);

  return null;
};

/* Export
============================================================================= */
export default FieldViewTimelineDefaultSelector;
