import React, {useCallback} from "react";
import {shallowEqual} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Button} from "../../../common";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {processDataSelected, selectSelectedField} from "../../redux/farmSlice";

/* =============================================================================
<FieldDetailsFooter />
============================================================================= */
const FieldDetailsFooter: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedField = useAppSelector(selectSelectedField, shallowEqual);

  const _handleProcessDataClick = useCallback(() => {
    dispatch(processDataSelected());
  }, [dispatch]);

  const _handleViewFieldClick = () => {
    navigate(`/fields/${selectedField}`);
  };

  return (
    <div className="px-4 pt-8 mt-auto">
      <Button className="w-full" onClick={_handleProcessDataClick}>
        Process Data
      </Button>
      <Button
        variant="secondary"
        className="w-full mt-2"
        onClick={_handleViewFieldClick}>
        View Field
      </Button>
    </div>
  );
};

/* Export
============================================================================= */
export default FieldDetailsFooter;
