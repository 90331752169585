import React, {useCallback, useEffect, useState} from "react";
import {shallowEqual} from "react-redux";

import UploadMosaicIdleDialog from "./UploadMosaicIdleDialog";
import UploadMosaicFailedDialog from "./UploadMosaicFailedDialog";
import UploadMosaicAbortedDialog from "./UploadMosaicAbortedDialog";
import UploadMosaicProcessedDialog from "./UploadMosaicProcessedDialog";
import UploadMosaicCompletedDialog from "./UploadMosaicCompletedDialog";
import UploadMosaicProcessFailedDialog from "./UploadMosaicProcessFailedDialog";

import {useAppSelector} from "../../../../util/hooks";

import {selectStatus} from "../../../redux/uploadSlice";

type DialogState =
  | "idle"
  | "failed"
  | "aborted"
  | "uploaded"
  | "processed"
  | "process-failed"
  | "";

/* =============================================================================
<UploadMosaicDialogs />
============================================================================= */
const UploadMosaicDialogs: React.FC = () => {
  const [dialog, setDialog] = useState<DialogState>("");

  const status = useAppSelector(selectStatus, shallowEqual);

  // Set dialog according to status
  useEffect(() => {
    switch (status) {
      case "idle":
        setDialog("idle");
        break;
      case "mosaic-upload-failed":
        setDialog("failed");
        break;
      case "mosaic-upload-aborted":
        setDialog("aborted");
        break;
      case "mosaic-uploaded":
        setDialog("uploaded");
        break;
      case "mosaic-processed":
        setDialog("processed");
        break;
      case "mosaic-process-failed":
        setDialog("process-failed");
        break;

      default:
        break;
    }
  }, [status]);

  const _handleClose = useCallback(() => {
    setDialog("");
  }, []);

  return (
    <>
      <UploadMosaicIdleDialog
        isVisible={dialog === "idle"}
        onClose={_handleClose}
      />
      <UploadMosaicFailedDialog
        isVisible={dialog === "failed"}
        onClose={_handleClose}
      />
      <UploadMosaicAbortedDialog
        isVisible={dialog === "aborted"}
        onClose={_handleClose}
      />
      <UploadMosaicCompletedDialog
        isVisible={dialog === "uploaded"}
        onClose={_handleClose}
      />
      <UploadMosaicProcessedDialog
        isVisible={dialog === "processed"}
        onClose={_handleClose}
      />
      <UploadMosaicProcessFailedDialog
        isVisible={dialog === "process-failed"}
        onClose={_handleClose}
      />
    </>
  );
};

/* Export
============================================================================= */
export default UploadMosaicDialogs;
