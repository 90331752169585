import {gql} from "../../__generated__/gql";

export const GET_FARMS = gql(/* GraphQL */ `
  query GetFarms {
    farms {
      id
      name
    }
  }
`);

export const GET_FIELDS = gql(/* GraphQL */ `
  query GetFields($where: field_season_shot_bool_exp) {
    field_season_shot(where: $where, distinct_on: fieldId) {
      id
      name
      cropName
      polygon {
        geometry
        geometry_wkt
      }
    }
  }
`);

export const GET_FIELD = gql(/* GraphQL */ `
  query GetField($id: bigint!) {
    field_season_shot_by_pk(id: $id) {
      id
      name
      season
      cropName
      polygon {
        size
        geometry
        geometry_wkt
      }
    }
  }
`);

export const GET_FIELD_MAPS = gql(/* GraphQL */ `
  query GetFieldMaps($where: high_res_maps_bool_exp) {
    high_res_maps(where: $where) {
      id
      name
      link
      ms_link
      ms_rapid_link
      rgb_link
      rgb_rapid_link
      captured_at
      createdAt
    }
  }
`);

export const GET_FIELD_REPORTS = gql(/* GraphQL */ `
  query GetFieldReports($where: reports_bool_exp) {
    reports(where: $where) {
      id
      name
      createdAt
      link
    }
  }
`);
