import React, {useCallback} from "react";
import {DateTime} from "luxon";

import {useAppSelector} from "../../../../util/hooks";
import {selectNotificationById} from "../../../redux/notificationsSlice";

interface Props {
  id: string;
  style: React.CSSProperties;
}

/* =============================================================================
<NotificationsListItem />
============================================================================= */
const NotificationsListItem: React.FC<Props> = ({id, style}) => {
  const notification = useAppSelector(
    state => selectNotificationById(state, id),
    (a, b) =>
      a.title === b.title &&
      a.body === b.body &&
      a.read === b.read &&
      a.createdAt === b.createdAt,
  );

  const _handleClick = useCallback(() => {
    // TODO
  }, []);

  return (
    <li className="px-5" style={style} onClick={_handleClick}>
      <div
        className={`flex py-5 rounded-lg shadow ${notification.read ? "bg-white" : "bg-[#FFF5EE]"}`}>
        <div
          className={`w-1.5 h-1.5 mt-1.5 ml-2 mr-1.5 rounded-full ${notification.read ? "bg-transparent" : "bg-orange-500"}`}
        />
        <div className="flex-1 mr-5 overflow-hidden">
          <div className="flex">
            <h3 className="flex-1 text-sm font-semibold">
              {notification.title}
            </h3>
            <span className="text-gray-500 text-xs font-normal">
              {DateTime.fromMillis(notification.createdAt).toLocaleString(
                DateTime.TIME_24_SIMPLE,
              )}
            </span>
          </div>
          <p className="mt-1.5 mr-6 text-gray-500 text-[13px] truncate">
            {notification.body}
          </p>
        </div>
      </div>
    </li>
  );
};

/* Export
============================================================================= */
export default NotificationsListItem;
