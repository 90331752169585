import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/24/outline";

import {GET_HIGH_RES_MAP} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {
  imageTypeSelected,
  selectMap,
  selectImageType,
} from "../../redux/fieldTimelineSlice";

/* =============================================================================
<FieldViewAnalysisImageType />
============================================================================= */
const FieldViewAnalysisImageType: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const mapId = useAppSelector(selectMap, shallowEqual);
  const imageType = useAppSelector(selectImageType, shallowEqual);

  const {data} = useQuery(GET_HIGH_RES_MAP, {
    variables: {
      id: mapId,
    },
  });

  // Select default image type on mount
  useEffect(() => {
    if (
      data?.high_res_maps_by_pk?.ms_link ||
      data?.high_res_maps_by_pk?.ms_rapid_link
    ) {
      dispatch(imageTypeSelected("ms"));
    } else {
      dispatch(imageTypeSelected("rgb"));
    }
  }, [data, dispatch]);

  const hasMsImage = useMemo(() => {
    if (
      data?.high_res_maps_by_pk?.ms_link ||
      data?.high_res_maps_by_pk?.ms_rapid_link
    ) {
      return true;
    }

    return false;
  }, [data]);

  const hasRgbImage = useMemo(() => {
    if (
      data?.high_res_maps_by_pk?.rgb_link ||
      data?.high_res_maps_by_pk?.rgb_rapid_link
    ) {
      return true;
    }

    return false;
  }, [data]);

  const _handleIconClick = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const _handleRgbImageClick = () => {
    dispatch(imageTypeSelected("rgb"));
  };

  const _handleMsImageClick = () => {
    dispatch(imageTypeSelected("ms"));
  };

  if (hasMsImage && hasRgbImage) {
    return (
      <div className="mt-4 px-4 pt-5 pb-4 rounded-lg shadow">
        <div className="flex items-center justify-between">
          <h2 className="text-xs font-medium text-[#787694]">Image type</h2>
          <button type="button" onClick={_handleIconClick}>
            {isOpen ? (
              <ChevronDownIcon
                className="h-4 w-4 text-black"
                aria-hidden="true"
              />
            ) : (
              <ChevronRightIcon
                className="h-4 w-4 text-black"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        {isOpen ? (
          <div className="mt-6">
            <button
              type="button"
              className="flex items-center mb-4"
              onClick={_handleRgbImageClick}>
              <span className="w-3.5 h-3.5 flex justify-center items-center rounded-full border border-black">
                {imageType === "rgb" && (
                  <span className="w-1.5 h-1.5 rounded-full bg-black" />
                )}
              </span>
              <span className="ml-2 text-sm font-medium text-gray-900">
                RGB
              </span>
            </button>
            <button
              type="button"
              className="flex items-center mb-4"
              onClick={_handleMsImageClick}>
              <span className="w-3.5 h-3.5 flex justify-center items-center rounded-full border border-black">
                {imageType === "ms" && (
                  <span className="w-1.5 h-1.5 rounded-full bg-black" />
                )}
              </span>
              <span className="ml-2 text-sm font-medium text-gray-900">MS</span>
            </button>
          </div>
        ) : (
          <h2 className="mt-4 mb-1 text-sm font-medium">
            {imageType?.toUpperCase()}
          </h2>
        )}
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldViewAnalysisImageType;
