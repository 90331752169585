import {gql} from "../../__generated__/gql";

export const GET_HIGH_RES_MAP = gql(/* GraphQL */ `
  query GetHighResMap($id: bigint!) {
    high_res_maps_by_pk(id: $id) {
      id
      name
      link
      ms_link
      ms_rapid_link
      rgb_link
      rgb_rapid_link
      captured_at
      createdAt
    }
  }
`);

export const GET_FIELD_MAP_LAYERS = gql(/* GraphQL */ `
  query GetFieldMapLayers($where: high_res_map_layers_bool_exp) {
    high_res_map_layers(where: $where) {
      id
      name
      link
      createdAt
    }
  }
`);

export const GET_FIELD_MAP_LAYER = gql(/* GraphQL */ `
  query GetFieldMapLayer($id: bigint!) {
    high_res_map_layers_by_pk(id: $id) {
      id
      name
      link
      createdAt
    }
  }
`);

export const GET_FIELD_MAP_ANNOTATIONS = gql(/* GraphQL */ `
  query GetFieldMapAnnotations($where: high_res_map_annotations_bool_exp) {
    high_res_map_annotations(where: $where) {
      id
      name
      area
      geometry
      description
      createdAt
    }
  }
`);

export const GET_FIELD_MAP_ANNOTATION = gql(/* GraphQL */ `
  query GetFieldMapAnnotation($id: bigint!) {
    high_res_map_annotations_by_pk(id: $id) {
      id
      name
      area
      geometry
      description
      createdAt
    }
  }
`);
