import React, {useCallback, useEffect, useState} from "react";
import {shallowEqual} from "react-redux";

import UploadRawIdleDialog from "./UploadRawIdleDialog";
import UploadRawFailedDialog from "./UploadRawFailedDialog";
import UploadRawAbortedDialog from "./UploadRawAbortedDialog";
import UploadRawFinishedDialog from "./UploadRawFinishedDialog";
import UploadRawProcessedDialog from "./UploadRawProcessedDialog";
import UploadRawCompletedDialog from "./UploadRawCompletedDialog";
import UploadRawProcessFailedDialog from "./UploadRawProcessFailedDialog";

import {useAppSelector} from "../../../../util/hooks";

import {selectStatus} from "../../../redux/uploadSlice";

type DialogState =
  | "idle"
  | "failed"
  | "aborted"
  | "uploaded"
  | "finished"
  | "processed"
  | "process-failed"
  | "";

/* =============================================================================
<UploadRawDialogs />
============================================================================= */
const UploadRawDialogs: React.FC = () => {
  const [dialog, setDialog] = useState<DialogState>("");

  const status = useAppSelector(selectStatus, shallowEqual);

  // Set dialog according to status
  useEffect(() => {
    switch (status) {
      case "idle":
        setDialog("idle");
        break;
      case "raw-upload-failed":
        setDialog("failed");
        break;
      case "raw-upload-aborted":
        setDialog("aborted");
        break;
      case "raw-uploaded":
        setDialog("uploaded");
        break;
      case "raw-finished":
        setDialog("finished");
        break;
      case "raw-processed":
        setDialog("processed");
        break;
      case "raw-process-failed":
        setDialog("process-failed");
        break;

      default:
        break;
    }
  }, [status]);

  const _handleClose = useCallback(() => {
    setDialog("");
  }, []);

  return (
    <>
      <UploadRawIdleDialog
        isVisible={dialog === "idle"}
        onClose={_handleClose}
      />
      <UploadRawFailedDialog
        isVisible={dialog === "failed"}
        onClose={_handleClose}
      />
      <UploadRawAbortedDialog
        isVisible={dialog === "aborted"}
        onClose={_handleClose}
      />
      <UploadRawCompletedDialog
        isVisible={dialog === "uploaded"}
        onClose={_handleClose}
      />
      <UploadRawFinishedDialog
        isVisible={dialog === "finished"}
        onClose={_handleClose}
      />
      <UploadRawProcessedDialog
        isVisible={dialog === "processed"}
        onClose={_handleClose}
      />
      <UploadRawProcessFailedDialog
        isVisible={dialog === "process-failed"}
        onClose={_handleClose}
      />
    </>
  );
};

/* Export
============================================================================= */
export default UploadRawDialogs;
