import React from "react";
import {shallowEqual} from "react-redux";
import {area as turfArea} from "@turf/turf";

import CloseIcon from "../../../assets/icons/edit-close.svg";
import {polygonToWkt} from "../../../util/functions";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {uploadSelected, actionClosed} from "../../redux/farmSlice";
import {selectPolygon as selectUploadPolygon} from "../../../upload/redux/uploadSlice";
import {
  polygonRemoved,
  selectPolygon,
  toolUnselected,
} from "../../redux/addFieldSlice";

/* =============================================================================
<AddFieldHeader />
============================================================================= */
const AddFieldHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const polygon = useAppSelector(selectPolygon, shallowEqual);
  const uploadPolygon = useAppSelector(selectUploadPolygon, shallowEqual);

  const areaInAcres = polygon ? turfArea(polygon) / 4046.8564224 : 0;

  const _handleCloseClick = () => {
    // Clear state
    dispatch(polygonRemoved());

    // Unselect add field tool
    dispatch(toolUnselected());

    if (
      polygon &&
      uploadPolygon &&
      polygonToWkt(uploadPolygon) === polygonToWkt(polygon)
    ) {
      // Return to upload
      dispatch(uploadSelected());
    } else {
      dispatch(actionClosed());
    }
  };

  return (
    <>
      <button
        type="button"
        className="block ml-auto px-2 pt-2 pb-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      {polygon ? (
        <h1 className="text-xl font-medium text-center mt-2">
          &asymp;{`${areaInAcres.toFixed(2)} Acres`}
        </h1>
      ) : null}
    </>
  );
};

/* Export
============================================================================= */
export default AddFieldHeader;
