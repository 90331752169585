import React, {useCallback, useEffect, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {useQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import {shallowEqual} from "react-redux";

import {Button, ErrorMessage, Select, TextInput} from "../../../common";
import CloseIcon from "../../../assets/icons/edit-close.svg";
import PlusIcon from "../../../assets/icons/edit-plus-2.svg";

import cropTypes from "../../../static/cropTypes";
import reportTypes from "../../../static/reportTypes";
import {formatBytes} from "../../../util/functions";
import {GET_FARMS, GET_FIELDS} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {selectTotalBytes} from "../../../upload/redux/uploadRawFilesSlice";
import {polygonAdded as fieldPolygonAdded} from "../../redux/addFieldSlice";
import {
  actionClosed,
  farmSelected,
  addFieldSelected,
  selectSelectedFarm,
  selectSelectedField,
} from "../../redux/farmSlice";
import {
  uploadRawData,
  rawUploadStarted,
  reportTypesChanged,
  createBoundaryChanged,
  selectDate,
  selectError,
  selectStatus,
  selectPolygon,
  selectDirectory,
  selectReportTypes,
} from "../../../upload/redux/uploadSlice";

interface Props {
  onClose: () => void;
  onSelectFolder: () => void;
}

type SelectOption = {
  label: string;
  value: string;
};

/* =============================================================================
<UploadRawData />
============================================================================= */
const UploadRawData: React.FC<Props> = ({onClose, onSelectFolder}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [fieldValue, setFieldValue] = useState<string>("");

  const farm = useAppSelector(selectSelectedFarm, shallowEqual);
  const date = useAppSelector(selectDate, shallowEqual);
  const error = useAppSelector(selectError, shallowEqual);
  const status = useAppSelector(selectStatus, shallowEqual);
  const polygon = useAppSelector(selectPolygon, shallowEqual);
  const directory = useAppSelector(selectDirectory, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);
  const selectedField = useAppSelector(selectSelectedField, shallowEqual);
  const reportTypesValue = useAppSelector(selectReportTypes, shallowEqual);

  const {data: farmsData} = useQuery(GET_FARMS);
  const {data: fieldsData} = useQuery(GET_FIELDS, {
    variables: {
      where: {
        farmId: {_eq: farm},
      },
    },
  });

  const farmOptions = useMemo(() => {
    if (farmsData?.farms) {
      return farmsData?.farms.map(farm => ({
        label: `${farm.name}`,
        value: `${farm.id}`,
      }));
    }

    return [];
  }, [farmsData]);

  const fieldOptions = useMemo(() => {
    const options: SelectOption[] = [];

    if (fieldsData?.field_season_shot) {
      fieldsData?.field_season_shot.map(field => {
        options.push({
          label: `${field.name}`,
          value: `${field.id}`,
        });
      });
    }

    options.push({
      label: "Add new field",
      value: "add-field",
    });

    return options;
  }, [fieldsData]);

  const fieldReportTypes = useMemo(() => {
    const field = fieldsData?.field_season_shot.find(
      field => `${field?.id}` === `${fieldValue}`,
    );
    const cropName = cropTypes.find(ct => ct.value === field?.cropName)?.value;

    if (cropName && reportTypes[cropName]) {
      return reportTypes[cropName];
    }

    const allFieldReportTypes: SelectOption[] = [];

    Object.keys(reportTypes).forEach(reportTypeName => {
      const rts = reportTypes[reportTypeName];

      rts?.forEach(rt => {
        const isAdded = allFieldReportTypes.find(itm => itm.value === rt.value);

        if (!isAdded) {
          allFieldReportTypes.push(rt);
        }
      });
    });

    return allFieldReportTypes;
  }, [fieldValue, fieldsData]);

  const isProcessing = status === "raw-processing";
  const disabled = !farm || !fieldValue || isProcessing;

  // Initialize field value
  useEffect(() => {
    if (selectedField) {
      setFieldValue(`${selectedField}`);
    }
  }, [selectedField]);

  const _handleCloseClick = () => {
    onClose();
  };

  const _handleSelectFolder = () => {
    onSelectFolder();
  };

  const _handleFarmValueChange = (value: string) => {
    dispatch(farmSelected(value));
    setFieldValue("");
  };

  const _handleFieldValueChange = (value: string) => {
    if (value === "add-field") {
      setFieldValue("");

      if (polygon) {
        // Polygon from data to uploaded
        dispatch(fieldPolygonAdded(polygon));
        // Move to add field form
        dispatch(addFieldSelected());
        // Boundary creation required at processing
        dispatch(createBoundaryChanged(true));
      }

      return;
    }

    setFieldValue(value);
    // Boundary creation not required for existing fields
    dispatch(createBoundaryChanged(false));
  };

  const _handleReportTypesValueChange = (value: string) => {
    dispatch(reportTypesChanged(value));
  };

  const _handleUploadClick = useCallback(async () => {
    const result = await dispatch(
      uploadRawData({farm: `${farm}`, field: fieldValue}),
    );

    if (uploadRawData.fulfilled.match(result)) {
      dispatch(actionClosed());
      dispatch(rawUploadStarted());
      navigate("/upload/raw");
    }
  }, [farm, fieldValue, dispatch, navigate]);

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <button
        type="button"
        className="block ml-auto p-3"
        onClick={_handleCloseClick}>
        <CloseIcon width={18} height={18} />
      </button>
      <div className="w-[490px] px-9 pb-10 pt-2">
        <div className="flex">
          <button
            type="button"
            className="w-12 h-12 flex items-center justify-center rounded-full bg-black"
            onClick={_handleSelectFolder}>
            <PlusIcon />
          </button>
          <div className="flex-1 pl-4">
            <h1 className="text-xl font-medium">Upload raw data</h1>
            <button
              type="button"
              className="text-sm font-medium text-[#454545]"
              onClick={_handleSelectFolder}>
              select a folder
            </button>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 gap-5 mt-9">
          <TextInput
            label="Name"
            value={directory ? `${directory} (${formatBytes(totalBytes)})` : ""}
            disabled={true}
            className="py-3"
            placeholder="e.g. HighRes-2cm"
          />
          <TextInput
            label="Date"
            value={
              date
                ? DateTime.fromMillis(date).toLocaleString(DateTime.DATE_SHORT)
                : ""
            }
            disabled={true}
            className="py-3"
            placeholder="Select Date"
          />
          <Select
            label="Farm"
            value={farm ? `${farm}` : ""}
            options={farmOptions}
            className="py-3"
            placeholder="Select farm"
            onChange={_handleFarmValueChange}
          />
          <Select
            label="Field"
            value={fieldValue}
            options={fieldOptions}
            className="py-3"
            placeholder="Select field"
            onChange={_handleFieldValueChange}
          />
          <Select
            label="Generate reports"
            value={reportTypesValue || ""}
            options={fieldReportTypes}
            isMulti={true}
            className="py-3 z-50"
            placeholder="(Optional)"
            containerProps={{
              className: "col-span-2",
            }}
            onChange={_handleReportTypesValueChange}
          />
          <Button
            variant="primary"
            loading={isProcessing}
            disabled={disabled}
            className="w-full col-span-2 mt-4"
            onClick={_handleUploadClick}>
            Upload
          </Button>
          {error && (
            <ErrorMessage
              className="col-span-2 mt-2 mb-1"
              message={
                error.message || "Something went wrong Please try again later"
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default UploadRawData;
