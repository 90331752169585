import wkt from "wkt";
import {area as turfArea} from "@turf/turf";

import type {UploadRawFile} from "../upload/redux/uploadRawFilesSlice";
import type {UploadMosaicPart} from "../upload/redux/uploadMosaicPartsSlice";
import type {Polygon} from "geojson";
import {DateTime} from "luxon";

export const errorMessage = (message?: string) => {
  switch (message) {
    case "API_INCORRECT_USERNAME_PASSWORD":
      return "Invalid email or password.";

    default:
      return "Something went wrong! Please try again later.";
  }
};

export const cls = (input: string) =>
  input
    .replace(/\s+/gm, " ")
    .split(" ")
    .filter(cond => typeof cond === "string")
    .join(" ")
    .trim();

// TODO - Add support for multi polygons
export const polygonToStr = (polygon: Polygon) => {
  let coordinates = polygon.coordinates;

  if (coordinates.length > 1) {
    let area = 0;

    for (let i = 0; i < coordinates.length; i++) {
      const cArea = turfArea({
        type: "Polygon",
        coordinates: [coordinates[i]],
      });

      if (cArea > area) {
        area = cArea;
        coordinates = [coordinates[i]];
      }
    }
  }

  const wktStr = coordinates
    .map(ring => `(${ring.map(p => `(${p[1]}, ${p[0]})`).join(", ")})`)
    .join(", ");

  return wktStr;
};

export const polygonToWkt = polygon => {
  if (polygon?.type === "Polygon") {
    return wkt.stringify(polygon);
  }

  if (polygon?.geometry_wkt) {
    return polygon.geometry_wkt;
  }

  if (polygon?.geometry) {
    const geom_arr = polygon.geometry
      .replaceAll("((", "")
      .replaceAll("))", "")
      .split("),(");

    let wktStr = "POLYGON((";

    for (let index = 0; index < geom_arr.length; index++) {
      const coords = geom_arr[index].split(",");
      wktStr += coords[1] + " " + coords[0] + ",";
      if (index == geom_arr.length - 1) {
        wktStr += coords[1] + " " + coords[0];
      }
    }

    wktStr += "))";

    return wktStr;
  }

  return null;
};

export const wktToPolygon = polygon => {
  if (typeof polygon === "string") {
    return wkt.parse(polygon);
  }

  if (polygon?.geometry_wkt) {
    return wkt.parse(polygon.geometry_wkt);
  }

  if (polygon?.geometry) {
    return {
      type: "Polygon",
      coordinates: [
        polygon.geometry
          .replaceAll("((", "")
          .replaceAll("))", "")
          .split("),(")
          .map(coords =>
            coords
              .split(",")
              .map(coord => Number(coord))
              .reverse(),
          ),
      ],
    };
  }

  return null;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const LEAP_SECONDS = [
  new Date("1980-01-06T00:00:00.000Z"),
  new Date("1981-07-01T00:00:00.000Z"),
  new Date("1982-07-01T00:00:00.000Z"),
  new Date("1983-07-01T00:00:00.000Z"),
  new Date("1985-07-01T00:00:00.000Z"),
  new Date("1988-01-01T00:00:00.000Z"),
  new Date("1990-01-01T00:00:00.000Z"),
  new Date("1991-01-01T00:00:00.000Z"),
  new Date("1992-07-01T00:00:00.000Z"),
  new Date("1993-07-01T00:00:00.000Z"),
  new Date("1994-07-01T00:00:00.000Z"),
  new Date("1996-01-01T00:00:00.000Z"),
  new Date("1997-07-01T00:00:00.000Z"),
  new Date("1999-01-01T00:00:00.000Z"),
  new Date("2006-01-01T00:00:00.000Z"),
  new Date("2009-01-01T00:00:00.000Z"),
  new Date("2012-07-01T00:00:00.000Z"),
  new Date("2015-07-01T00:00:00.000Z"),
  new Date("2017-01-01T00:00:00.000Z"),
  new Date("2018-01-01T00:00:00.000Z"),
  new Date("2019-01-01T00:00:00.000Z"),
].reverse();

export const gpsToUtc = (gpsWeek: number, gpsSeconds: number) => {
  // Constants
  const SECONDS_PER_WEEK = 604800; // 60 * 60 * 24 * 7
  const GPS_EPOCH = new Date("1980-01-06T00:00:00.000Z");

  // Calculate the total number of seconds since GPS epoch
  const totalSeconds = gpsWeek * SECONDS_PER_WEEK + gpsSeconds;

  // Calculate GPS time without leap seconds
  const gpsTime = new Date(GPS_EPOCH.getTime() + totalSeconds * 1000);

  // Add leap seconds
  let leapOffset = 0;

  for (let i = 0; i < LEAP_SECONDS.length; i++) {
    const year = LEAP_SECONDS[i];

    // @ts-expect-error athematic operation on date
    if (gpsTime - year > 0) {
      leapOffset += LEAP_SECONDS.length - i;
      break;
    }
  }

  // Calculate the total number of seconds since GPS epoch considering leap seconds
  const totalSecondsWithLeap = totalSeconds - leapOffset;

  // Calculate UTC time with leap seconds
  const utcTime = new Date(GPS_EPOCH.getTime() + totalSecondsWithLeap * 1000);

  return utcTime;
};

export const parseRawData = async (
  blobs: File[],
): Promise<{files: UploadRawFile[]; blobs: {[index: string]: File[]}}> => {
  // Extract mrk blobs
  const mrkBlobs = blobs.filter(blob =>
    blob.name.toUpperCase().endsWith(".MRK"),
  );

  // MRKs not found
  if (!mrkBlobs.length) {
    throw new Error("Please select a valid directory for raw data upload.");
  }

  // Read mrk blobs
  const mrkFiles = await Promise.all(mrkBlobs.map(blob => blob.text()));

  // Files to upload
  const uploadFiles: UploadRawFile[] = [];

  mrkFiles.forEach((file, i) => {
    // Iterate over each line of mrk
    file.split("\n").forEach((line, j) => {
      // Extract columns of a line
      const col = line.split("\t");

      if (col.length > 8) {
        // Extract timestamp
        const createdAt = gpsToUtc(
          +col[2].replace(/\[/g, "").replace(/\]/g, ""),
          +col[1],
        );

        // Extract sub directory name
        const directories = mrkBlobs[i].webkitRelativePath.split("/");
        const subdirectory = directories[directories.length - 2];

        // Add line to the files
        uploadFiles.push({
          id: `${subdirectory}-${j}`, // Combination of subdirectory and line number in MRK
          name: "",
          path: "",
          size: 0,
          files: [],
          geometry: {
            lat: +col[6].split(",")[0],
            lng: +col[7].split(",")[0],
            alt: +col[8].split(",")[0],
          },
          selected: false,
          uploaded: false,
          uploading: false,
          createdAt: createdAt.getTime(),
        });
      }
    });
  });

  // Extract jpeg files
  const jpgBlobs = blobs
    .filter(blob => blob.type === "image/jpeg")
    .filter(blob => blob.name[0]?.match(/^[a-z0-9]+$/i)); // File name starts with alphanumeric character

  // Jpeg's must match the mrk entries count
  if (jpgBlobs.length !== uploadFiles.length) {
    throw new Error("Please select a valid directory for raw data upload.");
  }

  const uploadBlobs = {};

  // Attach jpg blobs to related file
  let jpgId = 0;
  let jpgSubdirectory = "";
  jpgBlobs.forEach(blob => {
    const directories = blob.webkitRelativePath.split("/");
    const subdirectory = directories[directories.length - 2];

    if (jpgSubdirectory !== subdirectory) {
      jpgSubdirectory = subdirectory;
      jpgId = 0;
    }

    const id = `${jpgSubdirectory}-${jpgId}`;
    const uploadFile = uploadFiles.find(file => file.id === id);

    if (uploadFile) {
      uploadFile.name = blob.name;
      uploadFile.path = blob.webkitRelativePath;
      uploadFile.size = blob.size;
      uploadFile.files[0] = blob.name;
      uploadBlobs[uploadFile.id] = [blob];
    }

    jpgId += 1;
  });

  // Extract tiff files
  const tiffBlobs = blobs
    .filter(blob => blob.type === "image/tiff")
    .filter(blob => blob.name[0]?.match(/^[a-z0-9]+$/i)); // File name starts with alphanumeric character

  // Attach tiff blobs to related file
  let tiffId = 0;
  let tiffSubdirectory = "";
  tiffBlobs.forEach((blob, i) => {
    const directories = blob.webkitRelativePath.split("/");
    const subdirectory = directories[directories.length - 2];

    if (tiffSubdirectory !== subdirectory) {
      tiffSubdirectory = subdirectory;
      tiffId = 0;
    }

    const id = `${tiffSubdirectory}-${tiffId}`;
    const uploadFile = uploadFiles.find(file => file.id === id);

    if (uploadFile) {
      uploadFile.size += blob.size;
      uploadFile.files.push(blob.name);

      if (uploadBlobs[uploadFile.id]) {
        uploadBlobs[uploadFile.id].push(blob);
      }
    }

    if ((i + 1) % (tiffBlobs.length / jpgBlobs.length) === 0) {
      tiffId += 1;
    }
  });

  // Extract obs, nav, bin and mrk files
  const extraBlobs = blobs
    .filter(blob => {
      const blobExtension = blob.name.split(".").pop()?.toUpperCase();

      return (
        blobExtension === "OBS" ||
        blobExtension === "NAV" ||
        blobExtension === "BIN" ||
        blobExtension === "MRK"
      );
    })
    .filter(blob => blob.name[0]?.match(/^[a-z0-9]+$/i)); // File name starts with alphanumeric character

  // Attach extra blobs to upload files
  extraBlobs.forEach(blob => {
    const directories = blob.webkitRelativePath.split("/");
    const subdirectory = directories[directories.length - 2];

    const id = `${subdirectory}-extra`;
    const uploadFile = uploadFiles.find(file => file.id === id);

    if (uploadFile) {
      uploadFile.size += blob.size;
      uploadFile.files.push(blob.name);

      if (uploadBlobs[uploadFile.id]) {
        uploadBlobs[uploadFile.id].push(blob);
      }
    } else {
      uploadFiles.push({
        id,
        name: `${subdirectory}-misc`,
        path: blob.webkitRelativePath,
        size: blob.size,
        files: [blob.name],
        selected: false,
        uploaded: false,
        uploading: false,
        createdAt: Date.now(),
      });
      uploadBlobs[id] = [blob];
    }
  });

  // Check for file with empty blobs
  const invalidFile = uploadFiles.find(file => !uploadBlobs[file.id]?.length);

  // File must contain at least one blob
  if (invalidFile) {
    throw new Error("Please select a valid directory for raw data upload.");
  }

  return {
    files: uploadFiles,
    blobs: uploadBlobs,
  };
};

export const parseMosaicData = async (blob: File) => {
  if (blob.type !== "image/tiff") {
    throw new Error("Please select a valid GeoTIFF file.");
  }

  const uploadBlob = blob;
  const uploadParts: UploadMosaicPart[] = [];

  const partSize = 100 * 1024 * 1024; // 100 MB
  const partsCount = Math.ceil(blob.size / partSize);

  for (let i = 0; i < partsCount; i++) {
    const startAt = i * partSize;
    const endAt = Math.min(startAt + partSize, blob.size);

    uploadParts.push({
      id: i + 1,
      startAt,
      endAt,
      size: endAt - startAt,
      uploaded: false,
      uploading: false,
    });
  }

  return {
    blob: uploadBlob,
    parts: uploadParts,
  };
};

export const getHighResMapDate = highResMap => {
  let date = DateTime.fromISO(highResMap.createdAt).toJSDate();

  if (highResMap.captured_at) {
    date = DateTime.fromISO(highResMap.captured_at).toJSDate();
  }

  if (highResMap.link) {
    const dateStr = highResMap.link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.ms_link) {
    const dateStr = highResMap.ms_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.ms_rapid_link) {
    const dateStr = highResMap.ms_rapid_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.rgb_link) {
    const dateStr = highResMap.rgb_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  if (highResMap.rgb_rapid_link) {
    const dateStr = highResMap.rgb_rapid_link
      .match(/(_\d{8}_)/g)?.[0]
      ?.replaceAll("_", "");

    if (dateStr) {
      date = DateTime.fromFormat(dateStr, "yyyyLLdd").toJSDate();
    }
  }

  return date;
};
