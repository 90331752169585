import * as localForage from "localforage";

import type {Notification} from "../home/redux/notificationsSlice";

const STORAGE_KEY = "@lensai/notifications";

export const getNotifications = async () => {
  const notifications = await localForage.getItem<Notification[]>(STORAGE_KEY);

  return notifications || [];
};

export const addNotification = async (notification: Notification) => {
  const notifications = await localForage.getItem<Notification[]>(STORAGE_KEY);

  if (notifications?.length) {
    return await localForage.setItem(STORAGE_KEY, [
      notification,
      ...notifications,
    ]);
  }

  return await localForage.setItem(STORAGE_KEY, [notification]);
};

export const markNotificationAsRead = async (id: string) => {
  const notifications = await localForage.getItem<Notification[]>(STORAGE_KEY);

  if (notifications?.length) {
    await localForage.setItem(
      STORAGE_KEY,
      notifications.map(notification => {
        if (notification.id === id) {
          return {
            ...notification,
            read: true,
          };
        }

        return notification;
      }),
    );
  }
};

export const markAllNotificationsAsRead = async () => {
  const notifications = await localForage.getItem<Notification[]>(STORAGE_KEY);

  if (notifications?.length) {
    await localForage.setItem(
      STORAGE_KEY,
      notifications.map(notification => ({
        ...notification,
        read: true,
      })),
    );
  }
};

export const removeNotifications = async () => {
  await localForage.removeItem(STORAGE_KEY);
};
