import React from "react";

/* =============================================================================
<NotificationsListEmpty />
============================================================================= */
const NotificationsListEmpty: React.FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <p className="text-center text-gray-500">No notifications</p>
    </div>
  );
};

/* Export
============================================================================= */
export default NotificationsListEmpty;
