import React, {useEffect, useMemo} from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import RapidScoutIcon from "../../../assets/icons/app-rapid-scout.svg";
import FullScoutIcon from "../../../assets/icons/app-full-scout.svg";

import {GET_HIGH_RES_MAP} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {
  scanTypeSelected,
  selectMap,
  selectScanType,
} from "../../redux/fieldTimelineSlice";

/* =============================================================================
<FieldViewAnalysisScanType />
============================================================================= */
const FieldViewAnalysisScanType: React.FC = () => {
  const dispatch = useAppDispatch();

  const mapId = useAppSelector(selectMap, shallowEqual);
  const scanType = useAppSelector(selectScanType, shallowEqual);

  const {data} = useQuery(GET_HIGH_RES_MAP, {
    variables: {
      id: mapId,
    },
  });

  // Select default scan type on mount
  useEffect(() => {
    if (
      data?.high_res_maps_by_pk?.ms_rapid_link ||
      data?.high_res_maps_by_pk?.rgb_rapid_link
    ) {
      dispatch(scanTypeSelected("rapid"));
    } else {
      dispatch(scanTypeSelected("full"));
    }
  }, [data, dispatch]);

  const hasRapidScout = useMemo(() => {
    if (
      data?.high_res_maps_by_pk?.ms_rapid_link ||
      data?.high_res_maps_by_pk?.rgb_rapid_link
    ) {
      return true;
    }

    return false;
  }, [data]);

  const hasFullScout = useMemo(() => {
    if (
      data?.high_res_maps_by_pk?.ms_link ||
      data?.high_res_maps_by_pk?.rgb_link
    ) {
      return true;
    }

    return false;
  }, [data]);

  const _handleRapidScoutClick = () => {
    dispatch(scanTypeSelected("rapid"));
  };

  const _handleFullScoutClick = () => {
    dispatch(scanTypeSelected("full"));
  };

  if (hasRapidScout && hasFullScout) {
    return (
      <div className="flex items-center mt-4 mb-2.5 p-1 rounded-lg bg-[#EDF2F7]">
        <button
          type="button"
          className={`flex-1 flex items-center justify-center py-2 rounded-lg text-center text-sm font-medium ${scanType === "rapid" ? "bg-white" : "text-gray-500"}`}
          onClick={_handleRapidScoutClick}>
          <RapidScoutIcon fill={scanType === "rapid" ? "#000000" : "#787694"} />{" "}
          <span className="inline-block ml-2">Rapid Scout</span>
        </button>
        <button
          type="button"
          className={`flex-1 flex items-center justify-center py-2 rounded-lg text-center text-sm font-medium ${scanType === "full" ? "bg-white" : "text-gray-500"}`}
          onClick={_handleFullScoutClick}>
          <FullScoutIcon fill={scanType === "full" ? "#000000" : "#787694"} />{" "}
          <span className="inline-block ml-2">Full Scout</span>
        </button>
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldViewAnalysisScanType;
