import React from "react";

interface Props {
  value: "notifications" | "activity";
  onChange: (v: Props["value"]) => void;
}

/* =============================================================================
<NotificationsTab />
============================================================================= */
const NotificationsTab: React.FC<Props> = ({value, onChange}) => {
  const _handleNotificationsClick = () => {
    onChange("notifications");
  };

  const _handleActivityClick = () => {
    onChange("activity");
  };

  return (
    <div className="flex items-center mx-4 mt-3 mb-1 p-1 rounded-lg bg-[#EBEBEF]">
      <button
        type="button"
        className={`flex-1 py-2 rounded-lg text-center text-sm font-medium ${value === "notifications" ? "bg-white" : "text-gray-500"}`}
        onClick={_handleNotificationsClick}>
        Notifications
      </button>
      <button
        type="button"
        className={`flex-1 py-2 rounded-lg text-center text-sm font-medium ${value === "activity" ? "bg-white" : "text-gray-500"}`}
        onClick={_handleActivityClick}>
        Activity
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default NotificationsTab;
