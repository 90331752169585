import React from "react";

import UploadRawHeader from "../components/UploadRaw/UploadRawHeader";
import UploadRawFiles from "../components/UploadRaw/UploadRawFiles";
import UploadRawMap from "../components/UploadRaw/UploadRawMap";
import UploadRawDialogs from "../components/UploadRaw/UploadRawDialogs";

/* =============================================================================
<UploadRawScreen />
============================================================================= */
const UploadRawScreen: React.FC = () => {
  return (
    <div id="Upload-Raw" className="w-full h-full flex flex-col">
      <UploadRawHeader />
      <div className="flex flex-1 overflow-y-hidden">
        <UploadRawFiles />
        <UploadRawMap />
      </div>
      <UploadRawDialogs />
    </div>
  );
};

/* Export
============================================================================= */
export default UploadRawScreen;
