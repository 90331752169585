import React from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";

import cropTypes from "../../../static/cropTypes";
import {GET_FIELD} from "../../api/queries";
import {useAppSelector} from "../../../util/hooks";

import {selectSelectedField} from "../../redux/farmSlice";

/* =============================================================================
<FieldDetailsInfo />
============================================================================= */
const FieldDetailsInfo: React.FC = () => {
  const selectedField = useAppSelector(selectSelectedField, shallowEqual);
  const {data} = useQuery(GET_FIELD, {
    variables: {
      id: selectedField,
    },
  });

  const field = data?.field_season_shot_by_pk;

  if (field) {
    const cropType = cropTypes.find(ct => ct.value === field.cropName);
    const cropName = cropType ? cropType.label : field.cropName;

    return (
      <>
        <h2 className="text-3xl font-semibold text-center capitalize mt-6">{`${field.polygon.size.toFixed(1)} acres`}</h2>
        <h3 className="text-xl font-medium text-center capitalize mt-4">{`Field ${field.id}`}</h3>
        <div className="flex items-center justify-between mt-8 py-4 mx-4 border-y">
          <span className="capitalize text-sm font-medium">{`${field.name}-${cropName}`}</span>
          <span className="text-sm font-medium">{field.season}</span>
        </div>
      </>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldDetailsInfo;
