import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";
import {rawUploadCancelled} from "./uploadSlice";

interface UploadRawBlobsState {
  blobs: {
    [index: string]: File[];
  };
}

// Initial state

const initialState: UploadRawBlobsState = {
  blobs: {},
};

// Slice
const uploadRawBlobsSlice = createSlice({
  name: "uploadRawBlobs",
  initialState,
  reducers: {
    blobsAdded(state, action: PayloadAction<UploadRawBlobsState["blobs"]>) {
      state.blobs = action.payload;
    },
    blobsUpdated(state, action: PayloadAction<UploadRawBlobsState["blobs"]>) {
      state.blobs = {
        ...state.blobs,
        ...action.payload,
      };
    },
    blobsRemoved(state, action: PayloadAction<string[]>) {
      action.payload.forEach(file => {
        delete state.blobs[file];
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(rawUploadCancelled, state => {
      state.blobs = {};
    });
  },
});

// Actions
export const {blobsAdded, blobsUpdated, blobsRemoved} =
  uploadRawBlobsSlice.actions;

// Reducer
export default uploadRawBlobsSlice.reducer;

// Selectors

export const selectBlobs = (state: RootState) =>
  state.uploadRawBlobs?.blobs || {};

export const selectBlobsById = (state: RootState, {id}) =>
  selectBlobs(state)[id] || [];

export const selectBlobsTotal = (state: RootState) =>
  Object.values(selectBlobs(state))
    .map(files => files.length)
    .reduce((a, b) => a + b, 0);
