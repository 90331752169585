const sensorTypes = [
  {
    label: "Mavic3M (RGB)",
    value: "mavic3m_(rgb)",
    type: "rgb",
    sw: 17.4,
    fr: 12.29,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 77,
    enumSubValue: 2,
    payloadEnumValue: 68,
  },
  {
    label: "Mavic3M (MS)",
    value: "mavic3m_(ms)",
    type: "ms",
    sw: 5.2,
    fr: 4.34,
    imageWidth: 2592,
    imageHeight: 1944,
    enumValue: 77,
    enumSubValue: 2,
    payloadEnumValue: 68,
  },
  {
    label: "Matrice350 Zenmuse P1 (35mm)",
    value: "matrice350_zenmuse_p1_(35mm)",
    type: "ms",
    sw: 35.9,
    fr: 35,
    imageWidth: 8192,
    imageHeight: 5460,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Matrice350 Zenmuse P1 (24mm)",
    value: "matrice350_zenmuse_p1_(24mm)",
    type: "ms",
    sw: 35.9,
    fr: 24,
    imageWidth: 8192,
    imageHeight: 5460,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Matrice350 Zenmuse P1 (50mm)",
    value: "matrice350_zenmuse_p1_(50mm)",
    type: "ms",
    sw: 35.9,
    fr: 50,
    imageWidth: 8192,
    imageHeight: 5460,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Matrice350 Zenmuse H20 (W)",
    value: "matrice350_zenmuse_h20_(w)",
    type: "rgb",
    sw: 6.17,
    fr: 4.4,
    imageWidth: 4000,
    imageHeight: 3000,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Phantom 4 Advanced V2",
    value: "phantom_4_advanced_v2",
    type: "rgb",
    sw: 13.2,
    fr: 8.8,
    imageWidth: 5472,
    imageHeight: 3648,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Mavic 2 Pro",
    value: "mavic_2_pro",
    type: "rgb",
    sw: 13.2,
    fr: 10.26,
    imageWidth: 5472,
    imageHeight: 3648,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Micasense RedEdge-MX",
    value: "micasense_rededge-mx",
    type: "ms",
    sw: 4.8,
    fr: 5.5,
    imageWidth: 1280,
    imageHeight: 960,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Micasense RedEdge-P",
    value: "micasense_rededge-p",
    type: "ms",
    sw: 6.16,
    fr: 5.5,
    imageWidth: 2064,
    imageHeight: 1544,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "PhaseOne P5(35mm)",
    value: "phaseone_p5(35mm)",
    type: "rgb",
    sw: 53.4,
    fr: 35,
    imageWidth: 11608,
    imageHeight: 8708,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "PhaseOne P5(80mm)",
    value: "phaseone_p5(80mm)",
    type: "rgb",
    sw: 53.4,
    fr: 80,
    imageWidth: 11608,
    imageHeight: 8708,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Phantom 4 RTK",
    value: "dji_phantom_4_rtk",
    type: "rgb",
    sw: 13.2,
    fr: 8.8,
    imageWidth: 5472,
    imageHeight: 3648,
  },
  {
    label: "DJI Inspire 2 (X4S)",
    value: "dji_inspire_2_(x4s)",
    type: "rgb",
    sw: 13.2,
    fr: 8.8,
    imageWidth: 5472,
    imageHeight: 3078,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Inspire 2 (X5S-15mm)",
    value: "dji_inspire_2_(x5s-15mm)",
    type: "rgb",
    sw: 17.3,
    fr: 15,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Inspire 2 (X5S-14mm)",
    value: "dji_inspire_2_(x5s-14mm)",
    type: "rgb",
    sw: 17.3,
    fr: 14,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Inspire 2 (X5S- 45mm)",
    value: "dji_inspire_2_(x5s-_45mm)",
    type: "rgb",
    sw: 17.3,
    fr: 45,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Inspire 2 (X5S- 42mm)",
    value: "dji_inspire_2_(x5s-_42mm)",
    type: "rgb",
    sw: 17.3,
    fr: 42,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Inspire 2 (X5S- 12mm)",
    value: "dji_inspire_2_(x5s-_12mm)",
    type: "rgb",
    sw: 17.3,
    fr: 12,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Matrice 200 (X4S)",
    value: "dji_matrice_200_(x4s)",
    type: "rgb",
    sw: 13.2,
    fr: 8.8,
    imageWidth: 5472,
    imageHeight: 3078,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Matrice 300 RTK with Zenmuse L1 (RGB camera)",
    value: "dji_matrice_300_rtk_with_zenmuse_l1_(rgb_camera)",
    type: "rgb",
    sw: 13.2,
    fr: 8.8,
    imageWidth: 5472,
    imageHeight: 3648,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Matrice 350",
    value: "dji_matrice_350",
    type: "rgb",
    sw: 35.9,
    fr: 24,
    imageWidth: 9504,
    imageHeight: 6336,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "DJI Mavic 3 Enterprise",
    value: "dji_mavic_3_enterprise",
    type: "rgb",
    sw: 17.3,
    fr: 12.3,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Ebee ( Sensefly S.O.D.A)",
    value: "ebee_(_sensefly_s.o.d.a)",
    type: "rgb",
    sw: 12.75,
    fr: 10.5,
    imageWidth: 5280,
    imageHeight: 3956,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne QX1 + 15mm Voigtlander",
    value: "wingtraone_qx1_+_15mm_voigtlander",
    type: "rgb",
    sw: 23.2,
    fr: 15,
    imageWidth: 5456,
    imageHeight: 3632,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne RX1",
    value: "wingtraone_rx1",
    type: "rgb",
    sw: 35.9,
    fr: 35,
    imageWidth: 8000,
    imageHeight: 5320,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne RGB 61",
    value: "wingtraone_rgb_61",
    type: "rgb",
    sw: 35.9,
    fr: 24,
    imageWidth: 9504,
    imageHeight: 6336,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne (Sony RX1R2)",
    value: "wingtraone_(sony_rx1r2)",
    type: "rgb",
    sw: 35.9,
    fr: 35,
    imageWidth: 7952,
    imageHeight: 5304,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne (Sony A6100 20 mm)",
    value: "wingtraone_(sony_a6100_20_mm)",
    type: "rgb",
    sw: 23.5,
    fr: 20,
    imageWidth: 6000,
    imageHeight: 4000,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne (Sony A6100 12 mm)",
    value: "wingtraone_(sony_a6100_12_mm)",
    type: "rgb",
    sw: 23.5,
    fr: 12,
    imageWidth: 6000,
    imageHeight: 4000,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne (Sony QX1 20 mm)",
    value: "wingtraone_(sony_qx1_20_mm)",
    type: "rgb",
    sw: 23.2,
    fr: 20,
    imageWidth: 5456,
    imageHeight: 3632,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "WingtraOne (Sony QX1 15 mm)",
    value: "wingtraone_(sony_qx1_15_mm)",
    type: "rgb",
    sw: 23.2,
    fr: 15,
    imageWidth: 5456,
    imageHeight: 3632,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Quantum with Sony RX1RII",
    value: "quantum_with_sony_rx1rii",
    type: "rgb",
    sw: 35.9,
    fr: 35,
    imageWidth: 7952,
    imageHeight: 5304,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Quantum with Sony UMC 16mm",
    value: "quantum_with_sony_umc_16mm",
    type: "rgb",
    sw: 23.2,
    fr: 16,
    imageWidth: 5456,
    imageHeight: 3632,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
  {
    label: "Quantum with Sony UMC 20mm",
    value: "quantum_with_sony_umc_20mm",
    type: "rgb",
    sw: 23.2,
    fr: 20,
    imageWidth: 5456,
    imageHeight: 3632,
    enumValue: 89,
    enumSubValue: 2,
    payloadEnumValue: 50,
  },
];

export default sensorTypes;
