import React from "react";

import TrashIcon from "../../../../assets/icons/edit-trash.svg";
import FocusIcon from "../../../../assets/icons/edit-focus.svg";
import MapLineIcon from "../../../../assets/icons/map-line.svg";
import MapPointIcon from "../../../../assets/icons/map-point.svg";
import MapPolygonIcon from "../../../../assets/icons/map-polygon.svg";

interface Props {
  type: string;
  onDeleteClick: () => void;
}

/* =============================================================================
<FieldViewAnnotationsEditorFormHeader />
============================================================================= */
const FieldViewAnnotationsEditorFormHeader: React.FC<Props> = ({
  type,
  onDeleteClick,
}) => {
  return (
    <div className="flex items-center">
      {type === "Polygon" && <MapPolygonIcon width={20} height={20} />}
      {type === "LineString" && <MapLineIcon width={20} height={20} />}
      {type === "Point" && <MapPointIcon width={20} height={20} />}
      <h1 className="flex-1 px-5 font-medium text-lg">
        {type === "LineString" ? "Line" : type}
      </h1>
      <div className="w-5 h-5 rounded-full bg-orange-500" />
      <button type="button" className="ml-4">
        <FocusIcon width={20} height={20} />
      </button>
      <button type="button" className="ml-4" onClick={onDeleteClick}>
        <TrashIcon width={20} height={20} />
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default FieldViewAnnotationsEditorFormHeader;
