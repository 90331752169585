import {gql} from "../../__generated__/gql";

export const ADD_FARM = gql(/* GraphQL */ `
  mutation InsertFarm($input: farms_insert_input!) {
    insert_farms_one(object: $input) {
      id
    }
  }
`);

export const ADD_FIELD = gql(/* GraphQL */ `
  mutation InsertFieldSeasonShot($input: field_season_shot_insert_input!) {
    insert_field_season_shot_one(object: $input) {
      id
      fieldId
    }
  }
`);
