import React, {
  useState,
  useCallback,
  type FormEvent,
  type ChangeEvent,
} from "react";
import {Helmet} from "react-helmet";
import {useLazyQuery} from "@apollo/client";

import {Button, ErrorMessage, TextInput} from "../../common";
import AppLogo from "../../assets/images/app-logo.svg";
import LoginArtImage from "../../assets/images/login-art.webp";
import {errorMessage} from "../../util/functions";
import {useAppDispatch} from "../../util/hooks";

import {LOGIN} from "../api/queries";
import {loggedIn} from "../redux/authSlice";

/* =============================================================================
<LoginScreen />
============================================================================= */
const LoginScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, {loading, error}] = useLazyQuery(LOGIN, {
    fetchPolicy: "no-cache",
  });

  const _handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [],
  );

  const _handlePasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [],
  );

  const _handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (email && password && !loading) {
      const response = await login({
        variables: {
          input: {
            username: email,
            password,
          },
        },
      });

      if (response.data) {
        localStorage.setItem(
          "@auth/token",
          `${response.data.api_login_user?.token}`,
        );
        dispatch(loggedIn({id: response.data?.api_login_user?.id}));
      }
    }
  };

  return (
    <div id="login" className="h-full flex">
      <Helmet>
        <title>Login | Lense AI</title>
      </Helmet>
      <img src={LoginArtImage} className="hidden lg:inline" />
      <div className="h-full flex-1 flex flex-col items-center py-14 px-10 overflow-y-auto sm:py-32 sm:px-14">
        <div>
          <AppLogo width={159} height={29} fill="#FF7E1D" />
        </div>
        <h1 className="text-3xl font-semibold mt-20 text-center">LENSE AI</h1>
        <p className="mt-7 text-center">
          Ultimate analysis tool to navigate your crop land
        </p>
        <form
          className="w-full mt-12 md:w-4/6 lg:w-80 xl:w-96"
          onSubmit={_handleSubmit}>
          <div className="grid grid-cols-1 gap-y-7">
            <TextInput
              id="email"
              _size="lg"
              type="email"
              name="email"
              label="Email"
              value={email}
              autoComplete="email"
              placeholder="e.g. john@example.com"
              onChange={_handleEmailChange}
            />
            <TextInput
              id="password"
              _size="lg"
              type="password"
              name="password"
              label="Password"
              value={password}
              autoComplete="organization"
              placeholder="Enter Password"
              onChange={_handlePasswordChange}
            />
            <Button type="submit" className="mt-2.5" loading={loading}>
              Login
            </Button>
            <div className="text-center">
              <span className="opacity-70">Don’t have an account?</span>{" "}
              <a
                href="https://farmevo.ai/ContactUs"
                className="text-orange-500 font-semibold">
                Sign Up
              </a>
            </div>
            {error && <ErrorMessage message={errorMessage(error.message)} />}
          </div>
        </form>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default LoginScreen;
