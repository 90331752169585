import React, {useCallback} from "react";
import {
  VariableSizeList as List,
  type ListItemKeySelector,
  type ListChildComponentProps,
} from "react-window";

import NotificationsListItem from "./NotificationsListItem";
import NotificationsListSectionHeader from "./NotificationsListSectionHeader";

import {useAppSelector, useWindowDimensions} from "../../../../util/hooks";

import {selectNotificationsWithDates} from "../../../redux/notificationsSlice";
import NotificationsListEmpty from "./NotificationsListEmpty";

interface ItemData {
  type: "header" | "item";
  data: string;
}

/* =============================================================================
<NotificationsList />
============================================================================= */
const NotificationsList: React.FC = () => {
  const {height: windowHeight} = useWindowDimensions();

  const notifications = useAppSelector(
    selectNotificationsWithDates,
    (a, b) => a.length === b.length,
  );

  const getItemSize = useCallback(
    (index: number) => {
      if (notifications[index].type === "header") {
        return 40;
      }

      return 104;
    },
    [notifications],
  );

  if (!notifications.length) {
    return <NotificationsListEmpty />;
  }

  return (
    <List
      width={340}
      height={windowHeight * 0.88}
      className="mt-2"
      itemData={notifications}
      itemCount={notifications.length}
      itemKey={keyExtractor}
      itemSize={getItemSize}>
      {renderRow}
    </List>
  );
};

const keyExtractor: ListItemKeySelector<ItemData[]> = (index, data) =>
  data[index].data;

const renderRow: React.FC<ListChildComponentProps<ItemData[]>> = ({
  data,
  index,
  style,
}) => {
  const item = data[index];

  if (item.type === "header") {
    return <NotificationsListSectionHeader title={item.data} style={style} />;
  }

  return <NotificationsListItem id={item.data} style={style} />;
};

/* Export
============================================================================= */
export default NotificationsList;
