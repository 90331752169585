import React, {type PropsWithChildren} from "react";
import {shallowEqual} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

import {useAppSelector} from "../util/hooks";

import {selectIsAuthenticated} from "../auth/redux/authSlice";

/* =============================================================================
<RequireAuth />
============================================================================= */
const RequireAuth: React.FC<PropsWithChildren> = ({children}) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated, shallowEqual);

  if (!isAuthenticated) {
    // Redirect them to the / page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{from: location}} replace />;
  }

  return children;
};

/* Export
============================================================================= */
export default RequireAuth;
