import React from "react";
import {shallowEqual} from "react-redux";

import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {
  mapTypeSelected,
  satelliteTypeSelected,
  selectType,
} from "../redux/mapSlice";

/* =============================================================================
<MapTypeSelect />
============================================================================= */
const MapTypeSelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(selectType, shallowEqual);

  const _handleMapClick = () => {
    dispatch(mapTypeSelected());
  };

  const _handleSatelliteClick = () => {
    dispatch(satelliteTypeSelected());
  };

  return (
    <div
      className="absolute bottom-5 left-6 inline-flex rounded z-10 app-shadow"
      role="group">
      <button
        type="button"
        className={`px-3 py-1 text-sm ${type === "map" ? "text-gray-900 font-medium" : "text-gray-600 font-normal"} bg-white border border-gray-200 rounded-s-md hover:bg-gray-100 focus:z-10 focus:ring-2`}
        onClick={_handleMapClick}>
        Map
      </button>
      <button
        type="button"
        className={`px-3 py-1 text-sm ${type === "satellite" ? "text-gray-900 font-medium" : "text-gray-600 font-normal"} bg-white border border-gray-200 rounded-e-md hover:bg-gray-100 focus:z-10 focus:ring-2`}
        onClick={_handleSatelliteClick}>
        Satellite
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default MapTypeSelect;
