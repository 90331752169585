import {useState, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {type TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

import type {RootState, AppDispatch} from "../redux/helpers";

type DispatchFunc = () => AppDispatch;

export const useAppDispatch: DispatchFunc = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowDimensions;
};

export const useRouteQuery = () => {
  const {search} = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
