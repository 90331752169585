import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";
import {mosaicUploadCancelled} from "./uploadSlice";

interface UploadMosaicBlobState {
  blob: File | null;
}

// Initial state

const initialState: UploadMosaicBlobState = {
  blob: null,
};

// Slice
const uploadMosaicBlobSlice = createSlice({
  name: "uploadMosaicBlob",
  initialState,
  reducers: {
    blobAdded(state, action: PayloadAction<File>) {
      state.blob = action.payload;
    },
    blobRemoved(state) {
      state.blob = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(mosaicUploadCancelled, state => {
      state.blob = null;
    });
  },
});

// Actions
export const {blobAdded, blobRemoved} = uploadMosaicBlobSlice.actions;

// Reducer
export default uploadMosaicBlobSlice.reducer;

// Selectors

export const selectBlob = (state: RootState) => state.uploadMosaicBlob?.blob;
