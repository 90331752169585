import React, {type DragEvent} from "react";
import shp from "shpjs";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";
import toGeoJSON from "@mapbox/togeojson";
import {strFromU8, unzipSync} from "fflate";

import Header from "../components/Header";
import MapView from "../components/MapView";
import ActionView from "../components/ActionView";
import MapTypeSelect from "../components/MapTypeSelect";
import WelcomeDialog from "../components/WelcomeDialog";

import {useAppDispatch} from "../../util/hooks";

import {polygonAdded} from "../redux/addFieldSlice";
import {addFieldSelected} from "../redux/farmSlice";

/* =============================================================================
<HomeScreen />
============================================================================= */
const HomeScreen: React.FC = () => {
  const dispatch = useAppDispatch();

  const _handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    try {
      const files = event.dataTransfer.items
        ? [...event.dataTransfer.items].map(item => item.getAsFile())
        : [...event.dataTransfer.files];

      const kmlFile = files.find(file => file?.name.endsWith(".kml"));

      if (kmlFile) {
        await _handleKmlFile(kmlFile);
        return;
      }

      const kmzFile = files.find(file => file?.name.endsWith(".kmz"));

      if (kmzFile) {
        await _handleKmzFile(kmzFile);
        return;
      }

      const shpFile = files.find(file => file?.name.endsWith(".zip"));

      if (shpFile) {
        await _handleShpFile(shpFile);
        return;
      }

      throw new Error("Unsupported file");
    } catch (e) {
      toast("Please select a valid .kml, .kmz, or .zip file");
    }
  };

  const _handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const _handleKmlFile = async (file: File) => {
    const kmlText = await file.text();

    const kmlDocument = new DOMParser().parseFromString(
      kmlText.replace("xsi:", "xmlns:"), // Remove the "xsi:" prefix
      "application/xml",
    );

    const geoJSON = toGeoJSON.kml(kmlDocument);

    const polygon = geoJSON.features.find(
      feature => feature.geometry.type === "Polygon",
    )?.geometry;

    if (!polygon) {
      throw new Error("Invalid geometry");
    }

    dispatch(polygonAdded(polygon));
    dispatch(addFieldSelected());
  };

  const _handleKmzFile = async (file: File) => {
    const kmlText = strFromU8(
      Object.values(unzipSync(new Uint8Array(await file.arrayBuffer())))[0],
    );

    const kmlDocument = new DOMParser().parseFromString(
      kmlText.replace("xsi:", "xmlns:"), // Remove the "xsi:" prefix
      "application/xml",
    );

    const geoJSON = toGeoJSON.kml(kmlDocument);

    const polygon = geoJSON.features.find(
      feature => feature.geometry.type === "Polygon",
    )?.geometry;

    if (!polygon) {
      throw new Error("Invalid geometry");
    }

    dispatch(polygonAdded(polygon));
    dispatch(addFieldSelected());
  };

  const _handleShpFile = async (file: File) => {
    let geoJSON = await shp(await file.arrayBuffer());

    if (Array.isArray(geoJSON)) {
      geoJSON = geoJSON[0];
    }

    const polygon = geoJSON.features.find(
      feature => feature.geometry.type === "Polygon",
    )?.geometry;

    if (!polygon) {
      throw new Error("Invalid geometry");
    }

    dispatch(polygonAdded(polygon));
    dispatch(addFieldSelected());
  };

  return (
    <div
      id="home"
      className="h-full relative"
      onDrop={_handleDrop}
      onDragOver={_handleDragOver}>
      <Helmet>
        <title>Home | Lense AI</title>
      </Helmet>
      <Header />
      <MapView />
      <ActionView />
      <MapTypeSelect />
      <WelcomeDialog />
    </div>
  );
};

/* Export
============================================================================= */
export default HomeScreen;
