import React, {useCallback} from "react";
import {shallowEqual} from "react-redux";
import {PlayIcon, PauseIcon} from "@heroicons/react/24/outline";

import {formatBytes} from "../../../util/functions";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {
  selectStatus,
  mosaicUploadPaused,
  mosaicUploadStarted,
} from "../../redux/uploadSlice";
import {
  selectTotalBytes,
  selectUploadedBytes,
} from "../../redux/uploadMosaicPartsSlice";

/* =============================================================================
<UploadMosaicProgress />
============================================================================= */
const UploadMosaicProgress: React.FC = () => {
  const dispatch = useAppDispatch();

  const status = useAppSelector(selectStatus, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);
  const uploadedBytes = useAppSelector(selectUploadedBytes, shallowEqual);

  const progress =
    uploadedBytes && totalBytes ? (uploadedBytes / totalBytes) * 100 : 0;
  const showPauseBtn = status === "mosaic-uploading";
  const showResumeBtn = status === "mosaic-upload-paused";

  const _handlePauseClick = useCallback(async () => {
    dispatch(mosaicUploadPaused());
  }, [dispatch]);

  const _handleResumeClick = useCallback(async () => {
    dispatch(mosaicUploadStarted());
  }, [dispatch]);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-4 py-4">
        <h2 className="text-sm font-medium">
          {`Processing in progress (${progress.toFixed()}% of ${formatBytes(totalBytes)})`}
        </h2>
        {showPauseBtn && (
          <button type="button" onClick={_handlePauseClick}>
            <PauseIcon className="h-5 w-5 text-black" aria-hidden="true" />
          </button>
        )}
        {showResumeBtn && (
          <button type="button" onClick={_handleResumeClick}>
            <PlayIcon className="h-5 w-5 text-black" aria-hidden="true" />
          </button>
        )}
      </div>
      <div className="w-full bg-gray-200 h-1.5 ">
        <div
          style={{width: `${progress.toFixed()}%`}}
          className="bg-[#FF9437] h-1.5"
        />
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default UploadMosaicProgress;
