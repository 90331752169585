import {gql} from "../../__generated__/gql";

export const LOGIN = gql(/* GraphQL */ `
  query Login($input: api_login_user_input) {
    api_login_user(input: $input) {
      token
      id
    }
  }
`);
