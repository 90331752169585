import React, {useCallback, useState} from "react";
import {toast} from "react-toastify";
import {ClipLoader} from "react-spinners";
import {shallowEqual} from "react-redux";
import {PlayIcon, PauseIcon, TrashIcon} from "@heroicons/react/24/outline";

import {formatBytes} from "../../../../util/functions";
import {useAppDispatch, useAppSelector} from "../../../../util/hooks";

import {
  deleteRawFiles,
  selectStatus,
  rawUploadPaused,
  rawUploadStarted,
} from "../../../redux/uploadSlice";
import {
  selectTotalBytes,
  selectUploadedBytes,
  selectSelectedFiles,
} from "../../../redux/uploadRawFilesSlice";

/* =============================================================================
<UploadRawFilesHeader />
============================================================================= */
const UploadRawFilesHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const [deleting, setDeleting] = useState(false);

  const status = useAppSelector(selectStatus, shallowEqual);
  const totalBytes = useAppSelector(selectTotalBytes, shallowEqual);
  const uploadedBytes = useAppSelector(selectUploadedBytes, shallowEqual);
  const selectedFiles = useAppSelector(
    selectSelectedFiles,
    (a, b) => a.length === b.length,
  );

  const progress =
    uploadedBytes && totalBytes ? (uploadedBytes / totalBytes) * 100 : 0;
  const showDeleteBtn = selectedFiles.length > 0 && !deleting;
  const showPauseBtn =
    status === "raw-uploading" && !showDeleteBtn && !deleting;
  const showResumeBtn =
    status === "raw-upload-paused" && !showDeleteBtn && !deleting;

  const _handleDeleteClick = useCallback(async () => {
    setDeleting(true);

    const result = await dispatch(deleteRawFiles(selectedFiles));

    setDeleting(false);

    if (deleteRawFiles.rejected.match(result)) {
      toast(result.error.message);
    }
  }, [selectedFiles, dispatch]);

  const _handlePauseClick = useCallback(async () => {
    dispatch(rawUploadPaused());
  }, [dispatch]);

  const _handleResumeClick = useCallback(async () => {
    dispatch(rawUploadStarted());
  }, [dispatch]);

  return (
    <div className="flex-none">
      <div className="flex items-center justify-between px-4 py-4">
        <h2 className="text-sm font-medium">
          {`Processing in progress (${progress.toFixed()}% of ${formatBytes(totalBytes)})`}
        </h2>
        {showDeleteBtn && (
          <button type="button" onClick={_handleDeleteClick}>
            <TrashIcon className="h-5 w-5 text-black" aria-hidden="true" />
          </button>
        )}
        {showPauseBtn && (
          <button type="button" onClick={_handlePauseClick}>
            <PauseIcon className="h-5 w-5 text-black" aria-hidden="true" />
          </button>
        )}
        {showResumeBtn && (
          <button type="button" onClick={_handleResumeClick}>
            <PlayIcon className="h-5 w-5 text-black" aria-hidden="true" />
          </button>
        )}
        {deleting && <ClipLoader size={16} />}
      </div>
      <div className="w-full bg-gray-200 h-1.5 ">
        <div
          style={{width: `${progress.toFixed()}%`}}
          className="bg-[#FF9437] h-1.5"
        />
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default UploadRawFilesHeader;
