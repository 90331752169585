import React from "react";

interface Props {
  title: string;
  style: React.CSSProperties;
}

/* =============================================================================
<NotificationsListSectionHeader />
============================================================================= */
const NotificationsListSectionHeader: React.FC<Props> = ({title, style}) => {
  return (
    <li className="flex flex-col justify-end px-5 pb-4" style={style}>
      <div className="flex items-center">
        <div className="h-px flex-1 bg-[#EBEBEF]" />
        <h4 className="text-xs text-gray-500 font-semibold mx-4">{title}</h4>
        <div className="h-px flex-1 bg-[#EBEBEF]" />
      </div>
    </li>
  );
};

/* Export
============================================================================= */
export default NotificationsListSectionHeader;
